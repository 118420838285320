import { Component, OnInit } from '@angular/core';
import * as jQuery from 'jquery';
import {LocalStorageService,SessionStorageService,LocalStorage,SessionStorage} from 'angular-web-storage';
declare var $: any;
@Component({
  selector: 'app-admin-inner',
  templateUrl: './admin-inner.component.html',
  styleUrls: ['./admin-inner.component.css']
})
export class AdminInnerComponent implements OnInit {

  constructor(private session: SessionStorageService,) { }
	authData: any = {};
	role_id:number;
  ngOnInit() {
	  this.authData = this.session.get('authData');
	 // console.log(this.authData);
		this.role_id = this.authData.role;
	  
	 $("#sidebar").mCustomScrollbar({
		theme: "minimal"
	});	
  }

}
