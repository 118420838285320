import { Component, OnInit } from '@angular/core';
import { PetrolPumpServicesService } from '../../super-admin-service/petrol-pump-services.service';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { Router} from '@angular/router';
declare var $: any;
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { MasterServicesService } from '../../admin-services/master-services.service';


@Component({
  selector: 'app-owner-list',
  templateUrl: './owner-list.component.html',
  styleUrls: ['./owner-list.component.css']
})
export class OwnerListComponent implements OnInit {

  constructor(private session: SessionStorageService, private petrolPumpService: PetrolPumpServicesService, private router:Router,private masterService: MasterServicesService) { }
	addOwnerForm:FormGroup;
	
  countryDropdownList : any[] = [];
	stateDropdownList : any[] = [];
  cityDropdownList : any[] = [];
  currentPage:number;
	addOwner : any={};
	editOwner:any={};
	totalCat: number;
	itemList: any[] = [];
	OwnerList: any[] = [];	
	editOwnerList:any={};
  searchOwnerData : any={};
  success_message : string;
  error_message : string;
  name : string;
  index:number;
  serachOwnerForm= new FormGroup({
		name : new FormControl(' '),
	});

	
	successAddOwnerListData:{
		Petrolpump:{
			id:any,
			reg_no:any,
			name:string,
			owner_name:string,
			mobile_no:number,
			email:any
		}		
	}

  // Edit Petrol Pump Form data
	editOwnerForm= new FormGroup({
		name : new FormControl(' ',Validators.required),
				gstn_no : new FormControl(' ',Validators.required),
				pan_card : new FormControl(' '),
				pin : new FormControl(' ',Validators.required),
				country_id : new FormControl(' ',Validators.required),
				state_id : new FormControl(' ',Validators.required),
				city_id : new FormControl(' ',Validators.required),
				address : new FormControl(' ',Validators.required),
				mobile_no : new FormControl(' ',[Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
				email : new FormControl(' ',[Validators.required,Validators.email]),
				gender : new FormControl(' ',Validators.required),
				alt_mobile_no : new FormControl(' '),
				landline_no: new FormControl(' ')
  });
  gender = [
	
		{id:2, value: 'male'},
		{id:3, value: 'female'}
 ];
  ngOnInit() {
    
			this.addOwnerForm = new FormGroup({
				// Compulsary Elements
				owner_name : new FormControl(' ',Validators.required),
				gstn_no : new FormControl(' ',Validators.required),
				pan_card : new FormControl(' '),
				pin : new FormControl(' ',Validators.required),
				country_id : new FormControl(' ',Validators.required),
				state_id : new FormControl(' ',Validators.required),
				city_id : new FormControl(' ',Validators.required),
				address : new FormControl(' ',Validators.required),
				gender : new FormControl(1,Validators.required),
				mobile_no : new FormControl(' ',[Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
				email : new FormControl(' ',[Validators.required,Validators.email]),
				alt_mobile_no : new FormControl(' '),
				landline_no: new FormControl(' ')
			});
		// function to get countries
		this.petrolPumpService.getCountries()
    .subscribe(countrylistdata =>{this.countryDropdownList=countrylistdata.data});
    console.log(this.countryDropdownList);
		
		
		// Function to get Petrol Pump List
		this.pageWiseData(1);
  }

// Function to add petrol pump
addNewOwner()
{
  this.name = this.addOwner.owner_name;
  //console.log(this.addPetrolPump);
  //return;
  this.petrolPumpService.addOwnerData(this.addOwner).subscribe(data=>{
		console.log(data);
    if(data.status==201)
    {
      this.success_message='Owner Registered Successfully!';

      $('#addOwnerModal').modal('hide');
      this.pageWiseData(1)
      this.addOwnerForm.reset(); 
      
    }else{
      this.error_message='Owner cannot be Register!';
    }
  });
}

	// Get pump data( single row )
	getOwnerData(id,index)
	{
		this.index=index;
		this.petrolPumpService.getEditOwnerData(id).subscribe(data=>{
			this.editOwner.id=data.data.User.id;
			this.editOwner.owner_name=data.data.User.name;
			this.editOwner.gstn_no=data.data.User.gstin;						
			this.editOwner.pan_card=data.data.User.pan;
			this.editOwner.pin=data.data.User.pin;						
			this.editOwner.address=data.data.User.address;						
			this.editOwner.mobile_no=data.data.User.mobile_no;		
			this.editOwner.alt_mobile_no=data.data.User.alt_mobile_no;		
			this.editOwner.email=data.data.User.email;
			this.editOwner.gender=data.data.User.gender;
						
				
			
			this.editOwner.country_id=data.data.State.Country.id;
			this.petrolPumpService.getStates(this.editOwner.country_id)
			.subscribe(statelistdata =>{this.stateDropdownList=statelistdata.data});
			
			this.editOwner.state_id=data.data.User.state;
			this.petrolPumpService.getCities(this.editOwner.state_id)
			.subscribe(citylistdata =>{this.cityDropdownList=citylistdata.data});
			
			this.editOwner.city_id=data.data.User.city;		
			$('#editOwnerModal').modal('show');	
		});
	}




// Edit petrol pump data 
editOwnerListData(edit){
	edit.submitted=false;
	console.log(this.editOwner);
	this.petrolPumpService.editOwnerData(this.editOwner).subscribe(data=>{
		//console.log(data.status);
		if(data.status==200)
		{
			this.success_message='Owner has been Editted Successfully';
        $('#editOwnerModal').modal('hide');
        this.pageWiseData(1);
        this.editOwnerForm.reset();  
			//alert("success");
		
			}
			else{
        this.error_message='Owner could not be Editted';
      }
		});
}

// Function to delete petrol pump Record
deleteOwnerData(id, index) {
	if (confirm("Are you sure to delete this Item ")) {
		this.index = index;

		this.masterService.deleteDataByModel(id, 'User').subscribe(data => {
			if (data.status == true) {
				this.OwnerList.splice(this.index, 1);
				this.success_message = "Owner deleted sucessfully";

			} else if(data.status==403)
			{
					this.session.remove('authData');
					this.router.navigate(['']);
			}else {
				this.error_message = "Owner could not be deleted.";
			}
		});

		//this.editname=this.editCategoryData.name;	
	}
}
  // Function to get States
	onSelectCountry(){
		this.petrolPumpService.getStates(this.addOwner.country_id)
	.subscribe(statelistdata =>{this.stateDropdownList=statelistdata.data});
	}

	// Function to get Cities
	onSelectState(){
		this.petrolPumpService.getCities(this.addOwner.state_id)
	.subscribe(citylistdata =>{this.cityDropdownList=citylistdata.data});
	}

	// Function to Search Petrol Pump list
	searchOwner(){
	
		this.pageWiseData(1);
	} 

	// Reset Search Data
	resetSearchOwner(){
		this.searchOwnerData={};
		this.pageWiseData(1);
	} 
  pageWiseData(pageno){
		this.currentPage=pageno;
		this.petrolPumpService.getOwnerListData(pageno,this.searchOwnerData)
		.subscribe(alldata =>{
			this.OwnerList=alldata.data,
			//console.log(this.OwnerList);
			this.totalCat=alldata.count
		});
		
	} 
}
