import { Component, OnInit } from '@angular/core';
import {AccountService} from '../../../admin-services/account.service';
import { CommonsService } from '../../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
import { DatePipe } from '@angular/common';
// Custoem Function
import { OnCreatedDirective } from '../../../shared/on-created.directive';
import {Router} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-bank-reconciliation',
  templateUrl: './bank-reconciliation.component.html',
  styleUrls: ['./bank-reconciliation.component.css']
})
export class BankReconciliationComponent implements OnInit {

  constructor(private accountService: AccountService, private session:SessionStorageService,private commonsServices:CommonsService, private router: Router) { }
  

	  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  
	pipe: any;
	authData:any={};
	serachReportData:any={};	
	chequeClearedData:any={};	
	ledgerAutoSugestList: any[] = [];
	reportData: any[] = [];
	petro_id:number;
	ledger_is_debit:number;
	ledger_is_credit:number;
	ledger_opening:number;
	ledger_name:string;
	pump_name:number;
	pump_address:string;
	pump_mobile_no:number;
	row_total:number;
	gt_dr:number;
	gt_cr:number;
	closing_balance:number;
	not_reflect_balace:number;
	balance_as_bank:number;
	book_balace:number;
	load_more_status:boolean;
	show_loading_image:boolean;
	row_total_array:any={};
	config:any;
	success_message:string;
	error_message:string;
	error_message_check:string;
	is_bounce:boolean;
	i:number;
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	searchForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		tmp_ledger_id : new FormControl(' ',Validators.required),
		cheque_type : new FormControl(' ',Validators.required)
		
	});	

	chequeClearedForm= new FormGroup({
		detail_id : new FormControl(' ',Validators.required),
		cheque_no : new FormControl(' ',Validators.required),
		amount : new FormControl(' ',Validators.required),
		cleared_date : new FormControl(' ',Validators.required),
		is_bounced : new FormControl(' '),
		bounce_charges : new FormControl(' '),
		bounce_charges_cust : new FormControl(' '),
		customer_id : new FormControl(' ')
		
	});		

  ngOnInit() {	  
  
  // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		this.is_bounce=false; 
		this.closing_balance=0;
		this.load_more_status=true;
		this.show_loading_image=false;
		this.ledger_is_debit=constDefault.DEBIT_LEDGER;
		this.ledger_is_credit=constDefault.CREDIT_LEDGER;
		
		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		//console.log(this.authData);
		this.pump_name=this.authData.petro_name;
		this.pump_mobile_no=this.authData.petro_phone_no;
		this.pump_address=this.authData.petro_address;
		this.serachReportData.from_date=new Date(Date.now());
		this.serachReportData.to_date=new Date(Date.now());
		this.chequeClearedData.cleared_date=new Date(Date.now());
		this.serachReportData.cheque_type=" ";
  }
  
  // Auto Complete Ledger
    public filterItemList(value:any):void {	  
	   this.commonsServices.getLedgerByNameOrGroupId(value, this.petro_id,constDefault.BANK_ACCOUNTS_GROUP)
		.subscribe(alldata => {
		this.ledgerAutoSugestList=Object.entries(alldata.data).map(([value, label]) => ({value,label}));
		});
  }
  
  //Get Report Data
	getbankData() {
		
		this.show_loading_image=true;
		this.load_more_status=true;
		this.closing_balance=0;
		this.gt_dr=0;
		this.gt_cr=0;
		this.pipe = new DatePipe('en-US'); 
		this.serachReportData.from_date= this.pipe.transform(this.serachReportData.from_date, 'yyyy-MM-dd');
		this.serachReportData.to_date= this.pipe.transform(this.serachReportData.to_date, 'yyyy-MM-dd');
		this.serachReportData.ledger_id=this.serachReportData.tmp_ledger_id.value;
		this.serachReportData.cheque_type=this.serachReportData.cheque_type;
		this.accountService.getBankDataForReconciliation(this.petro_id,this.serachReportData)
	  	.subscribe(bankListData => {
	  	// console.log(purchaseListData);
	   	if(bankListData.status==200)
		{
			this.reportData=bankListData.data;
			
			this.ledger_opening=bankListData.actual_opening;
			this.book_balace=bankListData.search.book_balace;
			this.not_reflect_balace=bankListData.search.not_reflect_balace;
			this.balance_as_bank=bankListData.search.balance_as_bank;
		}else{
			this.reportData=[];
			this.show_loading_image=false;
			this.ledger_opening=bankListData.actual_opening;
			this.closing_balance=bankListData.actual_opening;
			this.book_balace=bankListData.search.book_balace;
			this.not_reflect_balace=bankListData.search.not_reflect_balace;
			this.balance_as_bank=bankListData.search.balance_as_bank;
		}	 
		this.load_more_status=false;
		this.show_loading_image=false;  
	  });
	}
	
	// Get LoadMore Ledger Data
	getBankLoadMoreData(last_id:number) {
		
	this.serachReportData.ledger_id=this.serachReportData.tmp_ledger_id.value;
	this.serachReportData.cheque_type=this.serachReportData.cheque_type;
	 this.accountService.getBankLoadMoreData(this.petro_id,last_id,this.serachReportData)
	  .subscribe(loadmoreData => {
		  
		  if(loadmoreData.status==200)
		  {
			 // console.log(this.reportDataDummy);
			 // console.log(loadmoreData.data);
			 this.reportData=this.reportData.concat(loadmoreData.data);
			 
			  this.load_more_status=true;
			 
		  }else{
			    this.load_more_status=false;
			    this.show_loading_image=false;
				
		  }
	   //console.log(purchaseListData);
	   //this.reportData=purchaseListData.data;
	   //this.ledger_opening=purchaseListData.opening;
	  });
	}
	// Search Bank data
	searchBankData(){

	  this.ledger_name=this.serachReportData.tmp_ledger_id.label;
	  this.getbankData();
  }
  resetSearchLedgerReport(){
	  this.serachReportData.tmp_ledger_id='';
	  this.serachReportData.cheque_type='';
	    this.serachReportData.from_date=new Date(Date.now());
	  this.serachReportData.to_date=new Date(Date.now());
	  this.reportData=[];
  }
  /*
  Total Calculation
Amit  
  */
  getTotal(amount,type,opeing,i,last,last_id){

		if(i==0)
		{
			  this.row_total=opeing;
		}else{
			 this.row_total=this.row_total_array[i-1];
		}
	 
	  if(type==this.ledger_is_debit)	 
	  {
	
		this.gt_dr=Number(this.gt_dr)+Number(amount);
		 //this.row_total= Number(this.row_total)+Number(amount);  
		 this.row_total_array[i]= Number(this.row_total)+Number(amount);  
	  }else{
				this.gt_cr=Number(this.gt_cr)+Number(amount);
		  		 //this.row_total= Number(this.row_total)-Number(amount);  
		  		this.row_total_array[i]= Number(this.row_total)-Number(amount);  
	  }
	 if(last==true && this.load_more_status==true)
	 {
		this.closing_balance=this.row_total_array[i];
		//this.getBankLoadMoreData(last_id);
	 } 
		
	 
  }
  
  /*
  Amit Sahu
  Bank Reconcliation 
  
  */
 
  concliationArr :any[]= [];

  onClickRecocliation(event,id,i){
	 
	  if(event.target.checked)
	  {
		
		 this.concliationArr[i]={'id':id,'status':1};
		
	  }else{
		    
		  this.concliationArr[i]={'id':id,'status':0};
	
		 // this.concliationArr = this.concliationArr.filter(word => word=id);
	  }
	
  }


	reconcliationVoucher(){
		this.show_loading_image=true;
	//	this.concliationArr = this.concliationArr.filter(word => word.length >0);
	//  console.log(this.concliationArr);
		 this.accountService.bankReconcilation(this.concliationArr)
	  .subscribe(success => {
		  
		  if(success.status==200)
		  {
			 this.success_message="Vouchers Reconcliation has been done successfully";
			 this.getbankData();
		  }else{
			 this.error_message="Vouchers reconciliation could not be done";
				this.show_loading_image=false;
		  }
	   //console.log(purchaseListData);
	   //this.reportData=purchaseListData.data;
	   //this.ledger_opening=purchaseListData.opening;
	  });
	}
	
	chequeCleared(){
		this.is_bounce=false;
		this.chequeClearedData.bank_id=this.serachReportData.tmp_ledger_id.value;
		this.chequeClearedData.petro_id=this.petro_id;
		this.pipe = new DatePipe('en-US'); 
		this.chequeClearedData.cleared_date= this.pipe.transform(this.chequeClearedData.cleared_date, 'yyyy-MM-dd');
		this.accountService.bankReconcilationChequeCleared(this.chequeClearedData)
	  .subscribe(success => {
		  
		  if(success.status==200)
		  {
			 this.success_message="Cheque details updated";
			 $('#chequeClearedModal').modal('hide');
			 this.getbankData();
		  }else{
			 this.error_message_check="Cheque details could not be updated";
		  }
	   //console.log(purchaseListData);
	   //this.reportData=purchaseListData.data;
	   //this.ledger_opening=purchaseListData.opening;
	  });
	}
	openChequeCleared(vd_id,amount,cheque_no,customer_id){
		
		this.chequeClearedData.detail_id=vd_id;
		this.chequeClearedData.cheque_no=cheque_no;
		this.chequeClearedData.amount=amount;
		this.chequeClearedData.customer_id=customer_id;
		$('#chequeClearedModal').modal('show');
	}
	
	  onBounceClick(event){
	  if(event.target.checked)
	  {
		this.is_bounce=true;
		 
	  }else{
		  this.is_bounce=false;
	  }
	 // console.log(this.concliationArr);
	  //alert();
  }
}
