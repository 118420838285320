import { Component, OnInit } from '@angular/core';
import { PetrolPumpServicesService } from '../../super-admin-service/petrol-pump-services.service';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { Router} from '@angular/router';
declare var $: any;

interface serviceData {
  data: any
}

@Component({
  selector: 'app-petrol-pump-list',
  templateUrl: './petrol-pump-list.component.html',
  styleUrls: ['./petrol-pump-list.component.css']
})
export class PetrolPumpListComponent implements OnInit {

  constructor(private petrolPumpService: PetrolPumpServicesService, private router:Router) { }


	// variables for petrol pump lists
	addPetrolPump : any={};
	editPetrolPump : any={};
	data : any[] = [];
	countryDropdownList : any[] = [];
	ownerListData:any[]=[];
	dealerDropdownList:any[]=[];
	stateDropdownList : any[] = [];
	cityDropdownList : any[] = [];
	packageDropdownList : any[] = [];
	serachPetrolPump : any={};
	name : string;
	totalCat: number;
	petrolPumpList: any[] = [];	
	success_message : string;
	error_message : string;
	index : number;
	currentPage : number;

	id: number;
	reg_no: number;
	owner_name:string;
	gstn_no:string;
	pan_card:string;
	address:string;
	city_id: number;
	state_id: number;
	country_id: number;
	pin: number;
	mobile_no: number;
	alt_mobile_no: number;
	landline_no: number;
	email:string;
	alt_email:string;
	package_id: number;
	active_from: any;
	active_to:any;
	addPetrolPumpForm:FormGroup;

	successAddPumpListData:{
		Petrolpump:{
			id:any,
			reg_no:any,
			name:string,
			owner_name:string,
			mobile_no:number,
			email:any
		}		
	}

	// Add New Petrol Pump Validation

	

	serachPetrolPumpForm= new FormGroup({
		name : new FormControl(' '),
	});	

	// Edit Petrol Pump Form data
	editPetrolPumpForm= new FormGroup({
		id: new FormControl(' '),
		reg_no : new FormControl(' ',Validators.required),
		name : new FormControl(' ',Validators.required),
		owner_id: new FormControl(''),
		owner_name : new FormControl(' ',Validators.required),
		gstn_no : new FormControl(' ',Validators.required),
		pan_card : new FormControl(' '),
		pin : new FormControl(' ',Validators.required),
		country_id : new FormControl(' ',Validators.required),
		state_id : new FormControl(' ',Validators.required),
		city_id : new FormControl(' ',Validators.required),
		alt_mobile_no : new FormControl(' '),
		landline_no : new FormControl(' '),		
		address : new FormControl(' ',Validators.required),
		mobile_no : new FormControl(' ',[Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
		email : new FormControl(' ',[Validators.required,Validators.email]),
		active_from : new FormControl(' '),
		active_to : new FormControl(' '),
		package_id : new FormControl(' '),
		daily_shift: new FormControl(' ',Validators.required),
		dealer_id: new FormControl(' ',Validators.required),
		alt_email : new FormControl(' ')
	});	

	ngOnInit() {
		
			this.addPetrolPumpForm = new FormGroup({
				// Compulsary Elements
				reg_no : new FormControl(' ',Validators.required),
				name : new FormControl(' ',Validators.required),
				owner_id: new FormControl(''),
				owner_name : new FormControl(' ',Validators.required),
				gstn_no : new FormControl(' ',Validators.required),
				pan_card : new FormControl(' '),
				pin : new FormControl(' ',Validators.required),
				country_id : new FormControl(' ',Validators.required),
				state_id : new FormControl(' ',Validators.required),
				city_id : new FormControl(' ',Validators.required),
				alt_mobile_no : new FormControl(' '),
				landline_no : new FormControl(' '),		
				address : new FormControl(' ',Validators.required),
				mobile_no : new FormControl(' ',[Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
				email : new FormControl(' ',[Validators.required,Validators.email]),
				active_from : new FormControl(' '),
				active_to : new FormControl(' '),
				package_id : new FormControl(' '),
				daily_shift: new FormControl(' ',Validators.required),
				dealer_id: new FormControl(' ',Validators.required),
				alt_email : new FormControl(' '),
			});
		// function to get countries
		this.petrolPumpService.getCountries()
		.subscribe(countrylistdata =>{this.countryDropdownList=countrylistdata.data});
		
		// Function to get packages
		this.petrolPumpService.getPackages()
		.subscribe(packagelistdata =>{this.packageDropdownList=packagelistdata.data});

		// Function to get dealer dropdown list
		this.petrolPumpService.getDealerList()
		.subscribe(dealerlistdata =>{this.dealerDropdownList=dealerlistdata.data});

		// Function to get Petrol Pump List
		this.pageWiseData(1);


		this.petrolPumpService.getOwner()
	.subscribe(ownerlistdata =>{this.ownerListData=ownerlistdata.data	});
	
	}

	// Function to add petrol pump
	addNewPetrolPump()
	{
		this.name = this.addPetrolPump.owner_name;
		//console.log(this.addPetrolPump);
		//return;
		this.petrolPumpService.addPetrolPumpData(this.addPetrolPump).subscribe(data=>{
			if(data.status==200)
			{
				this.success_message='Petrol Pump Registered Successfully!';

				$('#addPetrolPumpModal').modal('hide');
				this.pageWiseData(1)
				this.addPetrolPumpForm.reset(); 
				
			}else{
				this.error_message='Petrol Pump cannot be Register!';
			}
		});
	}

	// Get pump data( single row )
	getPetrolPumpData(id,index)
	{
		this.index=index;
		this.petrolPumpService.getEditPetrolPumpData(id).subscribe(data=>{
			console.log(data);
			this.editPetrolPump.id=data.data.Petrolpump.id;						
			this.editPetrolPump.reg_no=data.data.Petrolpump.reg_no;						
			this.editPetrolPump.name=data.data.Petrolpump.name;						
			this.editPetrolPump.owner_name=data.data.Petrolpump.owner_name;

			this.editPetrolPump.gstn_no=data.data.Petrolpump.gstn_no;						
			this.editPetrolPump.pan_card=data.data.Petrolpump.pan_card;
			this.editPetrolPump.pin=data.data.Petrolpump.pin;						
			this.editPetrolPump.address=data.data.Petrolpump.address;						
			this.editPetrolPump.mobile_no=data.data.Petrolpump.mobile_no;		
			this.editPetrolPump.alt_mobile_no=data.data.Petrolpump.alt_mobile_no;		
			this.editPetrolPump.landline_no=data.data.Petrolpump.landline_no;	
			this.editPetrolPump.email=data.data.Petrolpump.email;
			this.editPetrolPump.alt_email=data.data.Petrolpump.alt_email;			
			this.editPetrolPump.active_to=data.data.Petrolpump.active_to;			
			this.editPetrolPump.active_from=data.data.Petrolpump.active_from;			
			this.editPetrolPump.daily_shift=data.data.Petrolpump.daily_shift;			
			this.editPetrolPump.package_id=data.data.Petrolpump.package_id;			
			this.editPetrolPump.owner_id=data.data.Petrolpump.owner_id;			
			this.editPetrolPump.dealer_id=data.data.Petrolpump.company_id;			
			
			this.editPetrolPump.country_id=data.data.Country.id;
			this.petrolPumpService.getStates(this.editPetrolPump.country_id)
			.subscribe(statelistdata =>{this.stateDropdownList=statelistdata.data});
			
			this.editPetrolPump.state_id=data.data.State.id;
			this.petrolPumpService.getCities(this.editPetrolPump.state_id)
			.subscribe(citylistdata =>{this.cityDropdownList=citylistdata.data});
			
			this.editPetrolPump.city_id=data.data.City.id;		
			$('#editPetrolPumpModal').modal('show');	
		});
	}

	// Edit petrol pump data 
		editPetrolPumpData(edit){
			edit.submitted=false;
			this.petrolPumpService.editPetrolPumpData(this.editPetrolPump).subscribe(data=>{
				console.log(data.status);
				if(data.status==200)
				{
					//alert("success");
					this.success_message='Pump Data Edited Successfully!';
					$('#editPetrolPumpModal').modal('hide');
					$('#PP'+data.data[0].id).html(data.data[0].id);
					//console.log(data.data[0].id);
					this.successAddPumpListData={
						Petrolpump:{
							id:data.data[0].id,
							reg_no:data.data[0].reg_no,
							name:data.data[0].name,
							owner_name:data.data[0].owner_name,
							mobile_no:data.data[0].mobile_no,
							email:data.data[0].email
						},
					}
					this.petrolPumpList[this.index]=this.successAddPumpListData;
					this.editPetrolPumpForm.reset(); 	
				}else{
					this.error_message='Petrol Pump cannot be Register!';
				}
			});
		}

	// Function to delete petrol pump Record
	deletePetrolPumpData(id,index)
	{
		if(confirm("Are you sure to delete this Item category")) { 
			this.index=index;
				//alert(id);
			this.petrolPumpService.getDeletePumpData(id).subscribe(data=>{
				//console.log(data);
				if(data.status==true)
				{
					this.petrolPumpList.splice(this.index,1);
					this.success_message="Petrol pump deleted sucessfully";	
				}else{
					this.error_message= "Pump Information could not be deleted.";
				}
			});
		//this.editname=this.editCategoryData.name;	
		}
	}
		
	// Function to get States
	onSelectCountry(){
		this.petrolPumpService.getStates(this.addPetrolPump.country_id)
	.subscribe(statelistdata =>{this.stateDropdownList=statelistdata.data});
	}

onSelectEditCountry(){
		this.petrolPumpService.getStates(this.editPetrolPump.country_id)
	.subscribe(statelistdata =>{this.stateDropdownList=statelistdata.data});
	}
	// Function to get Cities
	onSelectState(){
		this.petrolPumpService.getCities(this.addPetrolPump.state_id)
	.subscribe(citylistdata =>{this.cityDropdownList=citylistdata.data});
	}
	
		// Function to get Cities
	onSelectEditState(){
		this.petrolPumpService.getCities(this.editPetrolPump.state_id)
	.subscribe(citylistdata =>{this.cityDropdownList=citylistdata.data});
	}

	// Function to Search Petrol Pump list
	searchPetrolPump(){
		this.pageWiseData(1);
	} 

	// Reset Search Data
	resetSearchPetrolPump(){
		this.serachPetrolPump={};
		this.pageWiseData(1);
	} 

	// Pagination 
	pageWiseData(pageno){
		this.currentPage=pageno;
		this.petrolPumpService.getPetrolPumpListData(pageno,this.serachPetrolPump)
		.subscribe(alldata =>{
			this.petrolPumpList=alldata.data,
			
			this.totalCat=alldata.count
		});
	} 

}
