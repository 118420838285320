import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { CommonsService } from '../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router} from '@angular/router';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
import { constDefault } from '../../const_default';
declare var $: any;

@Component({
  selector: 'app-card-master',
  templateUrl: './card-master.component.html',
  styleUrls: ['./card-master.component.css']
})
export class CardMasterComponent implements OnInit {

  constructor(private masterService: MasterServicesService, private commonService:CommonsService, private router:Router,private session:SessionStorageService, private commonsService:CommonsService, private formBuilder: FormBuilder) { }

  // Variable Initialization
  authData:any={};
	addCardData:any={};	
	editCardData:any={};	
	serachCardData:any={};	
	cardList:any[]=[];	
	cardDropdownList:any[]=[];	
	success_message:string;	
	error_message:string;	
	currentPage:number;	
	petro_id:number;	
	type_card:number;	
	type_wallets:number;	

	index:number;	
  totalData:number;	
  totalCat:number;
  CustomerDropdownList:any[]=[];
  model:string;
  add_loading:boolean;
  
  link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
  
addCardForm= new FormGroup({
  card_name : new FormControl(' ',Validators.required),
  type : new FormControl(' ',Validators.required),
  machine_id : new FormControl(' ',Validators.required),
  link_with_account : new FormControl(' ',Validators.required),
  merchant_share : new FormControl(' ',Validators.required),
  merchant_share_post:new FormControl(' ',Validators.required),
  card_reconsile_to: new FormControl(' ',Validators.required),
});	

editCardForm= new FormGroup({
  id:new FormControl(' '),
  type:new FormControl(' '),
  card_name : new FormControl(' ',Validators.required),
  machine_id : new FormControl(' ',Validators.required),
  link_with_account : new FormControl(' ',Validators.required),
  merchant_share : new FormControl(' ',Validators.required),
  merchant_share_post:new FormControl(' ',Validators.required),
  card_reconsile_to: new FormControl(' ',Validators.required),
});	

searchCardForm= new FormGroup({
  card_name : new FormControl(' ',Validators.required),
});	

  ngOnInit() {
	 
this.btnPrevSession = this.session.get('ButtonAuth');
	if(this.btnPrevSession)
	{
		let urlArr=this.router.url.split('/');
		this.link_path=urlArr[2];
		let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
		this.delete_btn=result.UserPrivilege.delete_auth;
		this.edit_btn=result.UserPrivilege.edit_auth;
		this.create_btn=result.UserPrivilege.create_auth;
	}	 
	  
    this.authData = this.session.get('authData');
    this.petro_id=this.authData.petro_id;
	
	this.type_card=constDefault.SALE_TYPE_CARD;
	this.type_wallets=constDefault.SALE_TYPE_WALLET;
	this.addCardData.type=constDefault.SALE_TYPE_CARD;
	
    this.commonService.getCustomerDropDownList(this.petro_id)
    .subscribe(customerlistdata =>{this.CustomerDropdownList=customerlistdata.data});
    // Page wise card list
    this.pageWiseCardList(1);
  }
  
  // Add Card
  addCard(add_card){
	  this.add_loading=true;
    add_card.submitted=false;
    this.authData = this.session.get('authData');
    this.addCardData.petro_id=this.authData.petro_id;
    //console.log(this.addCardData);
		this.masterService.addCardData(this.addCardData).subscribe(data=>{
      if(data.status==200)
      {
        //console.log("Location Data ///////////"+data);
        this.success_message='Card Information has been Added Successfully';
        $('#addCardModal').modal('hide');
        this.pageWiseCardList(1);
        this.addCardForm.reset();           
      }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
        this.error_message='Card Information could not be create';
      }
	  this.add_loading=false;
		});
  }

  // Get Card Data in Model by Id
  getCardData(id,index){
    this.index=index;
    //alert();
	// this.masterService.addNewCategory(this.name,this.parent_id,this.addCategoryData);
	this.masterService.getEidtCardData(id).subscribe(data=>{
		this.editCardData.id=data.data.Card.id;						
		this.editCardData.card_name=data.data.Card.card_name;						
    this.editCardData.machine_id=data.data.Card.machine_id;	
    this.commonService.getCustomerDropDownList(this.petro_id)
    .subscribe(customerlistdata =>{this.CustomerDropdownList=customerlistdata.data});					
		this.editCardData.link_with_account=data.data.Card.link_with_account;						
		this.editCardData.merchant_share=data.data.Card.merchant_share;						
		this.editCardData.merchant_share_post=data.data.Card.merchant_share_post;						
		this.editCardData.card_reconsile_to=data.data.Card.card_reconsile_to;						
		this.editCardData.type=data.data.Card.type;						
	 	$('#editCardModal').modal('show');
	  });
  }

  // Edit Card Data and Sumbit Data From Card
  editCardInfo(edit_card){
    edit_card.submitted=false;
    this.masterService.editCardData(this.editCardData ).subscribe(data=>{
      if(data.status==200)
      {			
        this.success_message='Card has been updated';
        $('#editCardModal').modal('hide');
        this.pageWiseCardList(1);
        this.editCardForm.reset(); 
      }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
        this.error_message='Card could not be updated';
      }
    });
  }

  // Delete Card Data
  deleteCardData(id,index){
    if(confirm("Are you sure to delete this Card Information")) { 
			this.index=index;
			this.model='Card'; 
				//alert(id);
			this.masterService.deleteDataByModel(id,this.model).subscribe(data=>{
				//console.log(data);
				if(data.status==true)
				{
					this.cardList.splice(this.index,1);
					this.success_message="Card Information deleted sucessfully";	
				}else{
					this.error_message= "Card Information could not be deleted.";
				}
			});
		}
  }

  // Search Nozzle
  searchCard(){
	 
		this.pageWiseCardList(1);
	} 

	// Reset Search Data
	resetSearchCard(){
		this.serachCardData={};
		this.pageWiseCardList(1);
	} 

	// Pagination 
	pageWiseCardList(pageno){
		this.currentPage=pageno;
		this.masterService.getCardListData(pageno,this.petro_id,this.serachCardData)
		.subscribe(alldata =>{
      //console.log(this.serachCardData);
	   if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.cardList=alldata.data,
			this.totalCat=alldata.count
			}
		});
  } 
}
