import {Component,OnInit} from '@angular/core';
import {MasterServicesService} from '../../admin-services/master-services.service';
import {CommonsService} from '../../admin-services/commons.service';
import {constDefault} from '../../const_default';
import { uniqueEmailValidation } from '../../shared/unique-email.directive';
//import { uniqueMobileValidation } from '../../shared/unique-mobile.directive';
import {FormGroup,FormControl,Validators,FormBuilder} from '@angular/forms';
import {uniqueByModalOrNameValidation} from '../../shared/unique-validation.directive';
import {LocalStorageService,SessionStorageService,LocalStorage,SessionStorage} from 'angular-web-storage';
import {Router} from '@angular/router';
declare var $: any;

@Component({
	selector: 'app-employee-master',
	templateUrl: './employee-master.component.html',
	styleUrls: ['./employee-master.component.css']
})
export class EmployeeMasterComponent implements OnInit {
	constructor(private masterService: MasterServicesService, private router: Router, private session: SessionStorageService, private commonsService: CommonsService, private formBuilder: FormBuilder) {}

	authData: any = {};
	addEmployeeData: any = {};
	editEmployeeData: any = {};
	value: string;
	serachEmployeeData: any = {};
	stateDropdownList: any[] = [];
	designationDropdownList: any[] = [];
	cityDropdownList: any[] = [];
	employeeList: any[] = [];
	success_message: string;
	error_message: string;
	currentPage: number;
	totalCat: number;
	petro_id: number;
	index: number;
	gender_male: number;
	gender_female: number;
	ms_married: number;
	ms_unmarried: number;
	other: number;
	add_loading: boolean;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	addEmployeeForm: FormGroup;
	editEmployeeForm = new FormGroup({
		id: new FormControl(' ', Validators.required),
		name: new FormControl(' ', Validators.required),
		mobile_no: new FormControl(' ', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^[0-9]*$')]),
		email: new FormControl(' ', [ Validators.email]),
		state: new FormControl(' ', Validators.required),
		city: new FormControl(' ', Validators.required),
		address: new FormControl(' ', Validators.required),
		pin: new FormControl(' ', Validators.required),
		gender: new FormControl(' ', Validators.required),
		marital_status: new FormControl(' ', Validators.required),
		designation_id: new FormControl(' ', Validators.required)
	});
	serachEmployeeForm = new FormGroup({
		name: new FormControl(' '),
	});

	ngOnInit() {
		
		// Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		// Constants

		this.commonsService.getStates(1)
		.subscribe(stateListdata => {
			this.stateDropdownList = stateListdata.data
		});
		this.pageWiseEmployeeList(1);
		// Get designation id
		this.commonsService.getDesignations(this.petro_id)
		.subscribe(alldata => {
			this.designationDropdownList = alldata.data
		});

		this.gender_male = constDefault.GENDER_MALE;
		this.gender_female = constDefault.GENDER_FEMALE;
		this.ms_married = constDefault.MARITIAL_STATUS_MARRIED;
		this.ms_unmarried = constDefault.MARITIAL_STATUS_UNMARRIED;
		this.other = constDefault.MARITIAL_STATUS_OTHER;

		this.addEmployeeForm = this.formBuilder.group({
			name: new FormControl(' ', Validators.required, uniqueByModalOrNameValidation(this.commonsService, 'Ledger', this.petro_id)),
		//	mobile_no: new FormControl(' ', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^[0-9]*$')],uniqueMobileValidation(this.commonsService)),
		mobile_no: new FormControl(' ', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^[0-9]*$')]),
			email : new FormControl(' ',[Validators.email,Validators.required],uniqueEmailValidation(this.commonsService)),
			state: new FormControl(' ', Validators.required),
			city: new FormControl(' ', Validators.required),
			address: new FormControl(' ', Validators.required),
			pin: new FormControl(' ', Validators.required),
			gender: new FormControl(' ', Validators.required),
			marital_status: new FormControl(' ', Validators.required),
			designation_id: new FormControl(' ', Validators.required)
		});
		
	}
	// Validation for only numbers
	numberOnly(value) {
		this.addEmployeeData.pin_code = value.replace(/[^0-9]/g, '');
	}

	// validation for GST number
	get name() {
		return this.addEmployeeForm.get('name');
	}

	onSelectState() {
		this.commonsService.getCities(this.addEmployeeData.state)
			.subscribe(citiesListdata => {
				this.cityDropdownList = citiesListdata.data
			});
	}
	onSelectStateForEdit() {
		this.commonsService.getCities(this.editEmployeeData.state)
			.subscribe(citiesListdata => {
				this.cityDropdownList = citiesListdata.data
			});
	}

	/*
	  Roshan Bagde
	  Add Employee Data;
	  14.01.19
  */

	addEmployee(add_emp) {
		this.add_loading=true;
		add_emp.submitted = false;
		this.authData = this.session.get('authData');
		this.addEmployeeData.petro_id = this.authData.petro_id;
		this.masterService.addNewEmployee(this.addEmployeeData).subscribe(data => {
			console.log(data);
			if (data.status == 201) {
				this.success_message = 'Employee has been Added';
				$('#addEmployeeModal').modal('hide');
				this.pageWiseEmployeeList(1);
				this.addEmployeeForm.reset();
			}else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			} else {
				this.error_message = 'Employee could not be Added';
			}
			this.add_loading=false;
		});
	}


	/*
	  Roshan Bagde
	  Get Employee Data For Edit;
	  14.01.19
  */
	getEmployeeData(id, index) {

		//console.log(id);
		this.index = index;
		this.masterService.getEidtEmployeeData(id).subscribe(data => {
			//console.log(data);
			if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.editEmployeeData.id = data.data.User.id;
			this.editEmployeeData.name = data.data.User.name;
			this.editEmployeeData.designation_id = data.data.User.designation_id;
			this.editEmployeeData.mobile_no = data.data.User.mobile_no;
			this.editEmployeeData.email = data.data.User.email;
			this.editEmployeeData.state = data.data.User.state;
			this.onSelectStateForEdit();
			this.editEmployeeData.city = data.data.User.city;
			this.editEmployeeData.address = data.data.User.address;
			this.editEmployeeData.pin = data.data.User.pin;
			this.editEmployeeData.gender = data.data.User.gender;
			this.editEmployeeData.marital_status = data.data.User.marital_status;
			$('#editEmployeeModal').modal('show');
			}
		});
	}

	/*
	  Roshan Bagde
	  Edit Employee
     14.01.19*/


	editEmployee(edit_emp) {
		edit_emp.submitted=false;
		this.editEmployeeData.petro_id = this.petro_id;
		console.log(this.editEmployeeData);
		this.masterService.editEmployee(this.editEmployeeData).subscribe(data => {
			if (data.status == 200) {
				this.success_message = 'Employee has been updated';
				this.pageWiseEmployeeList(1);
				this.editEmployeeForm.reset();
				$('#editEmployeeModal').modal('hide');
			} else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else {
				this.error_message = 'Employee could not be updated';
			}
		});
	}


	/*
	  Delete Employee 
	  Roshan Bagde
	  14.01.19
	  */


	deleteEmployee(id, index) {
		if (confirm("Are you sure to delete thisEmployee ")) {
			this.index = index;
			this.masterService.deleteDataByModel(id, 'User').subscribe(data => {
				if (data.status == true) {
					this.employeeList.splice(this.index, 1);
					this.success_message = "Employee deleted sucessfully";
				} else {
					this.error_message = "Employee could not be deleted.";
				}
			});
		}
	}


	/*
  Get Data for Employee list shoq
 Roshan Bagde
  14.01.19
  */

	pageWiseEmployeeList(pageno) {
		this.currentPage = pageno;
		this.masterService.getEmployeeListData(pageno, this.petro_id, this.serachEmployeeData)
		.subscribe(alldata => {
			if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.employeeList = alldata.data,
				this.totalCat = alldata.count
			}
		});
	}

	searchEmployee() {
		this.pageWiseEmployeeList(1);
	}

	resetSearchEmployee() {
		this.serachEmployeeData = {};
		this.pageWiseEmployeeList(1);
	}
	
	
	 isShiftInchanrge(event){
	//console.log(event);
	if(event.target.value==constDefault.DESIGNATION_ACCOUNTANT || event.target.value==constDefault.DESIGNATION_MANAGER || event.target.value==constDefault.DESIGNATION_SHIFT_INCH){
		this.addEmployeeForm.get('email').setValidators(Validators.required);
		
	}
	else{
		this.addEmployeeForm.get('email').clearValidators();

	}
  }
  
    isShiftInchanrgeEdit(event){
	//console.log(event);
	if(event.target.value==constDefault.DESIGNATION_ACCOUNTANT || event.target.value==constDefault.DESIGNATION_MANAGER || event.target.value==constDefault.DESIGNATION_SHIFT_INCH){
		this.editEmployeeForm.get('email').setValidators(Validators.required);

	
	}
	else{
		this.editEmployeeForm.get('email').clearValidators();

		
	}
  }
	
}