import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators ,FormBuilder, FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { AccountService } from '../../../admin-services/account.service';
import { ShiftSaleService } from '../../../admin-services/shift-sale.service';
import { constDefault } from '../../../const_default';
import { CommonsService } from '../../../admin-services/commons.service';
import { MasterServicesService } from '../../../admin-services/master-services.service';
import { DatePipe } from '@angular/common';
import { Router} from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
declare var $: any;
@Component({
  selector: 'app-sl-card-payment',
  templateUrl: './sl-card-payment.component.html',
  styleUrls: ['./sl-card-payment.component.css']
})

export class SlCardPaymentComponent implements OnInit {

  constructor(private router:Router,private commonsServices:CommonsService, private accountService: AccountService, private shiftSaleService: ShiftSaleService, private session:SessionStorageService,private masterService: MasterServicesService, private formBuilder: FormBuilder ) { }

  // Card-Sale

  authData:any={};
  petro_id:number;
  cardCurrentPage: number;
  dmy_shift_date:any;
  shift_date:any;
	addCardSaleData: any = {};
	viewCardSaleData: any = {};
	cardListAllData: any[] = [];
	cardSaleSummaryData: any[] = [];
	totalCardData: any = {};
	success_message: string;
	success_card_message: string;
  error_message: string;
  transactionShiftData:any[]=[];
  shift_no:number;
  day_shift_no:any;
  addCardSaleForm:FormGroup;
  cardList = [];
  card_type:number;
  pipe:any;
  list_loading:boolean;
  add_loading:boolean;
  cardSettlement:any = {};
  dsmList: any ={};
  dsm_wise_track:number;
  
  link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	// card sale (Form)
   
	// view Card Sale (Form)
	/*viewCardSaleForm = new FormGroup({
	 id: new FormControl(' '),
	 amount: new FormControl('', Validators.required),
	 voucher_no: new FormControl('', Validators.required),
	 date: new FormControl('', Validators.required),
	 card_id: new FormControl('', Validators.required),
	 vehicle_no: new FormControl('', Validators.required),
	 batch_no: new FormControl('', Validators.required),
	 description: new FormControl(''),
	 shift: new FormControl('', Validators.required),
  });*/
  
  ngOnInit() {
	  
	   // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
	  this.addCardSaleData.dsm_id=null;
    this.authData = this.session.get('authData');
    this.petro_id=this.authData.petro_id;
    this.card_type = constDefault.SALE_TYPE_CARD;
    this.addCardSaleForm = new FormGroup({
      amount: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      card_id: new FormControl('', Validators.required),
      vehicle_no: new FormControl('',Validators.pattern('^[a-zA-Z0-9]*$')),
      batch_no: new FormControl('', Validators.required),
      description: new FormControl(''),
	  dsm_id: new FormControl(''),
      shift: new FormControl('', Validators.required),
     });

     
     
  }

  // get data from Shift Sale Transaction
	getTransactionShiftData(shiftData:any,genelarGeneralSetup:any,dsmList:any){
		if(genelarGeneralSetup)
		{
			if(genelarGeneralSetup.AppSetupGeneral.dsm_wise_track)
			{
				this.dsm_wise_track=genelarGeneralSetup.AppSetupGeneral.dsm_wise_track;
				if(genelarGeneralSetup.AppSetupGeneral.dsm_wise_track==1)
				{
				this.addCardSaleForm.get('dsm_id').setValidators([Validators.required]);

				}else{
					this.addCardSaleForm.get('dsm_id').clearValidators();
				}
			}
			else{
					this.addCardSaleForm.get('dsm_id').clearValidators();
				}
		}
		this.dsmList=dsmList;
		this.transactionShiftData = shiftData;
		
		//this.shift_no=Number(this.transactionShiftData['shift']);
		this.shift_no = shiftData.shift;
		this.shift_date = shiftData.date;
		this.day_shift_no = shiftData.day_shift;
		this.getPageWiseCardPaymentList(0);
  }
  
  // get card sale list
  getCardSaleLists(){
    this.commonsServices.getCardNameList(this.shift_no,this.petro_id,this.card_type)
    .subscribe(cardNamelistdata => {
      if(cardNamelistdata.data)
      {
    this.cardList = Object.entries(cardNamelistdata.data).map(([value, label]) => ({value,label}));

      }
    });
  }

  getPageWiseCardPaymentList(page: number) {
    
	this.list_loading=true;
    this.cardCurrentPage = page;
    this.shiftSaleService.getCardListByShift(page, this.shift_no, this.petro_id,this.card_type)
     .subscribe(cardListData => {
       //console.log(cardListData)
     if(cardListData.status==200)
     {
      this.cardListAllData = cardListData.data,
      this.cardSaleSummaryData = cardListData.cardSaleSummary,
      this.totalCardData = cardListData.count
   
     }else{
        this.cardListAllData = [];
        this.totalCardData = 0;
		 this.cardSaleSummaryData=[];
     }
	 this.list_loading=false;
     });
   }
    
   /* View Data On View Button Click */
   showAddCardSale() {    
 
	  this.pipe = new DatePipe('en-US'); 
		this.dmy_shift_date = this.pipe.transform(this.shift_date, 'd-M-yyyy');
    this.addCardSaleData.shift = this.shift_no;
    this.addCardSaleData.date = this.dmy_shift_date;
    this.getCardSaleLists();
    $('#addCardSaleModal').modal("show");
   }
    
   /* Add Card Sale Data */
   addCardSales(card_sale) {
	   this.add_loading=true;
	   card_sale.submitted=false;
	   this.success_card_message='';
    this.authData = this.session.get('authData');
    this.addCardSaleData.petro_id = this.authData.petro_id;
    this.addCardSaleData.day_shift = this.day_shift_no;
    this.addCardSaleData.card_id = this.addCardSaleData.card_id.value;
    this.addCardSaleData.type = this.card_type;
    this.shiftSaleService.addCardData(this.addCardSaleData).subscribe(data => {
     if (data.status == 201) {
      this.success_card_message = 'Add data successfully';
     // $('#addCardSaleModal').modal('hide');
      this.getPageWiseCardPaymentList(1);
      
      this.add_loading=false;
	 this.addCardSaleData.shift=this.shift_no; 
	  this.addCardSaleData.date=this.dmy_shift_date; 
	  this.addCardSaleData.card_id=''; 
	  this.addCardSaleData.vehicle_no=''; 
	  this.addCardSaleData.amount=''; 
	  this.addCardSaleData.batch_no=''; 
	  this.addCardSaleData.dsm_id=''; 
	  this.addCardSaleData.description=''; 
		
    
     } else {
      this.error_message = 'Item category could not be created';
     }
    });
   }
    
   /* Remove Data on Remove Button Click */
   removeCardSale(id) {
    if (confirm("Are you sure to delete this Card Details ")) {
     this.masterService.deleteDataByModel(id, 'CardSale').subscribe(data => {
      if (data.status == true) {
     this.success_message = "Card Details deleted sucessfully";
     this.getPageWiseCardPaymentList(1);
      } else {
     this.error_message = "Card Details could not be deleted.";
      }
     });
    }
   }

   CardSaleSettlement(id){
     //alert(id);
     if(confirm("Do you really want to settle this Card Sale")){
		  this.pipe = new DatePipe('en-US'); 
		this.dmy_shift_date = this.pipe.transform(this.shift_date, 'd-M-yyyy');
     this.cardSettlement.id = id;
     this.cardSettlement.shift = this.shift_no;
     this.cardSettlement.day_shift_no = this.day_shift_no;
     this.cardSettlement.date = this.dmy_shift_date;
     this.cardSettlement.petro_id = this.petro_id;
     this.shiftSaleService.cardSettlement(this.cardSettlement).subscribe(data => {
      this.getPageWiseCardPaymentList(1);
     })
    }
   }

}
