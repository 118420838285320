import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators, FormBuilder,FormArray } from '@angular/forms';
import { ApplicationSetupService } from '../../admin-services/application-setup.service';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router} from '@angular/router';
import { constDefault } from '../../const_default';
declare var $: any;

@Component({
  selector: 'app-user-privileges',
  templateUrl: './user-privileges.component.html',
  styleUrls: ['./user-privileges.component.css']
})
export class UserPrivilegesComponent implements OnInit {

  constructor( private router: Router, private session: SessionStorageService, private formBuilder: FormBuilder,private applicationService: ApplicationSetupService) {}

	authData: any = {};

	serachData: any = {};
	navList: any[] = [];
	navListArr: any[] = [];
	subnavList: any[] = [];
	userList: any[] = [];
	previligesArr: any[] = [];
	show_loading_image:boolean;
	petro_id: number;
	success_message: string;
	error_message: string;
	
	userPrevForm: FormGroup;
  	userPrevDetailsData: FormArray;
	
	ngOnInit() {
		
		this.userPrevForm = this.formBuilder.group({
			user_id: new FormControl('', Validators.required),
			userPrevDetailsData: this.formBuilder.array([])
		});

		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		this.getNavList();
		this.applicationService.getUserList(this.petro_id).subscribe(data=>{		 
			if(data.status==200)
			{
					this.userList=data.data;	
			  }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}
		});
		
	}
	 getNavList(){
	this.show_loading_image=true;	
	  this.applicationService.getNavList().subscribe(data=>{
		 
			if(data.status==200)
			{
				
						//this.navListArr=data.data.list;
						this.setForm(data.data)
			  }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}
			this.show_loading_image=false;
		});
  }
  
  get addUserPrevillagesData() {
		return this.userPrevForm.get('userPrevDetailsData')
	}

  
  setForm(navdata){
	if(navdata)
		{
			this.show_loading_image=true;
			this.navList = Object.keys(navdata).map(function (index) {
				let navs = navdata[index];
				return navs;
			});
			// get exist privilages
			 
			   	// end get exist privilages
			this.navList.forEach((navData, index) => {
				//for (let a of this.commodityDropdownList ) {
				
				this.navListArr.push({'name':navData.NavMaster.nav_name,'type':1});
				this.userPrevDetailsData = this.userPrevForm.get('userPrevDetailsData') as FormArray;
				this.userPrevDetailsData.push(
					this.formBuilder.group({
						nav_id: [navData.NavMaster.id],
						access: [''],
						allcontrol: [''],
						create: [''],
						edit: [''],
						delete: ['']
						
					})
				);	
				if(navData.NavMasterSub)
				{
					this.subnavList = Object.keys(navData.NavMasterSub).map(function (index) {
					let subnavs = navData.NavMasterSub[index];
					return subnavs;
					});
					// Sub nav
						this.subnavList.forEach((navsumData, index) => {
						//for (let a of this.commodityDropdownList ) {
						this.navListArr.push({'name':navsumData.nav_name,'type':2,'is_report':navsumData.is_report});

						this.userPrevDetailsData = this.userPrevForm.get('userPrevDetailsData') as FormArray;
							this.userPrevDetailsData.push(
								this.formBuilder.group({
									nav_id: [navsumData.id],
									access: [''],
									allcontrol: [''],
									create: [''],
									edit: [''],
									delete: ['']
									
								})
							);	
								
						});
				}				
			});
			this.show_loading_image=false;
		}  
  }
  // add user privileges
  addUserPrevillages(formdata){
	  formdata.submitted=false;
	  this.show_loading_image=true;
	  this.applicationService.addUserPrivalageData(this.petro_id,this.userPrevForm.value).subscribe(data=>{		 
			if(data.status==200)
			{
						this.setForm(data.data)
			  }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}
			this.show_loading_image=false;
		});
  }
  
  // get user previlages data
   getUserPrevillages(){
	  //this.getNavList();
	  if(this.serachData.user_id)
			   {
				this.applicationService.getUserPrivalageData(this.petro_id,this.serachData.user_id).subscribe(data=>{		 
					if(data.status==200)
					{
				
							
							this.setUserPrivilages(data.data);
					  }else{
								this.userPrevDetailsData = this.userPrevForm.get('userPrevDetailsData') as FormArray;
							   if(this.userPrevDetailsData)
								{
								
									this.userPrevDetailsData.value.forEach((input, index) => {
										
											this.userPrevDetailsData.controls[index].get('access').setValue(false);
											this.userPrevDetailsData.controls[index].get('allcontrol').setValue(false);
											this.userPrevDetailsData.controls[index].get('create').setValue(false);
											this.userPrevDetailsData.controls[index].get('edit').setValue(false);
											this.userPrevDetailsData.controls[index].get('delete').setValue(false);
										
									});
								}
					  }
				});
			   }
  }
  
  setUserPrivilages(userPrivilages){
	   this.userPrevDetailsData = this.userPrevForm.get('userPrevDetailsData') as FormArray;
	   if(this.userPrevDetailsData)
		{
		
			this.userPrevDetailsData.value.forEach((input, index) => {
				const result = userPrivilages.find( prev => prev.UserPrivilege.nav_id ===input.nav_id);
				if(result.UserPrivilege.access==1)
				{
				   this.userPrevDetailsData.controls[index].get('access').setValue(true);
				}else{
					this.userPrevDetailsData.controls[index].get('access').setValue(false);
				}
				if(result.UserPrivilege.all_control==1)
				{
				   this.userPrevDetailsData.controls[index].get('allcontrol').setValue(true);
				}else{
					this.userPrevDetailsData.controls[index].get('allcontrol').setValue(false);
				}
				if(result.UserPrivilege.create_auth==1)
				{
				   this.userPrevDetailsData.controls[index].get('create').setValue(true);
				}else{
					this.userPrevDetailsData.controls[index].get('create').setValue(false);
				}
				if(result.UserPrivilege.edit_auth==1)
				{
				   this.userPrevDetailsData.controls[index].get('edit').setValue(true);
				}else{
					this.userPrevDetailsData.controls[index].get('edit').setValue(false);
				}
				if(result.UserPrivilege.delete_auth==1)
				{
				   this.userPrevDetailsData.controls[index].get('delete').setValue(true);
				}else{
					this.userPrevDetailsData.controls[index].get('delete').setValue(false);
				}
			});
		}
  }
  // set all control
  allControll(i,event){
	  this.userPrevDetailsData = this.userPrevForm.get('userPrevDetailsData') as FormArray;
	  
	  if(event.target.checked)
	  {
	  this.userPrevDetailsData.controls[i].get('create').setValue(true);
	  this.userPrevDetailsData.controls[i].get('edit').setValue(true);
	  this.userPrevDetailsData.controls[i].get('delete').setValue(true);
	  }else{
		   this.userPrevDetailsData.controls[i].get('create').setValue(false);
	  this.userPrevDetailsData.controls[i].get('edit').setValue(false);
	  this.userPrevDetailsData.controls[i].get('delete').setValue(false);
	  }
  }
  }
  
   



