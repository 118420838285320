import { Component, OnInit } from '@angular/core';
import * as jQuery from 'jquery';
declare var $: any;
@Component({
  selector: 'app-super-admin-inner',
  templateUrl: './super-admin-inner.component.html',
  styleUrls: ['./super-admin-inner.component.css']
})
export class SuperAdminInnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $("#sidebar").mCustomScrollbar({
      theme: "minimal" 
    });
  }

}
