import { Component ,enableProdMode} from '@angular/core';
import { Event , Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
 enableProdMode();
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {


  title = 'project1';
  showLoadingIndicator:boolean;
  constructor (private router:Router){
	  this.showLoadingIndicator=true;
	  this.router.events.subscribe((event: Event)=>{
		  if(event instanceof NavigationStart)
		  {
			this.showLoadingIndicator=true;  
		  }
		  
		  if(event instanceof NavigationEnd)
		  {
			this.showLoadingIndicator=false;  
		  }
	  });
	   //alert(this.showLoadingIndicator);
	
  }
 
}
                                                                                