import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators ,FormBuilder, FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { AccountService } from '../../../admin-services/account.service';
import { ShiftSaleService } from '../../../admin-services/shift-sale.service';
import { constDefault } from '../../../const_default';
import { MasterServicesService } from '../../../admin-services/master-services.service';
import { DatePipe } from '@angular/common';
import { Router} from '@angular/router';

// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
declare var $: any;

@Component({
  selector: 'app-sl-bank-deposite',
  templateUrl: './sl-bank-deposite.component.html',
  styleUrls: ['./sl-bank-deposite.component.css']
})
export class SlBankDepositeComponent implements OnInit {


  constructor(private router:Router,private accountService: AccountService, private shiftSaleService: ShiftSaleService, private session:SessionStorageService,private masterService: MasterServicesService, private formBuilder: FormBuilder ) { }

  options_form: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,
    
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'From Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  // Options For To Date (Search)
  options_to: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,
    
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'To Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  // Options Date (Modal)
  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,
    
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  authData:any={};
  addVoucher:any={};
  editVoucher:any={};
  cashBankLedgerList:any[]=[];
  ledgerListData:any[]=[];
  success_message:string;
  success_voucher_message:string;
  error_message:string;
  petro_id:number;
  addVoucherList:FormArray;
  addVoucherForm:FormGroup;
  credit_ledger:number;
  debit_ledger:number;
  voucher_ledger_type:number;
  serachVoucherData : any={};
  currentPage:number;
  totalPayVoucher:number;
  voucherList:any[]=[];
  editVoucherList:FormArray;
  index:number;
	model:string;
	shift_date:string;
	dmy_shift_date:string;
	shift_no:number;
	day_shift_no:number;
	pipe:any;
	transactionShiftData:any[]=[];
	list_loading:boolean;
	add_loading:boolean;
	edit_loading:boolean;
	dsmList: any ={};
	dsm_wise_track:number;
  searchContraVoucherForm = new FormGroup({
    from_date: new FormControl(''),
    to_date: new FormControl('')
   });

  viewVoucherForm = new FormGroup({
		id : new FormControl(''),
    name : new FormControl(' ',Validators.required),
  })

  editVoucherForm =this.formBuilder.group({
    vid:new FormControl(' ',Validators.required),
    date: new FormControl(' ',Validators.required),
    ledger_id: new FormControl(' ',Validators.required),
    ledger_name: new FormControl(' ',Validators.required),
    dr_cr: new FormControl(' ',Validators.required),
    amount: new FormControl(' ',Validators.required),
    narration: new FormControl(' '),
	dsm_id: new FormControl(''),
    editVoucherList:this.formBuilder.array([])
  });

    link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];

  ngOnInit() {
	  // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
	  // Session read for petro id
    this.authData = this.session.get('authData');
		this.petro_id=this.authData.petro_id;
		this.credit_ledger = constDefault.CREDIT_LEDGER;
		this.debit_ledger = constDefault.DEBIT_LEDGER;
		this.voucher_ledger_type = constDefault.CASH_LEDGER;
		this.addVoucher.dr_cr = this.credit_ledger;
		this.editVoucher.dr_cr = this.credit_ledger;
    
    this.addVoucherForm =this.formBuilder.group({
      dr_cr: new FormControl(' ',Validators.required),
      date: new FormControl(' ',Validators.required),
      ledger: new FormControl(' ',Validators.required),
     
      amount: new FormControl(' ',Validators.required),
      narration: new FormControl(' '),
	  dsm_id: new FormControl(''),
      addVoucherList:this.formBuilder.array([this.addVoucherItem()])
    });
   
    //this.addVoucher.date=new Date(Date.now());

    // Cash and Bank list
    this.accountService.getCashAndBankLedgerList(this.petro_id).subscribe(ledgerdata=>{
      this.cashBankLedgerList= Object.entries(ledgerdata.data).map(([value, label]) => ({value,label}));	
    })
    // Ledger List
    this.accountService.getLedgerListForVoucher(this.petro_id).subscribe(ledgerdata=>{
      this.ledgerListData= Object.entries(ledgerdata.data).map(([value, label]) => ({value,label}));
      
    })
    this.pageWiseData(1);
	}
	
  // get data from Shift Sale Transaction
	getTransactionShiftData(shiftData:any,genelarGeneralSetup:any,dsmList:any){
		if(genelarGeneralSetup)
		{
			if(genelarGeneralSetup.AppSetupGeneral.dsm_wise_track)
			{
				this.dsm_wise_track=genelarGeneralSetup.AppSetupGeneral.dsm_wise_track;
			}
		}
		this.dsmList=dsmList;
		
		this.transactionShiftData = shiftData;
		//this.shift_no=Number(this.transactionShiftData['shift']);
		this.shift_no = shiftData.shift;
		this.shift_date = shiftData.date;
		this.day_shift_no = shiftData.day_shift;
		this.pageWiseData(1);
	}

  // Function to add Multiple voucher Entries (Add Voucher)
  addVoucherItem(): FormGroup{
    return this.formBuilder.group({
      dr_cr:[constDefault.DEBIT_LEDGER,Validators.required],
      ledger_id:['',Validators.required],
      amount:['', Validators.required]
    })
  }

  // Function to add Multiple voucher Entries (Edit Voucher)
	editVoucherItem(): FormGroup {
		return this.formBuilder.group({
			dr_cr: [constDefault.DEBIT_LEDGER, Validators.required],
			ledger_id: ['', Validators.required],
			ledger_name: ['', Validators.required],
			amount: ['', Validators.required]
		})
  }
  
  // Function to Remove One Row in (Add Voucher Modal)
	removeItem(i) {
		if (confirm("Do you really want to Delete this Entry?")) {
			if (this.addVoucherList.length > 1) {
				this.addVoucherList.removeAt(i);
				this.getTotal(i)
			} else if (this.addVoucherList.length < 2) {
				//alert();
			}
		}
	}

	// Function to Remove One Row in Edit Voucher Table (Modal)
	removeEditItem(i) {
		if (confirm("Do you really want to Delete this Entry?")) {
			if (this.editVoucherList.length > 1) {
				this.editVoucherList.removeAt(i);
				this.getEditTotal(i)
			} else if (this.editVoucherList.length < 2) {
				//alert();
			}
		}
	}

	addBankDepositeModal(){
		   this.pipe = new DatePipe('en-US'); 
		this.dmy_shift_date = this.pipe.transform(this.shift_date, 'd-M-yyyy');
		this.addVoucher.date=this.dmy_shift_date;
		this.addVoucher.ledger="CASH";
		$('#addBankDepositeModal').modal('show');
	}
	
  // Get Voucher List Fields for (Add) Form Array
  get addVoucherFormData() {return this.addVoucherForm.get('addVoucherList')}
  
  // Get Voucher List Fields for (Edit) Form Array
	get editVoucherFormData() {return this.editVoucherForm.get('editVoucherList')}

  // Function to add One Row in Add Voucher Table (Add Modal)
	addItem() {
		this.addVoucherList = this.addVoucherForm.get('addVoucherList') as FormArray;
		this.addVoucherList.push(this.addVoucherItem());
  }
  // Function to add a row in Edit Voucher Table (Edit Modal)
	addEditItem() {
		this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
		this.editVoucherList.push(this.editVoucherItem());
	}
  
  cr_amount: number;
	dr_amount: number;
	// Calculate Total Amount
	getTotal(index) {
		this.addVoucherList = this.addVoucherForm.get('addVoucherList') as FormArray;
		this.cr_amount = 0;
		this.dr_amount = 0;
		this.addVoucherList.value.forEach((data, index) => {

			if (this.addVoucherList.value[index]['dr_cr'] == constDefault.CREDIT_LEDGER) {
				this.cr_amount = this.cr_amount + Number(this.addVoucherList.value[index]['amount']);
			}

			if (this.addVoucherFormData.value[index]['dr_cr'] == constDefault.DEBIT_LEDGER) {
				this.dr_amount = this.dr_amount + Number(this.addVoucherList.value[index]['amount']);
			}
		})
  }
  

  cr_total_view: number;
	dr_total_view: number;
	voucher_info: any = [];
	voucher_details: any = [];
	petro_name: string;
	v_date:string;
	v_no:number;
	test1: any[] = [];
	sum: number;
	viewStatus: boolean;
  // Get Voucher Data (Modal)
	getVoucherData(id, index) {
		this.test1 = [];
		this.sum = 0;
		this.viewStatus = true;
		$('#viewContraModal').modal('show');
		this.accountService.getContraVoucherDataById(id).subscribe(data => {
			this.voucher_info = data.data.Voucher;
			this.voucher_details = data.data.VoucherDetail;
			this.v_date = data.data.Voucher.date;
			this.v_no = data.data.Voucher.no;
			this.petro_name = this.authData.petro_name;	
			this.cr_total_view = 0;
			this.dr_total_view = 0;
			this.voucher_details.forEach((v_details, index) => {
				if (v_details.dr_cr == constDefault.CREDIT_LEDGER) {
					this.cr_total_view = Number(v_details.amount) + Number(this.cr_total_view);
				} else if (v_details.dr_cr == constDefault.DEBIT_LEDGER) {
					this.dr_total_view = Number(v_details.amount) + Number(this.dr_total_view);
				}
			})
		});
	}


	// Get data to Edit Contra voucher data by id
	edit_index: number;
	getEditVoucher(id, index) {
		this.editVoucherForm.setControl('editVoucherList', this.formBuilder.array([]));
		$('#editContraModal').modal('show');

		this.accountService.getContraVoucherDataById(id).subscribe(data => {
			console.log(data);
			this.editVoucher.date = data.data.Voucher.date;
			this.editVoucher.vid = data.data.Voucher.id;

			this.editVoucher.amount = data.data.Voucher.amount;
			this.editVoucher.dr_cr = data.data.Voucher.dr_cr;
			this.editVoucher.ledger_id = data.data.Ledger.id;
			this.editVoucher.ledger_name = data.data.Ledger.name;
			this.editVoucher.narration = data.data.Voucher.narration;
			this.editVoucher.dsm_id = data.data.Voucher.employee_id;

			for (let voucher_entry of data.data.VoucherDetail) {
				this.edit_index = 1;
				if (data.data.Voucher.ledger_id == voucher_entry.ledger_id) {

				} else {
					this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
					this.editVoucherList.push(
						this.formBuilder.group({
							dr_cr: [voucher_entry.dr_cr, Validators.required],
							ledger_id: [voucher_entry.ledger_id, Validators.required],
							ledger_name: [voucher_entry.Ledger.name, ''],
							amount: [voucher_entry.amount, '']
						})
					);

				}
			}
			this.getEditTotal(this.edit_index)
		});
  }
  
  total_credit: number;
	total_debit: number;
  voucherDetails: any[];
  
	// Submit Voucher Entry
	addVoucherData() {
		this.add_loading=true;
		this.success_voucher_message='';
		this.authData = this.session.get('authData');
		this.addVoucher.petro_id = this.authData.petro_id;
		this.addVoucher.ledger_id = constDefault.CASH_LEDGER;
		this.addVoucher.voucherDetails = this.addVoucherList.value;

		//this.addVoucher['date'] = this.transactionShiftData['date'] 
	 	this.addVoucher['day_shift'] = this.transactionShiftData['day_shift']
	 	this.addVoucher['shift'] = this.transactionShiftData['shift']
		//return;
    if(this.addVoucher.dr_cr==this.debit_ledger){
      this.total_debit = Number(this.addVoucher.amount) + Number(this.dr_amount);
      if (this.cr_amount == this.total_debit){
        this.accountService.addContraVoucher(this.addVoucher).subscribe(data => {
          		if (data.status == 201) {
          			this.success_voucher_message = 'Voucher has been created';
          			//$('#addBankDepositeModal').modal('hide');
          			this.pageWiseData(1);
								//this.addVoucherForm.reset();
					this.addVoucherForm.setControl('addVoucherList', this.formBuilder.array([this.addVoucherItem()]));
					//this.addVoucherForm.reset();
							
					this.addVoucher.date =this.dmy_shift_date;
					this.addVoucher.amount ='';
					this.addVoucher.narration ='';
					this.addVoucher.dsm_id ='';
          		} else {
          			//this.error_message = 'Item category could not be created';
          			this.error_message = 'Voucher could not be created';
							}
							//this.addVoucherForm.reset();
							this.add_loading=false;
          	});
      }
      else{
        this.error_message = 'Amount not matching';
      }
    }

    if(this.addVoucher.dr_cr==this.credit_ledger){
      this.total_credit = Number(this.addVoucher.amount) + Number(this.cr_amount);
      if (this.dr_amount == this.total_credit){
        this.accountService.addContraVoucher(this.addVoucher).subscribe(data => {
          		if (data.status == 201) {
          			this.success_voucher_message = 'Voucher has been created';
          			//$('#addBankDepositeModal').modal('hide');
          			this.pageWiseData(1);
								//this.addVoucherForm.reset();
					this.addVoucherForm.setControl('addVoucherList', this.formBuilder.array([this.addVoucherItem()]));
					//this.addVoucherForm.reset();
							
					this.addVoucher.date =this.dmy_shift_date;
					this.addVoucher.amount ='';
					this.addVoucher.narration ='';
					this.addVoucher.dsm_id ='';
          		} else {
          			//this.error_message = 'Item category could not be created';
          			this.error_message = 'Voucher could not be created';
							}
							//this.addVoucherForm.reset();
							this.add_loading=false;
          	});
      }else{
        this.error_message = 'Amount not matching';
      }
    }
	}

	cr_Edit_amount: number;
	dr_Edit_amount: number;

	getEditTotal(index) {
		this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
		this.cr_Edit_amount = 0;
		this.dr_Edit_amount = 0;
		this.editVoucherList.value.forEach((data, index) => {

			if (this.editVoucherList.value[index]['dr_cr'] == constDefault.CREDIT_LEDGER) {
				this.cr_Edit_amount = this.cr_Edit_amount + Number(this.editVoucherList.value[index]['amount']);
			}

			if (this.editVoucherList.value[index]['dr_cr'] == constDefault.DEBIT_LEDGER) {
				this.dr_Edit_amount = this.dr_Edit_amount + Number(this.editVoucherList.value[index]['amount']);
			}
			console.log("CR Total"+this.cr_Edit_amount);
			console.log("Dr Total"+this.dr_Edit_amount);
		})
  }


  // Edit Voucher data and Save Data 
	editVoucherData(edit_voucher) {
		edit_voucher.submitted=false;
		this.edit_loading=true;
		 this.editVoucher.voucherDetails = this.editVoucherList.value;
		
    if(this.editVoucher.dr_cr==this.debit_ledger){
      this.total_debit = Number(this.editVoucher.amount) + Number(this.dr_Edit_amount);
      if (this.cr_Edit_amount == this.total_debit){
        this.accountService.editContraVoucher(this.editVoucher).subscribe(data => {
          		if (data.status == 200) {
          			this.success_message = 'Voucher has been created';
          			$('#editContraModal').modal('hide');
          			this.pageWiseData(1);
          			this.editVoucherForm.reset();
          		} else {
          			//this.error_message = 'Item category could not be created';
          			this.error_message = 'Voucher could not be created';
							}
							this.editVoucherForm.reset();
							this.edit_loading=false;
          	});
      }
      else{
        this.error_message = 'Amount not matching';
      }
    }

    if(this.editVoucher.dr_cr==this.credit_ledger){
      this.total_credit = Number(this.editVoucher.amount) + Number(this.cr_Edit_amount);
			if (this.dr_Edit_amount == this.total_credit){
			  this.accountService.editContraVoucher(this.editVoucher).subscribe(data => {
			    		if (data.status == 200) {
			    			this.success_message = 'Voucher has been created';
			    			$('#editContraModal').modal('hide');
			    			this.pageWiseData(1);
			    			this.editVoucherForm.reset();
			    		} else {
			    			//this.error_message = 'Item category could not be created';
			    			this.error_message = 'Voucher could not be created';
							}
							this.editVoucherForm.reset();
							this.edit_loading=false;
			    	});
			}else{
			  this.error_message = 'Amount not matching';
			}
    }
	}
  
  // Delete Voucher by id
	deleteVoucher(id,index)
	{
		if(confirm("Are you sure to delete this Voucher Entry")) { 
			this.index=index;
			this.model='Voucher'; 
			this.masterService.deleteDataByModel(id,this.model).subscribe(data=>{
				if(data.status==true)
				{
					this.voucherList.splice(this.index,1);
					this.success_message="Voucher deleted sucessfully";	
				}else{
					this.error_message= "Voucher could not be deleted.";
				}
			});
		}
	}

// Get ledger value on change ledger list and set id to form array list option
	getLedgerId(value, i) {
		this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
		this.editVoucherList.controls[i].get('ledger_id').setValue(value.value);
  }

  // Function to Search Voucher Data
	searchContraVoucher() {
		this.pageWiseData(1);
	}

	// Reset Search Data
	resetSearchContraVoucher() {
		this.serachVoucherData = {};
		this.pageWiseData(1);
	}

	// Pagination 
	pageWiseData(pageno) {
		this.list_loading=true;
		this.currentPage = pageno;
		this.voucherList = [];
		this.shiftSaleService.getBankDepositeVoucherList(this.currentPage,this.shift_no, this.day_shift_no, this.petro_id)
			.subscribe(alldata => {
				this.voucherList = alldata.data,
					this.totalPayVoucher = alldata.count,
					this.list_loading=false;
			});
	}
	
		// Voucher Amount Keyup set amount (Roshan)
	onVoucherEditAmountKeyUp(event) {
		this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
		this.editVoucherList.controls[0].get('amount').setValue(event.target.value);
		this.getEditTotal(0);
	}
	
}