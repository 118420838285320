import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { constDefault } from '../const_default';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
interface loginData{
	status:any,
	name:string,
	role:number,
	message:any
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

	loggedInStatus=false;
	
	headersdata:any={};
		authValue:any={};
		user_id:string;
		login_token:string;
		login_year:string;

  constructor(private http: HttpClient, private session:SessionStorageService,  private local_storage:LocalStorageService) {
this.authValue = this.local_storage.get('localAuthData');
  //console.log(this.authValue);
  if(this.authValue){
    this.user_id=this.authValue.user_id;  
    this.login_token=this.authValue.token;
    this.login_year=this.authValue.financial_year_id;
    // console.log(this.login_year);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('user', this.user_id);
    headers = headers.append('year', this.login_year);
    this.headersdata = headers.append('token', this.login_token);
  }	  }
  
  setLoggedIn(value:boolean){
	  this.loggedInStatus=value;
  }
  
  get isLoggedIn(){
	  return  this.loggedInStatus;
  }
	  
  
  
  checkloginData(userid,password,year,clear_login){
	  //configUrl="http://localhost/society/mobile/login";
	return this.http.get<loginData>(constDefault.API_URL+"/managements/login/"+userid+"/"+password+"/"+year+"/"+clear_login);
	//obs.subscribe((response) => console.log(response));
	
  }
  logout(userid){
	  //configUrl="http://localhost/society/mobile/login";
	return this.http.get<loginData>(constDefault.API_URL+"/managements/logout/"+userid+'/'+this.login_year,{headers:this.headersdata});
	//obs.subscribe((response) => console.log(response));
	}
	
}
