import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { constDefault } from '../const_default';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

interface getItemPrice {
  data: any;
}

interface serviceData {
  status: any;
  data: any;
  count: number;
}

interface successData{
	status:any,
	message:any,
	id:number,
	name:string,
	parent_id:number,
	parentName:string,
}

interface deleteData{ 
	status:any,
	id:number,
}

// interface to add pump record and get responce in form of data(array), status(200/401), message(success/unsuccess)  
interface addData{
	data:any,
	status:number,
  message:string,
} 

interface addDataInt{
  name:string;
  id:number;
  status:number;
  message:string;
}

interface getData{
  data:any,
  status:number
}

@Injectable({
  providedIn: 'root'
})
export class MasterServicesService {
		headersdata:any={};
		authValue:any={};
		user_id:string;
		login_token:string;
		login_year:string;
		
  constructor(private http: HttpClient, private session:SessionStorageService,  private local_storage:LocalStorageService) { 
  
	this.authValue = this.local_storage.get('localAuthData');
  //console.log(this.authValue);
  if(this.authValue){
    this.user_id=this.authValue.user_id;  
    this.login_token=this.authValue.token;
    this.login_year=this.authValue.financial_year_id;
    // console.log(this.login_year);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('user', this.user_id);
    headers = headers.append('year', this.login_year);
    this.headersdata = headers.append('token', this.login_token);
  }
 
  }
 
 
 
 /*------------------------------ Services By Amit Sahu -------------------------------*/

  /*
  Get Category Data for Master
  22.10.18
  Amit Sahu  
  */
  getCategoryListData(pageno:number,petro_id:number,searchValue){
	return this.http.post<serviceData>(constDefault.API_URL+"/masters/itemCategoryList/page:"+pageno+'/'+petro_id+'/'+this.login_year,JSON.stringify(searchValue),{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
   /*
  Get Category List for Dropdown
  22.10.18
  Amit Sahu  
  */
  getCategoryListForDropdown(petro_id:number){
	  return this.http.get<serviceData>(constDefault.API_URL+"/masters/catDropList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }
  /*
  Add Item Category 
  22.10.18
  Amit Sahu  
  */
  addNewCategory(adddata){
	  //configUrl="http://localhost/society/mobile/login";
	  return this.http.post<successData>(constDefault.API_URL+"/masters/addItemCategory/"+this.login_year,JSON.stringify(adddata),{headers:this.headersdata})
	  //.subscribe(data=>{console.log('ABC',data)})
  }
   /*
  Get category detaiils for edit 
  22.10.18
  Amit Sahu  
  */
  getEditCategoryData(cat_id:number){
	  //configUrl="http://localhost/society/mobile/login";
    return this.http.get<successData>(constDefault.API_URL+"/masters/getItemCategoryDataById/"+cat_id+'/'+this.login_year,{headers:this.headersdata})
    //.subscribe(data=>{console.log('ABC',data)})
  }
    /*
    Edit Item Category 
    22.10.18
    Amit Sahu  
  */
  editCategoryService(editdata){
	  //configUrl="http://localhost/society/mobile/login";
	return this.http.post<successData>(constDefault.API_URL+"/masters/editItemCategory/"+this.login_year,JSON.stringify(editdata),{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})
  }
    /*
    Delete Item Category 
    22.10.18
    Amit Sahu  
  */
  getDeleteCategoryData(id){
	  //configUrl="http://localhost/society/mobile/login";
    return this.http.get<deleteData>(constDefault.API_URL+"/commons/deleteDataByModel/"+id+'/'+this.login_year,{headers:this.headersdata})
    //.subscribe(data=>{console.log('ABC',data)})
  }


  /*-------------------- Location Services --------------------*/
   /*
  Get Location 
  19/11/2018
  Roshan Bagde 
  */
getLocations(pageno:number,petro_id:number,searchValue){
  return this.http.post<serviceData>(constDefault.API_URL+"/masters/locationList/page:"+pageno+'/'+petro_id+'/'+this.login_year,JSON.stringify(searchValue),{headers:this.headersdata})
}
   /*
  Add Location 
  19/11/2018
  Roshan Bagde 
  */
addLocationData(adddata){
  return this.http.post<addDataInt>(constDefault.API_URL+"/masters/addLocation/"+this.login_year,JSON.stringify(adddata),{headers:this.headersdata})
}
  /*
  Get Edit Location Data 
  19/11/2018
  Roshan Bagde 
  */
getEditLocationData(loc_id:number){
  return this.http.get<successData>(constDefault.API_URL+"/masters/getLocationDataById/"+loc_id+'/'+this.login_year,{headers:this.headersdata})
}
  /*
    Submit Edited Location Data
  */
 editLocationData(editData){
    return this.http.post<successData>(constDefault.API_URL+"/masters/editLocation/"+this.login_year,JSON.stringify(editData),{headers:this.headersdata})
 }
    /*
  Delete Location Data
  22.10.18
  Amit Sahu  
  */
 deleteDataByModel(id,model){
  //configUrl="http://localhost/society/mobile/login";
  return this.http.get<deleteData>(constDefault.API_URL+"/commons/deleteDataByModel/"+id+"/"+model+'/'+this.login_year,{headers:this.headersdata})
  //.subscribe(data=>{console.log('ABC',data)})
}


  /*
 Delete Opening Stock
 14.6.2019
 Mrunali D
  */
 deleteOpeningStock(id){
  //configUrl="http://localhost/society/mobile/login";
  return this.http.get<deleteData>(constDefault.API_URL+"/masters/deleteOpeningStock/"+id+'/'+this.login_year,{headers:this.headersdata})
  //.subscribe(data=>{console.log('ABC',data)})
}
  
  /*
  Add Item  
  19.11.18
  Amit Sahu  
  */
  addNewItem(adddata){
	return this.http.post<addData>(constDefault.API_URL+"/masters/addItem/"+this.login_year,JSON.stringify(adddata),{headers:this.headersdata})
  }
  
  /*
  Amit Sahu
  Get item list
  19.11.18
  */
  /*
  Get Category Data for Master
  22.10.18
  Amit Sahu  
  */
  getItemListData(pageno:number,petro_id:number,searchValue){
	return this.http.post<serviceData>(constDefault.API_URL+"/masters/itemList/page:"+pageno+'/'+petro_id+'/'+this.login_year,JSON.stringify(searchValue),{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList
  }
  
  /*
  Amit Sahu
  Get Item Data For Edit
  19.11.18*/ 
  getEditItemData(item_id:number){
		return this.http.get<serviceData>(constDefault.API_URL+"/masters/getItemDataById/ "+item_id+'/'+this.login_year,{headers:this.headersdata})
	}
	
	 /*
  Add Item  
  19.11.18
  Amit Sahu  
  */
  editItem(editdata){
	return this.http.post<addData>(constDefault.API_URL+"/masters/editItem/"+this.login_year,JSON.stringify(editdata),{headers:this.headersdata})
  }


/*-------------------- Vehicle Master --------------------- */

 /*
  Get Vehicle  
  20.11.18
  Roshan Bagde
  */
  getledgerId(petro_id:number){
    return this.http.get<serviceData>(constDefault.API_URL+"/commons/customerDropList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }

  /*
  Add Vehicle  
  20.11.18
  Roshan Bagde
  */
  addVehicleData(adddata){
    //console.log(adddata);
    return this.http.post<addData>(constDefault.API_URL+"/masters/addVehicle/"+this.login_year,JSON.stringify(adddata),{headers:this.headersdata})
  }

  /*
  Get Vehicle List
  20.11.18
  Roshan Bagde
  */
  getVehicleListData(pageno:number,petro_id:number,searchValue){
		//console.log(searchValue);
		return this.http.post<serviceData>(constDefault.API_URL+"/masters/vehicleList/page:"+pageno+'/'+petro_id+'/'+this.login_year,JSON.stringify(searchValue),{headers:this.headersdata})
  }

  /*
  Get Vehicle Edit Data
  20.11.18
  Roshan Bagde
  */
  getEditVehicleDataById(veh_id:number){
    return this.http.get<serviceData>(constDefault.API_URL+"/masters/getVehicleDataById/"+veh_id+'/'+this.login_year,{headers:this.headersdata})
  }

  /*
  Edit Vehicle Data
  20.11.18
  Roshan Bagde
  */
  editVehicleData(editData){
    return this.http.post<addData>(constDefault.API_URL+"/masters/editVehicle/"+this.login_year,JSON.stringify(editData),{headers:this.headersdata})
  }

  /*-------------------- Customer Master ---------------------*/
  /*
  Add Item  
  19.11.18
  Amit Sahu  
  */
  addNewCustomer(adddata){
	return this.http.post<addData>(constDefault.API_URL+"/masters/addCustomer/"+this.login_year,JSON.stringify(adddata),{headers:this.headersdata})
	
  }
   /*
  Get Category Data for Master
  22.10.18
  Amit Sahu  
  */
  getCustomerListData(pageno:number,petro_id:number,searchValue){
	return this.http.post<serviceData>(constDefault.API_URL+"/masters/customerList/page:"+pageno+'/'+petro_id+'/'+this.login_year,JSON.stringify(searchValue),{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList
  }
  
    /*
  Amit Sahu
  Get Customer Data For Edit
  19.11.18*/ 
  getEidtCustomerData(cust_id:number){
		return this.http.get<serviceData>(constDefault.API_URL+"/masters/getCustomerDataById/ "+cust_id+'/'+this.login_year,{headers:this.headersdata})
	}
  
  	 /*
  Edit Customer
  20.11.18
  Amit Sahu  
  */
  editCustomer(editdata){
	  return this.http.post<addData>(constDefault.API_URL+"/masters/editCustomer/"+this.login_year,JSON.stringify(editdata),{headers:this.headersdata})
  }


  /* -------------------- Dispenser Master -------------------- */
  /*
  Add Dispenser  
  21.11.18
  Roshan Bagde
  */
 addDispenserData(adddata){
    //console.log(adddata);
    return this.http.post<addData>(constDefault.API_URL+"/masters/addDispenser/"+this.login_year,JSON.stringify(adddata),{headers:this.headersdata})
  }

  /*
  Get Dispenser List to View  
  21.11.18
  Roshan Bagde
  */
  getDispenserData(pageno:number,petro_id:number,searchValue){
    //console.log(petro_id);
    return this.http.post<serviceData>(constDefault.API_URL+"/masters/dispenserList/page:"+pageno+'/'+petro_id+'/'+this.login_year,JSON.stringify(searchValue),{headers:this.headersdata})
    //.subscribe(data=>{console.log('ABC',data)})categoryPageList
  }

  /*
  Get Edit Dispenser Data to View by Id
  21.11.18
  Roshan Bagde
  */
  getEidtDispenserData(desp_id:number){
  return this.http.get<serviceData>(constDefault.API_URL+"/masters/getDispenserDataById/"+desp_id+'/'+this.login_year,{headers:this.headersdata})
  }

  /*
  Edit Dispenser List to View  
  21.11.18
  Roshan Bagde
  */
 editDispenserData(adddata){
  return this.http.post<addData>(constDefault.API_URL+"/masters/editDispenser/"+this.login_year,JSON.stringify(adddata),{headers:this.headersdata})
}


/*-------------------------------------------------------------Tank Services----------------------------------------*/
  /*
  Get Category Data for Master
  22.10.18
  Amit Sahu  
  */
  getTankListData(pageno:number,petro_id:number,searchValue){
	  return this.http.post<serviceData>(constDefault.API_URL+"/masters/tankList/page:"+pageno+'/'+petro_id+'/'+this.login_year,JSON.stringify(searchValue),{headers:this.headersdata})
  }
  
  addNewTank(adddata){
	return this.http.post<addData>(constDefault.API_URL+"/masters/addTank/"+this.login_year,JSON.stringify(adddata),{headers:this.headersdata})
  }
    /*
  Amit Sahu
  Get Tank Data For Edit
  21.11.18*/ 
  getEidtTankData(tank_id:number){
		return this.http.get<serviceData>(constDefault.API_URL+"/masters/getTankDataById/ "+tank_id+'/'+this.login_year,{headers:this.headersdata})
	}
  
  /*
  Amit Sahu
  Edit Tank Data For Edit
  19.11.18*/ 
  editTank(editdata){
		return this.http.post<addData>(constDefault.API_URL+"/masters/editTank/"+this.login_year,JSON.stringify(editdata),{headers:this.headersdata})
	}
	/*
  Amit Sahu
  Edit Tank Data For Edit
  19.11.18*/ 
  addTankCalibration(caldata){
		return this.http.post<addData>(constDefault.API_URL+"/masters/addTankCalibration/"+this.login_year,caldata,{headers:this.headersdata})
  }
    /*Amit Sahu
 Get tank calibratin
  19.11.18*/ 
  getTankCalibration(tank_id:number){
		return this.http.get<serviceData>(constDefault.API_URL+"/masters/tankCalibrationList/"+tank_id+'/'+this.login_year,{headers:this.headersdata})
  }

  /* ------------------------------------- Nozzle Master ------------------------------------- */
  
  /*
  Roshan Bagde
  Get Tank id
  21.11.18
  */ 
  getTankId(petro_id:number){ 
    return this.http.get<serviceData>(constDefault.API_URL+"/commons/tankDropList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }

  /*
  Roshan Bagde
  Add Nozzle Data
  21.11.18
  */ 
  addNozzleData(addData){
    return this.http.post<addData>(constDefault.API_URL+"/masters/addNozzle/"+this.login_year,JSON.stringify(addData),{headers:this.headersdata})
  }

  /*
  Roshan Bagde
  Get nozzle List
  21.11.18
  */ 
  getNozzleListData(pageno:number,petro_id:number,searchValue){
    return this.http.post<serviceData>(constDefault.API_URL+"/masters/nozzleList/page:"+pageno+'/'+petro_id+'/'+this.login_year,JSON.stringify(searchValue),{headers:this.headersdata})
  }

  /*
  Roshan Bagde
  Get Nozzle Information by Id
  22.11.18
  */
  getEditNozzleDataById(nozzle_id:number){
    return this.http.get<serviceData>(constDefault.API_URL+"/masters/getNozzleDataById/"+nozzle_id+'/'+this.login_year,{headers:this.headersdata})
  }
  
  /*
  Roshan Bagde
  Edit and Submit Nozzle Data 
  22.11.18
  */
  editNozzleData(editdata){
    return this.http.post<addData>(constDefault.API_URL+"/masters/editNozzle/"+this.login_year,JSON.stringify(editdata),{headers:this.headersdata})
  }

  /*
  Roshan Bagde
  Get Reset Nozzle Data 
  28.11.18
  */
  getTankChangedList(nozzle_id:number){
    //alert(nozzle_id);
    return this.http.get<serviceData>(constDefault.API_URL+"/masters/tankChangedList/"+nozzle_id+'/'+this.login_year,{headers:this.headersdata})
  }

  /*
  Roshan Bagde
  Edit Tank Data 
  28.11.18
  */
  tankChangedData(editdata){
    return this.http.post<addData>(constDefault.API_URL+"/masters/tankChanged/"+this.login_year,JSON.stringify(editdata),{headers:this.headersdata})
  }

  /*
  Roshan Bagde
  Get Reset Nozzle List 
  28.11.18
  */
  getResetNozzleList(nozzle_id:number){
    return this.http.get<serviceData>(constDefault.API_URL+"/masters/getNozzleDataById/"+nozzle_id+'/'+this.login_year,{headers:this.headersdata})
  }

  /*
  Roshan Bagde
  Edit Reset Nozzle Data
  28.11.18
  */
  resetNozzleData(editdata){
    return this.http.post<addData>(constDefault.API_URL+"/masters/resetNozzle/"+this.login_year,JSON.stringify(editdata),{headers:this.headersdata})
  }


/*-------------------------------------------------------Price master---------------------------------------*/
  getItemListForPriceUpdate(petro_id:number){
    return this.http.get<serviceData>(constDefault.API_URL+"/commons/itemListForPriceEntry/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }
  
  /*
  Amit Sahu
  Add Price Data
  30.11.18
  */ 
  addPriceData(addData){
    return this.http.post<addData>(constDefault.API_URL+"/masters/addPrice/"+this.login_year,addData,{headers:this.headersdata})
  }

  /*
  Get Price List
  22.11.18
  Amit Sahu  
  */
  getPriceListData(pageno:number,petro_id:number,searchValue){
	  return this.http.post<serviceData>(constDefault.API_URL+"/masters/priceList/page:"+pageno+'/'+petro_id+'/'+this.login_year,JSON.stringify(searchValue),{headers:this.headersdata})
  }

   /*
  Roshan Bagde
  Edit and Submit Price Data
  30.11.18
  */ 
  editPriceData(addData){
    return this.http.post<addData>(constDefault.API_URL+"/masters/editPrice/"+this.login_year,addData,{headers:this.headersdata})
  }

  /*------------------------------------- Opening Stock Master -----------------------------------*/
  
  /*
    Get opening stock list
     28.11.18
     Roshan Bagde
  */
  getOpeningStockList(pageno:number,petro_id:number,searchValue){
    return this.http.post<serviceData>(constDefault.API_URL+"/masters/openingStockList/page:"+pageno+'/'+petro_id+'/'+this.login_year,JSON.stringify(searchValue),{headers:this.headersdata})
  }

   /*
    Add opening stock list
     03.12.18
     Roshan Bagde
  */
  addOpeningStockData(addData){
    return this.http.post<addData>(constDefault.API_URL+"/masters/addOpeningStock/"+this.login_year,addData,{headers:this.headersdata})

  }
  
   /*
    Add opening stock list
     03.12.18
     Roshan Bagde
  */
  addCommodityOpeningStockData(addData){
    return this.http.post<addData>(constDefault.API_URL+"/masters/addCommodityOpeningStock/"+this.login_year,addData,{headers:this.headersdata})

  }
  

  /*-------------------------------------- Card Master -------------------------------------------*/
  
  /*
    Show Card List
     03.12.18
     Roshan Bagde
  */
  getCardListData(pageno:number,petro_id:number,searchValue){
    return this.http.post<serviceData>(constDefault.API_URL+"/masters/cardList/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
  }

  getPGListData(pageno:number,petro_id:number,searchValue){
    return this.http.post<serviceData>(constDefault.API_URL+"/masters/pgList/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
  }

  /*
    Add Card List
     06.12.18
     Roshan Bagde
  */
  addCardData(addData){
    return this.http.post<addData>(constDefault.API_URL+"/masters/addCard/"+this.login_year,addData,{headers:this.headersdata})
  }

  addPGData(addData){
    return this.http.post<addData>(constDefault.API_URL+"/masters/addPG/"+this.login_year,addData,{headers:this.headersdata})
  }

  /*
    Edit Card Info by Id
     06.12.18
     Roshan Bagde
  */
  editCardData(editdata){
    return this.http.post<addData>(constDefault.API_URL+"/masters/editCard/"+this.login_year,editdata,{headers:this.headersdata})
  }

  editPGData(editdata){
    return this.http.post<addData>(constDefault.API_URL+"/masters/editPG/"+this.login_year,editdata,{headers:this.headersdata})
  }
 
  /*
    Get Card Info by Id
     06.12.18
     Roshan Bagde
  */
  getEidtCardData(tank_id:number){
    return this.http.get<serviceData>(constDefault.API_URL+"/masters/getCardDataById/"+tank_id+'/'+this.login_year,{headers:this.headersdata})
  }

  getEidtPGData(tank_id:number){
    return this.http.get<serviceData>(constDefault.API_URL+"/masters/getPGDataById/"+tank_id+'/'+this.login_year,{headers:this.headersdata})
  }

  	/*
		Roshan Bagde
		Get Customer List Data
		18.12.18
	*/
  getUserListData(pageno:number,petro_id:number,searchValue){
    return this.http.post<serviceData>(constDefault.API_URL+"/petros/customersList/page:"+pageno+"/"+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
    //.subscribe(data=>{console.log('ABC',data)})categoryPageList
    }

    // customerStatusChange(id:number,status:number,gstin:string, pan:string,petro_id:number){
    // return this.http.get<addDataInt>(constDefault.API_URL+"/petros/custStatusChange/"+id+"/"+status+"/"+gstin+"/"+pan+"/"+petro_id,{headers:this.headersdata})
    // // return this.http.post<addData>(constDefault.API_URL+"/petros/custStatusChange/"+id/status)
    // }
    customerStatusChange(custData:any){
      return this.http.post<addDataInt>(constDefault.API_URL+"/petros/custStatusChange/"+this.login_year,custData)
      // return this.http.post<addData>(constDefault.API_URL+"/petros/custStatusChange/"+id/status)
      }
	
	/*
  Get Item Data By Id For Sale 
  15.12.18
  Amit Sahu  
  */
  getItemDetailsByItemId(item_id:any){
	return this.http.get<getData>(constDefault.API_URL+"/petros/getItemDetailsByForSale/"+item_id+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }


  /*-------------------- Designation Services --------------------*/
  
   /*
  Get Designation 
  14/01/2018
  Roshan Bagde 
  */
 getDesignations(pageno:number,petro_id:number,searchValue){
  return this.http.post<serviceData>(constDefault.API_URL+"/masters/designationList/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
}
getBozers(pageno:number,petro_id:number,searchValue){
  return this.http.post<serviceData>(constDefault.API_URL+"/masters/bozerList/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
}
   /*
  Add Designation 
  14/01/2018
  Roshan Bagde 
  */
 addDesignationData(adddata){
  return this.http.post<addDataInt>(constDefault.API_URL+"/masters/addDesignation/"+this.login_year,adddata,{headers:this.headersdata})
}

addBozerData(adddata){
  return this.http.post<addDataInt>(constDefault.API_URL+"/masters/addBozer/"+this.login_year,adddata,{headers:this.headersdata})
}
  /*
  Get Edit Designation Data 
  14/01/2018
  Roshan Bagde 
  */
 getEditDesignationData(des_id:number){
  return this.http.get<serviceData>(constDefault.API_URL+"/masters/getDesignationDataById/"+des_id+'/'+this.login_year,{headers:this.headersdata})
}

getEditBozerData(des_id:number){
  return this.http.get<serviceData>(constDefault.API_URL+"/masters/getBozerDataById/"+des_id+'/'+this.login_year,{headers:this.headersdata})
}
  /*
    Submit Edited Designation Data
    14/01/2018
    Roshan Bagde 
  */
 editDesignationData(editData){
    return this.http.post<addData>(constDefault.API_URL+"/masters/editDesignation/"+this.login_year,editData,{headers:this.headersdata})
 }

 editBozerData(editData){
  return this.http.post<addData>(constDefault.API_URL+"/masters/editBozer/"+this.login_year,editData,{headers:this.headersdata})
}
 
 /*-------------------- Employee Master ---------------------*/
  /*
  Add Employee  
  14.01.19
  Roshan Bagde  
  */
  addNewEmployee(adddata){
	  return this.http.post<addData>(constDefault.API_URL+"/masters/addEmployee/"+this.login_year,adddata,{headers:this.headersdata})
  }

  /*
  Get Employee Data for Master
  14.01.19
  Roshan Bagde 
  */
  getEmployeeListData(pageno:number,petro_id:number,searchValue){
	  return this.http.post<serviceData>(constDefault.API_URL+"/masters/employeeList/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
  }
  
  /*
  Get Employee Data For Edit
  14.01.19
  Roshan Bagde 
  */ 
  getEidtEmployeeData(cust_id:number){
		return this.http.get<serviceData>(constDefault.API_URL+"/masters/getEmployeeDataById/ "+cust_id+'/'+this.login_year,{headers:this.headersdata})
	}
  
  /*
  Edit Employee
  15.01.19
  Roshan Bagde  
  */
  editEmployee(editdata){
	  return this.http.post<addData>(constDefault.API_URL+"/masters/editEmployee/"+this.login_year,editdata,{headers:this.headersdata})
  }

  /*
  Upload File
  15.01.19
  Amit Sahu  
  */
  upload_file_data(editdata){
	  return this.http.post<addData>(constDefault.API_URL+"/masters/uploadFile/"+this.login_year,editdata,{headers:this.headersdata})
  }


  /*----------------------------------- Reminder Master ------------------------------------*/

  /*
  Reminder List Data
  15.01.19
  Roshan Bagde  
  */
  getReminderListData(pageno:number,petro_id:number,searchValue){
    return this.http.post<serviceData>(constDefault.API_URL+"/masters/listReminder/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
  }

  /*
    Add Reminder Data
     06.12.
     Roshan Bagde
  */
 addReminderData(addData){
  return this.http.post<addData>(constDefault.API_URL+"/masters/addReminder/"+this.login_year,addData,{headers:this.headersdata})
}

  /*
    Get Reminder Info by Id
     07.02.19
     Roshan Bagde
  */
  getEditReminderData(tank_id:number){
    return this.http.get<serviceData>(constDefault.API_URL+"/masters/getReminderById/"+tank_id+'/'+this.login_year,{headers:this.headersdata})
  }

   /*
    Submit Edit Reminder Info by Id
     06.12.18
     Roshan Bagde
  */
  editReminderData(editdata){
    return this.http.post<addData>(constDefault.API_URL+"/masters/editReminder/"+this.login_year,editdata,{headers:this.headersdata})
  }
  
  /*
    Submit Edit Reminder Info by Id
     06.12.18
     Roshan Bagde
  */
  getExistPriceData(data){
    return this.http.post<addData>(constDefault.API_URL+"/masters/getPriceByShiftOrDate/"+this.login_year,data,{headers:this.headersdata})
  }

  /*
    Submit Edit Reminder Info by Id
     06.12.18
     Roshan Bagde
  */
 getEditPriceData(shift:number,date:any,petro_id:number){
  return this.http.get<addData>(constDefault.API_URL+"/masters/getPriceByShiftOrDate/"+shift+"/"+date+"/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
 }

  /*----------------------------------- Offers Master ------------------------------------*/

  /*
    Add Offers Data
     27.02.2019
     Roshan Bagde
  */
 addOffersData(addData){
  return this.http.post<addData>(constDefault.API_URL+"/masters/addOffer/"+this.login_year,addData,{headers:this.headersdata})
}

  /*
    Get Offers Info by Id
     27.02.2019
     Roshan Bagde
  */
  getEditOfferById(tank_id:number){
    return this.http.get<serviceData>(constDefault.API_URL+"/masters/getOfferById/"+tank_id+'/'+this.login_year,{headers:this.headersdata})
  }

   /*
    Submit Edit Offers Info by Id
     27.02.2019
     Roshan Bagde
  */
  editOffersData(editdata){
    return this.http.post<addData>(constDefault.API_URL+"/masters/editOffer/"+this.login_year,editdata,{headers:this.headersdata})
  }

   /*
  Get Offers List Data for Offers Master
  27.02.2019
  Roshan Bagde 
  */
 getOffersListData(pageno:number,petro_id:number,searchValue){
  return this.http.post<serviceData>(constDefault.API_URL+"/masters/listOffer/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
}
/************************** state master ******************************/

  /*
    Add state Data
    12.7.2019
    Mrunali D
  */
  addState(addData){
    return this.http.post<addData>(constDefault.API_URL+"/masters/addState/"+this.login_year,addData)
  }


 /* 
  get view state list
  13.7.2019
  Mrunali D
  */
  
  getEditStateData(state_id:number){
     return this.http.get<serviceData>(constDefault.API_URL+"/masters/getStateById/"+state_id+'/'+this.login_year)
  }

    /*
  state list
  15.7.2019
  list state  
  */
  getStateListData(pageno:number,searchValue){
	
    return this.http.post<serviceData>(constDefault.API_URL+"/masters/listState/page:"+pageno+'/'+this.login_year,searchValue)
  
    }
	
	 /*
    Submit Edit State
    15.7.2019
    Mrunali D
    */
  editStateData(editdata){
    return this.http.post<addData>(constDefault.API_URL+"/masters/editState/"+this.login_year,editdata)
  }
  
  /************************** City master ******************************/

  /*
    Add City Data
    16.7.2019
    Mrunali D
  */
  addCity(addData){
    return this.http.post<addData>(constDefault.API_URL+"/masters/addCity/"+this.login_year,addData,{headers:this.headersdata})
  }


 /* 
  get view City list
  13.7.2019
  Mrunali D
  */
  
  getEditCityData(city_id:number){
     return this.http.get<serviceData>(constDefault.API_URL+"/masters/getCityById/"+city_id+'/'+this.login_year,{headers:this.headersdata})
  }

    /*
  state list
  15.7.2019
  list state  
  */
  getCityListData(pageno:number,searchValue){
	
    return this.http.post<serviceData>(constDefault.API_URL+"/masters/listCity/page:"+pageno+'/'+this.login_year,searchValue,{headers:this.headersdata})
  
    }
	
	 /*
    Submit Edit State
    15.7.2019
    Mrunali D
    */
  editCityData(editdata){
    return this.http.post<addData>(constDefault.API_URL+"/masters/editCity/"+this.login_year,editdata,{headers:this.headersdata})
  }
  /*
  Mrunali D
  2019-10-17
  ms-hsd category list
  */
    getCategoryListOilForDropdown(petro_id:number){
	  return this.http.get<serviceData>(constDefault.API_URL+"/commons/catOilDropList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }
 /******* Financial year *****/
      /*
  year list
  25.2.2020
  list year  
  */
  getYearListData(pageno:number,searchValue){
	
    return this.http.post<serviceData>(constDefault.API_URL+"/masters/financialYearList/page:"+pageno+'/'+this.login_year,searchValue)
  
    }
	
    /*
    Add Year Data
    16.7.2019
    Mrunali D
  */
  addYear(addData){
    return this.http.post<addData>(constDefault.API_URL+"/masters/addYear/"+this.login_year,addData,{headers:this.headersdata})
  }

   /* 
  get view year list
  25.2.2020
  Mrunali D
  */
  
  getEditYearData(year_id:number){
     return this.http.get<serviceData>(constDefault.API_URL+"/masters/getYearById/"+year_id+'/'+this.login_year,{headers:this.headersdata})
  }
	 /*
    Submit Edit year
    25.2.2020
    Mrunali D
    */
  editYearData(editdata){
    return this.http.post<addData>(constDefault.API_URL+"/masters/editYear/"+this.login_year,editdata,{headers:this.headersdata})
  }
/*
Mrunali D 
25.2.2020 
Created new database
*/
createNewDatabase(id:number){
	  return this.http.get<serviceData>(constDefault.API_URL+"/masters/createNewDatabase/"+id,{headers:this.headersdata})
}

}
