import {Component,OnInit,ChangeDetectorRef} from '@angular/core';
import {MasterServicesService} from '../../../admin-services/master-services.service';
import {FormGroup,FormControl,Validators,FormBuilder} from '@angular/forms';
import {LocalStorageService,SessionStorageService,LocalStorage,SessionStorage} from 'angular-web-storage';
import {CommonsService} from '../../../admin-services/commons.service';
import {AccountService} from '../../../admin-services/account.service';
import {uniqueByModalOrNameValidation} from '../../../shared/unique-validation.directive';
import {Router} from '@angular/router';
import {constDefault} from '../../../const_default';
declare var $: any;

@Component({
	selector: 'app-ledger-master',
	templateUrl: './ledger-master.component.html',
	styleUrls: ['./ledger-master.component.css']
})
export class LedgerMasterComponent implements OnInit {

	constructor(private accountService: AccountService, private masterService: MasterServicesService, private router: Router, private session: SessionStorageService, private commonsService: CommonsService, private formBuilder: FormBuilder) {}

	authData: any = {};
	petro_id: number;
	addLedgerData: any = {};
	editLedgerData: any = {};
	updateLedgerOpeningData: any = {};
	data: any[] = [];
	success_message: string;
	error_message: string;
	serachLedgerData: any = {};
	ledgerList: any[] = [];
	finance_year: any[] = [];
	index: number;
	cityDropdownList: any[] = [];
	model: string;
	currentPage: number;
	totalCat: number;
	parentGroupList = [];
	addLedgerForm: FormGroup;
	stateDropdownList: any[] = [];

	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	editLedgerForm = this.formBuilder.group({
		id: new FormControl(' ', [Validators.required]),
		name: new FormControl(' ', [Validators.required]), //, uniqueByModalOrNameValidation(this.commonsService,'Group',this.petro_id)),  //
		group_id: new FormControl(' ', [Validators.required]),
		// Bank Details
		accno: new FormControl(' '),
		branch: new FormControl(' '),
		ifsc: new FormControl(' '),
		//party Details
		mobile_no: new FormControl(' ', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^[0-9]*$')]),
		email: new FormControl(' ', Validators.email),
		phone: new FormControl(' '),
		state_id: new FormControl(' '),
		city_id: new FormControl(' '),
		address: new FormControl(' '),
		pin_code: new FormControl(' '),
		fax: new FormControl(' '),
		aoc: new FormControl(' '),
		ao_charges: new FormControl(' '),
		tin: new FormControl(' '),
		cst: new FormControl(' '),
		gstin: new FormControl(' '),
		pan: new FormControl(' '),
		int_per: new FormControl(' '),
		credit_limit: new FormControl(' '),
		credit_charges: new FormControl(' '),
		credit_days: new FormControl(' '),
		is_expenses_creditor: new FormControl(' '),
		is_user_app: new FormControl(' '),
	    limit_of_user: new FormControl(' '),
	})

	updateLedgerOpeningForm = new FormGroup({
		opening_id: new FormControl(''),
		ledger_id: new FormControl(''),
		dr_cr: new FormControl(' ', Validators.required),
		amount: new FormControl(' ', Validators.required),
		financial_year_id: new FormControl(' ', Validators.required)
	})

	serachLedgerForm = new FormGroup({
		name: new FormControl(' ')
	});


	ngOnInit() {
		// Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		$('#bank_acc').hide();
		$('#cr_db').hide();
		//for auto select 
		this.commonsService.getParentGroupList(this.petro_id)
			.subscribe(parentGroupData => { 
				this.parentGroupList = Object.entries(parentGroupData.data).map(([value, label]) => ({
					value,
					label
				}));
			});

		this.pageWiseData(1);
		// Get States
		this.commonsService.getStates(1)
			.subscribe(stateListdata => {
				this.stateDropdownList = stateListdata.data
			});

		// Get 
		this.commonsService.financialYear()
			.subscribe(financial_year => {
				this.finance_year = financial_year.data
			});

		this.addLedgerForm = this.formBuilder.group({
			name: new FormControl(' ', [Validators.required], uniqueByModalOrNameValidation(this.commonsService, 'Ledger', this.petro_id)), //
			group_id: new FormControl(' ', [Validators.required]),
			// bank details
			accno: new FormControl(' '),
			branch: new FormControl(' '),
			ifsc: new FormControl(' '),
			//party details
			mobile_no: new FormControl(' ', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^[0-9]*$')]),
			email: new FormControl(' '),
			phone: new FormControl(' '),
			state_id: new FormControl(' '),
			city_id: new FormControl(' '),
			address: new FormControl(' '),
			pin_code: new FormControl(' '),
			fax: new FormControl(' '),
			aoc: new FormControl(' '),
			ao_charges: new FormControl(' '),
			tin: new FormControl(' '),
			cst: new FormControl(' '),
			gstin: new FormControl(' '),
			pan: new FormControl(' '),
			int_per: new FormControl(' '),
			credit_limit: new FormControl(' '),
			credit_charges: new FormControl(' '),
			credit_days: new FormControl(' '),
			is_expenses_creditor: new FormControl(' '),
			is_user_app: new FormControl(' '),
		    limit_of_user: new FormControl(' '),
		})
		this.addLedgerData.state_id=4;
		this.onSelectState();
		this.onSelectStateForEdit();
	}

	//  Check Validation For Add Ledger Data
	check_parentId() {
		if (this.addLedgerData.group_id.value == constDefault.BANK_ACCOUNTS_GROUP) {
			$('#bank_acc').show();
			$('#cr_db').hide();
			// Add Validations
			this.addLedgerForm.get('accno').setValidators([Validators.required]);
			this.addLedgerForm.get('accno').updateValueAndValidity();
			this.addLedgerForm.get('branch').setValidators([Validators.required]);
			this.addLedgerForm.get('branch').updateValueAndValidity();
			this.addLedgerForm.get('ifsc').setValidators([Validators.required]);
			this.addLedgerForm.get('ifsc').updateValueAndValidity();
			// Clear Validations
			this.addLedgerForm.get('mobile_no').clearValidators();
			this.addLedgerForm.get('mobile_no').updateValueAndValidity();
			this.addLedgerForm.get('email').clearValidators();
			this.addLedgerForm.get('email').updateValueAndValidity();
			this.addLedgerForm.get('state_id').clearValidators();
			this.addLedgerForm.get('state_id').updateValueAndValidity();
			this.addLedgerForm.get('city_id').clearValidators();
			this.addLedgerForm.get('city_id').updateValueAndValidity();
			this.addLedgerForm.get('address').clearValidators();
			this.addLedgerForm.get('address').updateValueAndValidity();
		} else if (this.addLedgerData.group_id.value == constDefault.SUNDRY_DEBTOR_GROUP || this.addLedgerData.group_id.value == constDefault.SUNDRY_CREDITOR_GROUP) {
			$('#bank_acc').hide();
			$('#cr_db').show();
			// Add Validations
			this.addLedgerForm.get('state_id').setValidators([Validators.required]);
			this.addLedgerForm.get('state_id').updateValueAndValidity();
			/*
			this.addLedgerForm.get('mobile_no').setValidators([Validators.required]);
			this.addLedgerForm.get('mobile_no').updateValueAndValidity();
			this.addLedgerForm.get('email').setValidators([Validators.required]);
			this.addLedgerForm.get('email').updateValueAndValidity();		
			this.addLedgerForm.get('city_id').setValidators([Validators.required]);
			this.addLedgerForm.get('city_id').updateValueAndValidity();
			this.addLedgerForm.get('address').setValidators([Validators.required]);
			this.addLedgerForm.get('address').updateValueAndValidity();*/
			// Clear Validations
			this.addLedgerForm.get('accno').clearValidators();
			this.addLedgerForm.get('accno').updateValueAndValidity();
			this.addLedgerForm.get('branch').clearValidators();
			this.addLedgerForm.get('branch').updateValueAndValidity();
			this.addLedgerForm.get('ifsc').clearValidators();
			this.addLedgerForm.get('ifsc').updateValueAndValidity();

		} else {
			$('#bank_acc').hide();
			$('#cr_db').hide();
			// bank details
			this.addLedgerForm.get('accno').clearValidators();
			this.addLedgerForm.get('accno').updateValueAndValidity();
			this.addLedgerForm.get('branch').clearValidators();
			this.addLedgerForm.get('branch').updateValueAndValidity();
			this.addLedgerForm.get('ifsc').clearValidators();
			this.addLedgerForm.get('ifsc').updateValueAndValidity();
			// party details
			this.addLedgerForm.get('mobile_no').clearValidators();
			this.addLedgerForm.get('mobile_no').updateValueAndValidity();
			this.addLedgerForm.get('email').clearValidators();
			this.addLedgerForm.get('email').updateValueAndValidity();
			this.addLedgerForm.get('state_id').clearValidators();
			this.addLedgerForm.get('state_id').updateValueAndValidity();
			this.addLedgerForm.get('city_id').clearValidators();
			this.addLedgerForm.get('city_id').updateValueAndValidity();
			this.addLedgerForm.get('address').clearValidators();
			this.addLedgerForm.get('address').updateValueAndValidity();
		}
	}

	// Check Validation For Edit LEdger
	check_groupId() {
		if (this.editLedgerData.group_id.value == constDefault.BANK_ACCOUNTS_GROUP) {
			$('#edit_bank_acc').show();
			$('#edit_cr_db').hide();
			// Add Validations
			this.editLedgerForm.get('accno').setValidators([Validators.required]);
			this.editLedgerForm.get('accno').updateValueAndValidity();
			this.editLedgerForm.get('branch').setValidators([Validators.required]);
			this.editLedgerForm.get('branch').updateValueAndValidity();
			this.editLedgerForm.get('ifsc').setValidators([Validators.required]);
			this.editLedgerForm.get('ifsc').updateValueAndValidity();
			// Clear Validations
			this.editLedgerForm.get('mobile_no').clearValidators();
			this.editLedgerForm.get('mobile_no').updateValueAndValidity();
			this.editLedgerForm.get('email').clearValidators();
			this.editLedgerForm.get('email').updateValueAndValidity();
			this.editLedgerForm.get('state_id').clearValidators();
			this.editLedgerForm.get('state_id').updateValueAndValidity();
			this.editLedgerForm.get('city_id').clearValidators();
			this.editLedgerForm.get('city_id').updateValueAndValidity();
			this.editLedgerForm.get('address').clearValidators();
			this.editLedgerForm.get('address').updateValueAndValidity();
		} else if (this.editLedgerData.group_id.value == constDefault.SUNDRY_DEBTOR_GROUP || this.editLedgerData.group_id.value == constDefault.SUNDRY_CREDITOR_GROUP) {
			$('#edit_bank_acc').hide();
			$('#edit_cr_db').show();
			this.editLedgerForm.get('mobile_no').setValidators([Validators.required]);
			this.editLedgerForm.get('mobile_no').updateValueAndValidity();
			this.editLedgerForm.get('email').setValidators([Validators.required]);
			this.editLedgerForm.get('email').updateValueAndValidity();
			this.editLedgerForm.get('state_id').setValidators([Validators.required]);
			this.editLedgerForm.get('state_id').updateValueAndValidity();
			this.editLedgerForm.get('city_id').setValidators([Validators.required]);
			this.editLedgerForm.get('city_id').updateValueAndValidity();
			this.editLedgerForm.get('address').setValidators([Validators.required]);
			this.editLedgerForm.get('address').updateValueAndValidity();
			// Clear Validations
			this.editLedgerForm.get('accno').clearValidators();
			this.editLedgerForm.get('accno').updateValueAndValidity();
			this.editLedgerForm.get('branch').clearValidators();
			this.editLedgerForm.get('branch').updateValueAndValidity();
			this.editLedgerForm.get('ifsc').clearValidators();
			this.editLedgerForm.get('ifsc').updateValueAndValidity();

		} else {
			$('#edit_bank_acc').hide();
			$('#edit_cr_db').hide();
			this.editLedgerForm.get('accno').clearValidators();
			this.editLedgerForm.get('accno').updateValueAndValidity();
			this.editLedgerForm.get('branch').clearValidators();
			this.editLedgerForm.get('branch').updateValueAndValidity();
			this.editLedgerForm.get('ifsc').clearValidators();
			this.editLedgerForm.get('ifsc').updateValueAndValidity();
			// party details
			this.editLedgerForm.get('mobile_no').clearValidators();
			this.editLedgerForm.get('mobile_no').updateValueAndValidity();
			this.editLedgerForm.get('email').clearValidators();
			this.editLedgerForm.get('email').updateValueAndValidity();
			this.editLedgerForm.get('state_id').clearValidators();
			this.editLedgerForm.get('state_id').updateValueAndValidity();
			this.editLedgerForm.get('city_id').clearValidators();
			this.editLedgerForm.get('city_id').updateValueAndValidity();
			this.editLedgerForm.get('address').clearValidators();
			this.editLedgerForm.get('address').updateValueAndValidity();
		}
	}

	onSelectState() {
		
		this.commonsService.getCities(this.addLedgerData.state_id)
			.subscribe(citiesListdata => {
				this.cityDropdownList = citiesListdata.data
			});
	}
	onSelectStateForEdit() {
		this.commonsService.getCities(this.editLedgerData.state_id)
			.subscribe(citiesListdata => {
				this.cityDropdownList = citiesListdata.data
			});
	}


	// Add Ledger Information 
	addLedger(add_ledger) {
		add_ledger.submitted = false;
		this.authData = this.session.get('authData');
		this.addLedgerData.petro_id = this.authData.petro_id;

		this.addLedgerData.ledger_group_id = this.addLedgerData.group_id.value;
		if (this.addLedgerData.is_expenses_creditor) {
			this.addLedgerData.expense_party = 1;
		} else {
			this.addLedgerData.expense_party = 0;
		}
		this.accountService.addNewLedger(this.addLedgerData).subscribe(data => {
			if (data.status == 201) {
				this.success_message = 'Ledger has been created';
				$('#addLedgerModal').modal('hide');
				this.pageWiseData(1);
				this.addLedgerForm.reset();
				this.onSelectState();
			} else {
				this.error_message = 'Ledger could not be created';
			}
		});
	}

	// Get Ledger Data to Edit
	getLedgerData(id, i) {

		$('#edit_bank_acc').hide();
		$('#edit_cr_db').hide();
		$('#editLedgerModal').modal('show');
		this.accountService.getEditLedgerData(id).subscribe(data => {
			if (data.status == 200) {
				this.editLedgerData.id = data.data.Ledger.id;
				if (data.data.Ledger.group_id == constDefault.BANK_ACCOUNTS_GROUP) {
					$('#edit_bank_acc').show();
					$('#edit_cr_db').hide();
					this.editLedgerData.name = data.data.Ledger.name;
					this.editLedgerData.group_id = data.data.Group.name;
					this.editLedgerData.accno = data.data.BankAccount.ac_no;
					this.editLedgerData.branch = data.data.BankAccount.branch;
					this.editLedgerData.ifsc = data.data.BankAccount.ifsc;
				} else if (data.data.Ledger.group_id == constDefault.SUNDRY_DEBTOR_GROUP || data.data.Ledger.group_id == constDefault.SUNDRY_CREDITOR_GROUP) {

					$('#edit_bank_acc').hide();
					$('#edit_cr_db').show();
					this.onSelectStateForEdit();
					this.editLedgerData.name = data.data.Ledger.name;
					this.editLedgerData.group_id = data.data.Group.name;
					this.editLedgerData.mobile_no = data.data.PartyDetail.mobile_no;
					this.editLedgerData.phone = data.data.PartyDetail.phone;
					this.editLedgerData.email = data.data.PartyDetail.email;
					this.editLedgerData.state_id = data.data.PartyDetail.state_id;
					this.editLedgerData.city_id = data.data.PartyDetail.city_id;
					this.editLedgerData.address = data.data.PartyDetail.address;
					this.editLedgerData.pin_code = data.data.PartyDetail.pin_code;
					this.editLedgerData.fax = data.data.PartyDetail.fax;
					this.editLedgerData.aoc = data.data.PartyDetail.aoc;
					this.editLedgerData.ao_charges = data.data.PartyDetail.ao_charges;
					this.editLedgerData.tin = data.data.PartyDetail.tin;
					this.editLedgerData.cst = data.data.PartyDetail.cst;
					this.editLedgerData.pan = data.data.PartyDetail.pan;
					this.editLedgerData.gstin = data.data.PartyDetail.gstin;
					this.editLedgerData.int_per = data.data.PartyDetail.int_per;
					this.editLedgerData.credit_limit = data.data.PartyDetail.credit_limit;
					this.editLedgerData.credit_charges = data.data.PartyDetail.credit_charges;
					this.editLedgerData.credit_days = data.data.PartyDetail.credit_days;
					this.editLedgerData.limit_of_user = data.data.PartyDetail.limit_of_user;
					if (data.data.PartyDetail.expense_party == 1) {
						this.editLedgerData.is_expenses_creditor = true;
					} else {
						this.editLedgerData.is_expenses_creditor = false;
					}
					if (data.data.PartyDetail.is_user_app == 1) {
						this.editLedgerData.is_user_app = true;
					} else {
						this.editLedgerData.is_user_app = false;
					}
				} else {
					$('#edit_bank_acc').hide();
					$('#edit_cr_db').hide();
					this.editLedgerData.name = data.data.Ledger.name;
					this.editLedgerData.group_id = data.data.Group.name;
				}
			}
		})
	}


	// Submit get Edit ledger data to save
	editLedgerInfo(editLedger) {
		editLedger.submitted = false;
		this.authData = this.session.get('authData');
		this.editLedgerData.petro_id = this.authData.petro_id;
		this.editLedgerData.ledger_group_id = this.editLedgerData.group_id.value;

		// this.addLedgerData.ledger_group_id=this.addLedgerData.group_id.value;
		if (this.editLedgerData.is_expenses_creditor) {
			this.editLedgerData.expense_party = 1;
		} else {
			this.editLedgerData.expense_party = 0;
		}
		this.accountService.editLedger(this.editLedgerData).subscribe(data => {
			if (data.status == 201) {
				this.success_message = 'Ledger has been created';
				$('#editLedgerModal').modal('hide');
				this.pageWiseData(1);
				this.addLedgerForm.reset();
			} else {
				this.error_message = 'Ledger could not be created';
			}
		});
	}

	//get data for Update Ledger Opening Data
	getLedgerOpeningDataInfo(id, i) {
		$('#updateLedgerOpeningModal').modal('show');
		this.authData = this.session.get('authData');
		this.updateLedgerOpeningData.petro_id = this.authData.petro_id;
		this.updateLedgerOpeningData.ledger_id = id;
		this.accountService.getLedgerOpenningById(id, this.updateLedgerOpeningData.petro_id).subscribe(data => {
			if (data.status == 200) {
				//this.updateLedgerOpeningData.petro_id
				this.updateLedgerOpeningData.opening_id = data.data.LedgerOpening.id;
				this.updateLedgerOpeningData.dr_cr = data.data.LedgerOpening.dr_cr;
				this.updateLedgerOpeningData.amount = data.data.LedgerOpening.amount;
				this.updateLedgerOpeningData.financial_year_id = data.data.LedgerOpening.financial_year_id;
			}
		})
	}

	// Update Ledger Opeing Balance 
	updateLedgerInfo(updateLedger) {
		this.authData = this.session.get('authData');
		this.updateLedgerOpeningData.petro_id = this.authData.petro_id;
		this.accountService.addLedgerOpenning(this.updateLedgerOpeningData).subscribe(data => {
			if (data.status == 200) {
				this.success_message = 'Ledger Opening Amount has been Added';
				$('#updateLedgerOpeningModal').modal('hide');
				this.pageWiseData(1);
				this.updateLedgerOpeningForm.reset();

			} else if (data.status == 201) {
				this.success_message = 'Ledger Opening Amount has been Updated';
				$('#updateLedgerOpeningModal').modal('hide');
				this.pageWiseData(1);
				this.updateLedgerOpeningForm.reset();
			} else {
				this.error_message = 'Ledger Opening Amount could not be created';
			}

		});
		updateLedger.submitted = false;
	}

	// Remove Ledger Information
	deleteLedgerData(id, i) {
		if (confirm("Are you sure to delete this Ledger")) {
			this.index = i;
			this.model = 'Ledger';
			this.masterService.deleteDataByModel(id, this.model).subscribe(data => {
				if (data.status == true) {
					this.ledgerList.splice(this.index, 1);
					this.success_message = "Ledger deleted sucessfully";
				} else {
					this.error_message = "Ledger could not be deleted.";
				}
			});
		}
	}

	// Search Ledger Data
	searchLedger() {
		this.pageWiseData(1);
	}

	// Reset Search Data
	resetSearchLedger() {
		this.serachLedgerData = {};
		this.pageWiseData(1);
	}


	// Pagination for Ledger data
	pageWiseData(pageno) {
		this.currentPage = pageno;
		this.accountService.getLedgerList(pageno, this.petro_id, this.serachLedgerData)
			.subscribe(alldata => {
				this.ledgerList = alldata.data,
					this.totalCat = alldata.count
			});
	}


}