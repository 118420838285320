import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { CommonsService } from '../../admin-services/commons.service';
import { FormGroup,FormControl,Validators, FormArray, FormBuilder } from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router} from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { constDefault } from '../../const_default';

import * as enLocale from 'date-fns/locale/en';
declare var $: any;
@Component({
  selector: 'app-openingstock-master',
  templateUrl: './openingstock-master.component.html',
  styleUrls: ['./openingstock-master.component.css']
})




/* 
  Opening Stock Master
  Functions to be perform
  pageWiseData(page_no)       // Pagination 
  getOpeningStockList()       // Get list of Opening Stock
  addItem()                   // Add multiple Fields on button click
  removeItem(position)        // Remove Added Fields form Position
  addOpeningStock()           // Add Items in opening stock
  deleteOpeningStock()        // Delete Item Stock

*/
export class OpeningstockMasterComponent implements OnInit {

	constructor(private masterService: MasterServicesService, private commonsServices: CommonsService, private router: Router, private session: SessionStorageService, private formBuilder: FormBuilder) {}


	options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//	minDate: new Date(Date.now()), // Minimal selectable date
		minDate: new Date(Date.now()), // Maximal selectable date
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Expiry Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};

	// Autocomplete
	item_name: string;
	key_code: number;
	focus_input_index: number;
	selectvalue: string;
	use_no: number;
  temp_value:string;

	itemAutoSugestList: any[] = [];
	// End Autocomplete
	add_loading: boolean;
	add_com_loading: boolean;
	authData: any = {};
	petro_id: number;
	addopeningStockData: any = {};
	editopeningStockData: any = {};
	data: any[] = [];
	name: string;
	success_message: string;
	tank_message: string;
	error_message: string;
	openingStockList: any[] = [];
	commodityDropdownList: any[] = [];
	commodityTankList: any[] = [];
	tankwiseStock: any = {};
	index: number;
	model: string;
	serachOpeningStock: any = {};
	currentPage: number;
	totalCat: number;
	transit_qty: number;
	totalcommodityStock: number;
	tankindex: number;
	counttank: number;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	succeOpeningStockData: {
		Location: {
			id: any,
			name: string,
			batch_no: string,
			stock_qty: string
		}
	}


	addOpeningStockForm: FormGroup;
	addOpeningCommodityStockForm: FormGroup;
	openingStock: FormArray;
	openingCommodityStock: FormArray;
	openingCommodityTank: FormArray;

	// addOpeningStockForm = new FormGroup({
	//   name : new FormControl(' ',Validators.required),
	//   batch_no: new FormControl(' ',Validators.required),
	//   expiry_date: new FormControl(' ',Validators.required),
	//   stock_qty :new FormControl(' ',Validators.required)
	// })

	editOpeningStockForm = new FormGroup({
		id: new FormControl(''),
		name: new FormControl(' ', Validators.required),
		batch_no: new FormControl(' '),
		expiry_date: new FormControl(' '),
		stock_qty: new FormControl(' ', Validators.required)
	})

	serachOpeningStockForm = new FormGroup({
		name: new FormControl(' '),
	});


	ngOnInit() {
		// Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
		this.addopeningStockData.item_id = "";
		this.addopeningStockData.batch_no = "";
		this.addopeningStockData.expiry_date = "";
		this.addopeningStockData.stock_quantity = "";

		this.addOpeningStockForm = this.formBuilder.group({
			openingStock: this.formBuilder.array([this.addStockItem()])
		});

		this.addOpeningCommodityStockForm = this.formBuilder.group({
			openingCommodityStock: this.formBuilder.array([])
		});


		/*openingStock:this.formBuilder.array([
		  this.addStockItem()
		])*/
		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		this.pageWiseData(1);

		// Check length of dynamic fields

		// Get Commodity for Add opeing stock Commdity wise
		this.masterService.getItemListForPriceUpdate(this.petro_id)
			.subscribe(commoditylistdata => {
				if (commoditylistdata.status == 403) {
					this.session.remove('authData');
					this.router.navigate(['']);
				} else {
					this.addCommodity(commoditylistdata.data);
				}
			});


	}

	get commodityStock() {
		return this.addOpeningCommodityStockForm.get('openingCommodityStock')
	}
	get openingStockData() {
		return this.addOpeningStockForm.get('openingStock')
	}


	/*-----------------------------------------------Add Opeing Commudity Amit-----------------------------------------*/
	/*addCommodityStockItem(): FormGroup{
    return this.formBuilder.group({
      item_id:['', Validators.required],
      stock_quantity:['', Validators.required]
    })
  }*/


	addCommodity(commodityList: any) {

		this.commodityDropdownList = Object.keys(commodityList).map(function (personNamedIndex) {
			let commodity = commodityList[personNamedIndex];
			// do something with person
			return commodity;
		});
		//console.log(this.commodityDropdownList);
		//this.i=0;
		this.commodityDropdownList.forEach((a, index) => {
			//for (let a of this.commodityDropdownList ) {

			this.openingCommodityStock = this.addOpeningCommodityStockForm.get('openingCommodityStock') as FormArray;
			this.openingCommodityStock.push(
				this.formBuilder.group({
					item_id: [a.Item.id, Validators.required],
					item_name: [a.Item.name, Validators.required],
					openingCommodityTank: this.formBuilder.array([]),
					transit_qty: [0, Validators.required],
					total_stock: [0],
					rate: [0],
					total_amount: [0]
				})
			);
			// Add Tank Item wise
			this.commonsServices.getTankByCommodityWise(this.petro_id, a.Item.id)
				.subscribe(tanktdata => {
					this.addTankByCommodity(tanktdata.data, index);
				});

			//this.i++;

		});

	}
	// Add Tank By Commudity
	addTankByCommodity(tankList, index) {

		this.commodityTankList = Object.keys(tankList).map(function (personNamedIndex) {
			let tank = tankList[personNamedIndex];
			// do something with person
			return tank;
		});

		this.openingCommodityStock = this.addOpeningCommodityStockForm.get('openingCommodityStock') as FormArray;
		//this.openingStock.controls[un].get('item_id').setValue(item_id);
		//console.log(this.openingCommodityStock);
		for (let tank of this.commodityTankList) {


			this.openingCommodityTank = this.openingCommodityStock.controls[index].get('openingCommodityTank') as FormArray;

			// this.openingStock.controls[un].get('item_id').setValue(item_id);

			this.openingCommodityTank.push(
				this.formBuilder.group({
					tank_id: [tank.Tank.id, Validators.required],
					tank_name: [tank.Tank.name],
					tank_stock_qty: [''],
					tank_capacity:[tank.Tank.capacity * 1000]
				})
			);

		}

	}
	getTank(fields) {
		return fields.controls.openingCommodityTank.controls;
	}
	//Count Total
	countTotalStock(index,index_tank,ind) {
	
		//alert(index);
		this.tankwiseStock = this.openingCommodityStock.controls[index].get('openingCommodityTank') as FormArray;
		//console.log(this.tankwiseStock);
		this.totalcommodityStock = 0;
		this.tankwiseStock.value.forEach((stock, index) => {
	      if(Number(stock.tank_stock_qty) >Number(stock.tank_capacity) )
		  {
			
			  this.temp_value=index_tank;
			  this.tankindex=ind;
			  this.counttank=1;
		//	alert();
			 // this.tank_message = "Tank Qty should not be greater than Tank Capacity";
   
		  }else{
			   this.temp_value=index_tank;
			  this.tankindex=ind;
			   this.counttank=0;
			
		  }
			// alert(stock.tank_stock_qty);
			this.totalcommodityStock = Number(this.totalcommodityStock) + Number(stock.tank_stock_qty);
		});

		this.openingCommodityStock = this.addOpeningCommodityStockForm.get('openingCommodityStock') as FormArray;
		this.transit_qty = this.openingCommodityStock.value[index]['transit_qty'];

		this.totalcommodityStock = Number(this.totalcommodityStock) + Number(this.transit_qty);
		this.openingCommodityStock.controls[index].get('total_stock').setValue(this.totalcommodityStock);
		this.onRateKeyUp(index);
	}
	// Add Commodity Stock
	addCommodityOpeningStock(com) {
		this.add_com_loading = true;
		com.submitted = false;
		//alert();
		// console.log(this.addOpeningCommodityStockForm.value);
		this.authData = this.session.get('authData');
		this.addOpeningCommodityStockForm.value.petro_id = this.authData.petro_id;
		this.masterService.addCommodityOpeningStockData(this.addOpeningCommodityStockForm.value).subscribe(data => {

			if (data.status == 200) {
				//console.log("Location Data ///////////"+data);
				this.success_message = 'Item Stock has been Added Successfully';
				this.pageWiseData(1);
				$("#addCommodityOpeningStockModal").modal('hide');

			} else if (data.status == 403) {
				this.session.remove('authData');
				this.router.navigate(['']);
			}
			this.add_com_loading = false;
		});

	}
	/*--------------------------------------------End Add Opeing Commudity Amit--------------------------------------*/
	// function to add multiple stock item 
	addStockItem(): FormGroup {
		return this.formBuilder.group({
			item_id: ['', Validators.required],
			batch_no: [''],
			expiry_date: [''],
			stock_quantity: ['', Validators.required],
			rate: [''],
			total_amt: ['']
		})
	}

	// Function to add One More Field
	addItem() {
		this.openingStock = this.addOpeningStockForm.get('openingStock') as FormArray;
		this.openingStock.push(this.addStockItem());
	}

	// Function to Remove One Field
	removeItem(i) {
		if (confirm("Do you really want to Delete this Entry?")) {
			if (this.openingStock.length > 1) {
				this.openingStock.removeAt(i);
			} else if (this.openingStock.length < 2) {
				//alert();
			}
		}
	}

	// Add New Inputs
	addOpeningStock(add_op_stock) {
		this.add_loading = true;
		add_op_stock.submitted = false;
		//console.log('console'+this.addOpeningStockForm.value);
		this.authData = this.session.get('authData');
		this.addOpeningStockForm.value.petro_id = this.authData.petro_id;
		console.log(this.addOpeningStockForm.value)
		this.masterService.addOpeningStockData(this.addOpeningStockForm.value).subscribe(data => {

			if (data.status == 200) {
				//console.log("Location Data ///////////"+data);
				this.success_message = 'Item Stock has been Added Successfully';
				this.pageWiseData(1);
				$("#addOpeningStockModal").modal('hide');
				this.addOpeningStockForm.reset();

			} else if (data.status == 403) {
				this.session.remove('authData');
				this.router.navigate(['']);
			}
			this.add_loading = false;
		});
	}

/*	//Delete Opening Stock Element
	getDeleteOpeningStockData(id, index) {
		if (confirm("Are you sure to delete this Item")) {
			this.index = index;

			this.masterService.deleteDataByModel(id, 'OpeningStock').subscribe(data => {
				if (data.status == true) {
					this.openingStockList.splice(this.index, 1);
					this.success_message = "Item deleted sucessfully";

				} else {
					this.error_message = "Item could not be deleted.";
				}
			});

			//this.editname=this.editCategoryData.name;	
		}
	}
*/

	//Delete Opening Stock Element
	getDeleteOpeningStockData(id, index) {
		if (confirm("Are you sure to delete this Item")) {
			this.index = index;

			this.masterService.deleteOpeningStock(id).subscribe(data => {
				if (data.status == 200) {
					this.openingStockList.splice(this.index, 1);
					this.success_message = "Item deleted sucessfully";

				} else {
					this.error_message = "Item could not be deleted.";
				}
			});

			//this.editname=this.editCategoryData.name;	
		}
	}


	// Function To search Opening Stock
	searchOpeningStock() {
		this.pageWiseData(1);
	}

	// Reset Search Data
	resetSearchOpeningStock() {
		this.serachOpeningStock = {};
		this.pageWiseData(1);
	}

	// Pagination Opening Stock
	pageWiseData(pageno) {
		this.currentPage = pageno;
		this.masterService.getOpeningStockList(pageno, this.petro_id, this.serachOpeningStock)
			.subscribe(alldata => {
				if (alldata.status == 403) {
					this.session.remove('authData');
					this.router.navigate(['']);
				} else {
					this.openingStockList = alldata.data,
						this.totalCat = alldata.count
				}
				console.log(alldata);
			});
	}

	public filterItemList(value: any): void {
		this.commonsServices.getItemByNameOrType(value, this.petro_id, constDefault.ITEM_TYPE_OTHER)
			.subscribe(alldata => {
				this.itemAutoSugestList = Object.entries(alldata.data).map(([value, label]) => ({
					value,
					label
				}));
			});
	}

	onselectItemOnSale(event, un) {
		if (event) {
			this.masterService.getItemDetailsByItemId(event.value)
				.subscribe(itemData => {
					//console.log(itemData);
					if (itemData.status == 403) {
						this.session.remove('authData');
						this.router.navigate(['']);
					} else {
						this.itemSelectSetData(itemData.data, un)
					}

				});
		}
	}

	itemSelectSetData(itemdata, un) {
		this.openingStock = this.addOpeningStockForm.get('openingStock') as FormArray;
		if (itemdata.Item.batch_wise == 1) {
			$('#batch_no' + un).removeAttr('disabled');
			$('#expiry_date' + un).removeAttr('disabled');
		} else {
			$('#batch_no' + un).attr('disabled', 'disabled');
			$('#expiry_date' + un).attr('disabled', 'disabled');
			this.openingStock.controls[un].get('batch_no').setValue('');
			this.openingStock.controls[un].get('expiry_date').setValue('');
		}
	}
	val:any;
	rate:number;
	rate_any:any;
	qty:number;
	total_amount:any;

	// Calculate Amount by  
	calculate_amount(row,event){
					  
			this.openingStock = this.addOpeningStockForm.get('openingStock') as FormArray;
			this.rate = this.openingStock.value[row]['rate'];
			this.qty = this.openingStock.value[row]['stock_quantity'];
			this.total_amount = this.rate * this.qty;
			
			this.openingStock.controls[row].get('total_amt').setValue(parseFloat(this.total_amount));
		
	}

	calculate_rate(i,key){
					  
			this.openingStock = this.addOpeningStockForm.get('openingStock') as FormArray;
			this.rate = this.openingStock.value[i]['rate'];
			this.qty = this.openingStock.value[i]['stock_quantity'];
			this.total_amount = this.rate * this.qty;
			
			this.openingStock.controls[i].get('total_amt').setValue(this.total_amount);
		
	}
	onTotalAmountOtherKeyUp(i){
		this.openingStock = this.addOpeningStockForm.get('openingStock') as FormArray;
		
			this.qty = this.openingStock.value[i]['stock_quantity'];
			this.total_amount = this.openingStock.value[i]['total_amt'];
			if(this.qty)
			{
			this.rate_any = Number(this.total_amount) / Number(this.qty);
			this.rate_any=this.rate_any.toFixed(2);
			this.rate=Number(this.rate_any);
			if(!this.rate){
				this.rate=0;
			}
			this.openingStock.controls[i].get('rate').setValue(this.rate);
			}
	}
	/*****************************commodity calculation*********************************/
	onRateKeyUp(i){
		this.openingCommodityStock = this.addOpeningCommodityStockForm.get('openingCommodityStock') as FormArray;
			this.rate = this.openingCommodityStock.value[i]['rate'];
			this.qty = this.openingCommodityStock.value[i]['total_stock'];
			this.total_amount = Number(this.rate) * Number(this.qty);
			this.total_amount=this.total_amount.toFixed(2);
			this.openingCommodityStock.controls[i].get('total_amount').setValue(this.total_amount);
	}
	onTotalAmountKeyUp(i){
		this.openingCommodityStock = this.addOpeningCommodityStockForm.get('openingCommodityStock') as FormArray;
		
			this.qty = this.openingCommodityStock.value[i]['total_stock'];
			this.total_amount = this.openingCommodityStock.value[i]['total_amount'];
			if(this.qty)
			{
			this.rate_any = Number(this.total_amount) / Number(this.qty);
			this.rate_any=this.rate_any.toFixed(2);
			this.rate=Number(this.rate_any);
			if(!this.rate){
				this.rate=0;
			}
			this.openingCommodityStock.controls[i].get('rate').setValue(this.rate);
			}
	}
}