import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { constDefault } from '../const_default';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

interface reportData{
  status:any,
  data:any,
  totalArr:any,
  
}
interface serviceDataShiftReport{
  status:any,
  data:any,
  amount:number,
  creditSale:any,
  bankDeposit:any,
  mshsdDetails:any,
  otherSale:any,
  shiftSummary:any,
  receiptExpenseData:any,
  totalOtherSaleReport:any,
  receiptData:any,
  expenseData:any,
  tank_wise_dip_reading:any,
  totalWalletSaleDtl:any,
  totalCardSaleDtl:any
  markdc:any,
 
}

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  headersdata:any={};
  authValue:any={};
  user_id:string;
  login_token:string;
  login_year:string;

  
  constructor(private http: HttpClient, private session:SessionStorageService, private local_storage:LocalStorageService) { 

  this.authValue = this.local_storage.get('localAuthData');
	if(this.authValue)
	{
	//console.log(this.authValue);
	this.user_id=this.authValue.user_id;
	this.login_token=this.authValue.token;
	this.login_year=this.authValue.financial_year_id;
	 //console.log(this.login_year);
	let headers: HttpHeaders = new HttpHeaders();
	headers = headers.append('Accept', 'application/json');
	headers = headers.append('user', this.user_id);
	headers = headers.append('year', this.login_year);
	this.headersdata = headers.append('token', this.login_token);
	}
}

getShiftReportData(shift_no:number,from_date:string,petro_id:number){
  //console.log("dfdf");
  //console.log(shift_no+'/'+from_date+'/'+petro_id);
  return this.http.get<serviceDataShiftReport>(constDefault.API_URL+'/commons/customreport1/1/'+from_date+'/'+petro_id+'/'+this.login_year+'/D',{headers:this.headersdata});
  
}

/*
Amit Sahu
Stock Summary Reports
11.04..2019
*/
stockSummaryReport(petro_id:number,searchData:any){
  return this.http.post<reportData>(constDefault.API_URL+'/reports/stockTransactionReport/'+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata});
  //  return this.http.post<reportData>(constDefault.API_URL+'/reports/stockTransactionReport/'+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata});
    
  }
  /*
Amit Sahu
Item Wise Sale Purchase data
13.05.2019
*/
getItemWiseSalePurchaseData(petro_id:number,searchData:any){
    return this.http.post<reportData>(constDefault.API_URL+'/reports/itemWiseSaleAndPurchaseReport/'+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata});
    
  }
    /*
Amit Sahu
Stock Transfer Report
14.05.2019
*/
getStockTransferReportData(petro_id:number,searchData:any){
    return this.http.post<reportData>(constDefault.API_URL+'/reports/getStockTransferReportData/'+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata});
    
  }
      /*
Amit Sahu
Location Wise Stock data
14.05.2019
*/
getLocationWiseStockData(petro_id:number,searchData:any){
    return this.http.post<reportData>(constDefault.API_URL+'/reports/getLocationWiseStockReport/'+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata});
    
  }    /*
Amit Sahu
Group wise Sale Report 
15.05.2019
*/
getGroupWiseSaleData(petro_id:number,searchData:any){
    return this.http.post<reportData>(constDefault.API_URL+'/reports/getGroupWiseSaleReport/'+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata});
    
  }
  
   /*
Amit Sahu
Item List Report
15.05.2019
*/
getItemListReportData(petro_id:number){
    return this.http.get<reportData>(constDefault.API_URL+'/reports/getItemListReport/'+petro_id+'/'+this.login_year,{headers:this.headersdata});
    
  }
    /*
Amit Sahu
Purchase Report
21.05.2019
*/
getPurchaseReportData(petro_id:number,data:any){
    return this.http.post<reportData>(constDefault.API_URL+'/reports/purchaseReport/'+petro_id+'/'+this.login_year,data,{headers:this.headersdata});
    
  }
  
      /*
Amit Sahu
Get sale cash credit data
21.05.2019
*/
getSaleCashCreditReportData(petro_id:number,data:any){
    return this.http.post<reportData>(constDefault.API_URL+'/reports/cashAndCreditSaleForMsHsdReport/'+petro_id+'/'+this.login_year,data,{headers:this.headersdata});
    
  }
        /*
Amit Sahu
group wise item summary report
21.05.2019
*/
stockItemSummaryGroupReport(petro_id:number,data:any){
    return this.http.post<reportData>(constDefault.API_URL+'/reports/groupWiseItemSummaryReport/'+petro_id+'/'+this.login_year,data,{headers:this.headersdata});
    
  }
   /*
Amit Sahu
group wise item summary report
21.05.2019
*/
stockItemSummaryMonthReport(petro_id:number,data:any){
    return this.http.post<reportData>(constDefault.API_URL+'/reports/monthWiseItemSummaryReport/'+petro_id+'/'+this.login_year,data,{headers:this.headersdata});
    
  }
  
     /*
Amit Sahu
Item Summary details (transaction wise)
21.05.2019
*/
stockItemSummaryDateReport(petro_id:number,data:any){
    return this.http.post<reportData>(constDefault.API_URL+'/reports/dayWiseItemSummaryReport/'+petro_id+'/'+this.login_year,data,{headers:this.headersdata});
    
  }
   /*
Amit Sahu
Item Summary details (transaction wise)
21.05.2019
*/
stockSummaryItemWiseItem(petro_id:number,data:any){
    return this.http.post<reportData>(constDefault.API_URL+'/reports/stockSummaryItemReport/'+petro_id+'/'+this.login_year,data,{headers:this.headersdata});
    
  }
    /*
Amit Sahu
Get Report Service Data
01.06.2019
*/
getDSRReportData(petro_id:number,data:any){
    return this.http.post<reportData>(constDefault.API_URL+'/reports/getDsrReportData/'+petro_id+'/'+this.login_year,data,{headers:this.headersdata});
    
  }
  
}
