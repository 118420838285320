import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators ,FormBuilder, FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { AccountService } from '../../../admin-services/account.service';
import { ShiftSaleService } from '../../../admin-services/shift-sale.service';
import { CommonsService } from '../../../admin-services/commons.service';
import { MasterServicesService } from '../../../admin-services/master-services.service';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-sl-dip-stock',
  templateUrl: './sl-dip-stock.component.html',
  styleUrls: ['./sl-dip-stock.component.css']
})
export class SlDipStockComponent implements OnInit {

  constructor(private commonsServices: CommonsService, private accountService: AccountService, private shiftSaleService: ShiftSaleService, private session: SessionStorageService, private masterService: MasterServicesService, private formBuilder: FormBuilder) {}

    pipe: any;
	authData: any = {};
	dipListData: any = {};
	addShiftData: any = {};
	dipListAllData: any[] = [];
	petro_id: number;
	item_price: number;
	op_dip_value: number;
	op_dip_stock_value: number;
	invalid_shift: string;
	entry_date: string;
	assignNozzleForm: FormGroup;
	shift_date: any;
	shift_no: number;
	day_shift_no: number;
	addShiftWiseDipReadingForm: FormGroup;
    dipListEntry: FormArray;
	success_message: string;
	error_message: string;
  	nozzleDropdownListData: any[] = [];
	tank_id:number;

	// Dip reading by Dip stock value
	ltr_value:number;
	dip_stock_ltr_value : number;
	dip_stock_length:any;
	dip_stock_difference:number;
	op_dip_incr_ratio:number;
	op_dip_stock_fractional_op:number; 

	// Dip Reading by Dip Reading Value
	incr_ratio:number;
	incr_factor:number;
	op_dip_stock:number;
	dip_value_info:number;
	dip_value_fractional_part:number
	length:number;
	final_op_dip_stock:any;
	ltr_length:number

	// Get closing dip stock
	closing_dip:number
	unloading_val:number
	closing_dip_stock:any;
	total_sales:any;

	// Get unloading Data
	unloading_dip:any;

	// Get closing dip data by closing stock
	cl_dip_stock_value:number;
	cl_dip_stock_differenc:number;
	cl_dip_incr_ratio:number;
	cl_dip_stock_fractional_op:any;
	cl_dip_stock_length:any;
	cl_ltr_value:number;
	cl_dip_stock_ltr_value:number;
	list_loading:boolean;

	ngOnInit() {
		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;

		this.addShiftWiseDipReadingForm = this.formBuilder.group({
			dipListEntry: this.formBuilder.array([])
		});
	}

	//get data from Shift Sale Transaction
	getTransactionShiftData(shiftData: any) {
		this.addShiftData=shiftData;
		this.shift_no = shiftData.shift;
		this.shift_date = shiftData.date;
		this.day_shift_no = shiftData.day_shift;
		this.getDipReadingListByShift();
	}

	/*=========================== Dip Reading Roshan ================================*/

	
	get addShiftWiseDipReading(){
		return this.addShiftWiseDipReadingForm.get('dipListEntry')
	}

	getDipReadingListByShift(){
		
		this.pipe = new DatePipe('en-US');
		this.entry_date = this.shift_date;
		this.entry_date = this.pipe.transform(this.entry_date, 'd-M-yy');
		this.shiftSaleService.getShiftWiseDipReadingData(this.day_shift_no, this.shift_no, this.entry_date, this.petro_id)
			.subscribe(dipListData => {
				this.dipListAllData = dipListData.data;
				this.getDipList(dipListData.data, dipListData.status);
				
			});
	}

	getDipList(dipList: any, status: number){
		this.list_loading=true;
		this.addShiftWiseDipReadingForm = this.formBuilder.group({
			dipListEntry: this.formBuilder.array([])
		});
		if(dipList)
		{
		this.dipListData = Object.keys(dipList).map(function (nozzleNamedIndex) {
			let dip = dipList[nozzleNamedIndex];
			return dip;
		});
		}
		if (status == 200 || status == 201){
			this.invalid_shift = ""
			this.dipListData.forEach((dipData, index) => {
				//console.log(dipData);
				this.dipListEntry = this.addShiftWiseDipReadingForm.get('dipListEntry') as FormArray;
				if(dipData.DipReading!=[]){
				this.dipListEntry.push(
					this.formBuilder.group({
						id: [dipData.DipData.dip_id], 
						tank_id: [dipData.Tank.id], 
						item_id: [dipData.Item.id],  
						op_dip_reading: [dipData.DipData.opening_dip],	
						op_dip_stock: [dipData.DipData.opening_dip_stock],	
						unloading: [dipData.DipData.unloading],	
						closing_dip: [dipData.DipData.closing_dip],
						closing_stock: [dipData.DipData.closing_dip_stock],	 
						total_sale: [dipData.DipData.total_sale]
					})
				);
				}
				this.list_loading=false;
			});
			
		} else{
			this.invalid_shift = 'Please enter a valid shift id';
		}
	}

	
	// Calculate Dip Reading by Dip Reading Value
	calculate_op_dip(row,event){
	//	if(event.keyCode == 9) {				  
			this.dipListEntry = this.addShiftWiseDipReadingForm.get('dipListEntry') as FormArray;
			this.op_dip_value = this.dipListEntry.value[row]['op_dip_reading'];
			this.tank_id = this.dipListEntry.value[row]['tank_id']
			this.dip_value_fractional_part =0;
			this.shiftSaleService.getDipByLength(this.op_dip_value, this.tank_id, this.petro_id)
			.subscribe(dipListInfo => {
				//console.log(dipListInfo);
				this.length = dipListInfo.data.TankCalibration.length; 
				this.incr_ratio = dipListInfo.data.TankCalibration.incr_ratio;
				this.incr_factor = dipListInfo.data.TankCalibration.incr_factor;
				this.ltr_length = dipListInfo.data.TankCalibration.ltr;
				if (Number(this.length)==Number(this.op_dip_value)){
				}else{


					this.dip_value_info = (Number(this.op_dip_value)-Number(this.length))*10  ;//(this.op_dip_value - Math.floor(this.op_dip_value)) * 10 ; 
					this.dip_value_fractional_part = this.dip_value_info * this.incr_ratio;
				
				}
			
				this.final_op_dip_stock = (Number(this.ltr_length) + Number(this.dip_value_fractional_part));
				this.final_op_dip_stock = parseFloat(this.final_op_dip_stock).toFixed(2);
				this.dipListEntry.controls[row].get('op_dip_stock').setValue(parseFloat(this.final_op_dip_stock));
				this.getClosingStock(row,event);
			});
		// }else{

		// }
	}
	
	// Calculate Dip reading by Dip stock value
	calculate_op_dip_stock(row,event){
	//	if(event.keyCode == 9) {
		this.dipListEntry = this.addShiftWiseDipReadingForm.get('dipListEntry') as FormArray;
		this.op_dip_stock_value = this.dipListEntry.value[row]['op_dip_stock'];
		this.tank_id = this.dipListEntry.value[row]['tank_id']
		this.shiftSaleService.getDipByLitre(this.op_dip_stock_value, this.tank_id, this.petro_id)
		.subscribe(dipListInfo => {
			if(dipListInfo.status==204){
				this.error_message = "Dip Reading Is Not Available For This Data";
			}else{
				this.ltr_value = dipListInfo.data.TankCalibration.ltr;
				this.dip_stock_difference = Number(this.op_dip_stock_value) - Number(dipListInfo.data.TankCalibration.ltr);
				this.op_dip_incr_ratio = dipListInfo.data.TankCalibration.incr_ratio;
				this.dip_stock_length = dipListInfo.data.TankCalibration.length;
				this.dip_stock_ltr_value = dipListInfo.data.TankCalibration.ltr;
				this.op_dip_stock_fractional_op = Number(this.dip_stock_difference) / Number(this.op_dip_incr_ratio);
				this.op_dip_stock_fractional_op = this.op_dip_stock_fractional_op / 10;
				this.dip_stock_length = Number(this.dip_stock_length) + Number(this.op_dip_stock_fractional_op);
				this.dip_stock_length = parseFloat(this.dip_stock_length).toFixed(2);
				this.dipListEntry.controls[row].get('op_dip_reading').setValue(parseFloat(this.dip_stock_length));
				this.getClosingStock(row,event);
			}
		}); 
		// }else{

		// }
	}

 
	
	// Get closing dip stock Data (opening_dip_stock - closing_dip)
	getClosingStock(row,event){ 
		
		//if(event.keyCode == 9) {
		this.dipListEntry = this.addShiftWiseDipReadingForm.get('dipListEntry') as FormArray;
		this.op_dip_value = this.dipListEntry.value[row]['op_dip_stock'];
		this.closing_dip = this.dipListEntry.value[row]['closing_dip'];
		this.tank_id = this.dipListEntry.value[row]['tank_id'];
		this.unloading_val = this.dipListEntry.value[row]['unloading'];
	
		this.total_sales= ((Number(this.op_dip_value)+Number(this.unloading_val)) - Number(this.closing_dip));
		this.dipListEntry.controls[row].get('total_sale').setValue(this.total_sales);
	
		this.dip_value_fractional_part=0;
		this.shiftSaleService.getDipByLength(this.closing_dip, this.tank_id, this.petro_id)
		.subscribe(dipListInfo => {
			this.length = dipListInfo.data.TankCalibration.length; 
			this.incr_ratio = dipListInfo.data.TankCalibration.incr_ratio;
			// this.incr_factor = dipListInfo.data.TankCalibration.incr_factor;
			this.ltr_length = dipListInfo.data.TankCalibration.ltr;


			if (Number(this.length)==Number(this.op_dip_value)){
			}else{


				this.dip_value_info = (Number(this.closing_dip)-Number(this.length))*10  ;//(this.op_dip_value - Math.floor(this.op_dip_value)) * 10 ; 
				this.dip_value_fractional_part = this.dip_value_info * this.incr_ratio;
			
			}

			// this.dip_value_info = (this.closing_dip - Math.floor(this.closing_dip)) * 10 ; 
			// this.dip_value_fractional_part = this.dip_value_info * this.incr_ratio;
			this.closing_dip_stock = (Number(this.ltr_length) + Number(this.dip_value_fractional_part));
			this.closing_dip_stock = parseFloat(this.closing_dip_stock).toFixed(2);
			this.total_sales = ((Number(this.op_dip_value)+Number(this.unloading_val)) - Number(this.closing_dip_stock));
			this.dipListEntry.controls[row].get('closing_stock').setValue(this.closing_dip_stock);
			// Total Sales
			this.total_sales = parseFloat(this.total_sales).toFixed(2);
			this.dipListEntry.controls[row].get('total_sale').setValue(this.total_sales);
		});
		// }else{

		// }


	
	}
	
	// Get closing dip Data by closing stock 
	getClosingDip(row,event){
		// if(event.keyCode == 9) {		  
			this.dipListEntry = this.addShiftWiseDipReadingForm.get('dipListEntry') as FormArray;
			this.cl_dip_stock_value = this.dipListEntry.value[row]['closing_stock'];
			this.tank_id = this.dipListEntry.value[row]['tank_id']
			this.shiftSaleService.getDipByLitre(this.cl_dip_stock_value, this.tank_id, this.petro_id)
			.subscribe(dipListInfo => {
				if(dipListInfo.status==204){
					this.error_message = "Dip Reading Is Not Available For This Data";
				}else{
					this.cl_ltr_value = dipListInfo.data.TankCalibration.ltr;
					this.cl_dip_stock_differenc = Number(this.cl_dip_stock_value) - Number(dipListInfo.data.TankCalibration.ltr);
					this.cl_dip_incr_ratio = dipListInfo.data.TankCalibration.incr_ratio;
					this.cl_dip_stock_length = dipListInfo.data.TankCalibration.length;
					this.cl_dip_stock_ltr_value = dipListInfo.data.TankCalibration.ltr;
					this.cl_dip_stock_fractional_op = Number(this.cl_dip_stock_differenc) / Number(this.cl_dip_incr_ratio);
					this.cl_dip_stock_fractional_op = this.cl_dip_stock_fractional_op / 10;
					this.cl_dip_stock_length = Number(this.cl_dip_stock_length) + Number(this.cl_dip_stock_fractional_op);
					this.cl_dip_stock_length = parseFloat(this.cl_dip_stock_length).toFixed(2);
					this.dipListEntry.controls[row].get('closing_dip').setValue(parseFloat(this.cl_dip_stock_length));
					this.getClosingStock(row,event);
				}
			});
		// }
	}
	// submit dip stock data
	addDipReadingStock() {
		this.list_loading=true;
		this.addShiftWiseDipReadingForm.value['date'] = this.addShiftData.date;
		this.addShiftWiseDipReadingForm.value['shift'] = this.addShiftData.shift;
		this.addShiftWiseDipReadingForm.value['petro_id'] = this.petro_id;
		this.addShiftWiseDipReadingForm.value['day_shift'] = this.addShiftData.day_shift;
		//console.log(this.addShiftWiseDipReadingForm.value);
		//return;
		// Nozzle Reading Entry
		this.shiftSaleService.addshiftWiseDipEntry(this.addShiftWiseDipReadingForm.value)
			.subscribe(dipListData => {
				if (dipListData.status == 200 || dipListData.status == 201) {
					this.success_message = "Dip Reading added sucessfully";
				} else {
					this.error_message = "Dip Reading could not be added ";
				}
				this.list_loading=false;

			});

	}

	// unloading commodity in the tank
	unloading_commodity(row,event){


	/* 	this.op_dip_value = this.dipListEntry.value[row]['op_dip_stock'];
		this.closing_dip = this.dipListEntry.value[row]['closing_dip'];
		this.tank_id = this.dipListEntry.value[row]['tank_id'];
		this.unloading_val = this.dipListEntry.value[row]['unloading'];
 */
		
		// if(event.keyCode == 9) {
			this.dipListEntry = this.addShiftWiseDipReadingForm.get('dipListEntry') as FormArray;
			this.op_dip_stock = this.dipListEntry.value[row]['op_dip_stock'];
			this.unloading_dip = this.dipListEntry.value[row]['unloading'];
			this.closing_dip = this.dipListEntry.value[row]['closing_dip'];
		
			this.unloading_dip = Number(this.op_dip_stock) + Number(this.unloading_dip)- Number(this.closing_dip);
			this.unloading_dip = parseFloat(this.unloading_dip).toFixed(2);
			this.dipListEntry.controls[row].get('total_sale').setValue(parseFloat(this.unloading_dip));

			console.log(this.unloading_dip)
			this.getClosingStock(row,event);
		// }
	}

	calculateTotal() {
		this.op_dip_stock
	}

	
}
