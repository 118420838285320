import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsService } from '../../../admin-services/reports.service';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { CommonsService } from '../../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder,FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
import { DatePipe } from '@angular/common';
// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
declare var $: any;

@Component({
  selector: 'app-purchase-report',
  templateUrl: './purchase-report.component.html',
  styleUrls: ['./purchase-report.component.css']
})
export class PurchaseReportComponent implements OnInit {

 constructor(private reportsService: ReportsService,private session:SessionStorageService,private router:Router,private commonsServices: CommonsService, private exportAsService:ExportAsService) { }

 exportAsConfig: ExportAsConfig;
	  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  
  authData:any={};
  serachReportData:any={};	
  reportData: any[] = [];
  config:any;
  partyList: any[] = [];
  petro_id:number;
  success_message:string;
  error_message:string;
  pump_name:number;
  pump_address:string;
  pump_mobile_no:number;
  pipe:any;
show_loading_image:boolean;
  
  searchReportForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		tmp_party_id : new FormControl(' '),
		
	});
	
  ngOnInit() {
	   this.authData = this.session.get('authData');
	 	this.petro_id = this.authData.petro_id;
	 	//console.log(this.authData);
	 	this.pump_name=this.authData.petro_name;
	 	this.pump_mobile_no=this.authData.petro_phone_no;
	 	this.pump_address=this.authData.petro_address;
		this.serachReportData.from_date=new Date(Date.now());
		this.serachReportData.to_date=new Date(Date.now());
		
		this.commonsServices.getLedgerListByGroup(constDefault.SUNDRY_CREDITOR_GROUP, this.petro_id)
			.subscribe(customerList => {
				if (customerList.data) {			
					this.partyList = Object.entries(customerList.data).map(([value, label]) => ({value,label}));		
			}
		});

  }
  getReportData() {
	  this.show_loading_image=true;
	  this.pipe = new DatePipe('en-US'); 
	  this.serachReportData.from_date= this.pipe.transform(this.serachReportData.from_date, 'yyyy-MM-dd');		
	  this.serachReportData.to_date= this.pipe.transform(this.serachReportData.to_date, 'yyyy-MM-dd');		
		if(this.serachReportData.tmp_party_id)
		{
			this.serachReportData.party_id=this.serachReportData.tmp_party_id.value;
		}
	 this.reportsService.getPurchaseReportData(this.petro_id,this.serachReportData)
	  .subscribe(reportAllData => {
	  // console.log(purchaseListData);
	   if(reportAllData.status==200)
		  {
				this.reportData=reportAllData.data;		
				
		  }
		  else if(reportAllData.status==403)
			{
				this.session.remove('authData');
				this.router.navigate(['']);
			}else{
				this.reportData=[];	
				this.total_amount=0;
				this.total_amount_tcs=0;				
			}	  
			this.show_loading_image=false;	
	  });
	}
	
	total_amount:number;
	total_amount_tcs:number;
 getTotal(tcs_amt,amount,i){
   
    if(i==0)   
    {
		this.total_amount_tcs=0;
        this.total_amount=0;
        
       // console.log(this.row_total);
    }

    this.total_amount=Number(this.total_amount)+Number(amount);
	this.total_amount_tcs=Number(this.total_amount_tcs)+Number(tcs_amt);
  }
  	
	exportTable(event) {
	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'stock_transfer_report');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }
// Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
}
