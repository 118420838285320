import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup,FormControl,Validators ,FormBuilder, FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { AccountService } from '../../../admin-services/account.service';
import { ShiftSaleService } from '../../../admin-services/shift-sale.service';
import { constDefault } from '../../../const_default';
import { CommonsService } from '../../../admin-services/commons.service';
import { MasterServicesService } from '../../../admin-services/master-services.service';
import { DatePipe } from '@angular/common';
import { DatepickerOptions } from 'ng2-datepicker';
import { Router} from '@angular/router';
import * as enLocale from 'date-fns/locale/en';
import { interval } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-sl-wallet-sale',
  templateUrl: './sl-wallet-sale.component.html',
  styleUrls: ['./sl-wallet-sale.component.css']
})
export class SlWalletSaleComponent implements OnInit {

  constructor(private router:Router,private commonsServices:CommonsService, private accountService: AccountService, private shiftSaleService: ShiftSaleService, private session:SessionStorageService,private masterService: MasterServicesService, private formBuilder: FormBuilder ) { }

  authData:any={};
  petro_id:number;
  cardCurrentPage: number;
  shift_date:any;
  dmy_shift_date:any;
	addWalletSaleData: any = {};
  convertWalletSaleData: any = {};
	viewCardSaleData: any = {};
	cardListAllData: any[] = [];
	cardSaleSummaryData: any[] = [];
	totalCardData: any = {};
  totalPhonepeData: any = {};
	success_message: string;
	success_card_message: string;
  error_message: string;
  transactionShiftData:any[]=[];
  shift_no:number;
  day_shift_no:any;
  addWalletSaleForm:FormGroup;
  convertWalletSaleForm:FormGroup;
  cardList = [];
  card_type:number;
  pipe:any;
  list_loading:boolean;
	add_loading:boolean; dsmList: any ={};
  dsm_wise_track:number;
  
    link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
  phonepeListAllData: any[] = [];
  view_wallet_type:number;
  view_pg_type:number;
	
	// card sale (Form)
   
	// view Card Sale (Form)
	/*viewCardSaleForm = new FormGroup({
	 id: new FormControl(' '),
	 amount: new FormControl('', Validators.required),
	 voucher_no: new FormControl('', Validators.required),
	 date: new FormControl('', Validators.required),
	 card_id: new FormControl('', Validators.required),
	 vehicle_no: new FormControl('', Validators.required),
	 batch_no: new FormControl('', Validators.required),
	 description: new FormControl(''),
	 shift: new FormControl('', Validators.required),
	});*/
	
  ngOnInit() {

    this.view_wallet_type=1;
	  this.view_pg_type=0;
	   // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
	  this.addWalletSaleData.dsm_id=null;
    this.convertWalletSaleData.dsmid=null;
    this.authData = this.session.get('authData');
    this.petro_id=this.authData.petro_id;
    this.card_type = constDefault.SALE_TYPE_WALLET;
    this.addWalletSaleForm = new FormGroup({
      amount: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      card_id: new FormControl('', Validators.required),
      vehicle_no: new FormControl('',Validators.pattern('^[a-zA-Z0-9]*$')),
      description: new FormControl(''),
	  dsm_id: new FormControl(''),
      shift: new FormControl('', Validators.required),
     });

     this.convertWalletSaleForm = new FormGroup({
       txnid: new FormControl('', Validators.required),
      amount: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      card_id: new FormControl('', Validators.required),
      vehicle_no: new FormControl('',Validators.pattern('^[a-zA-Z0-9]*$')),
      description: new FormControl(''),
	    dsm_id: new FormControl(''),
      shift: new FormControl('', Validators.required),
     });

     this.totalPhonepeData=0;

     this.autoLoad();
 
  }

  viewSaleType(type){
		if(type==0)
		{
			this.view_wallet_type=1;
      this.view_pg_type=0;
		
		}else{
      this.view_wallet_type=0;
      this.view_pg_type=1;
		}
	}

  autoLoad(){
		interval(5000).subscribe(x => {
			
		 this.getAutoloadPeningIndentList();
		});
	}

  getAutoloadPeningIndentList() {
    this.pipe = new DatePipe('en-US'); 
		this.shiftSaleService.autoloadPeningPhonepeList(this.petro_id,this.pipe.transform(this.shift_date, 'yyyy-MM-dd'))
			.subscribe(phonepeData => {
				if(phonepeData.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}
				this.phonepeListAllData = phonepeData.data
        this.totalPhonepeData=phonepeData.count;
   
        if (this.totalPhonepeData==0 && this.view_wallet_type==0){
          this.viewSaleType(0);
		
        }

				//console.log(this.indentListAllData);

			});
	}


  // get data from Shift Sale Transaction
	getTransactionShiftData(shiftData:any,genelarGeneralSetup:any,dsmList:any){
		if(genelarGeneralSetup)
		{
			if(genelarGeneralSetup.AppSetupGeneral.dsm_wise_track)
			{
				this.dsm_wise_track=genelarGeneralSetup.AppSetupGeneral.dsm_wise_track;
				if(genelarGeneralSetup.AppSetupGeneral.dsm_wise_track==1)
				{
				this.addWalletSaleForm.get('dsm_id').setValidators([Validators.required]);
        this.convertWalletSaleForm.get('dsm_id').setValidators([Validators.required]);

				}else{
					this.addWalletSaleForm.get('dsm_id').clearValidators();
          this.convertWalletSaleForm.get('dsm_id').clearValidators();
				}
			}
			else{
					this.addWalletSaleForm.get('dsm_id').clearValidators();
          this.convertWalletSaleForm.get('dsm_id').clearValidators();
				}
			}
		this.dsmList=dsmList;
		this.transactionShiftData = shiftData;
		//this.shift_no=Number(this.transactionShiftData['shift']);
		this.shift_no = shiftData.shift;
		this.shift_date = shiftData.date;
		this.day_shift_no = shiftData.day_shift;
		this.getPageWiseCardPaymentList(1);
	}

  // get card sale list
  getCardSaleLists(){
    this.commonsServices.getCardNameList(this.shift_no,this.petro_id,this.card_type)
    .subscribe(cardNamelistdata => {
      if(cardNamelistdata.data)
      {
    this.cardList = Object.entries(cardNamelistdata.data).map(([value, label]) => ({value,label}));

      }
    });
  }
  
  getpgCardLists(macid:number){
    this.commonsServices.getpgCardNameList(this.shift_no,this.petro_id,macid)
    .subscribe(cardNamelistdata => {
      if(cardNamelistdata.data)
      {
    this.cardList = Object.entries(cardNamelistdata.data).map(([value, label]) => ({value,label}));

    //this.card_id.
    this.convertWalletSaleData.card_id=this.cardList[0];

    //this.myselect.select(this.cardList[0].value);
      }
    });
  }
  public selected(value:any):void {
    console.log('Selected value is: ', value);
  }
  getPageWiseCardPaymentList(page: number) {
	  this.list_loading=true;
    this.cardCurrentPage = page;
    this.shiftSaleService.getCardListByShift(page, this.shift_no, this.petro_id,this.card_type)
     .subscribe(cardListData => {
     if(cardListData.status==200)
     {
      this.cardListAllData = cardListData.data,
      this.cardSaleSummaryData = cardListData.cardSaleSummary,
     this.totalCardData = cardListData.count
     }else{
          this.cardListAllData = [];
		  this.cardSaleSummaryData=[];
     this.totalCardData = 0
     }
	 this.list_loading=false;
     });
   }
    
   /* View Data On View Button Click */
   showAddWalletSale() {
	   this.pipe = new DatePipe('en-US'); 
		this.dmy_shift_date = this.pipe.transform(this.shift_date, 'd-M-yyyy');
    this.addWalletSaleData.shift = this.shift_no;
    this.addWalletSaleData.date = this.dmy_shift_date;
	  this.getCardSaleLists();
    $('#addWalletSaleModal').modal("show");
   }

   showConvertWalletSale(pgid:number,etxnid:string,txnamt:string) {
    this.pipe = new DatePipe('en-US'); 
   this.dmy_shift_date = this.pipe.transform(this.shift_date, 'd-M-yyyy');
   this.convertWalletSaleData.shift = this.shift_no;
   this.convertWalletSaleData.date = this.dmy_shift_date;
   this.convertWalletSaleData.shift = this.shift_no;
   this.convertWalletSaleData.txnid = etxnid;
   this.convertWalletSaleData.amount = txnamt;
   this.getpgCardLists(pgid);
   $('#convertWalletSaleModal').modal("show");
  }
    
   /* Add Card Sale Data */
   addWalletSales(wallet_sale) {
	   this.add_loading=true;
	   wallet_sale.submitted = true;
	   this.success_card_message='';
    this.authData = this.session.get('authData');
    this.addWalletSaleData.petro_id = this.authData.petro_id;
    this.addWalletSaleData.day_shift = this.day_shift_no;
    this.addWalletSaleData.card_id = this.addWalletSaleData.card_id.value;
    this.addWalletSaleData.type = this.card_type;
    this.shiftSaleService.addCardData(this.addWalletSaleData).subscribe(data => {
     if (data.status == 201) {
      this.success_card_message = 'Wallet has been created';
      //$('#addWalletSaleModal').modal('hide');
      this.getPageWiseCardPaymentList(1);
      //this.addWalletSaleForm.reset();
	  
	  this.addWalletSaleData.shift=this.shift_no; 
	  this.addWalletSaleData.date=this.dmy_shift_date; 
	  this.addWalletSaleData.card_id={'label':'','value':''}; 
	  this.addWalletSaleData.vehicle_no=''; 
	  this.addWalletSaleData.amount=''; 
	  this.addWalletSaleData.batch_no=''; 
	  this.addWalletSaleData.dsm_id=''; 
	  this.addWalletSaleData.description=''; 
    
    
     } else {
      this.error_message = 'Wallet could not be created';
     }
	 this.add_loading=false;
    });
   }


   convertWalletSales(wallet_sale) {
    this.add_loading=true;
    wallet_sale.submitted = true;
    this.success_card_message='';
   this.authData = this.session.get('authData');
   this.convertWalletSaleData.petro_id = this.authData.petro_id;
   this.convertWalletSaleData.day_shift = this.day_shift_no;
   this.convertWalletSaleData.card_id = this.convertWalletSaleData.card_id.value;

   console.log(this.convertWalletSaleData.card_id );
   this.convertWalletSaleData.type = 1;
   this.shiftSaleService.convertCardData(this.convertWalletSaleData).subscribe(data => {
    if (data.status == 201) {
     this.success_card_message = 'Wallet has been Converted';
     //$('#addWalletSaleModal').modal('hide');
     this.getAutoloadPeningIndentList();
     this.getPageWiseCardPaymentList(1);
     //this.addWalletSaleForm.reset();
   
   this.convertWalletSaleData.shift=this.shift_no; 
   this.convertWalletSaleData.date=this.dmy_shift_date; 
   this.convertWalletSaleData.card_id={'label':'','value':''}; 
   this.convertWalletSaleData.txnid=''; 
   this.convertWalletSaleData.vehicle_no=''; 
   this.convertWalletSaleData.amount=''; 
   this.convertWalletSaleData.batch_no=''; 
   this.convertWalletSaleData.dsm_id=''; 
   this.convertWalletSaleData.description=''; 
   $('#convertWalletSaleModal').modal("hide");
   
    } else {
     this.error_message = 'Wallet could not be Converted';
    }
  this.add_loading=false;
   });
  }
    
   /* Remove Data on Remove Button Click */
   removeCardSale(id) {
    if (confirm("Are you sure to delete this Wallet Details ")) {
     this.masterService.deleteDataByModel(id, 'CardSale').subscribe(data => {
      if (data.status == true) {
     this.success_message = "Wallet Details deleted sucessfully";
     this.getPageWiseCardPaymentList(1);
      } else {
     this.error_message = "Wallet Details could not be deleted.";
      }
     });
    }
   }

}
