import {Component,OnInit,Input} from '@angular/core';
import {FormGroup,FormControl,Validators,FormBuilder,FormArray} from '@angular/forms';
import {LocalStorageService,SessionStorageService,LocalStorage,SessionStorage} from 'angular-web-storage';
import {AccountService} from '../../admin-services/account.service';
import { CommonsService } from '../../admin-services/commons.service';
import {MasterServicesService} from '../../admin-services/master-services.service';
import {constDefault} from '../../const_default';
import {Router} from '@angular/router';
// Date Picker
import {DatepickerOptions} from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
declare var $: any;
import { DatePipe } from '@angular/common';
import { Alert } from 'selenium-webdriver';

@Component({
  selector: 'app-offer-master',
  templateUrl: './offer-master.component.html',
  styleUrls: ['./offer-master.component.css']
})

export class OfferMasterComponent implements OnInit {

	constructor(private accountService: AccountService, private commonsServices: CommonsService, private router: Router, private session: SessionStorageService, private masterService: MasterServicesService, private formBuilder: FormBuilder) {}
	// Options For From Date (Search)
	options_from: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,

		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'From Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {
			'width': '100%'
		}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};

	// Options For To Date (Search)
	options_to: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,

		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'To Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {
			'width': '100%'
		}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};

	// Options Date (Modal)
	options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,

		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {
			'width': '100%'
		}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};


	pipe: any;
	authData: any = {};
	addOffer: any = {};
	editOffer: any = {};
	success_message: string;
	error_message: string;
	petro_id: number;
	addOfferList: FormArray;
	addOfferForm: FormGroup;
	searchOffersData: any = {};
	editOfferFormData: any = {}
	currentPage: number;
	totalOffer: number;
	offersList: any[] = [];
	index: number;
	model: string;
	ItemList: any[] = [];
	offerEntry: any;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	searchOffersForm = new FormGroup({
		from_date: new FormControl(''),
		to_date: new FormControl('')
	});

	editOfferForm = new FormGroup({
		id: new FormControl(''),
		name: new FormControl('', Validators.required),
		item_name: new FormControl('', Validators.required),
		from_date: new FormControl('', Validators.required),
		to_date: new FormControl('', Validators.required),
	  // flat: new FormControl('', Validators.required),
	    flat: new FormControl(),
		discount_type: new FormControl('', Validators.required),
		t_and_c: new FormControl('')
	});


	ngOnInit() {
		this.btnPrevSession = this.session.get('ButtonAuth');
	if(this.btnPrevSession)
	{
		let urlArr=this.router.url.split('/');
		this.link_path=urlArr[2];
		let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
		this.delete_btn=result.UserPrivilege.delete_auth;
		this.edit_btn=result.UserPrivilege.edit_auth;
		this.create_btn=result.UserPrivilege.create_auth;
	}
		// Session read for petro id
		this.authData = this.session.get('authData');
		//console.log(this.authData)
		this.petro_id = this.authData.petro_id;
		this.pageWiseData(1);
		this.addOfferForm = this.formBuilder.group({
			addOfferList: this.formBuilder.array([this.addOfferItem()])
		});
		this.commonsServices.getIteamLsit(this.petro_id).subscribe(ItemData => {
			if (ItemData.data) {
				this.ItemList = Object.entries(ItemData.data).map(([value, label]) => ({
					value,
					label
				}));
			}
		})
	}

	// Get Offer List Fields for (Add) Form Array
	get addOfferFormData() {
		return this.addOfferForm.get('addOfferList')
	}


	// Function to add One Row in Add Offer Table (Add Modal)
	addItem() {
		this.addOfferList = this.addOfferForm.get('addOfferList') as FormArray;
		this.addOfferList.push(this.addOfferItem());
	}

	// Function to Remove One Row in Add Offer Table (Modal)
	removeAddItem(i) {
		if (confirm("Do you really want to Remove this Entry?")) {
			if (this.addOfferList.length > 1) {
				this.addOfferList.removeAt(i);
			} else if (this.addOfferList.length < 2) {}
		}
	}


	// function to add multiple Offer Entries 
	addOfferItem(): FormGroup {
		return this.formBuilder.group({
			name: [''],
			item_name: [''],
			from_date: [''],
			to_date: [''],
			flat: [''],
			discount_type: [0],
			t_and_c: [''],
		})
	}


	// Submit Offer Data
	addOfferData(add_offer) {
		add_offer.submitted = false;
		this.authData = this.session.get('authData');
		this.pipe = new DatePipe('en-US');
		this.addOffer.date = this.pipe.transform(this.addOffer.date, 'yyyy-MM-dd');
		this.addOfferForm.value.petro_id = this.authData.petro_id;
		//console.log(this.addOfferFormData.value);
		//return;
		this.masterService.addOffersData(this.addOfferForm.value).subscribe(data => {
			if (data.status == 201) {
				//console.log("Location Data ///////////"+data);
				this.success_message = 'Offers has been Added Successfully';
				this.pageWiseData(1);
				$("#addOfferModal").modal('hide');
			} else if (data.status == 403) {
				this.session.remove('authData');
				this.router.navigate(['']);
			} else {
				this.error_message = 'Offer could not be Added';
			}
		});
	}

	// Get Reminder Data in Model by Id
	getEditOffer(id, index) {
		this.index = index;
		this.masterService.getEditOfferById(id).subscribe(data => {
			console.log(data.data.Offer);
			this.offerEntry = data.data;
			this.editOfferFormData.id = this.offerEntry.Offer.id;
			this.editOfferFormData.name = this.offerEntry.Offer.name;
			this.editOfferFormData.item_name = this.offerEntry.Item.id;
			this.editOfferFormData.item_name = this.offerEntry.Item.name;
			this.editOfferFormData.from_date = this.offerEntry.Offer.from_date;
			this.editOfferFormData.to_date = this.offerEntry.Offer.to_date;
			this.editOfferFormData.flat = this.offerEntry.Offer.flat;
			this.editOfferFormData.discount_type = this.offerEntry.Offer.discount_type;
			this.editOfferFormData.t_and_c = this.offerEntry.Offer.t_and_c;
			$('#editOfferModal').modal('show');

		});

	}
	// Function to Search Offer Data
	searchOffers() {
		this.pageWiseData(1);
	}

	// Reset Search Data
	resetSearchOffer() {
		this.searchOffersData = {};
		this.pageWiseData(1);
	}

	// Pagination 
	pageWiseData(pageno) {
		this.currentPage = pageno;
		this.pipe = new DatePipe('en-US');
		this.searchOffersData.from_date = this.pipe.transform(this.searchOffersData.from_date, 'yyyy-MM-dd');
		this.searchOffersData.to_date = this.pipe.transform(this.searchOffersData.to_date, 'yyyy-MM-dd');
		console.log(this.searchOffersData)
		this.masterService.getOffersListData(pageno, this.petro_id, this.searchOffersData)
			.subscribe(alldata => {
				this.offersList = alldata.data,
					this.totalOffer = alldata.count
			});
	}

	// Delete 
	deleteOfferById(id, index) {
		if (confirm("Are you sure to delete this Offfer")) {
			this.index = index;
			this.masterService.deleteDataByModel(id, 'Offer').subscribe(data => {
				if (data.status == true) {
					this.offersList.splice(this.index, 1);
					this.success_message = "Offer deleted sucessfully";

				} else {
					this.error_message = "Offer could not be deleted.";
				}
			});

		}
	}

	// Edit Reminder Data and Sumbit Data From Reminder
	editOfferData(edit_offer) {
		edit_offer.submitted = false;
		//console.log(this.editOfferFormData.item_name);
		this.editOfferFormData.item_id = this.editOfferFormData.item_name.value;
		this.masterService.editOffersData(this.editOfferFormData).subscribe(data => {

			if (data.status == 200) {
				this.success_message = 'Offer has been updated';
				$('#editOfferModal').modal('hide');
				this.pageWiseData(1);
				this.editOfferForm.reset();
			} else if (data.status == 403) {
				this.session.remove('authData');
				this.router.navigate(['']);
			} else {
				this.error_message = 'Offer could not be updated';
			}
		});
	}

}
