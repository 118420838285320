import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsService } from '../../../admin-services/reports.service';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { FormGroup,FormControl,Validators ,FormBuilder,FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
declare var $: any;
@Component({
  selector: 'app-item-list-report',
  templateUrl: './item-list-report.component.html',
  styleUrls: ['./item-list-report.component.css']
})
export class ItemListReportComponent implements OnInit {
constructor(private reportsService: ReportsService,private session:SessionStorageService,private router:Router, private exportAsService:ExportAsService) { }
 exportAsConfig: ExportAsConfig;
  
  authData:any={};
  serachReportData:any={};	
  reportData: any[] = [];
  config:any;
  petro_id:number;
  success_message:string;
  error_message:string;
  pump_name:number;
  pump_address:string;
  pump_mobile_no:number;
  show_loading_image:boolean;
  ngOnInit() {
	   this.authData = this.session.get('authData');
	 	this.petro_id = this.authData.petro_id;
	 	//console.log(this.authData);
	 	this.pump_name=this.authData.petro_name;
	 	this.pump_mobile_no=this.authData.petro_phone_no;
	 	this.pump_address=this.authData.petro_address;

		this.reportsService.getItemListReportData(this.petro_id)
	  .subscribe(reportAllData => {
	  // console.log(purchaseListData);
	   if(reportAllData.status==200)
		  {
			this.reportData=reportAllData.data;
			 
		  }
		  else if(reportAllData.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.reportData=[];
		
		  }
	   
	  });
  }
 
	

  
  exportTable(event) {
	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'item_list_report');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }

  // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
  
  
}
