import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators ,FormBuilder, FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { AccountService } from '../../../admin-services/account.service';
import { ShiftSaleService } from '../../../admin-services/shift-sale.service';
import { constDefault } from '../../../const_default';
import { CommonsService } from '../../../admin-services/commons.service';
import { ApplicationSetupService } from '../../../admin-services/application-setup.service';
import { MasterServicesService } from '../../../admin-services/master-services.service';
import { DatePipe } from '@angular/common';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
import { Router} from '@angular/router';
declare var $: any;
@Component({
	selector: 'app-sl-sale',
	templateUrl: './sl-sale.component.html',
	styleUrls: ['./sl-sale.component.css']
})
export class SlSaleComponent implements OnInit {

	constructor(private router:Router, private commonsServices: CommonsService, private accountService: AccountService, private shiftSaleService: ShiftSaleService, private session: SessionStorageService, private masterService: MasterServicesService, private applicationSetupServices: ApplicationSetupService,private formBuilder: FormBuilder) {}

	// Sale
	credit_limit_error:string;
	vehicle_exist:string;
	sale_success_error:string;
	addSaleData: any = {};
	miscllCustList: any = {};
	addMiscllData:any = {};
	addVehicleData:any = {};
	addSaleForm: FormGroup;
	convertIndentForm:FormGroup;
	editSlipForm: FormGroup;
	addSaleItemList: FormArray;
	saleItemListData: any = {};
	creditSaleListAllData: any[] = [];
	saleDataForView: any[] = [];
	dsmList: any ={};
	addMiscllForm:FormGroup;
	addVehicleForm:FormGroup;
	dsm_wise_track:number;
	reset_cust_name:number;
	reset_item_name:number;
	slip_manual:number;
	arrlen:number;
	coupen_heading:string;
	error_message:string;
    web_url:string;
	view_sale_type:number;
	saleCurrentPage: number;
	mshsd_othersale: number;
	totalSaleData: number;
	qty: number;
	sale_type: number;
	rate: number;
	net_amt: any;
	dis_per: number;
	dis_amt: any;
	total_amt: any;
	item_id: number;
	batch_no: string;
	gst_type: number;
	cgst_per: number;
	sgst_per: number;
	igst_per: number;
	cgst_amt: any;
	sgst_amt: any;
	igst_amt: any;
	total_net_amt: any;
	total_sgst_amt: any;
	total_cgst_amt: any;
	total_igst_amt: any;
	total_dis_amt: any;
	total_gt_amt: any;
	gst_per_item: any;
	viewSaleStatus: number;
	type_ms_hsd: number;
	inclusive_amt: number;
	inclusive_rate: any;
	total_inclusive_amt: any;
	credit_sale_type: number;
	location_showroom: number;
	stockdata: number;
	i: number;
  logo_path:string;
  total_amount:any;
	// Sale Indeant
	convert_index: number;
	indentListAllData: any[] = [];
	converIndentData: any = {};
	editIndentData:any = {};
	editSaleData:any = {};
	nozzleDropdownListData: any[] = [];
	last_indent_id: number;

	shift_date: any;
	dmy_shift_date: any;
	shift_no: number;
	day_shift_no: number;
	authData: any = {};
	petro_id: number;
	itemAutoSugestList: any[] = [];
	selectedPeople2 = [];
	customer_list = [];
	vehicle_list = [];
	bozer_list = [];
	general_setup_data = [];
	pipe:any;
	loading:boolean;	
	add_sale_loading:boolean;	
	view_sale_loading:boolean;	
	convert_loading:boolean;	
	show_bozer: number;
	tmp_show_bozer: number;
	

	// Edit Endent sale
	editIndentForm = new FormGroup({
		indent_id: new FormControl('', Validators.required),
		quantity: new FormControl('', Validators.required),
	});

	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];

	ngOnInit() {
		
		// Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		this.addSaleData.dsm_id=null;
		//this.addSaleData.bozer_id=null;
		this.loading=false;
		
		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		this.view_sale_type=constDefault.CASH_SALE;
		this.credit_sale_type=constDefault.CREDIT_SALE;
		this.location_showroom=constDefault.LOCATION_SHOWROOM;
		this.slip_manual=1;
		this.coupen_heading='Coupen No';
		this.commonsServices.getLedgerListByGroup(constDefault.SUNDRY_DEBTOR_GROUP, this.petro_id)
		.subscribe(customerList => {
			if (customerList.data) {
				
				this.selectedPeople2 = Object.entries(customerList.data).map(([value, label]) => ({value,label}));
			
		}
			this.selectedPeople2.push({value: constDefault.MISCELLANEOUES_LEDGER, label: "MISCELLANEOUS"});
		});

		this.type_ms_hsd=constDefault.ITEM_TYPE_MSHSD;
		this.getAutoloadPeningIndentList();
		this.gst_type = constDefault.GST_TYPE_CGST; // Default gst type for sale
		this.addSaleData.gst_type = constDefault.GST_TYPE_CGST; // Default gst type for sale
		this.addSaleData.date = new Date(Date.now());
		
		this.addSaleForm = this.formBuilder.group({
			date: new FormControl('', Validators.required),
			shift: new FormControl('', Validators.required),
			customer_id: new FormControl('', Validators.required),
			miscll_cust_id: new FormControl(''),		
			state_id: new FormControl('', Validators.required),
			gst_type: new FormControl('', Validators.required),
			total_net_amt: new FormControl('', Validators.required),
			total_sgst_amt: new FormControl('0'),
			total_cgst_amt: new FormControl('0'),
			total_igst_amt: new FormControl(''),
			total_dis_amt: new FormControl('0'),
		    vno: new FormControl('',Validators.pattern('^[a-zA-Z0-9]*$')),
			vehicle_no: new FormControl(''),		
			total_inclusive_amt: new FormControl('0'),
			cash_to_driver: new FormControl('0'),
			include_driver_cash:new FormControl('0'),
			total_gt_amt: new FormControl('0', Validators.required),
			dsm_id: new FormControl(''),
			bozer_id: new FormControl(''),
			slip_no: new FormControl(''),
			coupen_no: new FormControl(''),
			addSaleItemList: this.formBuilder.array([this.addSaleItem()])
		});


		this.convertIndentForm = new FormGroup({
			indent_id: new FormControl('', Validators.required),
			shift: new FormControl('', Validators.required),
			date: new FormControl('', Validators.required),
			commodity_id: new FormControl('', Validators.required),
			commodity_name: new FormControl('', Validators.required),
			commodity: new FormControl(''),
			quantity: new FormControl(''),
			qnt_amount: new FormControl(''),
			dsm_idi: new FormControl(''),
			price: new FormControl(''),
			cash_to_driver: new FormControl(''),
		});

		this.addMiscllForm = this.formBuilder.group({
			name:new FormControl('',Validators.required)
		});
		this.addVehicleForm = this.formBuilder.group({
			name:new FormControl('',Validators.required)
		});
			this.editSlipForm = this.formBuilder.group({
			sale_id:new FormControl('',Validators.required),
			slip_no:new FormControl('',Validators.required),
		});
		 // Get invoice setp data

	   this.commonsServices.getInvoiceSetupData(this.petro_id)
		.subscribe(data =>{
		  if(data.status==200){			
			this.slip_manual=data.data.AppSetupInvoice.slip_no_manual;
			if(data.data.AppSetupInvoice.indent_no_head)
			{
			this.coupen_heading=data.data.AppSetupInvoice.indent_no_head;
			}
		}
	 	});
			 // Get sale setp data
      this.reset_item_name=0;
      this.reset_cust_name=0;
	   this.applicationSetupServices.getSaleSetupData(this.petro_id)
		.subscribe(data =>{
		  if(data.status==200){			
			this.reset_cust_name=data.data.AppSetupSale.reset_cust_name;
			this.reset_item_name=data.data.AppSetupSale.reset_item_name;
		
		}
	 	});
		
       
	     this.web_url =constDefault.API_URL;
		
	}

	getBozerList()
	{
		this.bozer_list =[];
	   // alert(this.addSaleData.customer_id.value);
	   // alert( $('#venicle_no_id').text());
				this.commonsServices.getBozerList(this.petro_id)
			   .subscribe(vehicleList => {
				   
				   if (vehicleList.status == 200) {
					   
				   this.bozer_list = Object.entries(vehicleList.data).map(([value, label]) => ({value,label}));
					   
				   }
			   });
	}

	viewSaleType(type){
		if(type==constDefault.CREDIT_SALE)
		{
			this.view_sale_type=constDefault.CREDIT_SALE;
			this.getPageWiseSaleList(0);
		}else{
			this.view_sale_type=constDefault.CASH_SALE;
			this.getPageWiseSaleList(0);
		}
	}


	// get data from Shift Sale Transaction
	getTransactionShiftData(shiftData: any,genelarGeneralSetup:any,dsmList:any) {
		// check dsm wise track
		if(genelarGeneralSetup)
		{
			
			if(genelarGeneralSetup.AppSetupGeneral.dsm_wise_track )
			{
				this.dsm_wise_track=genelarGeneralSetup.AppSetupGeneral.dsm_wise_track;
				if(genelarGeneralSetup.AppSetupGeneral.dsm_wise_track==1)
				{
				this.addSaleForm.get('dsm_id').setValidators([Validators.required]);
				this.convertIndentForm.get('dsm_idi').setValidators([Validators.required]);
			
				}else{
					this.addSaleForm.get('dsm_id').clearValidators();
					this.convertIndentForm.get('dsm_idi').clearValidators();
				}
			}
			else{
					this.addSaleForm.get('dsm_id').clearValidators();
					this.convertIndentForm.get('dsm_idi').clearValidators();
				
			}

			if(genelarGeneralSetup.AppSetupGeneral.show_bozer)
			{
				this.show_bozer=genelarGeneralSetup.AppSetupGeneral.show_bozer;
				this.tmp_show_bozer=this.show_bozer;
				this.getBozerList();
			}

		}
		this.dsmList=dsmList;
		this.general_setup_data=genelarGeneralSetup;
			this.shift_date = shiftData.date;
		 this.pipe = new DatePipe('en-US'); 
		this.dmy_shift_date = this.pipe.transform(this.shift_date, 'd-M-yyyy');
		this.shift_no = shiftData.shift;
		
		this.day_shift_no = shiftData.day_shift;
		this.view_sale_type=constDefault.CREDIT_SALE;
		this.getPageWiseSaleList(1);
	}

	// Add Miscllenious Customer
	addMiscllenious(){
		this.addMiscllData.petro_id = this.petro_id;
		this.addMiscllData.ledger_id = constDefault.MISCELLANEOUES_LEDGER;
		this.shiftSaleService.addMiscllenious(this.addMiscllData)
		.subscribe(addData => {
			if (addData.status == 200 || addData.status == 201) {
				this.shiftSaleService.getMisclleniousCustList(this.petro_id)
				.subscribe(misCustList =>{
					this.customer_list = Object.entries(misCustList.data).map(([value, label]) => ({value,label}));
				})
				$('#addMiscllenious').modal('hide');
				this.getPageWiseSaleList(1);
			}
		});
	}

//Open Add Sale popup
	openAddSaleModal() {
		console.log(this.show_bozer);
		if (this.show_bozer==1)
			this.tmp_show_bozer=1;
	
			console.log(this.tmp_show_bozer);
		this.addSaleData={};
		this.credit_limit_error="";
		//this.addSaleForm.reset();
		this.addSaleForm.setControl('addSaleItemList', this.formBuilder.array([this.addSaleItem()]));
		this.mshsd_othersale = 0;
		
		this.addSaleData.date = this.dmy_shift_date;
		this.addSaleData.shift = this.shift_no;
		this.addSaleData.customer_id = "";
		this.addSaleData.vehicle_no = "";
		this.sale_type = constDefault.CREDIT_SALE;
		this.shiftSaleService.getMisclleniousCustList(this.petro_id)
		.subscribe(misCustList =>{
			if(misCustList.data)
			{
			this.customer_list = Object.entries(misCustList.data).map(([value, label]) => ({value,label}));
			}
		});
		$('#addSaleModal').modal('show');
	}
	///Open Add Cash Sale popup
	openAddCashSaleModal() {
	     this.tmp_show_bozer=0;
		this.addSaleData={};
		this.credit_limit_error="";
		//this.addSaleForm.reset();
		this.addSaleForm.setControl('addSaleItemList', this.formBuilder.array([this.addSaleItem()]));
		this.mshsd_othersale = 0;
		
		this.addSaleData.date = this.dmy_shift_date;
		this.addSaleData.shift = this.shift_no;
		this.sale_type = constDefault.CASH_SALE;
		//this.addSaleForm.value.customer_id=constDefault.CASH_LEDGER;	
		this.addSaleData.customer_id = 'CASH';
		
		this.addSaleData.state_id = this.authData.state_id;
		this.addSaleData.gst_type = constDefault.GST_TYPE_CGST;
		$('#addSaleModal').modal('show');
		//console.log(this.addSaleData);
	}
///OPen after submit modal 
openAddSaleCreditModal(){


	   	if(this.reset_cust_name == 0)
		{
			this.addSaleData.customer_id = "";
			this.vehicle_list = [];
				this.shiftSaleService.getMisclleniousCustList(this.petro_id)
			.subscribe(misCustList =>{
				if(misCustList.data)
				{
				this.customer_list = Object.entries(misCustList.data).map(([value, label]) => ({value,label}));
				}
			});
		}

  	if(this.reset_item_name == 1)
		{
			this.addSaleItemList = this.addSaleForm.get('addSaleItemList') as FormArray;
			this.arrlen=this.addSaleItemList.length;

				 for(this.i=0;this.i<this.arrlen;this.i++)
				 {
					this.addSaleItemList.controls[this.i].get('qty').setValue('');
					this.addSaleItemList.controls[this.i].get('total_amt').setValue('');
						
				 }
    }else{
		this.addSaleForm.setControl('addSaleItemList', this.formBuilder.array([this.addSaleItem()]));
	}
   
		this.addSaleData.total_net_amt="";
		this.addSaleData.total_dis_amt="";
		this.addSaleData.total_gt_amt="";
		this.addSaleData.cash_to_driver="";
		this.addSaleData.total_inclusive_amt="";
		this.addSaleData.total_sgst_amt="";
		this.addSaleData.total_cgst_amt="";
		this.addSaleData.total_igst_amt="";
		this.addSaleData.slip_no="";
		this.addSaleData.coupen_no="";
		this.credit_limit_error="";
	     this.addSaleData.include_driver_cash = 0;
		
		this.addSaleData.date = this.dmy_shift_date;
		this.addSaleData.shift = this.shift_no;
	
		this.addSaleData.vehicle_no = "";
		this.sale_type = constDefault.CREDIT_SALE;
	
		$('#addSaleModal').modal('show');
	}
///end

	addSaleItem(): FormGroup {
		return this.formBuilder.group({
			item_id: ['', Validators.required],
			// item_name: ['', Validators.required],
			batch_no: [''],		
			qty: ['', Validators.required],
			rate: ['', Validators.required],
			inclusive_rate: ['', Validators.required],
			inclusive_total: ['', Validators.required],
			net_amt: ['', Validators.required],
			gst_rate: ['0'],
			cgst_per: ['0'],
			sgst_per: ['0'],
			igst_per: ['0'],
			cgst_amt: ['0'],
			sgst_amt: ['0'],
			igst_amt: ['0'],
			dis: [''],
			dis_amt: [''],
			total_amt: ['', Validators.required],
          
		})
	}

	// Function to add One More Field
	addSaleNewItem() {
		this.addSaleItemList = this.addSaleForm.get('addSaleItemList') as FormArray;
		this.addSaleItemList.push(this.addSaleItem());
	}

	// Function to Remove One Field
	removeSaleItem(i) {
		if (confirm("Do you really want to Delete this Item?")) {
			if (this.addSaleItemList.length > 1) {
				this.addSaleItemList.removeAt(i);
				this.calculateTotal();
			}

		}
	}

	get addSaleFormData() {
		return this.addSaleForm.get('addSaleItemList')
	}

	/*
	 Amit Shau
	 Get Customer Data by id;
	 20.11.18
	 */

	 // 
	onChangeCustomer(){	
       this.vehicle_list = [];	
		this.commonsServices.getCustomerDataById(this.addSaleData.customer_id.value).subscribe(data => {
			this.addSaleData.state_id = data.data.PartyDetail.state_id;
		    
		
			if(this.addSaleData.customer_id.value==constDefault.MISCELLANEOUES_LEDGER){
				// get miscllenious customer information from server  
				this.commonsServices.getCustomerDataById(this.petro_id)
				.subscribe(data=>{
					this.miscllCustList=data;
				});
				$('#show_miscllenious').show();
				$('#add_cust').show();
				this.addSaleData.state_id =this.authData.state_id;

				this.gstTypeFind(this.authData.state_id);
				
			}
			else{
				$('#show_miscllenious').hide();
				$('#add_cust').hide();
				this.gstTypeFind(data.data.PartyDetail.state_id);

			}
		
		});
		
		
    }
  
	// Find GST Type 
	gstTypeFind(state_id) {
		if (this.authData.state_id == state_id) {
			this.gst_type = constDefault.GST_TYPE_CGST;
		} else {
			this.gst_type = constDefault.GST_TYPE_IGST;
		}
		this.addSaleData.gst_type = this.gst_type;
		this.calculateTotal();
	}


	//Get Sale List
	cash_sale_count:any;
	credit_sale_count:any;
	getPageWiseSaleList(page: number) {
		this.credit_sale_count="";
		this.cash_sale_count="";
		this.loading=true;
		this.saleCurrentPage = page;
		this.shiftSaleService.getSaleListByTypeOrShift(page, this.shift_no, this.view_sale_type, this.petro_id)
			.subscribe(saleListData => {
				if (saleListData.status == 200) {
					this.creditSaleListAllData = saleListData.data,
						this.totalSaleData = saleListData.count
						if(this.view_sale_type==constDefault.CASH_SALE)
						{
							this.cash_sale_count=saleListData.count;
						}
						else if(this.view_sale_type==constDefault.CREDIT_SALE)
						{
							this.credit_sale_count=saleListData.count;
						}
				} else {
					this.creditSaleListAllData = [];
					this.totalSaleData = 0;
				}
				this.loading=false;
			});
	}

	viewSale(sale_id: number) {
		this.view_sale_loading=true;
		this.shiftSaleService.getSaledataById(sale_id)
			.subscribe(saleData => {
				this.saleDataForView = saleData.data;
				if (saleData.status == 200) {
					this.viewSaleStatus = 1;
						this.logo_path=saleData.data.img,
						this.total_amount=Math.round(saleData.data.Sale.total_amount).toFixed(2),
					this.view_sale_loading=false;
				}
			});
		$('#viewSaleModal').modal('show');
	}


	// Function For Add Sale

	addSale(add_sale) {
	
		this.add_sale_loading=true;
		add_sale.submitted=false;
		if(this.addSaleData.miscll_cust_id){
			this.addSaleForm.value.miss_customer_id=this.addSaleForm.value.miscll_cust_id.value;
		}
console.log (this.addSaleData.include_driver_cash);
		if(this.addSaleData.include_driver_cash){
			this.addSaleForm.value.include_driver_cash=this.addSaleData.include_driver_cash;
		}
		if (this.sale_type == constDefault.CASH_SALE) {

			this.addSaleForm.value.customer_id = constDefault.CASH_LEDGER;
			//this.addSaleForm.value.vehicle_no = ;
		} else {
			this.addSaleForm.value.customer_id = this.addSaleForm.value.customer_id.value;

            if(this.addSaleForm.value.bozer_id!=null)
			this.addSaleForm.value.bozer_id = this.addSaleForm.value.bozer_id.value;
			
			//this.addSaleForm.value.vehicle_no = this.addSaleForm.vehicle_no;
		}
		this.addSaleForm.value.ms_hsd_sale = this.mshsd_othersale;
		this.addSaleForm.value.sale_type = this.sale_type;
		this.addSaleForm.value.petro_id = this.petro_id;
		this.addSaleForm.value.day_shift = this.day_shift_no;
		this.addSaleForm.value.date = this.dmy_shift_date;
		//this.addSaleForm.value.include_driver_cash. = this.include_driver_cash;

	
		
		
		
		this.shiftSaleService.addSale(this.addSaleForm.value)
			.subscribe(addData => {
				if (addData.status == 200 || addData.status == 201) {
					//$('#addSaleModal').modal('hide');
					// reset data
					this.sale_success_error='Add Sale successfully';
					if(this.reset_item_name == 0)
					{
					   this.addSaleForm.setControl('addSaleItemList', this.formBuilder.array([this.addSaleItem()]));
					}
				
					//this.mshsd_othersale = 0;
					this.getPageWiseSaleList(1);
					this.stockdata = 0;
					this.vehicle_list = [];
				//document.getElementById('stockClearId').innerHTML = "0";
					//this.addSaleForm.reset();
				//	$('#stockClearId').val();
				
					if(this.sale_type == constDefault.CASH_SALE)
					{
					this.openAddCashSaleModal();
					
					//this.onselectItemOnSale(event,-1);
				
					}else if(this.sale_type == constDefault.CREDIT_SALE){
						this.openAddSaleCreditModal();
					
					//	this.onselectItemOnSale(event,-1);
					}
				}
				else if (addData.status == 202) {
				this.credit_limit_error='Your Credit limit is over. Total credit amount is '+addData.credit_amt+' and your maximum credit  limit is '+addData.credit_limit ;
				}
				else if (addData.status == 203) {
					this.credit_limit_error='Your Credit limit will be over after this sale. Total credit amount is '+addData.credit_amt+' and your maximum credit  limit is '+addData.credit_limit ;
				}
					this.add_sale_loading=false;
			});
	}

	/*---------------------------------------------Auto Complete----------------------------------*/
	public filterItemList(value: any): void {
		this.commonsServices.getItemByNameOrType(value, this.petro_id, this.mshsd_othersale)
		.subscribe(alldata => {
			this.itemAutoSugestList = Object.entries(alldata.data).map(([value, label]) => ({
				value,
				label
			}));
		});
	}
stockArr:any={};
	// function in select item on sale
	onselectItemOnSale(event, un) {
		if (event) {
			this.shiftSaleService.getItemDetailsByItemIdForSale(event.value,this.shift_no)
				.subscribe(itemData => {
					if (itemData.data.Item.item_category_id == constDefault.ITEM_CATEGORY_MS || itemData.data.Item.item_category_id == constDefault.ITEM_CATEGORY_HSD || itemData.data.Item.item_category_id == constDefault.ITEM_CATEGORY_BMS || itemData.data.Item.item_category_id == constDefault.ITEM_CATEGORY_BHSD) {
						this.mshsd_othersale = constDefault.ITEM_TYPE_MSHSD;
						$('.sale_rate').attr('readonly','readonly');
					} else {
						this.mshsd_othersale = constDefault.ITEM_TYPE_OTHER;
						$('.sale_rate').removeAttr('readonly');
					}
					this.onselectItemOnSaleCalc(itemData.data, un);
					
					//this.getNozzleList(nozzlelistdata.data,nozzlelistdata.status);
				});
				
				//display current stock 
			
				this.addSaleItemList = this.addSaleForm.get('addSaleItemList') as FormArray;
			   this.shiftSaleService.getStockItemLocation(this.petro_id,event.value,this.location_showroom, this.addSaleItemList.value[un]['batch_no'])
			   .subscribe(alldata => {
				if(alldata.status==200)
				{
					this.stockdata=1;
			    	this.stockArr[un]=alldata.data.Stock.stock_quantity;
				}else{
					this.stockArr[un]=0;
				}
			});
				
			//end
		} else {
			this.addSaleItemList = this.addSaleForm.get('addSaleItemList') as FormArray;
			this.addSaleItemList.controls[un].get('rate').setValue('');
			this.addSaleItemList.controls[un].get('gst_rate').setValue('');
			this.addSaleItemList.controls[un].get('cgst_per').setValue('');
			this.addSaleItemList.controls[un].get('sgst_per').setValue('');
			this.addSaleItemList.controls[un].get('igst_per').setValue('');
			this.addSaleItemList.controls[un].get('net_amt').setValue('');
			this.addSaleItemList.controls[un].get('total_amt').setValue('');
			this.calculateTotal();
		}
	}

	onselectItemOnSaleCalc(itemData, un) {
		this.addSaleItemList = this.addSaleForm.get('addSaleItemList') as FormArray;
		//this.addSaleItemList.controls[un].get('item_id').setValue(itemData.Item.id);
			
	
		if (itemData.Item.item_category_id == constDefault.ITEM_CATEGORY_MS || itemData.Item.item_category_id == constDefault.ITEM_CATEGORY_HSD || itemData.Item.item_category_id == constDefault.ITEM_CATEGORY_BMS || itemData.Item.item_category_id == constDefault.ITEM_CATEGORY_BHSD) {
				this.addSaleItemList.controls[un].get('rate').setValue(itemData.commodity_price);
	
		} else {
				this.addSaleItemList.controls[un].get('rate').setValue(itemData.Item.mrp);
		}
		
		
		this.addSaleItemList.controls[un].get('gst_rate').setValue(itemData.GstMaster.gst_percentage);
		this.addSaleItemList.controls[un].get('cgst_per').setValue(itemData.GstMaster.cgst);
		this.addSaleItemList.controls[un].get('sgst_per').setValue(itemData.GstMaster.sgst);
		this.addSaleItemList.controls[un].get('igst_per').setValue(itemData.GstMaster.igst);

		if (itemData.Item.batch_wise == 1) {
			$('#batch_no' + un).removeAttr('disabled');
		} else {
			$('#batch_no' + un).attr('disabled', 'disabled');
			this.addSaleItemList.controls[un].get('batch_no').setValue('');
		}
		this.rowWiseTotalSaleItem(un)
	}

	// Sale Item Row on discount key up 
	onDiscountPerkeyup(row) {
		this.rowWiseTotalSaleItem(row);
	}
	// Sale Item Row on quantity key up 
	onQtykeyup(row) {
		this.addSaleItemList = this.addSaleForm.get('addSaleItemList') as FormArray;
		this.qty = this.addSaleItemList.value[row]['qty'];
		this.item_id = this.addSaleItemList.value[row]['item_id'].value;
		this.batch_no = this.addSaleItemList.value[row]['batch_no'];
		if (!this.batch_no) {
			this.batch_no = " ";
		}
		if(this.mshsd_othersale == constDefault.ITEM_TYPE_OTHER)
		{
			this.rowWiseTotalSaleItem(row);
		/*this.shiftSaleService.getItemStockByIdAndBatchNo(this.item_id, this.batch_no, this.petro_id)
			.subscribe(stockdata => {
				this.setMaxStcokSaleItemQty(stockdata.data, row)
			});*/
		}else{
			this.rowWiseTotalSaleItem(row);
		}
	} 

	setMaxStcokSaleItemQty(stock: number, row) {
		this.addSaleItemList = this.addSaleForm.get('addSaleItemList') as FormArray;
		this.qty = parseFloat(this.addSaleItemList.value[row]['qty']);
		if(this.qty){
			if (stock < this.qty) {
			this.addSaleItemList.controls[row].get('qty').setValue(stock);
			} 
		}
		this.rowWiseTotalSaleItem(row);
	}
	rowWiseTotalSaleItem(row) {
		this.addSaleItemList = this.addSaleForm.get('addSaleItemList') as FormArray;
		this.item_id = this.addSaleItemList.value[row]['item_id'].value;
		if (this.item_id) {
			this.qty = this.addSaleItemList.value[row]['qty'];
			this.rate = this.addSaleItemList.value[row]['rate'];
			this.dis_per = this.addSaleItemList.value[row]['dis'];

			this.net_amt = this.qty * this.rate;
			this.net_amt =this.net_amt .toFixed(2)
			this.dis_amt = (this.net_amt / 100) * this.dis_per;
			this.dis_amt=this.dis_amt .toFixed(2);
			this.total_amt = this.net_amt - this.dis_amt;
			this.total_amt=this.total_amt.toFixed(2);
			this.addSaleItemList.controls[row].get('dis_amt').setValue(this.dis_amt);
			this.addSaleItemList.controls[row].get('net_amt').setValue(this.net_amt);
			this.addSaleItemList.controls[row].get('total_amt').setValue(this.total_amt);

			this.cgst_per = this.addSaleItemList.value[row]['cgst_per'];
			this.sgst_per = this.addSaleItemList.value[row]['sgst_per'];
			this.igst_per = this.addSaleItemList.value[row]['igst_per'];
			
			/*this.cgst_amt = (this.total_amt / 100) * this.cgst_per;
			this.sgst_amt = (this.total_amt / 100) * this.sgst_per;
			this.igst_amt = (this.total_amt / 100) * this.igst_per;*/
			// Inclusive gst
			
			this.cgst_amt = (Number(this.total_amt) * this.cgst_per) / (100+Number(this.igst_per));
			this.sgst_amt = (Number(this.total_amt) * this.sgst_per) / (100+Number(this.igst_per));
			this.igst_amt = (Number(this.total_amt) * this.igst_per) / (100+Number(this.igst_per));
			this.inclusive_amt=this.total_amt-this.igst_amt;
			// Inclusive rate
			this.gst_per_item=(Number(this.rate) * this.igst_per) / (100+Number(this.igst_per));
			this.inclusive_rate=this.rate-this.gst_per_item;
			this.inclusive_rate=this.inclusive_rate.toFixed(2);
			
			this.cgst_amt=this.cgst_amt.toFixed(2);
			this.sgst_amt=this.sgst_amt.toFixed(2);
			this.igst_amt=this.igst_amt.toFixed(2);

			this.addSaleItemList.controls[row].get('cgst_amt').setValue(this.cgst_amt);
			this.addSaleItemList.controls[row].get('sgst_amt').setValue(this.sgst_amt);
			this.addSaleItemList.controls[row].get('igst_amt').setValue(this.igst_amt);
			this.addSaleItemList.controls[row].get('inclusive_total').setValue(this.inclusive_amt);
			this.addSaleItemList.controls[row].get('inclusive_rate').setValue(this.inclusive_rate);
		}
		this.calculateTotal();
	}

	calculateTotal() {
		this.addSaleItemList = this.addSaleForm.get('addSaleItemList') as FormArray;

		this.total_net_amt = 0;
		this.total_sgst_amt = 0;
		this.total_cgst_amt = 0;
		this.total_igst_amt = 0;
		this.total_dis_amt = 0;
		this.total_gt_amt = 0;
		this.total_inclusive_amt = 0;

		this.addSaleItemList.value.forEach((itemData, index) => {
			this.total_net_amt = Number(this.total_net_amt) + Number(this.addSaleItemList.value[index]['net_amt']);
			this.total_sgst_amt = Number(this.total_sgst_amt) + Number(this.addSaleItemList.value[index]['sgst_amt']);
			this.total_cgst_amt = Number(this.total_cgst_amt) + Number(this.addSaleItemList.value[index]['cgst_amt']);
			this.total_igst_amt = Number(this.total_igst_amt) + Number(this.addSaleItemList.value[index]['igst_amt']);
			this.total_dis_amt = Number(this.total_dis_amt) + Number(this.addSaleItemList.value[index]['dis_amt']);
			this.total_gt_amt = Number(this.total_gt_amt) + Number(this.addSaleItemList.value[index]['total_amt']);
			this.total_inclusive_amt = Number(this.total_inclusive_amt) + Number(this.addSaleItemList.value[index]['inclusive_total']);
				
		});
		
		
	
		if(this.total_net_amt)
		{
		 this.total_net_amt=Number(this.total_net_amt);
		 this.total_net_amt=this.total_net_amt.toFixed(2);
		}
		if(this.total_dis_amt)
		{
		 this.total_dis_amt=Number(this.total_dis_amt);
		 this.total_dis_amt=this.total_dis_amt.toFixed(2);
		}
		this.addSaleData.total_net_amt = this.total_net_amt;
		this.addSaleData.total_dis_amt = this.total_dis_amt;
		if (this.gst_type == constDefault.GST_TYPE_IGST) {
			this.total_sgst_amt = 0;
			this.total_cgst_amt = 0;
		} else {
			this.total_igst_amt = 0;
		}
		if(this.total_igst_amt)
		{
		 this.total_igst_amt=Number(this.total_igst_amt);
		 this.total_igst_amt=this.total_igst_amt.toFixed(2);
		}
		if(this.total_sgst_amt)
		{
		 this.total_sgst_amt=Number(this.total_sgst_amt);
		 this.total_sgst_amt=this.total_sgst_amt.toFixed(2);
		}if(this.total_cgst_amt)
		{
		 this.total_cgst_amt=Number(this.total_cgst_amt);
		 this.total_cgst_amt=this.total_cgst_amt.toFixed(2);
		}
		/*if(!this.total_igst_amt)
		{
			this.total_igst_amt=0;
		}
		if(!this.total_sgst_amt)
		{
			this.total_sgst_amt=0;
		}
		if(!this.total_cgst_amt)
		{
			this.total_cgst_amt=0;
		}*/
		if(this.total_inclusive_amt)
		{
			this.total_inclusive_amt=Number(this.total_inclusive_amt);
	    	this.total_inclusive_amt=this.total_inclusive_amt.toFixed(2);
		}
		this.addSaleData.total_igst_amt = this.total_igst_amt;
		this.addSaleData.total_sgst_amt =this.total_sgst_amt;
		this.addSaleData.total_cgst_amt = this.total_cgst_amt;
		this.addSaleData.total_inclusive_amt = this.total_inclusive_amt;
		this.addSaleData.total_gt_amt = Number(this.total_gt_amt).toFixed(2);
	}

	/*---------------------------------------------End Auto Complete----------------------------------*/

	// Indent
	getAutoloadPeningIndentList() {
		this.shiftSaleService.autoloadPeningIntendList(this.petro_id)
			.subscribe(indentData => {
				if(indentData.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}
				this.indentListAllData = indentData.data
				//console.log(this.indentListAllData);

			});
	}

	/*
	Indent Convert To Sale 
	*/
	price:number;
	total_quant:any;
	indentConvertSale(convert_index: number, indent_id: number, commodity_id: number, commodity_name: string, qty_type: number, qty: number,driver_cash:any, qnt_amount:any,item_id:number) {
		
		this.shiftSaleService.getPriceByShift(item_id,this.shift_no,this.petro_id)
		.subscribe(get_price => {
			//console.log(get_price.data.Price.price);
			this.price=get_price.data.Price.price

			this.credit_limit_error="";
			this.convert_index = convert_index;
			this.converIndentData.shift = this.shift_no;
			this.converIndentData.date = this.dmy_shift_date;
			this.converIndentData.commodity_id = commodity_id;
			this.converIndentData.commodity_name = commodity_name;
			this.converIndentData.cash_to_driver = driver_cash;
			this.converIndentData.indent_id = indent_id;
			this.converIndentData.price = this.price;
			this.converIndentData.dsm_idi = null;
			//this.converIndentData.qnt_amount = 0;
			// if qty_type = 0 (value in amount_quantity)
			
			if (qty_type == 0) {
				this.converIndentData.quantity = Number(qty);
				this.total_quant = qty * this.price;
				this.total_quant = parseFloat(this.total_quant).toFixed(2)
				
				this.converIndentData.qnt_amount = this.total_quant;
				
			}

			// if qty_type = 1 (full tank)
			else if(qty_type==1){
				//this.total_quant = qty * this.price;
				this.converIndentData.qnt_amount = 0
				this.converIndentData.quantity = 0;
				//console.log(this.converIndentData.qnt_amount)
			}

			// if qty_type = 2 (value in qty)
			else if(qty_type==2){				
				this.total_quant =  qnt_amount / this.price;
				this.total_quant = parseFloat(this.total_quant).toFixed(2);
				this.converIndentData.quantity =this.total_quant;
				this.converIndentData.qnt_amount = qnt_amount;
			}
			else{
				this.converIndentData.quantity = 0;
				this.converIndentData.qnt_amount = 0;
			}
			
		})
		
		$('#convertIndentToSaleModal').modal('show');
	}

	qty_value:number;
	calc_amount(key,price){
		//console.log(key);
		if(key.keyCode==9){
			this.qty_value = this.converIndentData.quantity;
			key = this.qty_value * price;			
			key = Number(key);
			this.converIndentData.qnt_amount = parseFloat(key).toFixed(2);
		}

	}

	calc_quantity(key,price){
		if(key.keyCode==9){
			this.qty_value = this.converIndentData.qnt_amount;
			key = this.qty_value / price;			
			key = Number(key);
			this.converIndentData.quantity = parseFloat(key).toFixed(2);
		}
	}
 
	convertIndent() {	
		this.convert_loading=true;
		

		this.converIndentData.day_shift = this.day_shift_no;
		
		//console.log(this.converIndentData);
		this.credit_limit_error="";
		//console.log(this.converIndentData);return;
		this.shiftSaleService.converIdentToCreditSale(this.converIndentData)
		.subscribe(addData => {
		
			if (addData.status == 201) {
				if(this.indentListAllData)
				this.indentListAllData.splice[this.convert_index, 1];

				this.getAutoloadPeningIndentList();
				this.getPageWiseSaleList(1);
				$('#convertIndentToSaleModal').modal('hide');
				
			}
			else if (addData.status == 202) {
				this.credit_limit_error='Your Credit limit is over. Total credit amount is '+addData.credit_amt+' and your maximum credit  limit is '+addData.credit_limit ;
			}
			else if (addData.status == 203) {
				this.credit_limit_error='Your Credit limit will be over after this sale. Total credit amount is '+addData.credit_amt+' and your maximum credit  limit is '+addData.credit_limit ;
			}
			this.convert_loading=false;
		});
	}


	editIndentSale(convert_index: number, indent_id: number, commodity_id: number, commodity_name: string, qty_type: number, qty: number,) {
		this.editIndentData.indent_id = indent_id;
		if (qty_type == 1) {
			this.editIndentData.quantity = qty;
		}
		$('#editIndentModal').modal('show');
	}
 
	editIndent() { 
		this.shiftSaleService.editIndents(this.editIndentData)
		.subscribe(editData => {
			if (editData.status == 201) {
				this.indentListAllData.splice[this.convert_index, 1];
				this.getPageWiseSaleList(1);
				this.getAutoloadPeningIndentList();
				$('#editIndentModal').modal('hide');
			}
		});
	}

	// Delete shift sale transiction sale converted from indent
	deleteSale(sale_id,index){
			if (confirm("Are you sure to delete this Sale Entry")) {
			   //this.model = 'Voucher';
			   this.shiftSaleService.deleteSale(sale_id).subscribe(data => {
				if (data.status == 200) {
				   this.creditSaleListAllData.splice(index, 1);
				   //this.success_message = "Voucher deleted sucessfully";
				   this.getPageWiseSaleList(1);
				   this.getAutoloadPeningIndentList();
				} else if(data.status == 205){
					alert('Invoice created, sale will not be deleted');
				}
				else {
					alert('Sale could not be deleted');
				   //this.error_message = "Voucher could not be deleted.";
				}
			   });
			   //this.editname=this.editCategoryData.name;	
		}
	}
	/********************Qty By amt*****************************/
	qtyByAmt(k){
		$('#tAmt'+k).removeAttr('readonly');
	}	
	qty_str:string;
	onTotalAmtKeyUp(row){
		this.addSaleItemList = this.addSaleForm.get('addSaleItemList') as FormArray;
		this.item_id = this.addSaleItemList.value[row]['item_id'].value;
		if (this.item_id) {
			//this.qty = this.addSaleItemList.value[row]['qty'];
			this.rate = this.addSaleItemList.value[row]['rate'];			
			this.total_amt = this.addSaleItemList.value[row]['total_amt'];			
			this.qty=Number(this.total_amt)/Number(this.rate);
			this.qty_str=this.qty.toFixed(2);
			this.qty=Number(this.qty_str);
			//this.net_amt = this.qty * this.rate;
			//this.net_amt =this.net_amt .toFixed(2)
			//this.dis_amt = (this.net_amt / 100) * this.dis_per;
			//this.dis_amt=this.dis_amt .toFixed(2);
			//this.total_amt = this.net_amt - this.dis_amt;
			//this.total_amt=this.total_amt.toFixed(2);
			this.addSaleItemList.controls[row].get('dis_amt').setValue('');
			this.addSaleItemList.controls[row].get('net_amt').setValue(this.total_amt);
			this.addSaleItemList.controls[row].get('inclusive_total').setValue(this.total_amt);
			this.addSaleItemList.controls[row].get('qty').setValue(this.qty);

			this.cgst_per = this.addSaleItemList.value[row]['cgst_per'];
			this.sgst_per = this.addSaleItemList.value[row]['sgst_per'];
			this.igst_per = this.addSaleItemList.value[row]['igst_per'];

			//this.cgst_amt = (this.total_amt / 100) * this.cgst_per;
			//this.sgst_amt = (this.total_amt / 100) * this.sgst_per;
			//this.igst_amt = (this.total_amt / 100) * this.igst_per;
			//this.cgst_amt=this.cgst_amt.toFixed(2);
			//this.sgst_amt=this.sgst_amt.toFixed(2);
			//this.igst_amt=this.igst_amt.toFixed(2);

			this.addSaleItemList.controls[row].get('cgst_amt').setValue('0');
			this.addSaleItemList.controls[row].get('sgst_amt').setValue('0');
			this.addSaleItemList.controls[row].get('igst_amt').setValue('0');
		}
		this.calculateTotal();
	}
 onChangeVehicle()
 {
	// alert(this.addSaleData.customer_id.value);
	// alert( $('#venicle_no_id').text());
	 		this.commonsServices.getVehicleList(this.petro_id,this.addSaleData.customer_id.value)
			.subscribe(vehicleList => {
				
				if (vehicleList.status == 200) {
					
				this.vehicle_list = Object.entries(vehicleList.data).map(([value, label]) => ({value,label}));
					
				}
			});
 }
 addVehicles()
 {
	 this.vehicle_exist="";
	 	this.addVehicleData.petro_id = this.petro_id;
		this.addVehicleData.ledger_id = this.addSaleData.customer_id.value;
		if(this.addVehicleData.ledger_id != null)
		{
		
		this.addVehicleData.vehicle_no = this.addVehicleData.name;
		this.shiftSaleService.addVehicle(this.addVehicleData)
		.subscribe(addData => {
			if (addData.status == 200 || addData.status == 201) {
				
				this.commonsServices.getVehicleList(this.petro_id,this.addVehicleData.ledger_id)
			.subscribe(vehicleList => {
				 this.vehicle_list = Object.entries(vehicleList.data).map(([value, label]) => ({value,label}));	
			  });
			   this.addVehicleData.name='';
				$('#addVehicles').modal('hide');
				this.getPageWiseSaleList(1);
			}else if(addData.status == 204 ) {
				this.vehicle_exist ="Vehicle already available";
			}
		});
		}else{
				this.vehicle_exist ="Customer not selected";
		}
 }
 
 	printComponent() {
		var printContents = document.getElementById("print_invoice").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()"> <div style="width:600px;height:800px;margin:auto;margin-bottom:190px;">' + printContents + '</div></body></html>');
		popupWin.document.close();
	}
	
	
	//edit view sale for slip number
 editViewSale(sale_id: number,slip_no:number) {	
	   this.editSaleData.sale_id = sale_id;
	   this.editSaleData.slip_no = slip_no;
	   $('#editSaleModal').modal('show');
	}
	
	
	editSale() { 
	this.editSaleData.petro_id = this.petro_id;
		this.shiftSaleService.editSales(this.editSaleData)
		.subscribe(editSData => {
			if (editSData.status == 200) {
				this.getPageWiseSaleList(1);
				$('#editSaleModal').modal('hide');
			}
		});
	}

	
	
	
	
	
}
