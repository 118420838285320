import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { CommonsService } from '../../admin-services/commons.service';
//import { uniqueMobileValidation } from '../../shared/unique-mobile.directive';
import { FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';

import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-customer-master',
  templateUrl: './customer-master.component.html',
  styleUrls: ['./customer-master.component.css']
})
export class CustomerMasterComponent implements OnInit {

 constructor(private masterService: MasterServicesService, private router:Router,private session:SessionStorageService, private commonsService:CommonsService, private formBuilder: FormBuilder ) { }
	
	authData:any={};
	addCustomerData:any={};	
	editCustomerData:any={};	
	value:string;
	serachCustomerData:any={};	
	stateDropdownList : any[] = [];
	cityDropdownList : any[] = [];
	customerList : any[] = [];

	success_message:string;
	error_message:string;
	currentPage:number;
	totalCat:number;
	petro_id:number;
	index:number;
	add_loading:boolean;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	succeAddData:{
				Ledger:{
					id:number,						
					name:string
				},
				PartyDetail:{
					mobile_no:string,						
					email:string,						
					phone:string,	
					address:string,						
					pin_code:number,						
					fax:string,						
					aoc:string,						
					ao_charges:string,						
					tin:string,						
					cst:string,
					gstin:string,
					pan:string,						
					int_per:string,						
					credit_limit:string,						
					credit_charges:string,						
					credit_days:number,
						State:{
							name:string
						},
						City:{
							name:string
						}
					}
			};
	

	addCustomerForm:FormGroup;

	editCustomerForm= new FormGroup({
		id : new FormControl(' ',Validators.required),
		name : new FormControl(' ',Validators.required),
	//	mobile_no : new FormControl(' ',[Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
		mobile_no : new FormControl(' '),
		//email : new FormControl(' ',[Validators.email]),
		email : new FormControl(' '),
		phone : new FormControl(' '),
		state_id : new FormControl(' ',Validators.required),
	//	state_id : new FormControl(' '),
	//	city_id : new FormControl(' ',Validators.required),
		city_id : new FormControl(' '),
	//	address : new FormControl(' ',Validators.required),
		address : new FormControl(' '),
		pin_code : new FormControl(' '),
		fax : new FormControl(' '),
		aoc : new FormControl(' '),
		ao_charges : new FormControl(' '),
		tin : new FormControl(' '),
		cst : new FormControl(' '),
		gstin : new FormControl(' '),
	//	pan : new FormControl(' ',Validators.required),
		pan : new FormControl(' '),
		int_per : new FormControl(' '),
		credit_limit : new FormControl(' '),
		credit_charges : new FormControl(' '),
		credit_days : new FormControl(' '),
		is_android_app : new FormControl(' '),
		android_user_limit : new FormControl(' '),
		
	});	
	serachCustomerForm= new FormGroup({
	
		name : new FormControl(' '),
	
		
	});	
  
  
  
  ngOnInit() {
	  this.btnPrevSession = this.session.get('ButtonAuth');
	if(this.btnPrevSession)
	{
		let urlArr=this.router.url.split('/');
		this.link_path=urlArr[2];
		let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
		this.delete_btn=result.UserPrivilege.delete_auth;
		this.edit_btn=result.UserPrivilege.edit_auth;
		this.create_btn=result.UserPrivilege.create_auth;
	}
	this.authData = this.session.get('authData');
	this.petro_id=this.authData.petro_id;
	
	this.addCustomerForm= this.formBuilder.group({
		name : new FormControl(' ',Validators.required,uniqueByModalOrNameValidation(this.commonsService,'Ledger',this.petro_id)),
		//mobile_no : new FormControl(' ',[Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')],uniqueMobileValidation(this.commonsService)),
		mobile_no : new FormControl(' '),
	//	email : new FormControl(' ',[Validators.email]),
		email : new FormControl(' '),
		phone : new FormControl(' ',[Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
		state_id : new FormControl(' ',Validators.required),
		city_id : new FormControl(' '),
		address : new FormControl(' '),
		pin_code : new FormControl(' '),
		fax : new FormControl(' '),
		aoc : new FormControl(' '),
		ao_charges : new FormControl(' '),
		tin : new FormControl(' '),
		cst : new FormControl(' '),
		gstin : new FormControl(' '),
		pan : new FormControl(' '),
		int_per : new FormControl(' '),
		credit_limit : new FormControl(' '),
		credit_charges : new FormControl(' '),
		credit_days : new FormControl(' '),
		is_android_app : new FormControl(''),
		android_user_limit : new FormControl(' ')
	});
	this.addCustomerData.state_id = 4;
	this.addCustomerData.city_id = 0;
	this.addCustomerData.is_android_app = 0;
	this.onSelectState();
	
	this.commonsService.getStates(1)
		.subscribe(stateListdata =>{ 
			this.stateDropdownList=stateListdata.data
	});
	 
	this.pageWiseCustomerList(1);
	 
	
  }
  // Validation for only numbers
//   numberOnly(value) {
//     this.addCustomerData.pin_code = value.replace(/[^0-9]/g,'');
//   }

  // validation for GST number
  
  get name(){
	  return this.addCustomerForm.get('name');
  }
  
  onSelectState(){
	  
	  this.commonsService.getCities(this.addCustomerData.state_id)
	.subscribe(citiesListdata =>{ this.cityDropdownList=citiesListdata.data});
	
  }
    onSelectStateForEdit(){
	  
	  this.commonsService.getCities(this.editCustomerData.state_id)
	.subscribe(citiesListdata =>{ this.cityDropdownList=citiesListdata.data});
	
  }
  
  
  /*
  Get Data for Customer list shoq
  Amit Shau
  20.11.18
  */
    pageWiseCustomerList(pageno){
	   this.currentPage=pageno;
	   this.masterService.getCustomerListData(pageno,this.petro_id,this.serachCustomerData)
		.subscribe(alldata =>{
			if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.customerList=alldata.data,
			this.totalCat=alldata.count	
			}
	 });

   } 
  
    /*
  Add Category 
  Amit Sahu
  16.11.18
  */
   addCustomer(add_cust)
  {
	  this.add_loading=true;
	add_cust.submitted = false;
	this.authData = this.session.get('authData');
	this.addCustomerData.petro_id=this.authData.petro_id;
	this.masterService.addNewCustomer(this.addCustomerData).subscribe(data=>{
		if(data.status==200)
		{		
			this.success_message='Customer has been created';
			$('#addCustomerModal').modal('hide');
			this.pageWiseCustomerList(1);
			this.addCustomerForm.reset();
			this.addCustomerData.state_id = 0;
			this.addCustomerData.city_id = 0;
			this.addCustomerData.is_android_app = 0; 
			
		}else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.error_message='Customer could not be created';
		}
		this.add_loading=false;
	});
  }

  /*
  Amit Shau
  Get Customer Data For Edit;
  20.11.18
  */
   getCustomerData(id,index)
  {
	
	this.index=index;
	//this.name=this.addCategoryData.name;
	//this.parent_id=this.addCategoryData.parent_id;
	//this.masterService.addNewCategory(this.name,this.parent_id,this.addCategoryData);
	this.masterService.getEidtCustomerData(id).subscribe(data=>{
		//console.log(data)
		this.editCustomerData.state_id=data.data.PartyDetail.state_id;
		this.onSelectStateForEdit();
		
		this.editCustomerData.id=data.data.Ledger.id;						
		this.editCustomerData.name=data.data.Ledger.name;						
		this.editCustomerData.mobile_no=data.data.PartyDetail.mobile_no;						
		this.editCustomerData.email=data.data.PartyDetail.email;						
		this.editCustomerData.phone=data.data.PartyDetail.phone;						
		this.editCustomerData.state_id=data.data.PartyDetail.state_id;						
		this.editCustomerData.city_id=data.data.PartyDetail.city_id;						
		this.editCustomerData.address=data.data.PartyDetail.address;						
		this.editCustomerData.pin_code=data.data.PartyDetail.pin_code;						
		this.editCustomerData.fax=data.data.PartyDetail.fax;						
		this.editCustomerData.aoc=data.data.PartyDetail.aoc;						
		this.editCustomerData.ao_charges=data.data.PartyDetail.ao_charges;						
		this.editCustomerData.tin=data.data.PartyDetail.tin;						
		this.editCustomerData.cst=data.data.PartyDetail.cst;						
		this.editCustomerData.gstin=data.data.PartyDetail.gstin;						
		this.editCustomerData.pan=data.data.PartyDetail.pan;						
		this.editCustomerData.int_per=data.data.PartyDetail.int_per;						
		this.editCustomerData.credit_limit=data.data.PartyDetail.credit_limit;						
		this.editCustomerData.credit_charges=data.data.PartyDetail.credit_charges;						
		this.editCustomerData.credit_days=data.data.PartyDetail.credit_days;			
		this.editCustomerData.is_android_app=data.data.PartyDetail.is_user_app;			
		this.editCustomerData.android_user_limit=data.data.PartyDetail.limit_of_user;			

		$('#editCustomerModal').modal('show');
	});	
  }
  
  /*
  Amit Sahu
  Edit Customer
20.11.18*/  
   editCustomer(edit_cust)
  {
	edit_cust.submitted=false;
	this.masterService.editCustomer(this.editCustomerData).subscribe(data=>{
		if(data.status==200)
		{			
			this.success_message='Customer has been updated';
			$('#editCustomerModal').modal('hide');
				this.succeAddData={
				Ledger:{
					id:data.data.ledger_id,						
					name:data.data.name
				},
				PartyDetail:{
					mobile_no:data.data.mobile_no,						
					email:data.data.email,						
					phone:data.data.phone,						
				//	state_id:data.data.state_id,						
					//city_id:data.data.city_id,						
					address:data.data.address,						
					pin_code:data.data.pin_code,						
					fax:data.data.fax,						
					aoc:data.data.aoc,						
					ao_charges:data.data.ao_charges,						
					tin:data.data.tin,						
					cst:data.data.cst,						
					gstin:data.data.gstin,						
					pan:data.data.pan,						
					int_per:data.data.int_per,						
					credit_limit:data.data.credit_limit,						
					credit_charges:data.data.credit_charges,						
					credit_days:data.data.credit_days,
						State:{
							name:data.data.state_name
						},
						City:{
							name:data.data.city_name
						}
					}
			}
			this.customerList[this.index]=this.succeAddData;
			//console.log(this.categoryList);
			this.editCustomerForm.reset(); 
			
		}else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.error_message='Customer could not be updated';
		}
	});
  }

/*
  Delete Customer 
  Amit Sahu
  19.11.18
  */
   deleteCustomer(id,index)
  {
	if(confirm("Are you sure to delete this Customer ")) { 
	this.index=index;

	this.masterService.deleteDataByModel(id,'Ledger').subscribe(data=>{
		if(data.status==true)
		{
		this.customerList.splice(this.index,1);
		this.success_message="Customer deleted sucessfully";		
		
		}else{
			this.error_message="Customer could not be deleted.";
		}
	});
	}
  }
  /*
  Amit Sahu
  Search Customer-master
  19.11.18
  */
  searchCustomer()
  {
	 
	 this.pageWiseCustomerList(1);
  }
  
  resetSearchCustomer(){
	     this.serachCustomerData={};
		this.pageWiseCustomerList(1);
  }

  is_app_selected(event){
	//console.log(event);
	if(event.target.value==1){
		this.addCustomerForm.get('pan').setValidators(Validators.required);

		$('#usr_limit').show();
		
		
	}
	else{
		this.addCustomerForm.get('pan').clearValidators();

		$('#usr_limit').hide();
		
	}
  }
  
    is_app_selected_edit(event){
	//console.log(event);
	if(event.target.value==1){
		this.editCustomerForm.get('pan').setValidators(Validators.required);

	
	}
	else{
		this.editCustomerForm.get('pan').clearValidators();

		
	}
  }
}
