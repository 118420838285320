import { Component,Output,EventEmitter, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators ,FormBuilder, FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { AccountService } from '../../../../admin-services/account.service';
import { constDefault } from '../../../../const_default';
import { MasterServicesService } from '../../../../admin-services/master-services.service';


// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
declare var $: any;

@Component({
  selector: 'app-edit-contra-voucher-ledger',
  templateUrl: './edit-contra-voucher-ledger.component.html',
  styleUrls: ['./edit-contra-voucher-ledger.component.css']
})
export class EditContraVoucherLedgerComponent implements OnInit {

  constructor(private accountService: AccountService, private session:SessionStorageService,private masterService: MasterServicesService, private formBuilder: FormBuilder ) { }

@Output() buttonClick:EventEmitter<any>=new EventEmitter<any>();
  options_form: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,
    
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'From Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  // Options For To Date (Search)
  options_to: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,
    
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'To Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  // Options Date (Modal)
  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: enLocale,
    
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  authData:any={};
  addVoucher:any={};
  editVoucher:any={};
  cashBankLedgerList:any[]=[];
  ledgerListData:any[]=[];
  success_message:string;
  error_message:string;
  petro_id:number;
  addVoucherList:FormArray;
  addVoucherForm:FormGroup;
  credit_ledger:number;
  debit_ledger:number;
  voucher_ledger_type:number;
  serachVoucherData : any={};
  currentPage:number;
  totalPayVoucher:number;
  voucherList:any[]=[];
  editVoucherList:FormArray;
  index:number;
  model:string;
  


  editVoucherForm =this.formBuilder.group({
    vid:new FormControl(' ',Validators.required),
    date: new FormControl(' ',Validators.required),
    ledger_id: new FormControl(' ',Validators.required),
    ledger_name: new FormControl(' ',Validators.required),
    dr_cr: new FormControl(' ',Validators.required),
    cheque_no: new FormControl(' '),
    cheque_date: new FormControl(' '), 
    amount: new FormControl(' ',Validators.required),
    narration: new FormControl(' ',Validators.required),
    editVoucherList:this.formBuilder.array([])
  });


  ngOnInit() {
    $("#cheque_info").hide();
		// Session read for petro id
		this.petro_id=this.authData.petro_id;
    this.authData = this.session.get('authData');
		this.addVoucher.date=new Date(Date.now());
    this.addVoucher.cheque_date=new Date(Date.now());	
    // Cash and Bank list
    this.accountService.getCashAndBankLedgerList(this.petro_id).subscribe(ledgerdata=>{
      this.cashBankLedgerList= Object.entries(ledgerdata.data).map(([value, label]) => ({value,label}));	
    })
    // Ledger List
    this.accountService.getLedgerListForVoucher(this.petro_id).subscribe(ledgerdata=>{
      this.ledgerListData= Object.entries(ledgerdata.data).map(([value, label]) => ({value,label}));
		})
		
		
		this.credit_ledger = constDefault.CREDIT_LEDGER;
		this.debit_ledger = constDefault.DEBIT_LEDGER;
    this.voucher_ledger_type = constDefault.CASH_LEDGER;
    
   
   
    
  }

  // Show check info options if selected ledget value is not cash for Edit Contra Voucher
  show_edit_check_option(value:any){
    //console.log(value.value);
    // this.editVoucher.ledger_id = value.value;
    // console.log(this.editVoucher.ledger_id);
    if(this.editVoucher.ledger_id.value!=this.voucher_ledger_type){
      //alert();
      $("#edit_cheque_info").show();
    }else{
			$("#edit_cheque_info").hide();
			this.editVoucher.cheque_info.value = "";
			this.editVoucher.cheque_date.value = "";
		}
  }



  // Function to add Multiple voucher Entries (Edit Voucher)
	editVoucherItem(): FormGroup {
		return this.formBuilder.group({
			dr_cr: [constDefault.CREDIT_LEDGER, Validators.required],
			ledger_id: ['', Validators.required],
			ledger_name: ['', Validators.required],
			amount: ['', Validators.required]
		})
  }
  


	// Function to Remove One Row in Edit Voucher Table (Modal)
	removeEditItem(i) {
		if (confirm("Do you really want to Delete this Entry?")) {
			if (this.editVoucherList.length > 1) {
				this.editVoucherList.removeAt(i);
				this.getEditTotal(i)
			} else if (this.editVoucherList.length < 2) {
				//alert();
			}
		}
	}

  
  // Get Voucher List Fields for (Edit) Form Array
	get editVoucherFormData() {return this.editVoucherForm.get('editVoucherList')}


  // Function to add a row in Edit Voucher Table (Edit Modal)
	addEditItem() {
		this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
		this.editVoucherList.push(this.editVoucherItem());
	}
  
  cr_amount: number;
	dr_amount: number;
	
  cr_total_view: number;
	dr_total_view: number;
	voucher_info: any = [];
	voucher_details: any = [];
	petro_name: string;
	v_date:string;
	v_no:number;
	test1: any[] = [];
	sum: number;
	viewStatus: boolean;
 


	// Get data to Edit Contra voucher data by id
	edit_index: number;
	getEditVoucher(id, index) {
		this.editVoucherForm.setControl('editVoucherList', this.formBuilder.array([]));
		$('#editContraModal').modal('show');

		this.accountService.getContraVoucherDataById(id).subscribe(data => {
			//console.log(data);
			this.editVoucher.date = data.data.Voucher.date;
			this.editVoucher.vid = data.data.Voucher.id;
			if (data.data.Voucher.cheque_no == null) {
				//alert(data.data.Voucher.cheque_no);
				$('#edit_cheque_info').hide();
			} else {
				this.editVoucher.cheque_no = data.data.Voucher.cheque_no;
				if(data.data.Voucher.cheque_date)
				{
				this.editVoucher.cheque_date = data.data.Voucher.cheque_date;
				}
			}

			this.editVoucher.amount = data.data.Voucher.amount;
			this.editVoucher.dr_cr = data.data.Voucher.dr_cr;
			this.editVoucher.ledger_id = data.data.Ledger.id;
			this.editVoucher.ledger_name = data.data.Ledger.name;
			this.editVoucher.narration = data.data.Voucher.narration;

			for (let voucher_entry of data.data.VoucherDetail) {
				this.edit_index = 1;
				if (data.data.Voucher.ledger_id == voucher_entry.ledger_id) {

				} else {
					this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
					this.editVoucherList.push(
						this.formBuilder.group({
							dr_cr: [voucher_entry.dr_cr, Validators.required],
							ledger_id: [voucher_entry.ledger_id, Validators.required],
							ledger_name: [voucher_entry.Ledger.name, ''],
							amount: [voucher_entry.amount, '']
						})
					);

				}
			}
			this.getEditTotal(this.edit_index)
		});
  }
  
  total_credit: number;
	total_debit: number;
  voucherDetails: any[];
  


	cr_Edit_amount: number;
	dr_Edit_amount: number;

	getEditTotal(index) {
		this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
		//console.log(index);
		this.cr_Edit_amount = 0;
		this.dr_Edit_amount = 0;
		this.editVoucherList.value.forEach((data, index) => {

			if (this.editVoucherList.value[index]['dr_cr'] == constDefault.CREDIT_LEDGER) {
				this.cr_Edit_amount = this.cr_Edit_amount + Number(this.editVoucherList.value[index]['amount']);
			}

			if (this.editVoucherList.value[index]['dr_cr'] == constDefault.DEBIT_LEDGER) {
				this.dr_Edit_amount = this.dr_Edit_amount + Number(this.editVoucherList.value[index]['amount']);
			}
		})
		//console.log(this.dr_Edit_amount);
  }


  // Edit Voucher data and Save Data 
	editVoucherData(edit_voucher) {
		edit_voucher.submitted=false;
		 this.editVoucher.voucherDetails = this.editVoucherList.value;
		//console.log("Dr_Cr : "+this.editVoucher.dr_cr);
		
		// If ledger type is cash then cheque info should be empty
		if(this.editVoucher.ledger_id==this.voucher_ledger_type){
			this.editVoucher.cheque_no = "";
			this.editVoucher.cheque_date = "";
		}
    if(this.editVoucher.dr_cr==this.debit_ledger){
      this.total_debit = Number(this.editVoucher.amount) + Number(this.dr_Edit_amount);
     // console.log(this.editVoucher.dr_cr);
      if (this.cr_Edit_amount == this.total_debit){
        //console.log(this.cr_amount+" "+this.total_debit);
        this.accountService.editContraVoucher(this.editVoucher).subscribe(data => {
          		if (data.status == 200) {
          			this.success_message = 'Voucher has been created';
          			$('#editContraModal').modal('hide');
          			
          			this.editVoucherForm.reset();
					this.buttonClick.emit(event);
          		} else {
          			//this.error_message = 'Item category could not be created';
          			this.error_message = 'Voucher could not be created';
							}
							this.editVoucherForm.reset();
          	});
      }
      else{
        this.error_message = 'Amount not matching';
      }
    }

    if(this.editVoucher.dr_cr==this.credit_ledger){
      this.total_credit = Number(this.editVoucher.amount) + Number(this.cr_Edit_amount);
      console.log(this.total_credit);
			if (this.dr_Edit_amount == this.total_credit){
			  console.log(this.cr_amount+" "+this.total_debit);
			  this.accountService.editContraVoucher(this.editVoucher).subscribe(data => {
			    		if (data.status == 200) {
			    			this.success_message = 'Voucher has been created';
			    			$('#editContraModal').modal('hide');
			    	
			    			this.editVoucherForm.reset();
			    		} else {
			    			//this.error_message = 'Item category could not be created';
			    			this.error_message = 'Voucher could not be created';
							}
							this.editVoucherForm.reset();
			    	});
			}else{
			  this.error_message = 'Amount not matching';
			}
    }
	}
  
  

// Get ledger value on change ledger list and set id to form array list option
	getLedgerId(value, i) {
		//console.log(value)
		this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
		this.editVoucherList.controls[i].get('ledger_id').setValue(value.value);
		//console.log(this.editVoucherList)
  }

  

	
}
