import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
import { CommonsService } from '../../admin-services/commons.service';

import { Router} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-dispenser-master',
  templateUrl: './dispenser-master.component.html',
  styleUrls: ['./dispenser-master.component.css']
})
export class DispenserMasterComponent implements OnInit {

  constructor(private masterService: MasterServicesService, private router:Router,private session:SessionStorageService, private commonsService:CommonsService, private formBuilder: FormBuilder) { }
  authData:any={};
  petro_id:number;
  success_message:string;
	error_message:string;
	index:number;
	model:string;
	currentPage:number;
  totalCat:number;
  add_loading:boolean;
  addDispenserData:any={};
  editDispenserData:any={}	
  dispenserList: any[] = [];
  searchDispenserData:any={};
  
  link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
  succeAddDispData:{
		Dispenser:{
			id:any,
      name:string,
      nozzle_quantity:number,
		}
	}

	addDispenserForm:FormGroup;
  /*addDispenserForm = new FormGroup({
    name : new FormControl(' ',[Validators.required]),
    nozzle_quantity : new FormControl(' ',[Validators.required])
  })*/

  editDispenserForm = new FormGroup({
	  id : new FormControl(''),
    name : new FormControl(' ',[Validators.required]),
    nozzle_quantity : new FormControl(' ',[Validators.required])

	})
	
	searchDispenserForm= new FormGroup({
		name : new FormControl(' '),
	});

  ngOnInit() {
   
   // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
    this.authData = this.session.get('authData');
    this.petro_id=this.authData.petro_id;
	
	this.addDispenserForm = this.formBuilder.group({
    name : new FormControl(' ',Validators.required, uniqueByModalOrNameValidation(this.commonsService,'Dispenser',this.petro_id)),
    nozzle_quantity : new FormControl(' ',[Validators.required])
  })
    //console.log("Location Data ///////////"+this.petro_id);
    // Get Dispenser Lists
    this.pageWiseData(1);
  }

  
   get name(){
	  return this.addDispenserForm.get('name');
  }
  
  // Add Dispenser data
  addDispenser(add_disp)
	{
		this.add_loading=true;
		add_disp.submitted = false;
		this.authData = this.session.get('authData');
    this.addDispenserData.petro_id=this.authData.petro_id;
    
		this.masterService.addDispenserData(this.addDispenserData).subscribe(data=>{
			//alert(data);
			if(data.status==200)
			{
				
				this.success_message='Dispenser has been created';
			$('#addDispenserModal').modal('hide');
			// this.succeAddDispData={
			// 	Dispenser:{
			// 		id:data.data.id,
      //     name:data.data.name,
      //     nozzle_quantity:data.data.nozzle_quantity
			// 	}
			// }
			// this.dispenserList.unshift(this.succeAddDispData);
			this.pageWiseData(1);
			this.addDispenserForm.reset(); 
			
		}else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.error_message='Dispenser could not be created';
		}
		this.add_loading=false;
		});
	}

  // Get Data to Edit
  getDispenserData(id,index){
			
    this.index=index;
    this.masterService.getEidtDispenserData(id).subscribe(data=>{
	if(data.status==403)
			{
				  this.session.remove('authData');
				   this.router.navigate(['']);
			}else
			{
				this.editDispenserData.id=data.data.Dispenser.id;
				this.editDispenserData.name=data.data.Dispenser.name;	
				this.editDispenserData.nozzle_quantity=data.data.Dispenser.nozzle_quantity;	  
				$('#editDispenserModal').modal('show');
		}	
    });
		
		
  }

  // Edit Dispenser Data
  editDispenser(edit_form){
		edit_form.submitted=false;
    this.authData = this.session.get('authData');
    this.editDispenserData.petro_id=this.authData.petro_id;
    
		this.masterService.editDispenserData(this.editDispenserData).subscribe(data=>{
			//alert(data);
			if(data.status==200)
			{
				
				this.success_message='Dispenser has been Updated';
				this.pageWiseData(1);
				$('#editDispenserModal').modal('hide');
				this.editDispenserForm.reset();       
			
		}else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.error_message='Dispenser could not be created';
		}
		});
  }
  
  // Delete Dispenser Data
  deleteDispenserData(id,index){
    if(confirm("Are you sure to delete this Location")) { 
			this.index=index;
			this.model='Dispenser'; 
				//alert(id);
			this.masterService.deleteDataByModel(id,this.model).subscribe(data=>{
				//console.log(data);
				if(data.status==true)
				{
					this.dispenserList.splice(this.index,1);
					this.success_message="Location deleted sucessfully";	
				}else{
					this.error_message= "Location could not be deleted.";
				}
			});
		//this.editname=this.editCategoryData.name;	
		}
  }

  // Search Dispenser Data
  searchDispenser(){
		this.pageWiseData(1);
	} 

	// Reset Search Data
	resetSearchDispenser(){
		this.searchDispenserData={};
		this.pageWiseData(1);
	} 

	// Pagination 
	pageWiseData(pageno){
		//alert('getLocations');
		this.currentPage=pageno;
		this.masterService.getDispenserData(pageno,this.petro_id,this.searchDispenserData)
		.subscribe(alldata =>{
			if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.dispenserList=alldata.data,
			this.totalCat=alldata.count
			}
		});
		
	} 
}
