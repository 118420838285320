import { Component, OnInit } from '@angular/core';
import { AccountReportService } from '../../../admin-services/account-report.service';
import { CommonsService } from '../../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
import { Router} from '@angular/router';
// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';

// Custoem Function
import { OnCreatedDirective } from '../../../shared/on-created.directive';

// Export
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

declare var $: any;
@Component({
  selector: 'app-balance-sheet',
  templateUrl: './balance-sheet.component.html',
  styleUrls: ['./balance-sheet.component.css']
})
export class BalanceSheetComponent implements OnInit {

  constructor(private accountReportService: AccountReportService, private session:SessionStorageService,private commonsServices:CommonsService,private exportAsService:ExportAsService,private router:Router) { }
	exportAsConfig: ExportAsConfig;
	  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
	
	authData:any={};
	serachReportData:any={};	
	reportData: any[] = [];
	petro_id:number;

	pump_name:number;
	pump_address:string;
	pump_mobile_no:number;
	row_total:number;


	show_details:boolean;
	load_more_status:boolean;
	show_loading_image:boolean;
	data_status:boolean;
	row_total_array:any={};
	sub_row_total_array:any={};
	config:any;

	searchForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
	
		
	});	

  ngOnInit() {
		
		this.show_loading_image=false;

		this.data_status=false;

		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		//console.log(this.authData);
		this.pump_name=this.authData.petro_name;
		this.pump_mobile_no=this.authData.petro_phone_no;
		this.pump_address=this.authData.petro_address;
		this.serachReportData.from_date=new Date(Date.now());
		this.serachReportData.to_date=new Date(Date.now());
		
		
  }
  
    //Get Report Data
	getReportData() {
			this.show_loading_image=true;
			this.load_more_status=true;
		
	 this.accountReportService.getBalancesheetData(this.petro_id,this.serachReportData)
	  .subscribe(balanceData => {
	  // console.log(purchaseListData);
	  if(balanceData.status==200)
		  {
	   this.reportData=balanceData.data;
	   this.data_status=true;
	  /* this.serachReportData.from_date=balanceData.data.from_date;
	   this.serachReportData.to_date=balanceData.data.to_date;*/
			this.calculateProfitandLoss(balanceData.data);
		  }else{
			  this.reportData=[];
			  this.data_status=false;
			 

		  }
		   this.show_loading_image=false;
	   
	  });
	}
	searchReport(){
		this.getReportData();
	}

	net_total:number;
	gross_side_left:number;
	gross_side_right:number;
	profit:number;
	calculateProfitandLoss(allData){
	
		
		this.gross_side_left=(Number(allData.GROUP_CAPITAL_ACCOUNT.Group.final_amount)+Number(allData.GROUP_LOAN_LIABILITY.Group.final_amount)+Number(allData.GROUP_CURRENT_LIABILITIES.Group.final_amount)+Number(allData.GROUP_SUSPENSE_ACCOUNT.Group.final_amount));
		this.gross_side_right=(Number(allData.GROUP_FIXED_ASSETS.Group.final_amount)+Number(allData.GROUP_CURRENT_ASSETS.Group.final_amount)+Number(allData.GROUP_INVESTESTMENT.Group.final_amount)+Number(allData.GROUP_LOAN_AND_ADVANCES_ASSETS.Group.final_amount));
		
		if(allData.Profit_and_loss>0){
			this.gross_side_right=Number(this.gross_side_right)+Number(allData.Profit_and_loss);
		}else{
				
				this.gross_side_left=Number(this.gross_side_left)+Number(allData.Profit_and_loss);
		}
		if(allData.final_opening>0){
			this.gross_side_right=Number(this.gross_side_right)+Number(allData.final_opening);
		}else{
				
				this.gross_side_left=Number(this.gross_side_left)+Number(allData.final_opening);
		}
		
		
		
	}
	showDetails(event){
		if(event.target.checked){
			this.show_details=true;
		}else{
			this.show_details=false;
		}
	}
	
	
	exportTable(event) {
	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, ' Balance sheet ');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }
  
   // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}

}
