import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { CommonsService } from '../../admin-services/commons.service';
import { Router} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-financial-master',
  templateUrl: './financial-master.component.html',
  styleUrls: ['./financial-master.component.css']
})
export class FinancialMasterComponent implements OnInit {


  constructor(private masterService: MasterServicesService, private commonsServices: CommonsService, private router: Router, private session: SessionStorageService, private formBuilder: FormBuilder) {}

	authData: any = {};
	addYearData: any = {};
	editYearData: any = {};
	serachData: any = {};
	listYear: any[] = [];

	totalItem:number;
	//petro_id:1;
	success_message: string;
	error_message: string;
	
	currentPage: 1;
	index: number;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	add_loading:boolean;
	addYearForm:FormGroup;
	
	editYearForm = new FormGroup({
		id: new FormControl(''),
		
		name: new FormControl('', Validators.required),
		current_fiancial_year: new FormControl('', Validators.required),
		from_date: new FormControl(' ', Validators.required),
		to_date: new FormControl(' ', Validators.required),
		
		
	});
	serachFinancForm = new FormGroup({
		name: new FormControl('')

	});


	ngOnInit() {
		// Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
	
	this.addYearData.current_fiancial_year="";
	
		this.authData = this.session.get('authData');
		
		this.addYearForm= this.formBuilder.group({
		current_fiancial_year: new FormControl(' ', Validators.required),
		name: new FormControl(' ', Validators.required),
		from_date: new FormControl(' ', Validators.required),
		to_date: new FormControl(' ', Validators.required),
		
	   });
	
		this.yearListData(1);

		
		
	}
	get name(){
		  return this.addYearForm.get('name');
		  }

	// year list
	yearListData(pageno) {
		this.currentPage = pageno;
		
		this.masterService.getYearListData(pageno,this.serachData)
			.subscribe(alldata => {
				if(alldata.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}
					this.listYear = alldata.data,
					this.totalItem = alldata.count
			});

	}
  searchYear() {

		this.yearListData(1);
	}

//reset list
	resetSearchYear() {
		this.serachData ={};
		this.yearListData(1);
	}
	

	// Add year
	addYear(add_item) {
		//console.log(add_item);
		this.add_loading=true;
		add_item.submitted = false;
		this.authData = this.session.get('authData');
		console.log(this.addYearData);
		this.masterService.addYear(this.addYearData).subscribe(data => {
			if (data.status == 201) {

				this.success_message = 'Year has been created';
				
				$('#addYearModal').modal('hide');
			//	$('#addStateForm').reset();
			     this.addYearForm.reset();
				this.yearListData(1);
			
				this.addYearData.current_fiancial_year = "";
			}else if(data.status==403)
				{
					 this.session.remove('authData');
						this.router.navigate(['']);
				} else {
				this.error_message = 'year could not be created';
			}
			this.add_loading=false;
			
		});

	}
	/*
	Delete Year 
	Mrunali D
	25.2.2020
	*/
	deleteYear(id, index) {
		if (confirm("Are you sure to delete this Year ")) {
			this.index = index;

			this.masterService.deleteDataByModel(id, 'FinancialYear').subscribe(data => {
				if (data.status == true) {
					this.listYear.splice(this.index, 1);
					this.success_message = "Financial Year deleted sucessfully";

				} else if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}else {
					this.error_message = "Financial Year could not be deleted.";
				}
			});

			
		}
	}


	// Get Edit Data

	getYearDataForEdit(id, index) {


		this.index = index;

		this.masterService.getEditYearData(id).subscribe(data => {
			if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}

			this.editYearData = data.data.FinancialYear;
			
			$('#editYearModal').modal('show');


		});
	}

	editYear(edit_item) {
		edit_item.submitted = false;

		this.masterService.editYearData(this.editYearData).subscribe(data => {
			if (data.status == 200) {
				this.success_message = 'Year has been updated';
				$('#editYearModal').modal('hide');
                  
				this.editYearForm.reset();
                this.yearListData(1);
			}else if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				} else {
				this.error_message = 'Year could not be created';
			}
		});

	}
  //created new database
CreateDatabase(id, index)
{
			if (confirm("Are you sure to Create new Database ")) {
			

			this.masterService.createNewDatabase(id).subscribe(data => {
				if (data.status == 201) {
				
					this.success_message = "Financial Year database created sucessfully";
                     this.yearListData(1);
				} else if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}else if(data.status==304)
				{
					  this.error_message ="Last Financial Not Found";
				}
				else {
					this.error_message = "Financial Year database could not be created.";
				}
			});

			
		}
}

}
