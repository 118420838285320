import { Component, OnInit } from '@angular/core';
import { AccountReportService } from '../../../admin-services/account-report.service';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';

// Custoem Function
import { OnCreatedDirective } from '../../../shared/on-created.directive';

// Export
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
@Component({
  selector: 'app-customer-ageing',
  templateUrl: './customer-ageing.component.html',
  styleUrls: ['./customer-ageing.component.css']
})
export class CustomerAgeingComponent implements OnInit {

  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
    constructor(private accountReportService: AccountReportService, private session:SessionStorageService,private router:Router,private exportAsService:ExportAsService) { }
		exportAsConfig: ExportAsConfig;
	authData:any={};
	reportData: any[] = [];
	
	serachReportData:any={};	
	
	petro_id:number;
	pump_name:number;
	pump_address:string;
	pump_mobile_no:number;
	gt_dr:number;
	gt_cr:number;
	td0:number;td30:number;td60:number;td90:number;td120:number;td150:number;td180:number;td210:number;td240:number;td270:number;td300:number;td330:number;td360:number;td365:number;
	total_closing:number;
	total_closing_cr:number;
	load_more_status:boolean;
	show_loading_image:boolean;
	ledger_is_debit:number;
	ledger_is_credit:number;
	pipe:any;
	config:any;
	searchForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
	});	

	show_details:boolean;
	
  ngOnInit() {
	        	this.dr_opening=[];
				this.cr_opening=0;
				this.cr_closing=0;
				this.dr_closing=0;
                 this.opening_total=0;
				 this.total_closing_cr = 0;
				this.total_closing=0;

				this.td0=0;
				this.td30=0;
				this.td60=0;
				this.td90=0;
				this.td120=0;
				this.td150=0;
				this.td180=0;
				this.td210=0;
				this.td240=0;
				this.td270=0;
				this.td300=0;
				this.td330=0;
				this.td360=0;
				this.td365=0;


	  this.authData = this.session.get('authData');
	  this.petro_id = this.authData.petro_id;
	  this.ledger_is_debit=constDefault.DEBIT_LEDGER;
	  this.ledger_is_credit=constDefault.CREDIT_LEDGER;
	  
	  this.serachReportData.from_date=new Date(Date.now());
	  this.serachReportData.to_date=new Date(Date.now());
		this.show_details=false;
		//console.log(this.authData);
		this.pump_name=this.authData.petro_name;
		this.pump_mobile_no=this.authData.petro_phone_no;
		this.pump_address=this.authData.petro_address;
		//this.getReportData();
  }
    //Get Report Data
	searchReport() {
	        	this.dr_opening=[];
				this.cr_opening=0;
				this.cr_closing=0;
				this.dr_closing=0;
                 this.opening_total=0;
				 this.total_closing_cr = 0;
				this.total_closing=0;
				 this.gt_dr=0;
				    this.gt_cr=0;

					this.td0=0;
				this.td30=0;
				this.td60=0;
				this.td90=0;
				this.td120=0;
				this.td150=0;
				this.td180=0;
				this.td210=0;
				this.td240=0;
				this.td270=0;
				this.td300=0;
				this.td330=0;
				this.td360=0;
				this.td365=0;
	this.show_loading_image=true;
	this.load_more_status=true;
	 this.pipe = new DatePipe('en-US'); 
	  this.serachReportData.from_date= this.pipe.transform(this.serachReportData.from_date, 'yyyy-MM-dd');		
	  this.serachReportData.to_date= this.pipe.transform(this.serachReportData.to_date, 'yyyy-MM-dd');
	 this.accountReportService.getCustomerAgeingData(this.petro_id,this.serachReportData)
	  .subscribe(reports => {
	  // console.log(purchaseListData);
	
	  if(reports.status==200)
		  {
			
				  this.reportData=reports.data;	
				
				// this.gt_dr=reports.totalArr.final_dr_amt;	
				// this.gt_cr=reports.totalArr.final_cr_amt;	
			  
			  }else{
				  this.reportData=[];
				  
		  }
		     this.show_loading_image=false;
		   this.load_more_status=false;
	   
	  });
	

	}
	
	
	dr_opening:number[];
	cr_opening:number;
	dr_closing:number;
	cr_closing:number;

	opening_total:number;
	showDetails(event){
		if(event.target.checked){
			
			this.show_details=true;
			
		}else{
			
			this.show_details=false;
		
		}
		this.searchReport();
	}

  getTotal(d0,d30,d60,d90,d120,d150,d180,d210,d240,d270,d300,d330,d360,d365,index){
	
			this.td0=this.td0+Number(d0);
			this.td30=this.td30+Number(d30);
			this.td60=this.td60+Number(d60);
			this.td90=this.td90+Number(d90);
			this.td120=this.td120+Number(d120);
			this.td150=this.td150+Number(d150);
			this.td180=this.td180+Number(d180);
			this.td210=this.td210+Number(d210);
			this.td240=this.td240+Number(d240);
			this.td270=this.td270+Number(d270);
			this.td300=this.td300+Number(d300);
			this.td330=this.td330+Number(d330);
			this.td360=this.td360+Number(d360);
			this.td365=this.td365+Number(d365);
			this.dr_opening[index]=Number(d0)+Number(d30)+Number(d60)+Number(d90)+Number(d120)+Number(d150)+Number(d180)+Number(d210)+Number(d240)+Number(d270)+Number(d300)+Number(d330)+Number(d360)+Number(d365);
			this.cr_opening=this.cr_opening+this.dr_opening[index]
			
		
		//	this.total_closing=Number(this.dr_closing)-Number(this.cr_closing);
		//	this.total_closing=	this.opening_total+this.gt_dr-this.gt_cr;
  }
  exportTable(event) {
	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'Customer-Ageing ');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }
  
   // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}


}
