import { Component, OnInit , Input,ViewChild, ChangeDetectorRef} from '@angular/core';
import { ShiftSaleService } from '../../admin-services/shift-sale.service';
import { FormGroup,FormControl,Validators, FormArray, FormBuilder } from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { CommonsService } from '../../admin-services/commons.service';
import {AccountService } from '../../admin-services/account.service';
import { Router} from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
import { SlNozzleReadingComponent } from './sl-nozzle-reading/sl-nozzle-reading.component';
import { SlReceiptComponent } from './sl-receipt/sl-receipt.component';
import { SlExpensesComponent } from './sl-expenses/sl-expenses.component';
import { SlCardPaymentComponent } from './sl-card-payment/sl-card-payment.component';
import { SlBankDepositeComponent } from './sl-bank-deposite/sl-bank-deposite.component';
import { SlSaleComponent } from './sl-sale/sl-sale.component';
import { SlDipStockComponent } from './sl-dip-stock/sl-dip-stock.component';
import { SlWalletSaleComponent } from './sl-wallet-sale/sl-wallet-sale.component';
import { SlStockComponent } from './sl-stock/sl-stock.component';
import { interval } from 'rxjs';
import { Console } from '@angular/core/src/console';
import { DatePipe } from '@angular/common';

declare var $: any;

@Component({
	selector: 'app-shift-sale-transactions',
	templateUrl: './shift-sale-transactions.component.html',
	styleUrls: ['./shift-sale-transactions.component.css'],
})
export class ShiftSaleTransactionsComponent implements OnInit {

	options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		// locale: enLocale,
		//	minDate: new Date(Date.now()), // Minimal selectable date
		maxDate: new Date(Date.now()), // Maximal selectable date
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {
			'width': '100%'
		}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 

	};

	// Load Child Component
	@ViewChild(SlNozzleReadingComponent) nozzleReading_componant_file;
	@ViewChild(SlReceiptComponent) receipt_componant_file;
	@ViewChild(SlExpensesComponent) expenses_componant_file;
	@ViewChild(SlBankDepositeComponent) bankDeposite_componant_file;
	@ViewChild(SlCardPaymentComponent) cardPayment_componant_file;
	@ViewChild(SlSaleComponent) sale_componant_file;
	@ViewChild(SlWalletSaleComponent) wallet_sale_componant_file;
	@ViewChild(SlDipStockComponent) dip_sale_componant_file;
	@ViewChild(SlStockComponent) stock_componant_file;

	constructor(private accountService: AccountService, private shiftSaleService: ShiftSaleService, private masterService: MasterServicesService, private router: Router, private session: SessionStorageService, private formBuilder: FormBuilder, private commonsServices: CommonsService, private cdr: ChangeDetectorRef) {}

	//Summary
	total_other: number;
	total_rs: number;
	dataInLiters: any[]=[];
	saleInRupees:any[]=[];
	saleOfOthers:any[]=[];
	allShiftList:any[]=[];
	dsmList:any={};
	genelarGeneralSetup:any[]=[];
	totalCardSale:number;
	ms:number;
	hsd:number;
	total_cash_balance:number;
	bozer_total:number;
	addCloseShiftData:any={};
	i:number;
	itemError:any={};
	addCloseShiftDataForm=new FormGroup({
	 cash_from_pre: new FormControl(' '),
	 total_sale: new FormControl(' '),
	 bozer_refill: new FormControl(' '),
	 bozer_sale: new FormControl(' '),
	 bozer_total: new FormControl(' '),
	 credit_sale: new FormControl(' '),
	 cash_sale: new FormControl(' '),
	 receipt: new FormControl(' '),
	 expenses: new FormControl(' '),
	 driver_expenses: new FormControl(' '),
	 bank_deposit: new FormControl(' '),
	 card_sale: new FormControl(' '),
	 wallet_sale: new FormControl(' '),
	 cash_balance: new FormControl(' '),
	 cash_to_next: new FormControl(' '),
	 shifyt_inc_bal: new FormControl(' '),
	 cash_ac_bal: new FormControl(' ')
	});
	commodityDropdownList:any[]=[];
	addPriceForm:FormGroup;
	addPriceFormData:any={};
	show_bozer:number;
	//pipe: any;
	authData: any = {};
	petro_id: number;
	role_id: number;
	lock_shift: number;
	addShiftData: any = {};
	invalid_shift: string;
	success_message: string;
	error_message: string;
	warning_message: string;
	requestPending:boolean;	
	priceValerror:boolean;
	close_loading:boolean;	
	addPriceData:any={};
	cash_balance_any:any;
	addShiftDataForm = new FormGroup({
	day_shift: new FormControl('' ,Validators.required),
	shift: new FormControl('', Validators.required),
	date: new FormControl(' ', Validators.required)
	});
	date_change:number;
	dragging:any={};

	ngOnInit() {
		
		this.dragging ="";
		this.date_change=0;
		
		this.requestPending=false;
		this.addShiftData.date = new Date(Date.now());

		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		this.role_id = this.authData.role;

		this.autoLoad();
		// Get Current Shift Data
		this.shiftSaleService.getCurrentShiftData(this.petro_id)
			.subscribe(currentShiftData => {
				this.addShiftData.petro_id = this.petro_id;
				this.addShiftData.role_id = this.role_id;
				this.addShiftData.day_shift = currentShiftData.data.DailyShift.day_shift_no;
				this.addShiftData.shift = currentShiftData.data.DailyShift.shift;
				this.addShiftData.date = currentShiftData.data.DailyShift.date;
				this.addShiftData.lock_shift= currentShiftData.data.DailyShift.status;
				this.shiftValidation();
			});
		this.addPriceFormData.date= new Date(Date.now())
		this.priceValerror=true;
		this.addPriceForm=new FormGroup({
			date: new FormControl('', Validators.required),
			shift_no: new FormControl('', Validators.required)
		});	
		// Get Item List for update price
		this.masterService.getItemListForPriceUpdate(this.petro_id)
		.subscribe(listdata =>{ this.commodityDropdownList=listdata.data
		});
		
		// Application Setup Data
		
		this.commonsServices.applicationGeneralData(this.petro_id)
		.subscribe(listdata =>{ 	
		this.genelarGeneralSetup=listdata.data
		this.show_bozer=listdata.data.AppSetupGeneral.show_bozer;
		

		});

	
		// DSm List
		this.shiftSaleService.getDsmData(this.petro_id)
			.subscribe(dsmListInfo => {
				this.dsmList = dsmListInfo.data;
				
			});
		
		$('#addItemCategoryModal	').modal({backdrop: 'static', keyboard: false,show:false})  
	}
	// Autoload for getting indend list
	autoLoad(){
		interval(5000).subscribe(x => {
			
		 this.sale_componant_file.getAutoloadPeningIndentList();
		});
	}
	// On shift date change get shift value
	onDateChange() {
		
			if (this.addShiftData.day_shift) {		
					if(this.date_change==1)
					{
						this.shiftValidation(); // Call Shift Validation Function
					}else{
						this.date_change=1;
					}
				//this.shiftValidation(); // Call Shift Validation Function
			}
		
	}
	//On shift change get data
	onShiftNoKeyUp(event, close_shift) {
		close_shift.submitted = false;
		if (event.target.value) {
			this.addShiftData.petro_id = this.petro_id;
			this.addShiftData.day_shift = event.target.value;
			this.shiftValidation(); // Call Shift Validation Function
		} else {
			this.addShiftData.shift = '';
			this.shiftValidation(); // Call Shift Validation Function
		}
	} 

	// Shift Validation (summary page)
	shiftValidation() {
		this.shiftSaleService.shiftValidationOrInfo(this.addShiftData)
			.subscribe(shiftInfo => {
				if (shiftInfo.status == 204) {
					this.invalid_shift = 'Invalid Shift';
					this.addShiftData.shift = '';
				} else {
					this.invalid_shift = "";
					this.addShiftData.shift = shiftInfo.shift;
				}
				this.nozzleReading_componant_file.getTransactionShiftData(this.addShiftData);
				this.receipt_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
				this.bankDeposite_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
				this.expenses_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
				this.cardPayment_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
				this.sale_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
				this.wallet_sale_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
				this.dip_sale_componant_file.getTransactionShiftData(this.addShiftData);
				this.stock_componant_file.getTransactionShiftData(this.addShiftData);
				
				this.showSummary(this.addShiftData.shift,this.addShiftData.date,this.addShiftData.day_shift); // Get Summary Data
			});
	}
	// on close shift save data and generate new shift 
	setPriceArr:any[]=[];
	a:number;
	sale_sum:number;
	 closeShift(close_shift){
		if(confirm("Are You Sure You Want To Close This Shift?")){
			//this.addPrice(true)
			//$('#addItemCategoryModal').modal('show');
			//return;

        let emptyMessage:string='';
	
		emptyMessage=this.checkEmptyMessage(this.catSaleArr);


		if (emptyMessage=="OK")
		{

		
			close_shift.submitted=true;
			this.close_loading=true;
			this.addShiftData.petro_id=this.petro_id;
			this.addShiftData.incharge_id=this.authData.user_id;
			console.log(this.authData.user_id);
			this.addShiftData['SummaryData']=this.addCloseShiftData;
			
			this.sale_sum=Number(Math.abs(this.addShiftData.SummaryData.cash_sale))+Number(this.addShiftData.SummaryData.credit_sale);
		   /*	if(this.addShiftData.SummaryData.total_sale>=this.sale_sum && this.addCloseShiftData.total_oil_sale>=this.addCloseShiftData.cash_bill_amount)
			{*/
			 this.pipe = new DatePipe('en-US'); 
			this.addShiftData.date= this.pipe.transform(this.addShiftData.date, 'yyyy-MM-dd');


			if (this.getGeneralSetup(this.genelarGeneralSetup) == 1) {
				// check dsm is assigen or not
				this.shiftSaleService.getShiftNozzleAssignedList(this.petro_id, this.addShiftData.shift)
					.subscribe(nozzlelistInfo => {
						if (nozzlelistInfo.status == 200) {

							this.shiftSaleService.closeShift(this.addShiftData)
								.subscribe(successdata => {
									if (successdata.status == 500) {
										this.error_message = 'Shift Could Not Close';
										this.success_message = "";
									} else if (successdata.status == 201) {
										this.success_message = 'Shift Close Successfully';
										this.error_message = "";
										this.addShiftData.day_shift = successdata.data.DailyShift.day_shift_no;
										this.addShiftData.shift = successdata.data.DailyShift.shift;
										this.addShiftData.date = successdata.data.DailyShift.date;
										this.addPriceFormData.shift_no = successdata.data.DailyShift.shift;
										this.addPriceFormData.date = successdata.data.DailyShift.date;
										if (successdata.data.price_set == 0) {

											$('#addItemCategoryModal').modal('show');
											if (successdata.data.price_data) {

												this.a = 0;
												for (let item of this.commodityDropdownList) {

													const result = successdata.data.price_data.find(prev => prev.Price.item_id === item.Item.id);

													let prs = {'target': {'value': result.Price.price}};
													this.pushItemPrice(prs, this.a);
													this.setPriceArr[this.a] = result.Price.price;
													this.a = this.a + 1;
												}
											}
										}
									} else {
										this.success_message = 'Shift Close Successfully';
										this.error_message = "";
									}
									this.shiftValidation();
									this.close_loading = false;
								});

						} else if (nozzlelistInfo.status == 204) {
							this.error_message = 'Please assign Dsm';
							this.success_message = "";
							this.close_loading = false;
							return;
						} else {
							this.error_message = 'Some Thing going wrong';
							this.success_message = "";
							this.close_loading = false;
							return;
						}
					});
			}else {
			this.shiftSaleService.closeShift(this.addShiftData)
			.subscribe(successdata => {
				if(successdata.status==500)
				{
					this.error_message='Shift Could Not Close';
					this.success_message="";
					this.warning_message="";
				}else if (successdata.status==201){
					this.success_message='Shift Close Successfully';
					this.error_message="";
					this.warning_message="";
					this.addShiftData.day_shift=successdata.data.DailyShift.day_shift_no;
					this.addShiftData.shift=successdata.data.DailyShift.shift;
					this.addShiftData.date=successdata.data.DailyShift.date;
					this.addPriceFormData.shift_no = successdata.data.DailyShift.shift;
					this.addPriceFormData.date = successdata.data.DailyShift.date;
					if(successdata.data.price_set==0)
					{
						
						$('#addItemCategoryModal').modal('show');
						if(successdata.data.price_data)
						{
							
							this.a=0;
							for(let item of this.commodityDropdownList)
						   { 
							
							const result = successdata.data.price_data.find( prev => prev.Price.item_id ===item.Item.id);
							
							let prs={'target':{'value':result.Price.price}};
							this.pushItemPrice(prs,this.a);
							  this.setPriceArr[this.a]=result.Price.price;
							  this.a=this.a+1;
						   }
						}
					}
				}else{
					this.success_message='Shift Close Successfully';
					this.error_message="";
					this.warning_message="";
				}
				this.shiftValidation();
				this.close_loading=false;
			}); 

		}
			/*	}else{
				this.error_message="Total sale should be greter than sum of cash sale and credit sale";
				this.close_loading=false;
			}
			*/
		}else{
			this.warning_message=emptyMessage;
		}
	}

	
		
		
	   
   }

   getGeneralSetup(genelarGeneralSetup:any) {


	return genelarGeneralSetup.AppSetupGeneral.dsm_wise_track;
}

getGeneralSetupBozer(genelarGeneralSetup:any) {

	if(genelarGeneralSetup.AppSetupGeneral.show_bozer)
	{
		return genelarGeneralSetup.AppSetupGeneral.show_bozer;
	}
	return 0;
}
	
	/*===================================== Summery ===================================*/

	showinLiter:any=[];
	summaryData:any=[];
	saleMsHsd:any=[];
	catSaleArr:any=[];
	//totalSaleinRupees:number;
	card:number;
	cash:number;
	ms_sale:number;
	hsd_sale:number;
	//totalSaleInLiters:number;
	ac_balance:number;
	entry_date:number;
	
	pipe:any;
	//<------------------------------------------------------ Refresh on tab click------------------->
	//Summary
	refreshSummaryData(){
		this.showSummary(this.addShiftData.shift,this.addShiftData.date,this.addShiftData.day_shift);
	}
	//Nozzle Reading
	refreshNozzleReadingData(){
		this.nozzleReading_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
	}
	//Sale
	refreshSaleData(){
		this.sale_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
	}
	//Receipt
	refreshReceiptData(){
		this.receipt_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
	}
	//Expenses
	refreshExpensesData(){
		
		this.expenses_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
	}
	//Bank Deposit
	refreshBankDepositData(){
		this.bankDeposite_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
	}
	//Card Sale
	refreshCardSaleData(){
		this.cardPayment_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
	}
	//Wallet Sale
	refreshWalletSaleData(){
		this.wallet_sale_componant_file.getTransactionShiftData(this.addShiftData,this.genelarGeneralSetup,this.dsmList);
	}
	//Card Sale
	refreshDipReadingData(){
		this.dip_sale_componant_file.getTransactionShiftData(this.addShiftData);
	}
	//stock transfer data
	refreshStockTransferData(){
		this.stock_componant_file.getTransactionShiftData(this.addShiftData);
	}
	
	
	/*
	Get Summary data for display*/
	
	showSummary(shift,date,day_shift_no){

		this.nozzleChartDataLabels=[];
			this.requestPending=true;
		this.pipe = new DatePipe('en-US'); 
		this.entry_date = this.pipe.transform(date, 'd-M-yyyy');
		this.shiftSaleService.getShiftSaleSummarydata(shift,this.petro_id,this.entry_date,day_shift_no)
		.subscribe(summaryData => {
			// this.card = Number(summaryData.graph_data.);
			 //this.cash = Number(summaryData['TotalCashSale']['0']['0']['totalcashSale']) ;
			 //this.totalSaleInLiters = Number(summaryData['ms']) + Number(summaryData['hsd']);
			// this.totalSaleinRupees = this.cash + this.card;
			this.catSaleArr=summaryData.graph_data.group_sale_data;

			//if (!summaryData.graph_data){
			this.showSale(summaryData.graph_data.total_ms_sale,summaryData.graph_data.total_hsd_sale);
			
			this.showSaleTypeSale(summaryData.graph_data.total_oil_sale,summaryData.graph_data.total_other_sale);
			this.showAllTypeSale(summaryData.graph_data.total_credit_sale,summaryData.graph_data.total_cash_sale,summaryData.graph_data.card_sale);
			
			this.showNozzleSale(summaryData.graph_data.nozzle_sale);
		//	}
			//this.addCloseShiftDataForm.value.cash_from_pre=summaryData.summary_data.previous_shift_amount;
			this.addCloseShiftData.cash_bill_amount=summaryData.summary_data.cash_bill_amount;
			this.addCloseShiftData.total_oil_sale=summaryData.graph_data.total_oil_sale;
			this.addCloseShiftData.cash_from_pre=summaryData.summary_data.previous_shift_amount;
			this.addCloseShiftData.total_sale=summaryData.summary_data.total_sale;
			this.addCloseShiftData.bozer_refill=summaryData.summary_data.bozer_refill;
			this.addCloseShiftData.bozer_sale=summaryData.summary_data.bozer_sale;
			this.addCloseShiftData.credit_sale=summaryData.summary_data.total_credit_sale_amt;


			this.addCloseShiftData.cash_sale=summaryData.summary_data.total_cash_sale_amt;


			this.addCloseShiftData.receipt=summaryData.summary_data.total_receipt_amt;
			this.addCloseShiftData.expenses=summaryData.summary_data.total_payment_amt;
			this.addCloseShiftData.driver_expenses=summaryData.summary_data.total_driver_cash;
			this.addCloseShiftData.bank_deposit=summaryData.summary_data.total_bank_deposit_amt;
			this.addCloseShiftData.card_sale=summaryData.summary_data.total_card_sale_amt;
			this.addCloseShiftData.wallet_sale=summaryData.summary_data.total_wallet_sale_amt;
			this.addCloseShiftData.cash_ac_bal=summaryData.summary_data.cash_ac_balance;

			this.bozer_total=Number(summaryData.summary_data.total_sale)-Number(summaryData.summary_data.bozer_refill)+Number(summaryData.summary_data.bozer_sale);
			this.addCloseShiftData.bozer_total=this.bozer_total.toFixed(2);
			this.total_cash_balance=(Number(summaryData.summary_data.previous_shift_amount)+Number(summaryData.summary_data.total_cash_sale_amt)+Number(summaryData.summary_data.total_receipt_amt))-(Number(summaryData.summary_data.total_payment_amt)+Number(summaryData.summary_data.total_driver_cash)+Number(summaryData.summary_data.total_bank_deposit_amt)+Number(summaryData.summary_data.total_card_sale_amt)+Number(summaryData.summary_data.total_wallet_sale_amt));	
			
			this.addCloseShiftData.cash_balance=this.total_cash_balance.toFixed(2); 
			/*if(summaryData.summary_data.cash_to_next)
			{
			
				this.addCloseShiftData.cash_to_next=summaryData.summary_data.cash_to_next;
			}else{
			this.addCloseShiftData.cash_to_next=this.total_cash_balance.toFixed(2);
			}*/
			this.addCloseShiftData.shifyt_inc_bal=0;
			if(summaryData.summary_data.shift_inch_bal>0) 
			{
			this.addCloseShiftData.shifyt_inc_bal=summaryData.summary_data.shift_inch_bal;
			}
			
			if(!this.addCloseShiftData.cash_ac_bal)
			{
				this.addCloseShiftData.cash_ac_bal=0;
			}
			this.addCloseShiftData.cash_to_next=Number(Number(this.total_cash_balance)-(Number(this.addCloseShiftData.shifyt_inc_bal)+Number(this.addCloseShiftData.cash_ac_bal))).toFixed(2);
			this.requestPending=false;
		});
		
	}
	
	// Cash given change calculation
	onCashGivenChange(){
		this.addCloseShiftData.cash_balance;
		this.addCloseShiftData.shifyt_inc_bal=(Number(this.addCloseShiftData.cash_balance)-(Number(this.addCloseShiftData.cash_to_next)+Number(this.addCloseShiftData.cash_ac_bal))).toFixed(2);
	}

	onInchargeGivenChange(){
		this.addCloseShiftData.shifyt_inc_bal;
		this.addCloseShiftData.cash_ac_bal=(Number(this.addCloseShiftData.cash_balance)-(Number(this.addCloseShiftData.shifyt_inc_bal)+Number(this.addCloseShiftData.cash_to_next)+Number(this.addCloseShiftData.cash_ac_bal))).toFixed(2);
	}
	/*********************Chart ************************/
	public chartPosition: any = {
		legend: { position: 'left' }
	}
	public charLegentHide: any = {
		legend: { display:false }
	}
	


	public literDoughnutChartLabels = ['MS', 'HSD'];
	public literDoughnutChartData: number[] = [0, 0];
	public literDoughnutChartType = 'doughnut';
	public literColors = [{ // all colors in order
		backgroundColor: ['#63dcef', '#2f7cae'],
		borderColor: ['#63dcef', '#2f7cae'],
		pointBackgroundColor: ['#c12c2e', '#97009c'],
		pointBorderColor: ['#fff', '#fff'],
		pointHoverBackgroundColor: ['#fff', '#fff'],
		pointHoverBorderColor: ['#E10A18', '#8C0090']
	}]

	public chartClicked(e: any): void {}
	public chartHovered(e: any): void {}

	showSale(ms_sale,hsd_sale) {
		this.literDoughnutChartData = [Number(ms_sale), Number(hsd_sale)];
	}

	// Sale in Rupees
	public saleDoughnutChartLabels: string[] = ['Oil', 'Other'];
	public saleDoughnutChartData: number[] = [0,0];
	public saleDoughnutChartType: string = 'pie';
	public saleColors: Array < any > = [{ // all colors in order
		backgroundColor: ['#3876d2', '#e68030'],
		borderColor: ['#3876d2', '#e68030'],
		pointBackgroundColor: ['#c12c2e', '#97009c'],
		pointBorderColor: ['#fff', '#fff'],
		pointHoverBackgroundColor: ['#fff', '#fff'],
		pointHoverBorderColor: ['#E10A18', '#8C0090']
	}]
	
	showSaleTypeSale(oil_sale,other_sale) {
		this.saleDoughnutChartData = [Number(oil_sale), Number(other_sale)];
	}

	
	// Sale of Others
	public otherDoughnutChartLabels: string[] = ['Credit', 'Cash','Card'];
	public otherDoughnutChartData: number[] = [0, 0,0];
	public otherDoughnutChartType: string = 'bar';
	public otherColors: Array < any > = [{ // all colors in order
		backgroundColor: ['#ed861a', '#3896c6','#c2c0c1'],
		borderColor: ['#ed861a', '#3896c6','#c2c0c1','#de2d02', '#e68030'],
		pointBackgroundColor: ['#c12c2e', '#2196F3','#000'],
		pointBorderColor: ['#fff', '#fff','#fff'],
		pointHoverBackgroundColor: ['#fff', '#fff','#fff'],
		pointHoverBorderColor: ['#E10A18', '#8C0090','#000']
	}]
	showAllTypeSale(credit,cash,card) {
		
		if(!credit)
		{
			credit=0;
		}
		if(!cash)
		{
			cash=0;
		}
		if(!card)
		{
			card=0;
		}
		
		this.otherDoughnutChartData = [Number(credit), Number(cash), Number(card)];
	}
	
	// Sale of Others

	public nozzleChartDataLabels: string[] = [];
	public nozzleChartData: number[] = [];
	public nozzleChartType: string = 'line';
	public nozzleChartColors: Array < any > = [{ // all colors in order
		backgroundColor: ['#7243d3', '#2196F3','#000','#de2d02', '#e68030'],
		borderColor: ['#7243d3', '#2196F3','#000'],
		pointBackgroundColor: ['#c12c2e', '#2196F3','#000'],
		pointBorderColor: ['#fff', '#fff','#fff'],
		pointHoverBackgroundColor: ['#fff', '#fff','#fff'],
		pointHoverBorderColor: ['#E10A18', '#8C0090','#000']
	}]
	showNozzleSale(nozzlelist) {
		//this.nozzleChartDataLabels=[''];
		
		this.nozzleChartData=[];
		//this.nozzleChartData = [Number(credit), Number(cash), Number(cash)];
			nozzlelist.forEach((nozzleData, index) => {
				this.nozzleChartDataLabels.push(String(nozzleData.Nozzle.name));
				this.nozzleChartData.push(Number(nozzleData.ShiftWiseNozzle.total_sale));
			});
			//this.nozzleChartDataLabels=this.dummynozzleChartDataLabels;
			//this.nozzleChartData=this.dummynozzleChartData;
	}

	pushItemPrice(event:any,index:number){
		//alert(data.value);
		//console.log(event.target.value);
		//console.log(index);
		this.addPriceData=this.commodityDropdownList;	 
		this.addPriceData[index].Item.price=event.target.value;
		if(event.target.value)
		{
		  this.itemError[index]=false;	
		}else{
			this.itemError[index]=true;	
		}
	}

	addPrice(add_price){
		//console.log(this.addPriceFormData.date);
		add_price.submitted=false;
	   this.priceValerror=true;
	   this.i=0;
	   for(let item of this.commodityDropdownList)
	   {		 
		   //alert(item.Item.name);
		  // console.log(item.Item);
		  if(!item.Item.price)
		  {
			  this.itemError[this.i]=true;	
			  this.priceValerror=false;
		  }else{
			  this.itemError[this.i]=false;	
		  }
		  this.i=this.i+1;
	   }	 
		if(this.priceValerror==true)
		{
			if(this.addPriceFormData.date==null){
			  this.addPriceFormData.date = new Date(Date.now());
			}
		this.addPriceFormData.Item=this.addPriceData;
		this.addPriceFormData.petro_id=this.petro_id;
		//this.addPriceFormData.date= new Date(Date.now())
		//console.log(this.addPriceData); 
		  this.pipe = new DatePipe('en-US'); 
		  this.addPriceFormData.date= this.pipe.transform(this.addPriceFormData.date, 'yyyy-MM-dd');
		this.masterService.addPriceData(this.addPriceFormData).subscribe(data=>{
			
			  if(data.status==200)
			  {
				  this.success_message='Price has been added';
				  this.addPriceForm.reset(); 
				  $('#addItemCategoryModal').modal('hide');
				  //this.addPriceFormData.date= new Date(Date.now())
				  //this.pageWisePriceList(1);				
			  }
			  else if(data.status==204)
			  {
				  this.error_message='Invalid Date or Shift';
				  this.warning_message="";
								  
			  }
			  else{
				  this.error_message='Price could not be added';
				  this.warning_message="";
			  }
		  });
		}else{
			this.error_message='Please enter price for all item';
			this.warning_message="";
		}
		return;
	}
	/*Quick Link*/
	viewQuick(){
		 $('.zoom-btn-sm').toggleClass('scale-out');
		  if (!$('.zoom-card').hasClass('scale-out')) {
			$('.zoom-card').toggleClass('scale-out');
		  }
	}
	
	/*==================Move Quick Link Button===============*/
	// On keydown quick link
	onmouseDownQuckLink(event){
	
	this.dragging = event;  
	///console.log(this.dragging);
	//this.dragging.offsetX=100;
	//console.log(this.dragging.offsetX);
  }
  
  onmouseUpQuckLink(){
	
	this.dragging = "";  
	///console.log(this.dragging);
	//this.dragging.offsetX=100;
	//console.log(this.dragging.offsetX);
  }
  button_design:any;
  top:any;
  left:any;
	moveQuckLinkButton(e){
	
		if(this.dragging) {
		
			/*this.dragging.css({
				 top : e.pageY - 50,
				 left : e.pageX - 50
			});*/  
			this.top=(e.clientY - 50)+'px';
			this.left=(e.clientX - 50)+'px';

			this.button_design={'top':this.top,'left':this.left};
			//this.design='top:'+this.top+';left:'+this.left;
		}
}

// View Shift List
viewShiftList(){
		this.shiftSaleService.getShifyList(this.petro_id).subscribe(data=>{
			
			  if(data.status==200)
			  {
				this.allShiftList=data.data;
				 		
			  }
				else if(data.status==403)
					{
						  this.session.remove('authData');
							this.router.navigate(['']);
					}
					
					$('#allShiftListModal').modal('show');
			
		  });
}
// Go To Shift
goToShiftNo(shift,date,day_shift,status){
	this.addShiftData.day_shift=day_shift;
	this.addShiftData.shift=shift;
	this.addShiftData.date=date;
	this.addShiftData.lock_shift=status;
	$('#allShiftListModal').modal('hide');
	this.shiftValidation();
}

 checkEmptyMessage(nsalearr)
{

	let rtnStr:string; 
	rtnStr="OK";
	
	for(let cat_sale of nsalearr){ 

		if (Number(cat_sale.cash_qty)<0){

		if(cat_sale.Item.item_category_id==1){
			rtnStr='002:MS Cash and Credit Sale Does Not Match '
		} 
		if(cat_sale.Item.item_category_id==2){
			rtnStr='001:HSD Cash and Credit Sale Does Not Match '
		} 
		if(cat_sale.Item.item_category_id==3){
			rtnStr='003:BMS Cash and Credit Sale Does Not Match '
		} 
		if(cat_sale.Item.item_category_id==4){
			rtnStr='004:BHSD Cash and Credit Sale Does Not Match '
		} 
		break;
		}
		

	   }

	   if (this.addCloseShiftData.cash_balance<0){
		rtnStr='Cash Balance Should Not be Negative'
	   }

	   if (this.addCloseShiftData.shifyt_inc_bal<0){
		rtnStr='Shift Incharge Balance Should Not be Negative'
	   }

	   if (this.addCloseShiftData.cash_ac_bal<0){
		rtnStr='Cash Ac Balance Should Not be Negative'
	   }

	   if (this.addCloseShiftData.cash_to_next<0){
		rtnStr='Cash To Next shift  Should Not be Negative'
	   }


	   if ((Number(this.addCloseShiftData.cash_to_next)+Number(this.addCloseShiftData.cash_ac_bal)+Number(this.addCloseShiftData.shifyt_inc_bal))>Number(this.addCloseShiftData.cash_balance)){
		rtnStr='Cash Balance is less Than  (Shift Incharge Balance +Shift Incharge Balance + Cash Ac Balance) Please Correct '
	   }


	   return rtnStr;

}
	
	
}