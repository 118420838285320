import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl,Validators,FormBuilder} from '@angular/forms';
import {LocalStorageService,SessionStorageService,LocalStorage,SessionStorage} from 'angular-web-storage';
import {AccountService} from '../../../admin-services/account.service';
import {CommonsService} from '../../../admin-services/commons.service';
import { MasterServicesService } from '../../../admin-services/master-services.service';
import {uniqueByModalOrNameValidation} from '../../../shared/unique-validation.directive';
import {constDefault} from '../../../const_default';
declare var $: any;

@Component({
  selector: 'app-sl-quick-links',
  templateUrl: './sl-quick-links.component.html',
  styleUrls: ['./sl-quick-links.component.css']
})
export class SlQuickLinksComponent implements OnInit {

 constructor(private masterService: MasterServicesService,private accountService: AccountService, private session: SessionStorageService, private commonsServices: CommonsService, private formBuilder: FormBuilder) {}

	// Add ledger
  	authData: any = {};
	petro_id: number;
	addLedgerData: any = {};
	success_message: string;
	quick_success_message: string;
	error_message: string;
	addLedgerForm: FormGroup;
	cityDropdownList: any[] = [];
	parentGroupList = [];
	stateDropdownList: any[] = [];
	
	// Add Customer
	addCustomerForm:FormGroup;
	cityDropdownListCustomer: any[] = [];
	addCustomerData:any={};	
	
	//Add Item
	addItemData: any = {};
	categoryDropdownList: any[] = [];
	gstSlabDropdownList: any[] = [];
	unitDropdownList: any[] = [];
	addItemForm:FormGroup;
	
  ngOnInit() {
	  
	  this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		$('#bank_acc').hide();
		$('#cr_db').hide();
		//for auto select 
		this.commonsServices.getParentGroupList(this.petro_id)
			.subscribe(parentGroupData => { 
				this.parentGroupList = Object.entries(parentGroupData.data).map(([value, label]) => ({
					value,
					label
				}));
			});

		
		// Get States
		this.commonsServices.getStates(1)
			.subscribe(stateListdata => {
				this.stateDropdownList = stateListdata.data
			});
			
					this.addLedgerForm = this.formBuilder.group({
			name: new FormControl(' ', [Validators.required], uniqueByModalOrNameValidation(this.commonsServices, 'Ledger', this.petro_id)), //
			group_id: new FormControl(' ', [Validators.required]),
			// bank details
			accno: new FormControl(' '),
			branch: new FormControl(' '),
			ifsc: new FormControl(' '),
			//party details
			mobile_no: new FormControl(' ', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^[0-9]*$')]),
			email: new FormControl(' ', Validators.email),
			phone: new FormControl(' '),
			state_id: new FormControl(' '),
			city_id: new FormControl(' '),
			address: new FormControl(' '),
			pin_code: new FormControl(' '),
			fax: new FormControl(' '),
			aoc: new FormControl(' '),
			ao_charges: new FormControl(' '),
			tin: new FormControl(' '),
			cst: new FormControl(' '),
			gstin: new FormControl(' '),
			pan: new FormControl(' '),
			int_per: new FormControl(' '),
			credit_limit: new FormControl(' '),
			credit_charges: new FormControl(' '),
			credit_days: new FormControl(' '),
			is_expenses_creditor: new FormControl(' ')
		})
		
		//***********Add Customer**************
		this.addCustomerForm= this.formBuilder.group({
		name : new FormControl(' ',Validators.required,uniqueByModalOrNameValidation(this.commonsServices,'Ledger',this.petro_id)),
		mobile_no : new FormControl(' ',[Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
		email : new FormControl(' ',[Validators.required,Validators.email]),
		phone : new FormControl(' ',[Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
		state_id : new FormControl(' ',Validators.required),
		city_id : new FormControl(' ',Validators.required),
		address : new FormControl(' ',Validators.required),
		pin_code : new FormControl(' '),
		fax : new FormControl(' '),
		aoc : new FormControl(' '),
		ao_charges : new FormControl(' '),
		tin : new FormControl(' '),
		cst : new FormControl(' '),
		gstin : new FormControl(' '),
		pan : new FormControl(' ',Validators.required),
		int_per : new FormControl(' '),
		credit_limit : new FormControl(' '),
		credit_charges : new FormControl(' '),
		credit_days : new FormControl(' '),
		is_android_app : new FormControl(''),
		android_user_limit : new FormControl(' ')
	});
	
		this.addItemForm= this.formBuilder.group({
		item_type: new FormControl(' ', Validators.required),
		name: new FormControl('', Validators.required, uniqueByModalOrNameValidation(this.commonsServices,'Item',this.petro_id)),
		item_category_id: new FormControl(' ', Validators.required),
		hsn: new FormControl(' '),
		mrp: new FormControl(' '),
		sp: new FormControl(' '),
		gst_slab_id: new FormControl(' ', Validators.required),
		//unit_value : new FormControl(' ',Validators.required),
		unit_id: new FormControl(' ', Validators.required),
		alt_unit_id: new FormControl(' ', Validators.required),
		unit_value: new FormControl(' ', Validators.required),
		alt_unit_value: new FormControl(' ', Validators.required),
		min_stock: new FormControl(' '),
		max_stock: new FormControl(' '),
		barcode: new FormControl(' '),
		batch_wise: new FormControl(' ')
	});
	
		this.masterService.getCategoryListForDropdown(this.petro_id)
			.subscribe(catlistdata => {
				this.categoryDropdownList = catlistdata.data
			});

		this.commonsServices.getGstSlabDropdown()
			.subscribe(listdata => {
				this.gstSlabDropdownList = listdata.data
				//console.log(this.gstSlabDropdownList);	
			});

		this.commonsServices.getUnitDropdown()
			.subscribe(listdata => {
				this.unitDropdownList = listdata.data

			});
  }
  /***************************************************Ledger master*******************************************************/
  //  Check Validation For Add Ledger Data
	checkLedgerParentId() {
		if(this.addLedgerData.group_id)
		{
		if (this.addLedgerData.group_id.value == constDefault.BANK_ACCOUNTS_GROUP) {
			$('#bank_acc').show();
			$('#cr_db').hide();
			// Add Validations
			this.addLedgerForm.get('accno').setValidators([Validators.required]);
			this.addLedgerForm.get('accno').updateValueAndValidity();
			this.addLedgerForm.get('branch').setValidators([Validators.required]);
			this.addLedgerForm.get('branch').updateValueAndValidity();
			this.addLedgerForm.get('ifsc').setValidators([Validators.required]);
			this.addLedgerForm.get('ifsc').updateValueAndValidity();
			// Clear Validations
			this.addLedgerForm.get('mobile_no').clearValidators();
			this.addLedgerForm.get('mobile_no').updateValueAndValidity();
			this.addLedgerForm.get('email').clearValidators();
			this.addLedgerForm.get('email').updateValueAndValidity();
			this.addLedgerForm.get('state_id').clearValidators();
			this.addLedgerForm.get('state_id').updateValueAndValidity();
			this.addLedgerForm.get('city_id').clearValidators();
			this.addLedgerForm.get('city_id').updateValueAndValidity();
			this.addLedgerForm.get('address').clearValidators();
			this.addLedgerForm.get('address').updateValueAndValidity();
		} else if (this.addLedgerData.group_id.value == constDefault.SUNDRY_DEBTOR_GROUP || this.addLedgerData.group_id.value == constDefault.SUNDRY_CREDITOR_GROUP) {
			$('#bank_acc').hide();
			$('#cr_db').show();
			// Add Validations
			this.addLedgerForm.get('mobile_no').setValidators([Validators.required]);
			this.addLedgerForm.get('mobile_no').updateValueAndValidity();
			this.addLedgerForm.get('email').setValidators([Validators.required]);
			this.addLedgerForm.get('email').updateValueAndValidity();
			this.addLedgerForm.get('state_id').setValidators([Validators.required]);
			this.addLedgerForm.get('state_id').updateValueAndValidity();
			this.addLedgerForm.get('city_id').setValidators([Validators.required]);
			this.addLedgerForm.get('city_id').updateValueAndValidity();
			this.addLedgerForm.get('address').setValidators([Validators.required]);
			this.addLedgerForm.get('address').updateValueAndValidity();
			// Clear Validations
			this.addLedgerForm.get('accno').clearValidators();
			this.addLedgerForm.get('accno').updateValueAndValidity();
			this.addLedgerForm.get('branch').clearValidators();
			this.addLedgerForm.get('branch').updateValueAndValidity();
			this.addLedgerForm.get('ifsc').clearValidators();
			this.addLedgerForm.get('ifsc').updateValueAndValidity();

		} else {
			$('#bank_acc').hide();
			$('#cr_db').hide();
			// bank details
			this.addLedgerForm.get('accno').clearValidators();
			this.addLedgerForm.get('accno').updateValueAndValidity();
			this.addLedgerForm.get('branch').clearValidators();
			this.addLedgerForm.get('branch').updateValueAndValidity();
			this.addLedgerForm.get('ifsc').clearValidators();
			this.addLedgerForm.get('ifsc').updateValueAndValidity();
			// party details
			this.addLedgerForm.get('mobile_no').clearValidators();
			this.addLedgerForm.get('mobile_no').updateValueAndValidity();
			this.addLedgerForm.get('email').clearValidators();
			this.addLedgerForm.get('email').updateValueAndValidity();
			this.addLedgerForm.get('state_id').clearValidators();
			this.addLedgerForm.get('state_id').updateValueAndValidity();
			this.addLedgerForm.get('city_id').clearValidators();
			this.addLedgerForm.get('city_id').updateValueAndValidity();
			this.addLedgerForm.get('address').clearValidators();
			this.addLedgerForm.get('address').updateValueAndValidity();
		}
		}else{
			$('#bank_acc').hide();
			$('#cr_db').hide();
			this.addLedgerForm.get('accno').clearValidators();
			this.addLedgerForm.get('accno').updateValueAndValidity();
			this.addLedgerForm.get('branch').clearValidators();
			this.addLedgerForm.get('branch').updateValueAndValidity();
			this.addLedgerForm.get('ifsc').clearValidators();
			this.addLedgerForm.get('ifsc').updateValueAndValidity();
			// party details
			this.addLedgerForm.get('mobile_no').clearValidators();
			this.addLedgerForm.get('mobile_no').updateValueAndValidity();
			this.addLedgerForm.get('email').clearValidators();
			this.addLedgerForm.get('email').updateValueAndValidity();
			this.addLedgerForm.get('state_id').clearValidators();
			this.addLedgerForm.get('state_id').updateValueAndValidity();
			this.addLedgerForm.get('city_id').clearValidators();
			this.addLedgerForm.get('city_id').updateValueAndValidity();
			this.addLedgerForm.get('address').clearValidators();
			this.addLedgerForm.get('address').updateValueAndValidity();
		}
	}
	
	onSelectState() {
		this.commonsServices.getCities(this.addLedgerData.state_id)
			.subscribe(citiesListdata => {
				this.cityDropdownList = citiesListdata.data
			});
	}
	
	// Add Ledger Information 
	addLedger(add_ledger) { 
		add_ledger.submitted = false;
		this.authData = this.session.get('authData');
		this.addLedgerData.petro_id = this.authData.petro_id;

		this.addLedgerData.ledger_group_id = this.addLedgerData.group_id.value;
		if (this.addLedgerData.is_expenses_creditor) {
			this.addLedgerData.expense_party = 1;
		} else {
			this.addLedgerData.expense_party = 0;
		}
		this.accountService.addNewLedger(this.addLedgerData).subscribe(data => {
			if (data.status == 201) {
				this.quick_success_message = 'Ledger has been created';
				$('#addLedgerModal').modal('hide');
			
				this.addLedgerForm.reset();
			} else {
				this.error_message = 'Ledger could not be created';
			}
		});
	}
	/******************************************Customer master*******************************************************/
	
	get customername(){
	  return this.addCustomerForm.get('name');
  }
  
  onSelectStateCustomer(){
	  
	  this.commonsServices.getCities(this.addCustomerData.state_id)
	.subscribe(citiesListdata =>{ this.cityDropdownListCustomer=citiesListdata.data});
	
  }
  
  
    /*
  Add Category 
  Amit Sahu
  16.11.18
  */
   addCustomer(add_cust)
  {
	add_cust.submitted = false;
	this.authData = this.session.get('authData');
	this.addCustomerData.petro_id=this.authData.petro_id;
	this.masterService.addNewCustomer(this.addCustomerData).subscribe(data=>{
		if(data.status==200)
		{		
			this.quick_success_message='Customer has been created';
			$('#addCustomerModal').modal('hide');
		
			this.addCustomerForm.reset();
			this.addCustomerData.state_id = 0;
			this.addCustomerData.city_id = 0;
			this.addCustomerData.is_android_app = 0; 
			
		}else{
			this.error_message='Customer could not be created';
		}
	});
  }
    is_app_selected(event){
	//console.log(event);
	if(event.target.value){
		$('#usr_limit').show();
	}
	else{
		$('#usr_limit').hide();
	}
  }
  
  /**************************************************Add Itme*****************************************/
  
   get itemname(){
	  return this.addItemForm.get('name');
  }
  // Check if Category selected as HSD or MS then hide barcode and batchwise Entries
	selectItemCategoryAdd() {
		if (this.addItemData.item_category_id == constDefault.ITEM_CATEGORY_MS || this.addItemData.item_category_id == constDefault.ITEM_CATEGORY_HSD || this.addItemData.item_category_id == constDefault.ITEM_CATEGORY_BMS || this.addItemData.item_category_id == constDefault.ITEM_CATEGORY_BHSD)  {
			$("#batch_barcodeAdd").hide();			
		} else {
			$("#batch_barcodeAdd").show();			
		}
	}
	
	// Add Item
	addItem(add_item) {
		add_item.submitted = false;
		this.authData = this.session.get('authData');
		this.addItemData.petro_id = this.authData.petro_id;
		//console.log(this.addItemData);
		this.masterService.addNewItem(this.addItemData).subscribe(data => {
			if (data.status == 200) {

				this.quick_success_message = 'Item has been created';
				$('#addNewItemModal').modal('hide');

				this.addItemForm.reset();
				this.addItemData.gst_slab_id = "";
				this.addItemData.unit_id = "";
				this.addItemData.alt_unit_id = "";
				this.addItemData.item_type = '0';
				this.addItemData.item_category_id = "";
			} else {
				this.error_message = 'Item could not be created';
			}
			
		});

	}
}
