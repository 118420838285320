import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators ,FormBuilder, FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
//import { AccountService } from '../../../admin-services/account.service';
import { ShiftSaleService } from '../../../admin-services/shift-sale.service';
import { constDefault } from '../../../const_default';
import { CommonsService } from '../../../admin-services/commons.service';
//import { MasterServicesService } from '../../../admin-services/master-services.service';
import { DatePipe } from '@angular/common';
//import { DatepickerOptions } from 'ng2-datepicker';
//import * as enLocale from 'date-fns/locale/en';
import { Router} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-sl-stock',
  templateUrl: './sl-stock.component.html',
  styleUrls: ['./sl-stock.component.css']
})
export class SlStockComponent implements OnInit {

 constructor(private router:Router, private commonsServices: CommonsService,private shiftSaleService: ShiftSaleService, private session: SessionStorageService, private formBuilder: FormBuilder) {}
	
	error_message:string;
	success_message:string;
	stock_transfer_error:string;
	shift_date: any;
	dmy_shift_date: any;
	shift_no: number;
	day_shift_no: number;
	pipe:any;
	stockTransferData: any = {};
	authData: any = {};
	petro_id: number;
	locationList:any=[];
	itemAutoSugestList:any[]=[];
	stock_transfer_loading:boolean;
	stockTransferForm: FormGroup;
	addTransferItemList: FormArray;
	sale_success_error:string;
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	stockdata:number;
	btnPrevSession:any[]=[];
	
  ngOnInit() {
	  
	   // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
	  this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		
		this.stockTransferForm = this.formBuilder.group({
			date: new FormControl('', Validators.required),
			shift: new FormControl('', Validators.required),
			from_location: new FormControl('',Validators.required),		
			to_location: new FormControl('', Validators.required),
			addTransferItemList: this.formBuilder.array([this.addTransferItem()])
		});
		this.commonsServices.getLocationListByPetroId(this.petro_id)
		.subscribe(alldata => {
			this.locationList = alldata.data;
		});
		
  }
  // get data from Shift Sale Transaction
	getTransactionShiftData(shiftData: any) {
		


			this.shift_date = shiftData.date;
		 this.pipe = new DatePipe('en-US'); 
		this.dmy_shift_date = this.pipe.transform(this.shift_date, 'd-M-yyyy');
		this.shift_no = shiftData.shift;

		this.getPageWiseTransferList(1);
	}
  addTransferItem(): FormGroup {
		return this.formBuilder.group({
			item_id: ['', Validators.required],
			batch_no: [''],
			qty: ['', Validators.required],
			
		})
	}
	
	// Open stock Transfer modal
/*	openStockTransferModal(){
		this.stock_transfer_loading=false;
		this.stockTransferData.date=this.dmy_shift_date;
		this.stockTransferData.shift=this.shift_no;
		$('#stockTransferModal').modal('show');
	}*/
	
	  openStockTransferModal(){
        this.stockTransferData={};
        this.stockTransferForm.setControl('addTransferItemList',
       this.formBuilder.array([this.addTransferItem()]));
            this.stock_transfer_loading=false;
            this.stockTransferData.date=this.dmy_shift_date;
            this.stockTransferData.shift=this.shift_no;
            this.stockTransferData.from_location = "";
            this.stockTransferData.to_location = "";
            this.commonsServices.getLocationListByPetroId(this.petro_id)
            .subscribe(alldata => {
                this.locationList = alldata.data;
            });
                $('#stockTransferModal').modal('show');
        }

	// Get from controll date
	get addTransferFormData() {
		return this.stockTransferForm.get('addTransferItemList')
	}
	
	
	// Function to add One More Field
	addTransferNewItem() {
		this.addTransferItemList = this.stockTransferForm.get('addTransferItemList') as FormArray;
		this.addTransferItemList.push(this.addTransferItem());
	}

	// Function to Remove One Field
	removeTransferItem(i) {
		if (confirm("Do you really want to Delete this Item?")) {
			if (this.addTransferItemList.length > 1) {
				this.addTransferItemList.removeAt(i);
				
			}

		}
	}
	
	// Add Stock Transfer
	/*
	addStockTransfer(stock_transfer){
		stock_transfer.submitted=false;
		this.stock_transfer_error="";
		this.stock_transfer_loading=true;
		this.stockTransferForm.value.petro_id=this.petro_id;
		if(this.stockTransferForm.value.from_location==this.stockTransferForm.value.to_location)
		{
			this.stock_transfer_error='Please select a different To location';
			this.stock_transfer_loading=false;
		}else{
			this.shiftSaleService.addStaockTransfer(this.stockTransferForm.value)
			.subscribe(alldata => {
				if (alldata.status == 200 || alldata.status == 201) {
				this.stockTransferForm.setControl('addTransferItemList', this.formBuilder.array([this.addTransferItem()]));
				this.stock_transfer_loading=false;
				this.getPageWiseTransferList(1);
				//this.stockTransferForm.reset();
				//this.openStockTransferModal();
			
				//$('#stockTransferModal').modal('hide'); 
		    //	this.stockTransferForm.reset();
				}else{
					this.stock_transfer_error='Stock transfer could not be added';
					this.stock_transfer_loading=false;
				}
			});
		}
	}*/
	 addStockTransfer(stock_transfer){
        stock_transfer.submitted=false;
            this.stock_transfer_error="";
            this.stock_transfer_loading=true;
            this.stockTransferForm.value.petro_id=this.petro_id;
            this.stockTransferForm.value.day_shift = this.day_shift_no;
            this.stockTransferForm.value.date = this.dmy_shift_date;


            if(this.stockTransferForm.value.from_location==this.stockTransferForm.value.to_location)
        {
            this.stock_transfer_error='Please select a different To location';
            this.stock_transfer_loading=false;
        }
		else
        {
			   this.shiftSaleService.addStaockTransfer(this.stockTransferForm.value)
                    .subscribe(alldata => {
                        if (alldata.status == 200 || alldata.status == 201) {


                      this.stockTransferForm.setControl('addTransferItemList',
                     this.formBuilder.array([this.addTransferItem()]));
                        this.stock_transfer_loading=false;
                        this.getPageWiseTransferList(1);
                        this.stockdata=0;

                        // this.stockTransferForm.reset();
                        this.openStockTransferModal();
                         
                    //    this.getStock(event,-1)

                    //  $('#stockTransferModal').modal('hide');

                        }
                        else{
                           this.stock_transfer_error='Stock transfer could not be added';
                            this.stock_transfer_loading=false;
                        }
                    });
                }
    }

	
	loading:boolean;
	trsnsferCurrentPage:number;
	totalTransferData:number;
	transferListAllData:any=[];
	// Get stock transfer list data
	getPageWiseTransferList(page: number){
		this.loading=true;
		this.trsnsferCurrentPage = page;
		this.shiftSaleService.getTransferListByTypeOrShift(page, this.shift_no,this.petro_id)
			.subscribe(saleListData => {
				if (saleListData.status == 200) {
					this.transferListAllData = saleListData.data,
						this.totalTransferData = saleListData.count
						
				} else {
					this.transferListAllData = [];
					this.totalTransferData = 0;
				}
				this.loading=false;
			});
	}
	// Item list Auto Filter
	/*---------------------------------------------Auto Complete----------------------------------*/
	public filterItemList(value: any): void {
		this.commonsServices.getItemByNameOrType(value, this.petro_id, constDefault.ITEM_TYPE_OTHER)
		.subscribe(alldata => {
			this.itemAutoSugestList = Object.entries(alldata.data).map(([value, label]) => ({
				value,
				label
			}));
		});
	}
	// Get stock by item , loaction and batch 
	// batch is optional
	stockArr:any={};
	getStock(event,index){
		
		if(this.stockTransferData.from_location)
		{
		if(event)
		{
			this.addTransferItemList = this.stockTransferForm.get('addTransferItemList') as FormArray;
			this.shiftSaleService.getStockItemLocation(this.petro_id,event.value,this.stockTransferData.from_location, this.addTransferItemList.value[index]['batch_no'])
			.subscribe(alldata => {
				if(alldata.status==200)
				{
					this.stockdata=1;
				this.stockArr[index]=alldata.data.Stock.stock_quantity;
				}else{
					this.stockArr[index]=0;
				}
			});
		}else{
			this.stockArr[index]=0;	
		}
	
	}else{
		alert('Please select from location');
	}
	

	}

	/*
	delete stock transaction
	Mrunali D
	25.6.19
	*/
	deleteStockTransfer(id,index) {
		if (confirm("Are you sure to delete this Stock ")) {
		

			this.shiftSaleService.deleteStockTransfer(id).subscribe(data => {
				if (data.status == 200) {
					this.transferListAllData.splice(index,1);
					this.success_message = "Stock deleted sucessfully";

				} else if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}else {
					this.error_message = "Stock could not be deleted.";
				}
			});

			//this.editname=this.editCategoryData.name;	
		}
	}

}
