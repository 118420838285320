import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { CommonsService } from '../../admin-services/commons.service';
import { Router} from '@angular/router';
declare var $: any;

interface serviceData {
  data: any
}


@Component({
  selector: 'app-category-master',
  templateUrl: './category-master.component.html',
  styleUrls: ['./category-master.component.css']
})



export class CategoryMasterComponent implements OnInit {
	
		
  constructor(private masterService: MasterServicesService, private router:Router,private session:SessionStorageService,private formBuilder: FormBuilder,private commonsService:CommonsService) {

  }
	authData:any={};
	addCategoryData:any={};
	editCategoryData:any={};
	serachCategoryData:any={};
	categoryList : any[] = [];
	totalCat : number;
	categoryDropdownList : any[] = [];
	data : any[] = [];
	//name:string;
	parent_id:any;
	success_message:string;
	error_message:string;
	model:string;
	index:number;
	petro_id:number;
	currentPage:number;
	add_loading:boolean;
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	succeAddCatData:{
		ItemCategory:{
			id:any,
			name:string
		},
		Category1:{
			name:string
		}
	}
	// Add Category Validation
	addCategoryForm:FormGroup;
	/*addCategoryForm= new FormGroup({
		name : new FormControl(' ',Validators.required),
		parent_id : new FormControl(' '),
		
	});	*/
	
	editCategoryForm= new FormGroup({
		id : new FormControl(' ',[Validators.required]),
		name : new FormControl(' ',[Validators.required]),
		parent_id : new FormControl(' '),
		
	});	
	
	serachCategoryForm= new FormGroup({
		name : new FormControl(' '),
	});	
  
  ngOnInit() {
	  
	
	
	this.btnPrevSession = this.session.get('ButtonAuth');
	if(this.btnPrevSession)
	{
		let urlArr=this.router.url.split('/');
		this.link_path=urlArr[2];
		let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
		this.delete_btn=result.UserPrivilege.delete_auth;
		this.edit_btn=result.UserPrivilege.edit_auth;
		this.create_btn=result.UserPrivilege.create_auth;
	}
	
	 this.addCategoryData.parent_id="";
	this.authData = this.session.get('authData');
	this.petro_id=this.authData.petro_id;
	
	this.addCategoryForm= this.formBuilder.group({
		name : new FormControl(' ',Validators.required ,uniqueByModalOrNameValidation(this.commonsService,'ItemCategory',this.petro_id)),
		parent_id : new FormControl(' '),
		
	});
	
	
	
	this.pageWiseData(1);
	
	/*this.masterService.getCategoryListData(1,this.petro_id,this.serachCategoryData)
	.subscribe(alldata =>{ this.categoryList=alldata.data

	 });
	*/
	
	 this.masterService.getCategoryListForDropdown(this.petro_id)
	.subscribe(catlistdata =>{ this.categoryDropdownList=catlistdata.data
	
	 });
	 
	 
	 	
	//console.log('list'+this.categoryDropdownList)
  }
  get name(){
	  return this.addCategoryForm.get('name');
  }
  
    /*
  Add Category 
  Amit Sahu
  16.11.18
  */
   addItemCategory(item_cat)
  {
	 item_cat.submitted=false;
	this.add_loading=true;
	//this.name=this.addCategoryData.name;
	//this.parent_id=this.addCategoryData.parent_id;
	//this.masterService.addNewCategory(this.name,this.parent_id,this.addCategoryData);
	this.authData = this.session.get('authData');
	this.addCategoryData.petro_id=this.authData.petro_id;
	//console.log('console'+this.addCategoryData.petro_id);
	this.masterService.addNewCategory(this.addCategoryData).subscribe(data=>{
		if(data.status==true)
		{
						
			this.success_message='Item category has been created';
			$('#addItemCategoryModal').modal('hide');

			// this.succeAddCatData={
			// 	ItemCategory:{
			// 		id:data.id,
			// 		name:data.name
			// 	},
			// 	Category1:{
			// 		name:data.parentName
			// 	}
			// }
			// this.categoryList.unshift(this.succeAddCatData);
			this.pageWiseData(1);
			this.addCategoryForm.reset(); 
			
		}else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.error_message='Item category could not be created';
		}
		this.add_loading=false;
	});
	
	
  }
    /*
  Get Category data for edit Category 
  Amit Sahu
  16.11.18
  */
  getItemCategoryData(id,index)
  {
	
	this.index=index;
	//this.name=this.addCategoryData.name;
	//this.parent_id=this.addCategoryData.parent_id;
	//this.masterService.addNewCategory(this.name,this.parent_id,this.addCategoryData);
	this.masterService.getEditCategoryData(id).subscribe(data=>{
		if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
		this.editCategoryData.id=data.id;						
		this.editCategoryData.name=data.name;						
		this.editCategoryData.parent_id=data.parent_id;		
		$('#editItemCategoryModal').modal('show');
			}
		
	});
	
	//this.editname=this.editCategoryData.name;	
	
  }
    /*
  Edit Category 
  Amit Sahu
  16.11.18
  */
   editItemCategory(edit_form)
  {
	edit_form.submitted=false;

	//this.name=this.addCategoryData.name;
	//this.parent_id=this.addCategoryData.parent_id;
	//this.masterService.addNewCategory(this.name,this.parent_id,this.addCategoryData);
	this.masterService.editCategoryService(this.editCategoryData).subscribe(data=>{
		if(data.status==true)
		{
		
			//this.router.navigate(['admin/categoryMaster']);	
			this.success_message='Item category has been updated';
			$('#editItemCategoryModal').modal('hide');
			$('#catNameTD'+data.id).html(data.name);
			
			this.succeAddCatData={
				ItemCategory:{
					id:data.id,
					name:data.name
				},
				Category1:{
					name:data.parentName
				}
			}
			
			this.categoryList[this.index]=this.succeAddCatData;
			//console.log(this.categoryList);
			this.editCategoryForm.reset(); 
					
			
		}else{
			this.error_message='Item category could not be created';
		}
	});
	
	
  }
    /*
  Delete Category 
  Amit Sahu
  16.11.18
  */
   deleteCategory(id,index)
  {
	if(confirm("Are you sure to delete this Category")) { 
		this.index=index;
		this.model='ItemCategory'; 
			//alert(id);
		this.masterService.deleteDataByModel(id,this.model).subscribe(data=>{
			//console.log(data);
			if(data.status==true)
			{
				this.categoryList.splice(this.index,1);
				this.success_message="Category deleted sucessfully";	
			}else{
				this.error_message= "Category could not be deleted.";
			}
		});
	//this.editname=this.editCategoryData.name;	
	}
  }
  /*
  Search Category 
  Amit Sahu
  16.11.18
  */
   searchItemCategory(){
	  this.pageWiseData(1);
	 /*  this.masterService.getCategoryListData(1,this.petro_id,this.serachCategoryData)
		.subscribe(alldata =>{ this.categoryList=alldata.data

	 });*/
   } 
	/*
  Search Category 
  Amit Sahu
  16.11.18
  */
   resetSearchItemCategory(){
   this.serachCategoryData={};
   this.pageWiseData(1);
	/*   this.masterService.getCategoryListData(1,this.petro_id,this.serachCategoryData)
		.subscribe(alldata =>{ this.categoryList=alldata.data

	 });*/
   } 
   pageWiseData(pageno){
	   this.currentPage=pageno;
	   this.masterService.getCategoryListData(pageno,this.petro_id,this.serachCategoryData)
		.subscribe(alldata =>{
			 if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.categoryList=alldata.data,
			this.totalCat=alldata.count	
			}
					

	 });

   } 
   
  

  }
