import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './admin-services/login.service';
import { Router} from '@angular/router';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})


export class AuthGuard implements CanActivate {
	
	value:any={};
	local_data:any={};
	  constructor(private loginservices: LoginService, private router:Router , private session:SessionStorageService, private local_storage: LocalStorageService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //  return this.loginservices.isLoggedIn;
  
  // Run Multi tab  ;  Amit Sahu ; 08.04.2019
   this.local_data = this.local_storage.get('localAuthData');
    this.session.set('authData',this.local_data);
   
   
   this.value = this.session.get('authData');
   const expectedRole = next.data.expectedRole;
	   if( this.value.role==7 || this.value.role==8 || this.value.role==9)
	   {
		   this.value.role=2;
	   }else{
		     this.value.role= this.value.role;
	   }
	  if((!this.loginservices.isLoggedIn && this.value==null) || (this.value.role != expectedRole))
	  {
		
		  this.router.navigate(['login'])
	  }else{
		  //return this.loginservices.isLoggedIn;
		 // const expectedRole=this.value.role;
		 
		return true;
	  }
  }
}
