import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { CommonsService } from '../../admin-services/commons.service';
import { Router} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-city-master',
  templateUrl: './city-master.component.html',
  styleUrls: ['./city-master.component.css']
})
export class CityMasterComponent implements OnInit {

 constructor(private masterService: MasterServicesService, private commonsServices: CommonsService, private router: Router, private session: SessionStorageService, private formBuilder: FormBuilder) {}
authData: any = {};
	addCityData: any = {};
	editCityData: any = {};
	serachCityData: any = {};
	listCity: any[] = [];
	stateDropdownList: any[] = [];
	totalCity:number;
	//petro_id:1;
	success_message: string;
	error_message: string;
	
	currentPage: 1;
	index: number;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	
	add_loading:boolean;
	addCityForm:FormGroup;
	editCityForm = new FormGroup({
		id: new FormControl(''),
		
		name: new FormControl('', Validators.required),
		state_id: new FormControl('', Validators.required),
		
		
	});
	serachCityForm = new FormGroup({
		name: new FormControl('')

	});

  ngOnInit() {
	  // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
	
	
		this.addCityData.state_id = "";
		this.authData = this.session.get('authData');
		

		this.addCityForm= this.formBuilder.group({
	
	
		state_id: new FormControl(' ', Validators.required),
		name: new FormControl(' ', Validators.required),
		
	   });
		
		this.cityListData(1);

		
		this.commonsServices.getStates(1)
			.subscribe(listdata => {
				this.stateDropdownList = listdata.data
					
			});

    }
	 get name(){
	  return this.addCityForm.get('name');
    }
	
	cityListData(pageno) {
		this.currentPage = pageno;
		
		this.masterService.getCityListData(pageno,this.serachCityData)
			.subscribe(alldata => {
				if(alldata.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}
					this.listCity = alldata.data,
					this.totalCity = alldata.count
			});

	}
	/*
	Mrunali D
	16.7.2019
	*/
	searchCity() {

		this.cityListData(1);
	}

	resetSearchCity() {
		this.serachCityData ={};
		this.cityListData(1);
	}
	
	// Add city
	addCity(add_city) {
		this.add_loading=true;
		add_city.submitted = false;
		this.authData = this.session.get('authData');
		
		this.masterService.addCity(this.addCityData).subscribe(data => {
			if (data.status == 201) {

				this.success_message = 'City has been created';
				
				$('#addCityModal').modal('hide');
			     this.addCityForm.reset();
				this.cityListData(1);
				this.addCityData.state_id = "";
			}else if(data.status==403)
				{
					 this.session.remove('authData');
						this.router.navigate(['']);
				} else {
				this.error_message = 'city could not be created';
			}
			this.add_loading=false;
			
		});

	}
	/*
	Delete City 
	Mrunali D
	16.7.2019
	*/
	deleteCity(id, index) {
		if (confirm("Are you sure to delete this City ")) {
			this.index = index;

			this.masterService.deleteDataByModel(id, 'City').subscribe(data => {
				if (data.status == true) {
					this.listCity.splice(this.index, 1);
					this.success_message = "City deleted sucessfully";

				} else if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}else {
					this.error_message = "City could not be deleted.";
				}
			});

		}
	}
	
	// Get Edit Data

	getCityDataForEdit(id, index) {


		this.index = index;

		this.masterService.getEditCityData(id).subscribe(data => {
			if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}
				//console.log(data.data);
			//this.editStateData = data.data.Country;
			this.editCityData = data.data.City;
			
			$('#editCityModal').modal('show');


		});
	}


	editCity(edit_city) {
		edit_city.submitted = false;

		this.masterService.editCityData(this.editCityData).subscribe(data => {
			if (data.status == 200) {
				this.success_message = 'City has been updated';
				$('#editCityModal').modal('hide');
                  
				this.editCityForm.reset();
                this.cityListData(1);
			}else if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				} else {
				this.error_message = 'city could not be created';
			}
		});

	}

}
