import { Component, OnInit } from '@angular/core';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
import { OnCreatedDirective } from '../../../shared/on-created.directive';
import { SaleServiceService } from '../../../admin-services/sale-service.service';
import{DatePipe}from '@angular/common';
import { Router} from '@angular/router';

@Component({
  selector: 'app-daily-customer-sale',
  templateUrl: './daily-customer-sale.component.html',
  styleUrls: ['./daily-customer-sale.component.css']
})
export class DailyCustomerSaleComponent implements OnInit {


  authData:any={};
   petro_id:number;
   pump_name:string;
   pump_address:string;
   shift_no:number;
   setDob:string;
   show_loading_image:boolean;
	
   setDob1:string;
   reportData: any[] = [];
   serachAllCSRData:any={};
   ledger:any[]=[];
  config:any;
total_amount:any;
total_qty_hsd:any;
total_amt_hsd:any;
total_qty_bhsd:any;
total_amt_bhsd:any;
total_qty_ms:any;
total_amt_ms:any;
total_qty_bms:any;
total_amt_bms:any;
total_other:any;
   //serachLedgerReportData:any={};	

  constructor(private router: Router,private saleServiceService:SaleServiceService, private session:SessionStorageService,private exportAsService:ExportAsService) { }
  exportAsConfig: ExportAsConfig;
  
  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'YYYY-MM-DD',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		//locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
   
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  searchAllCSRForm= new FormGroup({
	from_date : new FormControl(' ',Validators.required),
	to_date : new FormControl(' ',Validators.required),
	tmp_ledger_id : new FormControl(' ',Validators.required)
		
	});		
	


  ngOnInit() {
	  // Define zero all total
	this.total_amount=0;
	this.total_qty_hsd=0;
	this.total_amt_hsd=0;
	this.total_qty_bhsd=0;
	this.total_amt_bhsd=0;
	this.total_qty_ms=0;
	this.total_amt_ms=0;
	this.total_qty_bms=0;
	this.total_amt_bms=0;
	this.total_other=0;
	
	// Hide loading image
	this.show_loading_image=false;
  
    this.authData = this.session.get('authData');
    this.petro_id = this.authData.petro_id;
    this.serachAllCSRData.from_date=new Date(Date.now());
	  this.serachAllCSRData.to_date=new Date(Date.now());
    this.pump_name=this.authData.petro_name;	
	 	this.pump_address=this.authData.petro_address;
	  //this.serachAllCSRData.to_date=new Date(Date.now());
  }
  searchAllCSR(){
      
	this.show_loading_image=true;  
  this.total_amount=0.00;
  this.total_qty_hsd=0.00;
  this.total_amt_hsd=0.00;
  this.total_qty_bhsd=0.00;
  this.total_amt_bhsd=0.00;
  this.total_qty_ms=0.00;
  this.total_amt_ms=0.00;
  this.total_qty_bms=0.00;
  this.total_amt_bms=0.00;
  this.total_other=0.00;
    var datePipe =  new DatePipe('en-US');
    this.setDob = datePipe.transform(this.serachAllCSRData.from_date, 'yyyy-MM-dd');

    this.setDob1 = datePipe.transform(this.serachAllCSRData.to_date, 'yyyy-MM-dd');

    this.serachAllCSRData.from_date=this.setDob;
    this.serachAllCSRData.to_date=this.setDob1;
    this.saleServiceService.getDailySaleData(this.petro_id,this.serachAllCSRData)
   
  .subscribe(purchaseListData => {
   //console.log(purchaseListData);
   if(purchaseListData.status==200)
    {
		this.reportData=purchaseListData.data;
		
  
       }else if(purchaseListData.status==403)
       {
           this.session.remove('authData');
           this.router.navigate(['']);
       }else{
		   // Hide loading image
		   this.show_loading_image=false;  
	   } 
	      this.show_loading_image=false;  
  });
}
 
getTotalAmt(amount){

  this.total_amount=Number(this.total_amount)+Number(amount);
   this.total_amount=this.total_amount.toFixed(2);

}



resetAllCSRData(){
	  
  this.serachAllCSRData.from_date=new Date();
  this.serachAllCSRData.to_date=new Date();
  this.reportData=[];
  

  this.total_amount=0.00;
  this.total_qty_hsd=0.00;
  this.total_amt_hsd=0.00;
  this.total_qty_bhsd=0.00;
  this.total_amt_bhsd=0.00;
  this.total_qty_ms=0.00;
  this.total_amt_ms=0.00;
  this.total_qty_bms=0.00;
  this.total_amt_bms=0.00;
  this.total_other=0.00;
  

}
exportTable(event) {
    if(event.target.value=='xlsx')
    {
    this.exportAsConfig = {
      type: event.target.value, // the type you want to download
      elementId: 'exportTable', // the id of html/table element
    }	
      //this.exportTableDirective.exportData();
      // download the file using old school javascript method
      this.exportAsService.save(this.exportAsConfig, 'Shift Report ');
      // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
      this.exportAsService.get(this.config).subscribe(content => {
      
      });
    }
  }
  // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}


}
