import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { CommonsService } from '../../admin-services/commons.service';
import { ApplicationSetupService } from '../../admin-services/application-setup.service';
import {  SessionStorageService, SessionStorage } from 'angular-web-storage';
import { DatePipe } from '@angular/common';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
import { constDefault } from '../../const_default';

declare var $: any; 
@Component({
  selector: 'app-left-auth-navigation',
  templateUrl: './left-auth-navigation.component.html',
  styleUrls: ['./left-auth-navigation.component.css']
})
export class LeftAuthNavigationComponent implements OnInit {

   constructor(private session:SessionStorageService,private commonsServices:CommonsService,private applicationService: ApplicationSetupService) { }
  
   options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Start Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
     options1: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'MM-YYYY',
		barTitleFormat: 'MM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Start Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  
  searchData:any={};
  searchGstRData:any={};
    authData:any={};
	navListData:any[]=[];
	petro_id:number;
	login_year:number;
	fr_date:string;
	t_date:string;
	month:string;
	pipe: any;
	gstr_one_nav:number;
	gstr_threeb_nav:number;
  gstrOneForm= new FormGroup({
		from_date : new FormControl(' ',[Validators.required]),
		to_date : new FormControl(' ',[Validators.required]),

		
	});
	gstrThreeBForm= new FormGroup({
		from_date : new FormControl(' ',[Validators.required]),
		to_date : new FormControl(' ',[Validators.required]),


		
	});
  ngOnInit() {
	  this.gstr_one_nav=constDefault.GSTR_ONE_NAV;
	  this.gstr_threeb_nav=constDefault.GSTR_THREEB_NAV;
     this.searchData.from_date =new Date();
    this.searchData.to_date =new Date();
    this.searchGstRData.from_date =new Date();
    this.searchGstRData.to_date =new Date();
	this.authData = this.session.get('authData');
    this.petro_id=this.authData.petro_id;
	this.login_year=this.authData.financial_year_id;
	
	this.applicationService.getUserNavigationDataByUserid(this.authData.user_id).subscribe(data=>{
			if(data.status==200)
			{
				
					this.navListData=data.data.data;	
					this.session.set('ButtonAuth',data.data.user_auth);
			 }
		});
		
		$("#sidebar").mCustomScrollbar({
		theme: "minimal"
	});
		
  }
  openExportGstrOneModal(){
	  $('#exportGstrOneModal').modal('show');
  }
   get from_date(){
	  return this.gstrOneForm.get('from_date');
  }
   get to_date(){
	  return this.gstrOneForm.get('to_date');
  }
  exportGSTROne(){
		  this.pipe = new DatePipe('en-US'); 
		this.fr_date= this.pipe.transform( this.searchData.from_date, 'yyyy-MM-dd');
		this.t_date= this.pipe.transform( this.searchData.to_date, 'yyyy-MM-dd');
		window.open(constDefault.API_URL+'/reports/gstGstrOne/'+this.petro_id+'/'+this.fr_date+'/'+this.t_date+'/'+this.login_year,'_blank' );
		this.searchData.petro_id=this.petro_id;  
	   //this.commonsServices.exportGstrOne(this.searchData)
      //.subscribe(dispenserlistdata =>{});
  }
  exportGSTRThreeB(){
		  this.pipe = new DatePipe('en-US'); 
		this.fr_date= this.pipe.transform( this.searchGstRData.from_date, 'yyyy-MM-dd');
		this.t_date= this.pipe.transform( this.searchGstRData.to_date, 'yyyy-MM-dd');
		window.open(constDefault.API_URL+'/reports/gstGstrThreeB/'+this.petro_id+'/'+this.fr_date+'/'+this.t_date+'/'+this.login_year,'_blank' );

	   //this.commonsServices.exportGstrOne(this.searchData)
      //.subscribe(dispenserlistdata =>{});
  }
openGstrThreeBModal(){
	  $('#exportGstrThreeBModal').modal('show');
  }
}
