import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { CommonsService } from '../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router} from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
declare var $: any;

@Component({
  selector: 'app-reminder-master',
  templateUrl: './reminder-master.component.html',
  styleUrls: ['./reminder-master.component.css']
})
export class ReminderMasterComponent implements OnInit {

  constructor(private masterService: MasterServicesService, private commonService:CommonsService, private router:Router,private session:SessionStorageService, private commonsService:CommonsService, private formBuilder: FormBuilder) { }

  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
	//	minDate: new Date(Date.now()), // Minimal selectable date
	 // maxDate: new Date(Date.now()),  // Maximal selectable date
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Expiry Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  editOptions: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
	//	minDate: new Date(Date.now()), // Minimal selectable date
	 // maxDate: new Date(Date.now()),  // Maximal selectable date
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Expiry Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  
  // Variable Initialization
  authData:any={};
	addReminderData:any={};	
	editReminderData:any={};	
	serachReminderData:any={};	
	reminderList:any[]=[];	
	reminderDropdownList:any[]=[];	
	success_message:string;	
	error_message:string;	
	currentPage:number;	
	petro_id:number;	
	index:number;	
  totalData:number;	
  totalCat:number;
  CustomerDropdownList:any[]=[];
  model:string;
  add_loading:boolean;
  addReminderForm:FormGroup;
  
link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];

editReminderForm= new FormGroup({
  id:new FormControl(' '),
  r_date : new FormControl(' ',Validators.required),
  reminder_before_days : new FormControl(' ',Validators.required),
  event_name : new FormControl(' ',Validators.required),
  remark:new FormControl(' ',Validators.required)
});	

searchReminderForm= new FormGroup({
  event_name : new FormControl(' ',Validators.required),
});	

  ngOnInit() {
	  
	  // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
    this.addReminderData.r_date=new Date(Date.now());
    this.authData = this.session.get('authData');
    this.petro_id=this.authData.petro_id;
    this.commonService.getCustomerDropDownList(this.petro_id)
    .subscribe(customerlistdata =>{this.CustomerDropdownList=customerlistdata.data});
    // Page wise Reminder list
    this.pageWiseReminderList(1);
    this.addReminderForm= new FormGroup({
      r_date : new FormControl(' ',Validators.required),
      reminder_before_days : new FormControl(' ',Validators.required),
      event_name : new FormControl(' ',Validators.required),
      remark:new FormControl(' ',Validators.required)
    });	
  }
  
  // Add Reminder
  addReminder(add_reminder){
	  this.add_loading=true;
    add_reminder.submitted=false;
    this.authData = this.session.get('authData');
    this.addReminderData.petro_id=this.authData.petro_id;

		this.masterService.addReminderData(this.addReminderData).subscribe(data=>{
      if(data.status==201)
      {
        //console.log("Location Data ///////////"+data);
        this.success_message='Reminder has been Added Successfully';
        $('#addReminderModal').modal('hide');
        this.pageWiseReminderList(1);
        this.addReminderForm.reset();           
      }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
        this.error_message='Reminder could not be create';
      }
	  this.add_loading=false;
		});
  }

  // Get Reminder Data in Model by Id
  getReminderData(id,index){
    this.index=index;
    //alert();
	// this.masterService.addNewCategory(this.name,this.parent_id,this.addCategoryData);
	this.masterService.getEditReminderData(id).subscribe(data=>{
    
	
    //if(data.data.status==201)
		this.editReminderData.id=data.data.Reminder.id;						
		this.editReminderData.r_date=data.data.Reminder.rem_date;						
    this.editReminderData.reminder_before_days=data.data.Reminder.rem_before_days;										
		this.editReminderData.event_name=data.data.Reminder.event_name;						
		this.editReminderData.remark=data.data.Reminder.remark;						
	 	$('#editReminderModal').modal('show');
	  });
  }

  // Edit Reminder Data and Sumbit Data From Reminder
  editReminderInfo(edit_reminder){
    edit_reminder.submitted=false;
    this.masterService.editReminderData(this.editReminderData ).subscribe(data=>{
      if(data.status==200)
      {			
        this.success_message='Reminder has been updated';
        $('#editReminderModal').modal('hide');
        this.pageWiseReminderList(1);
        this.editReminderData.reset(); 
      }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
        this.error_message='Reminder could not be updated';
      }
    });
  }

  // Delete Card Data
  deleteReminderData(id,index){
    if(confirm("Are you sure to delete this Reminder Information")) { 
			this.index=index;
			this.model='Reminder'; 
				//alert(id);
			this.masterService.deleteDataByModel(id,this.model).subscribe(data=>{
				//console.log(data);
				if(data.status==true)
				{
					this.reminderList.splice(this.index,1);
					this.success_message="Reminder deleted sucessfully";	
				}else{
					this.error_message= "Reminder could not be deleted.";
				}
			});
		}
  }

  // Search Nozzle
  searchReminder(){
	 
		this.pageWiseReminderList(1);
	} 

	// Reset Search Data
	resetReminderName(){
		this.serachReminderData={};
		this.pageWiseReminderList(1);
	} 

	// Pagination 
	pageWiseReminderList(pageno){
		this.currentPage=pageno;
		this.masterService.getReminderListData(pageno,this.petro_id,this.serachReminderData)
		.subscribe(alldata =>{
      if(alldata.data){
		  
			this.reminderList=alldata.data,
      this.totalCat=alldata.count
      }
	  if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}
      //console.log(this.reminderList)
		});
  } 

}
