import { Component, OnInit } from '@angular/core';
import { AccountReportService } from '../../../admin-services/account-report.service';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
import { Router} from '@angular/router';


// Custoem Function
import { OnCreatedDirective } from '../../../shared/on-created.directive';




@Component({
  selector: 'app-bill-to-bill-outstanding',
  templateUrl: './bill-to-bill-outstanding.component.html',
  styleUrls: ['./bill-to-bill-outstanding.component.css']
})
export class BillToBillOutstandingComponent implements OnInit {

    constructor(private accountReportService: AccountReportService, private session:SessionStorageService,private router:Router) { }
	authData:any={};
	reportData: any[] = [];
	
	petro_id:number;
	pump_name:number;
	pump_address:string;
	pump_mobile_no:number;
	gt_dr:number;
	gt_cr:number;
	load_more_status:boolean;
	show_loading_image:boolean;
	
  ngOnInit() {
	  
	  this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		//console.log(this.authData);
		this.pump_name=this.authData.petro_name;
		this.pump_mobile_no=this.authData.petro_phone_no;
		this.pump_address=this.authData.petro_address;
		this.getReportData();
  }
    //Get Report Data
	getReportData() {
	this.load_more_status=true;

	 this.accountReportService.getCustomerBillToBIllOutrstandingData(this.petro_id)
	  .subscribe(reports => {
	  // console.log(purchaseListData);
	  if(reports.status==200)
		  {
				  this.reportData=reports.data;		  
			  }else{
				  this.reportData=[];
		  }
		   this.load_more_status=false;
	   
	  });
	

	}
	
	
	calcTotalDr(amount,index){
			if(index==0)
			{
				this.gt_dr=0;
				this.gt_cr=0;
			}
			if(amount>0)
			{
				this.gt_dr=Number(this.gt_dr)+Number(amount);
			}

	 
  }
  calcTotalCr(amount,index){
			if(index==0)
			{
				this.gt_dr=0;
				this.gt_cr=0;
			}
			if(amount)
			{
				this.gt_cr=Number(this.gt_cr)+Number(amount);
			}
	 
  }
  
  // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
}
