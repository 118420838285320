import { Component, OnInit } from '@angular/core';
import { AccountReportService } from '../../../admin-services/account-report.service';
import { CommonsService } from '../../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';

// Custoem Function
import { OnCreatedDirective } from '../../../shared/on-created.directive';

// Export
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

declare var $: any;

@Component({
  selector: 'app-trial-balance',
  templateUrl: './trial-balance.component.html',
  styleUrls: ['./trial-balance.component.css']
})
export class TrialBalanceComponent implements OnInit {
constructor(private accountReportService: AccountReportService, private session:SessionStorageService,private commonsServices:CommonsService,private exportAsService:ExportAsService,private router:Router) { }
	exportAsConfig: ExportAsConfig;
	  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
	
	authData:any={};
	serachReportData:any={};	
	reportData: any[] = [];
	petro_id:number;

	pump_name:number;
	pump_address:string;
	pump_mobile_no:number;
	row_total:number;
	odebit:number;
	ocredit:number;
	gt_dr:number;
	gt_cr:number;
	opening_diff:number;
	closing_balance:number;
	show_opeing:boolean;
	load_more_status:boolean;
	show_loading_image:boolean;
	row_total_array:any={};
	sub_row_total_array:any={};
	config:any;
    pipe:any;
	searchForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		tmp_ledger_id : new FormControl(' ',Validators.required)
		
	});	

  ngOnInit() {
		this.gt_dr=0;
		this.gt_cr=0;
		this.odebit=0;
		this.ocredit=0;
		this.opening_diff=0;
		this.closing_balance=0;
		this.load_more_status=true;
		this.show_loading_image=false;
		this.show_opeing=false;


		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		//console.log(this.authData);
		this.pump_name=this.authData.petro_name;
		this.pump_mobile_no=this.authData.petro_phone_no;
		this.pump_address=this.authData.petro_address;
		this.serachReportData.from_date=new Date(Date.now());
		this.serachReportData.to_date=new Date(Date.now());
		
		this.getReportData();
  }
  
    //Get Report Data
	getReportData() {
			this.show_loading_image=true;
			this.load_more_status=true;
			this.gt_dr=0;
			this.gt_cr=0;
			this.odebit=0;
		    this.ocredit=0;
		    this.opening_diff=0;
				this.pipe = new DatePipe('en-US'); 
			 this.serachReportData.from_date= this.pipe.transform(this.serachReportData.from_date, 'yyyy-MM-dd');		
	  this.serachReportData.to_date= this.pipe.transform(this.serachReportData.to_date, 'yyyy-MM-dd');
			
	 this.accountReportService.getTrialBalanceData(this.petro_id,this.serachReportData)
	  .subscribe(balanceData => {
	  // console.log(purchaseListData);
	  if(balanceData.status==200)
		  {
	   this.reportData=balanceData.data.group_data;
	   this.serachReportData.from_date=balanceData.data.from_date;
	  this.serachReportData.to_date=balanceData.data.to_date;
      
		  }else{
			  this.reportData=[];
			 

		  }
		   this.show_loading_image=false;
	   
	  });
	}
	
	searchReport(){
		this.getReportData();
	}
	resetSearchReport(){
		this.serachReportData={};
		this.getReportData();
	}
	 getClosing(opeing,i,debit,credit){
	 
	   
			 if(opeing >= 0) 
			 {
				   this.odebit=Number(this.odebit)+Number(opeing);
			 }else{
				  this.ocredit=Number(this.ocredit)+Number(Math.abs(opeing));
			 }
			this.gt_dr=Number(this.gt_dr)+Number(debit);
			this.gt_cr=Number(this.gt_cr)+Number(credit);

			this.row_total_array[i]= Number(opeing)+Number(debit)-Number(credit);  

               this.opening_diff=this.odebit-this.ocredit;
	 
  }
  
  
  getSubClosing(opeing,s,debit,credit,i){
	 
				this.sub_row_total_array[i+'_'+s]= Number(opeing)+Number(debit)-Number(credit);  


	 
  }
  
  
  /*
  View sub group 
  amit sahu
  06.01.19
  */
  showDetails(event){
	this.show_loading_image=true;
	  if(event.target.checked)
	  {
		this.reportData.forEach((a, index) => {
		//Get Sub Group List with data
		this.accountReportService.getTrialBalanceDetails(this.petro_id,a.Group.id,this.serachReportData)
		.subscribe(detailsdata =>{
			this.reportData[index]['Details']=detailsdata.data;
			this.reportData[index]['LedDetails']=detailsdata.ledData;
			this.show_loading_image=false;
		});

		});
	  }else{
		  this.reportData.forEach((a, index) => {
		//Get Sub Group List with data
		this.reportData[index]['Details']=[];
		this.reportData[index]['LedDetails']=[];
		this.show_loading_image=false;
	  })
		
  }

}



/*showOpeing(event){
	
	  if(event.target.checked)
	  {
		this.show_opeing=true;
	  }else{
		 this.show_opeing=false;		
	}
  

	}*/
	
	
	// Go to ledger pageX
	//Amit Sahu
	goLedgerpage(group_id){
		this.serachReportData.group_id=group_id;
		this.session.set('trialledgerSession',this.serachReportData);
		this.router.navigate(['admin/trial-ledger']);
	}
		
	goLedgerReportpage(ledger_id,ledger_name){
		//alert(ledger_id);
		this.serachReportData.ledger_id=ledger_id;
		this.serachReportData.ledger_name=ledger_name;
		this.session.set('trialVoucherSession',this.serachReportData);
		this.router.navigate(['admin/ledgerReport']);
	}
	
	
	
	  exportTable(event) {
	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'Trail Balance ');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }
  
   // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
	
}