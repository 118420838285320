import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { constDefault } from '../const_default';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';


interface serviceData {
  data: any;
  count: number;
}
interface addData{
	data:any,
	status:number,
  message:string,
} 

interface bankReconc {
  status: any;
  data: any;
  search: any;
  opening: number;
  actual_opening: number;
}
@Injectable({
  providedIn: 'root'
})
export class ApplicationSetupService {
  headersdata:any={};
  authValue:any={};
  user_id:string;
  login_token:string;
	login_year:string;
  
  constructor(private http: HttpClient, private session:SessionStorageService,private local_storage:LocalStorageService) { 

    this.authValue = this.local_storage.get('localAuthData');
    //console.log(this.authValue);
    if(this.authValue){
      this.user_id=this.authValue.user_id;
      this.login_token=this.authValue.token;
      this.login_year=this.authValue.financial_year_id;
      // console.log(this.login_year);
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Accept', 'application/json');
      headers = headers.append('year', this.login_year);
      headers = headers.append('user', this.user_id);
      this.headersdata = headers.append('token', this.login_token);
    }
  }


  /*
    Add Purchase setup details
    20.02.19
    Roshan Bagde  
  */
  addPurchaseSetup(adddata){ 
    return this.http.post<addData>(constDefault.API_URL+"/adminSetups/addPurchaseSetup/"+this.login_year,adddata,{headers:this.headersdata})
  }
  
  /*
    Get Purchase setup details
    20.02.19
    Roshan Bagde  
  */
 getPurchaseData(petro_id:number){
		return this.http.get<addData>(constDefault.API_URL+"/adminSetups/listAppSetupPurchase/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }
  
  /*
    Delete all Data from Database
    20.02.19
    Roshan Bagde  
  */
  deletedAllDataFormTable(petro_id:number){
    return this.http.get<addData>(constDefault.API_URL+"/adminSetups/deletedAllDataFormTable/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }
  /*
    add invoice  setup details
    20.02.19
   Amit Sahu  
  */
  addInvoiceSetup(adddata){ 
    return this.http.post<addData>(constDefault.API_URL+"/adminSetups/addInvoiceSetup/"+this.login_year,adddata,{headers:this.headersdata})
  }
  /*
    get invoice  setup details
    20.02.19
   Amit Sahu  
  */
  
  getInvoiceSetupData(petro_id:number){
		return this.http.get<addData>(constDefault.API_URL+"/adminSetups/getInvoiceSetupData/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }
  
   /*
    add general  setup details
    07.04.19
   Amit Sahu  
  */
  addGeneralSetup(adddata){ 
    return this.http.post<addData>(constDefault.API_URL+"/adminSetups/addGeneralSetup/"+this.login_year,adddata,{headers:this.headersdata})
  }
  /*
    get general  setup details
    20.02.19
   Amit Sahu  
  */
  
  getGeneralSetupData(petro_id:number){
		return this.http.get<addData>(constDefault.API_URL+"/adminSetups/getGeneralSetupData/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }
  /*
    get nav list
    20.02.19
   Amit Sahu  
  */
  
  getNavList(){
		return this.http.get<addData>(constDefault.API_URL+"/adminSetups/navigationsList/"+this.login_year,{headers:this.headersdata})
  }
 /*
    get user list
    24.05.19
   Amit Sahu  
  */
  getUserList(petro_id:number){
		return this.http.get<addData>(constDefault.API_URL+"/commons/userDropList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }  
  /*
    add user privalages
    24.05.19
   Amit Sahu  
  */
  addUserPrivalageData(petro_id:number,data:any){
		return this.http.post<addData>(constDefault.API_URL+"/adminSetups/addPrevillageToUser/"+petro_id+'/'+this.login_year,data,{headers:this.headersdata})
  } 
/*
    get user privalages by user id
    24.05.19
   Amit Sahu  
  */
  getUserPrivalageData(petro_id:number,user_id:number){
		return this.http.get<addData>(constDefault.API_URL+"/adminSetups/getPrivilegeByUser/"+petro_id+'/'+user_id+'/'+this.login_year,{headers:this.headersdata})
  } 
/*
    get user privalages by user id for navigation
    24.05.19
   Amit Sahu  
  */
  getUserNavigationDataByUserid(user_id:number){
		return this.http.get<addData>(constDefault.API_URL+"/adminSetups/getUserPreAndNavList/"+user_id+'/'+this.login_year,{headers:this.headersdata})
  }   
  /*
    get user privalages by user id for navigation
    24.05.19
   Amit Sahu  
  */
  getUserNavigationDataByPackage(petrol:number){
		return this.http.get<addData>(constDefault.API_URL+"/adminSetups/packageWisePrivilege/"+petrol+'/'+this.login_year,{headers:this.headersdata})
  }
    /*
Change DSR
 29.7.2019
 Mrunali D
  */
  changedDsr(petro_id:number,data:any){
		return this.http.post<addData>(constDefault.API_URL+"/adminSetups/changedDSR/"+petro_id+'/'+this.login_year,data,{headers:this.headersdata})
  }
  
  /*
 add sale  setup details
 24.09.19
 Mrunali D
  */
  addSaleSetup(adddata){ 
    return this.http.post<addData>(constDefault.API_URL+"/adminSetups/addSaleSetup/"+this.login_year,adddata,{headers:this.headersdata})
  }
  /*
    get sale  setup details
   24.9.2019
 Mrunali D
  */
  
  getSaleSetupData(petro_id:number){
		return this.http.get<addData>(constDefault.API_URL+"/adminSetups/getSaleSetupData/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }
  
  /*
   Changed Password
   18.11.2019
   Mrunali D
  */
  
  changedPassword(adddata){
		return this.http.post<addData>(constDefault.API_URL+"/adminSetups/changedPassword/"+this.login_year,adddata)
  }

  addTCSSetup(adddata) {
    return this.http.post<addData>(constDefault.API_URL + "/adminSetups/addTCSSetup/" + this.login_year, adddata, { headers: this.headersdata })
  }

  getTCSSetupData(petro_id) {
    return this.http.get<addData>(constDefault.API_URL + "/adminSetups/getTCSSetupData/" + petro_id + '/' + this.login_year, { headers: this.headersdata })
  }



  
}
