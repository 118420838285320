import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { CommonsService } from '../../admin-services/commons.service';
import { FormGroup,FormControl,Validators, FormBuilder } from '@angular/forms';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router} from '@angular/router';
import { constDefault } from '../../const_default';
declare var $: any;



@Component({
	selector: 'app-item-master',
	templateUrl: './item-master.component.html',
	styleUrls: ['./item-master.component.css']
})
export class ItemMasterComponent implements OnInit {
	
	constructor(private masterService: MasterServicesService, private commonsServices: CommonsService, private router: Router, private session: SessionStorageService, private formBuilder: FormBuilder) {}

	authData: any = {};
	addItemData: any = {};
	editItemData: any = {};
	serachItemData: any = {};
	itemList: any[] = [];
	categoryDropdownList: any[] = [];
	gstSlabDropdownList: any[] = [];
	unitDropdownList: any[] = [];
	petro_id: number;
	success_message: string;
	error_message: string;
	totalItem: number;
	currentPage: 1;
	index: number;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	successAddEditData: {
		Item: {
			id: number,
			name: string,
			hsn: string,
			mrp: number,
			sp: number,
			min_stock: string,
			max_stock: string,
			unit_value:number,
			unit_id: number,
			alt_unit_value: number,
			alt_unit_id: number,
		},
		ItemCategory: {
			name: string
		},
		GstMaster: {
			name: string
		},
		Unit: {
			name: string
		}
	}
	add_loading:boolean;
	addItemForm:FormGroup;
	editItemForm = new FormGroup({
		id: new FormControl(''),
		item_type: new FormControl('', Validators.required),
		name: new FormControl('', Validators.required),
		item_category_id: new FormControl('', Validators.required),
		hsn: new FormControl(''),
		mrp: new FormControl(''),
		sp: new FormControl(''),
		gst_slab_id: new FormControl('', Validators.required),
		min_stock: new FormControl(''),
		max_stock: new FormControl(''),
		barcode: new FormControl(''),
		batch_wise: new FormControl(''),
		unit_value:new FormControl(''),
		unit_id:new FormControl(''),
		alt_unit_value: new FormControl(''),
		alt_unit_id: new FormControl('')
	});
	serachItemForm = new FormGroup({
		name: new FormControl('')

	});


	ngOnInit() {
		// Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
	
		this.addItemData.gst_slab_id = "";
		this.addItemData.unit_id = "";
		this.addItemData.alt_unit_id = "";
		this.addItemData.item_type = 0;
		this.addItemData.item_category_id = "";
		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;

		this.addItemForm= this.formBuilder.group({
		item_type: new FormControl('', Validators.required),
		name: new FormControl('', Validators.required, uniqueByModalOrNameValidation(this.commonsServices,'Item',this.petro_id)),
		item_category_id: new FormControl(' ', Validators.required),
		hsn: new FormControl(''),
		mrp: new FormControl(''),
		sp: new FormControl(''),
		gst_slab_id: new FormControl('', Validators.required),
		//unit_value : new FormControl('',Validators.required),
		unit_id: new FormControl('', Validators.required),
		alt_unit_id: new FormControl('', Validators.required),
		unit_value: new FormControl('', Validators.required),
		alt_unit_value: new FormControl('', Validators.required),
		min_stock: new FormControl(''),
		max_stock: new FormControl(''),
		barcode: new FormControl(''),
		batch_wise: new FormControl(0)
	});
		
		this.itemListData(1);

		this.masterService.getCategoryListForDropdown(this.petro_id)
			.subscribe(catlistdata => {
				this.categoryDropdownList = catlistdata.data
			});

		this.commonsServices.getGstSlabDropdown()
			.subscribe(listdata => {
				this.gstSlabDropdownList = listdata.data
				//console.log(this.gstSlabDropdownList);	
			});

		this.commonsServices.getUnitDropdown()
			.subscribe(listdata => {
				this.unitDropdownList = listdata.data

			});

	}
	 get name(){
	  return this.addItemForm.get('name');
  }

	// Check if Category selected as HSD or MS then hide barcode and batchwise Entries
	selectItemCategoryAdd() {
		if (this.addItemData.item_category_id == constDefault.ITEM_CATEGORY_MS || this.addItemData.item_category_id == constDefault.ITEM_CATEGORY_HSD || this.addItemData.item_category_id == constDefault.ITEM_CATEGORY_BMS || this.addItemData.item_category_id == constDefault.ITEM_CATEGORY_BHSD)  {
			$("#batch_barcodeAdd").hide();			
		} else {
			$("#batch_barcodeAdd").show();			
		}
	}

	selectItemCategoryEdit(){
		if (this.editItemData.item_category_id == constDefault.ITEM_CATEGORY_MS || this.editItemData.item_category_id == constDefault.ITEM_CATEGORY_HSD || this.editItemData.item_category_id == constDefault.ITEM_CATEGORY_BMS || this.editItemData.item_category_id == constDefault.ITEM_CATEGORY_BHSD)  {	
			$("#batch_barcodeEdit").hide();
		} else {		
			$("#batch_barcodeEdit").show();
		}
	}

	// Add Item
	addItem(add_item) {
		this.add_loading=true;
		add_item.submitted = false;
		this.authData = this.session.get('authData');
		this.addItemData.petro_id = this.authData.petro_id;
		//console.log(this.addItemData);
		this.masterService.addNewItem(this.addItemData).subscribe(data => {
			if (data.status == 200) {

				this.success_message = 'Item has been created';
			//	$('#addItemCategoryModal').modal('hide');
				this.itemListData(1);
			//	this.addItemForm.reset();
				this.addItemData.name = "";
			//	this.addItemData.gst_slab_id = "";
				//this.addItemData.unit_id = "";
				//this.addItemData.alt_unit_id = "";
			//	this.addItemData.item_type = '0';
				this.addItemData.item_category_id = "";
			}else if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				} else {
				this.error_message = 'Item could not be created';
			}
			this.add_loading=false;
			
		});

	}

	itemListData(pageno) {
		this.currentPage = pageno;
		this.masterService.getItemListData(pageno, this.petro_id, this.serachItemData)
			.subscribe(alldata => {
				if(alldata.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}
					this.itemList = alldata.data,
					this.totalItem = alldata.count
			});

	}

	// Get Edit Data

	getItemDataForEdit(id, index) {


		this.index = index;

		this.masterService.getEditItemData(id).subscribe(data => {
			if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}
			this.editItemData = data.data.Item;
			//console.log(this.editItemData.item_category_id)
			if (this.editItemData.item_category_id > 2) {
				$("#batch_barcodeEdit").show();
			} else {
				$("#batch_barcodeEdit").hide();
			}
			$('#editItemModal').modal('show');


		});
	}


	editItem(edit_item) {
		edit_item.submitted = false;

		this.masterService.editItem(this.editItemData).subscribe(data => {
			if (data.status == 200) {
				this.success_message = 'Item has been updated';
				$('#editItemModal').modal('hide');

				this.successAddEditData = {
					Item: {
						id: data.data.id,
						name: data.data.name,
						hsn: data.data.hsn,
						mrp: data.data.mrp,
						sp: data.data.sp,
						min_stock: data.data.min_stock,
						max_stock: data.data.max_stock,
						unit_value: data.data.unit_value,
						unit_id: data.data.unit_id,
						alt_unit_value: data.data.alt_unit_value,
						alt_unit_id: data.data.alt_unit_id,
					},
					ItemCategory: {
						name: data.data.item_cat_name
					},
					GstMaster: {
						name: data.data.gst_name
					},

					Unit: {
						name: data.data.unit_name
					}
				}

				this.itemList[this.index] = this.successAddEditData;
				this.editItemForm.reset();

			}else if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				} else {
				this.error_message = 'Item could not be created';
			}
		});

	}
	/*
	Delete Item 
	Amit Sahu
	19.11.18
	*/
	deleteItem(id, index) {
		if (confirm("Are you sure to delete this Item ")) {
			this.index = index;

			this.masterService.deleteDataByModel(id, 'Item').subscribe(data => {
				if (data.status == true) {
					this.itemList.splice(this.index, 1);
					this.success_message = "Item deleted sucessfully";

				} else if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}else {
					this.error_message = "Item could not be deleted.";
				}
			});

			//this.editname=this.editCategoryData.name;	
		}
	}

	/*
	Amit Sahu
	Search item-master
	19.11.18
	*/
	searchItem() {

		this.itemListData(1);
	}

	resetSearchItem() {
		this.serachItemData = {};
		this.itemListData(1);
	}

}