import { Component, OnInit } from '@angular/core';
import { AccountReportService } from '../../../admin-services/account-report.service';
import { CommonsService } from '../../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';

// Custoem Function
import { OnCreatedDirective } from '../../../shared/on-created.directive';

// Export
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

declare var $: any;
@Component({
  selector: 'app-profit-and-loss',
  templateUrl: './profit-and-loss.component.html',
  styleUrls: ['./profit-and-loss.component.css']
})
export class ProfitAndLossComponent implements OnInit {
constructor(private accountReportService: AccountReportService, private session:SessionStorageService,private commonsServices:CommonsService,private exportAsService:ExportAsService,private router:Router) { }
	exportAsConfig: ExportAsConfig;
	  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
	
	authData:any={};
	serachReportData:any={};	
	reportData: any[] = [];
	petro_id:number;

	pump_name:number;
	pump_address:string;
	pump_mobile_no:number;
	row_total:number;
	pipe:any;

	show_details:boolean;
	show_detailscol:string="2";
	load_more_status:boolean;
	show_loading_image:boolean;
	data_status:boolean;
	row_total_array:any={};
	sub_row_total_array:any={};
	config:any;

	searchForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
	
		
	});	

  ngOnInit() {
		
		this.show_loading_image=false;

		this.data_status=false;

		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		//console.log(this.authData);
		this.pump_name=this.authData.petro_name;
		this.pump_mobile_no=this.authData.petro_phone_no;
		this.pump_address=this.authData.petro_address;
		this.serachReportData.from_date=new Date(Date.now());
		this.serachReportData.to_date=new Date(Date.now());
		
		
  }
  
    //Get Report Data
	getReportData() {
			this.show_loading_image=true;
			this.load_more_status=true;
			 this.pipe = new DatePipe('en-US'); 
	  this.serachReportData.from_date= this.pipe.transform(this.serachReportData.from_date, 'yyyy-MM-dd');		
	  this.serachReportData.to_date= this.pipe.transform(this.serachReportData.to_date, 'yyyy-MM-dd');
	 this.accountReportService.getPropfitAndLossData(this.petro_id,this.serachReportData)
	  .subscribe(balanceData => {
	  // console.log(purchaseListData);
	  if(balanceData.status==200)
		  {
	   this.reportData=balanceData.data;
	   this.data_status=true;
	  /* this.serachReportData.from_date=balanceData.data.from_date;
	   this.serachReportData.to_date=balanceData.data.to_date;*/
			this.calculateProfitandLoss(balanceData.data);
		  }else{
			  this.reportData=[];
			  this.data_status=false;
			 

		  }
		   this.show_loading_image=false;
	   
	  });
	}
	searchReport(){
		this.getReportData();
	}
	gross_profit:number;
	net_profit:number;
	gross_total:number;
	net_total:number;
	gross_side_left:number;
	gross_side_right:number;
	net_side_left:number;
	net_side_right:number;
	net_total_right:number;
	calculateProfitandLoss(allData){
		if(!allData.all_opening){
			allData.all_opening=0;
		}
		if(!allData.purchase.amount){
			allData.purchase.amount=0;
		}
		if(!allData.direct_Expense.amount){
			allData.direct_Expense.amount=0;
		}
		
		if(!allData.sale_account.amount){
			allData.sale_account.amount=0;
		}
		if(!allData.indirect_income.amount){
			allData.indirect_income.amount=0;
		}
		if(!allData.allClosing){
			allData.allClosing=0;
		}
		
		this.gross_side_left=(Number(allData.all_opening)+Number(allData.direct_Expense.amount)+Number(allData.purchase.amount));
		this.gross_side_right=(Number(allData.sale_account.amount)+Number(allData.direct_income.amount));
		if(allData.allClosing>0){
			this.gross_side_right=Number(this.gross_side_right)+Number(allData.allClosing);
		}else{
			this.gross_side_left=Number(this.gross_side_left)+Number(Math.abs(allData.allClosing));
		}
		
		this.gross_profit=Number(this.gross_side_left)-Number(this.gross_side_right);
		
		if(this.gross_profit  > 0 && this.gross_profit < 1)
		{
			
				this.gross_profit=Math.round(this.gross_profit);
		}
	
		
		if(this.gross_side_left>this.gross_side_right){
			this.gross_total=this.gross_side_left;
		}else{
			this.gross_total=this.gross_side_right;
		}
		
		//Net
		if(!allData.indirect_Expense.amount){
			allData.indirect_Expense.amount=0;
		}
		if(!allData.indirect_income.amount){
			allData.indirect_income.amount=0;
		}
		if(this.gross_profit<=0)
		{
			this.net_side_left=Number(Math.abs(this.gross_profit))-Number(allData.indirect_Expense.amount);
		
				this.net_side_right=Number(allData.indirect_income.amount);
		}else{
			
				this.net_side_right=Number(allData.indirect_income.amount);
			this.net_side_left=Number(this.gross_profit)+Number(allData.indirect_Expense.amount);
		}
		
		this.net_profit=Number(this.net_side_left)-Number(this.net_side_right);
	  
	//console.log(this.net_profit);
	  
	/*  
		if(this.gross_profit<=0)
		{
				console.log('net profit' + this.net_profit );
					console.log('gross_profit' + this.gross_profit );
			    this.net_total=Number(Math.abs(this.net_profit))+Number(Math.abs(this.gross_profit))+Number(allData.indirect_Expense.amount);
		
				this.net_total_right=Number(this.net_profit)+Number(Math.abs(this.gross_profit))+Number(allData.indirect_income.amount);
		}else{
			console.log('more' + this.gross_profit );
			console.log('net profit' + this.net_profit );
			console.log('IE profit' + allData.indirect_Expense.amount );
			this.net_total=Number(this.net_profit)-Number(allData.indirect_Expense.amount)-Number(this.gross_profit);
		  //  this.net_total_right=Number(this.gross_profit)+Number(allData.indirect_income.amount);
				this.net_total_right=Number(allData.indirect_income.amount);
		//	this.net_total=Number(this.gross_profit)+Number(allData.indirect_Expense.amount);
		}
	*/
	
	if(this.net_side_left>this.net_side_right && this.net_side_left!=0){

			this.net_total=Number(Math.abs(this.net_profit))+Number(allData.indirect_Expense.amount);
			this.net_total_right=Number(Math.abs(this.gross_profit))+Number(Math.abs(allData.indirect_income.amount));
			
		}else{
		
			this.net_total=Number(Math.abs(this.gross_profit))+Number(Math.abs(allData.indirect_Expense.amount));
			this.net_total_right=Number(Math.abs(this.net_profit))+Number(Math.abs(this.gross_profit))+Number(Math.abs(allData.indirect_income.amount));
		}
		
		
	}

	goLedgerpage(group_id){
		this.serachReportData.group_id=group_id;
		this.session.set('trialledgerSession',this.serachReportData);
		this.router.navigate(['admin/trial-ledger']);
	}
		
	goLedgerReportpage(ledger_id,ledger_name){
		//alert(ledger_id);
		this.serachReportData.ledger_id=ledger_id;
		this.serachReportData.ledger_name=ledger_name;
		this.session.set('trialVoucherSession',this.serachReportData);
		this.router.navigate([]).then(result => {  window.open('#/admin/ledgerReport', '_blank'); });
	}
	showDetails(event){
		if(event.target.checked){
			this.show_detailscol="3";
			this.show_details=true;
			
		}else{
			this.show_detailscol="2";
			this.show_details=false;
		
		}
		
	}
	
	
	exportTable(event) {

	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
		options: { // html-docx-js document options
			orientation: 'landscape',
			margins: {
			  top: '20'
			},
			escape : 'false',
			ignoreColumn: []
		  }
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'Profit and loss ');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }
  
    // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
  

	ExportTable(){
		$("exportTable").tableExport({
		headings: true,                    // (Boolean), display table headings (th/td elements) in the <thead>
		footers: true,                     // (Boolean), display table footers (th/td elements) in the <tfoot>
		formats: ["xls", "csv", "txt"],    // (String[]), filetypes for the export
		fileName: "id",                    // (id, String), filename for the downloaded file
		bootstrap: true,                   // (Boolean), style buttons using bootstrap
		position: "well" ,                // (top, bottom), position of the caption element relative to table
		ignoreRows: null,                  // (Number, Number[]), row indices to exclude from the exported file
		ignoreCols: null,                 // (Number, Number[]), column indices to exclude from the exported file
		ignoreCSS: ".tableexport-ignore"   // (selector, selector[]), selector(s) to exclude from the exported file
	});
	
}
  
}
