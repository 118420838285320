import { Component, OnInit } from '@angular/core';
import { AccountReportService } from '../../../admin-services/account-report.service';
import { CommonsService } from '../../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
import { Router} from '@angular/router';
// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';

// Custoem Function
import { OnCreatedDirective } from '../../../shared/on-created.directive';

// Export
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

declare var $: any;

@Component({
  selector: 'app-customer-bill-ledger',
  templateUrl: './customer-bill-ledger.component.html',
  styleUrls: ['./customer-bill-ledger.component.css']
})
export class CustomerBillLedgerComponent implements OnInit {

  constructor(private accountReportService: AccountReportService, private session:SessionStorageService,private commonsServices:CommonsService,private exportAsService:ExportAsService,private router:Router) { }
	exportAsConfig: ExportAsConfig;
	  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
	
	authData:any={};
	serachReportData:any={};	
	reportData: any[] = [];
	ledgerAutoSugestList: any[] = [];
	petro_id:number;

	pump_name:number;
	pump_address:string;
	cust_name:string;
	pump_mobile_no:number;
	gtotal:number;
	total_debit:number;
	total_credit:number;
	opening:number;
	balance:any={};
	first_balance:number;
	closing_balance:number;


	load_more_status:boolean;
	show_loading_image:boolean;
	config:any;

	searchForm= new FormGroup({
		tmp_ledger_id : new FormControl(' ',Validators.required),		
		from_date : new FormControl(' '),		
		to_date : new FormControl(' ')		
	});	

  ngOnInit() {

		this.serachReportData.from_date=new Date(Date.now());
		this.serachReportData.to_date=new Date(Date.now());
		this.load_more_status=true;
		this.show_loading_image=false;



		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		//console.log(this.authData);
		this.pump_name=this.authData.petro_name;
		this.pump_mobile_no=this.authData.petro_phone_no;
		this.pump_address=this.authData.petro_address;

		
  }
  
  public filterItemList(value:any):void {	  
	   this.commonsServices.getLedgerByName(value, this.petro_id)
		.subscribe(alldata => {
		this.ledgerAutoSugestList=Object.entries(alldata.data).map(([value, label]) => ({value,label}));
		});
  }
   //Get Report Data
	getReportData() {
			this.show_loading_image=true;
			this.load_more_status=true;
	this.cust_name=this.serachReportData.tmp_ledger_id.label;
	this.serachReportData.customer_id=this.serachReportData.tmp_ledger_id.value;
	 this.accountReportService.getCustomerBillToBIllLedgerData(this.petro_id,this.serachReportData)
	  .subscribe(balanceData => {
	  // console.log(purchaseListData);
	  if(balanceData.status==200)
		  {
		   this.reportData=balanceData.data;
		   this.opening=balanceData.opening;
	

			  }else{
				  this.reportData=[];
				 this.opening=balanceData.opening;
				 this.closing_balance=balanceData.opening;
				 if(balanceData.opening>0)
				 {
					 this.total_debit=balanceData.opening;
				 }else{
					  this.total_credit=balanceData.opening;
				 }

		  }
		   this.show_loading_image=false;
	   
	  });
	}
	// Get LoadMore Ledger Data
	/*getBillToLedgerReportLoadMoreData(last_id:number) {
		
	this.serachReportData.customer_id=this.serachReportData.tmp_ledger_id.value;
	 this.accountReportService.getBillToLedgerReportLoadMoreData(this.petro_id,last_id,this.serachReportData)
	  .subscribe(loadmoreData => {
		  
		  if(loadmoreData.status==200)
		  {
			 // console.log(this.reportDataDummy);
			 // console.log(loadmoreData.data);
			 this.reportData=this.reportData.concat(loadmoreData.data);
			  //console.log(this.reportData);
			  this.load_more_status=true;
			 
		  }else{
			    this.load_more_status=false;
			    this.show_loading_image=false;
				
		  }
	   //console.log(purchaseListData);
	   //this.reportData=purchaseListData.data;
	   //this.ledger_opening=purchaseListData.opening;
	  });
	
	}*/
	searchReport(){
		this.getReportData();
	}
	resetSearchReport(){
		this.serachReportData={};
		this.reportData=[];
		this.gtotal=0;
        this.cust_name="";
	}
	 getClosing(total,type,i,last){	
		if(i==0)
		{
		this.gtotal=0;
		this.total_debit=0;
		this.total_credit=0;
		this.first_balance=this.opening;
		}else{
			this.first_balance=this.balance[i-1];
		}
		
		if(type==2)
		{
			this.total_credit=Number(this.total_credit)+Number(total);
			this.balance[i]=Number(this.first_balance)-Number(total);
		}else if(type==1)
		{
			this.total_debit=Number(this.total_debit)+Number(total);
			this.balance[i]=Number(this.first_balance)+Number(total);
		}
		
		this.gtotal=Number(this.gtotal)+Number(total);
		if(last)
		{
		this.closing_balance=this.balance[i];	
		}
		
	 
  }
  
    exportTable(event) {
		if(event.target.value)
		{
		this.exportAsConfig = {
			type: event.target.value, // the type you want to download
			elementId: 'exportTable', // the id of html/table element
		}	
			//this.exportTableDirective.exportData();
		// download the file using old school javascript method
		this.exportAsService.save(this.exportAsConfig, 'Ledger Report ');
		// get the data as base64 or json object for json type - this will be helpful in ionic or SSR
		this.exportAsService.get(this.config).subscribe(content => {
		
		});
	  }
  }
  // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
}
