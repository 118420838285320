import { Component, OnInit } from '@angular/core';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
import { OnCreatedDirective } from '../../../shared/on-created.directive';
import { SaleServiceService } from '../../../admin-services/sale-service.service';
import { CommonsService } from '../../../admin-services/commons.service';
import{DatePipe}from '@angular/common';
import { Router} from '@angular/router';
import { constDefault } from '../../../const_default';

declare var $: any;
@Component({
  selector: 'app-selected-customer-credit-wise',
  templateUrl: './selected-customer-credit-wise.component.html',
  styleUrls: ['./selected-customer-credit-wise.component.css']
})
export class SelectedCustomerCreditWiseComponent implements OnInit {



  authData:any={};
   petro_id:number;
   pump_name:string;
   pump_address:string;
   shift_no:number;
   setDob:string;
   show_loading_image:boolean;
	
   setDob1:string;
   reportData: any[] = [];
   serachCustCSRData:any={};
   ledger:any[]=[];
   selectedPeople2 = [];
  
total_amount:any;
total_qty_hsd:any;
total_amt_hsd:any;
total_qty_bhsd:any;
total_amt_bhsd:any;
total_qty_ms:any;
total_amt_ms:any;
total_qty_bms:any;
total_amt_bms:any;
total_other:any;
config:any;
   //serachLedgerReportData:any={};	

  constructor(private router: Router,private saleServiceService:SaleServiceService, private session:SessionStorageService,private exportAsService:ExportAsService,private commonsServices: CommonsService) { }
  exportAsConfig: ExportAsConfig;
  
  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'YYYY-MM-DD',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		//locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
   
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  searchAllCSRForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		tmp_customer_id : new FormControl(' ',Validators.required)
		
	});		
	


  ngOnInit() {
	  // Define zero all total
	this.total_amount=0;
	this.total_qty_hsd=0;
	this.total_amt_hsd=0;
	this.total_qty_bhsd=0;
	this.total_amt_bhsd=0;
	this.total_qty_ms=0;
	this.total_amt_ms=0;
	this.total_qty_bms=0;
	this.total_amt_bms=0;
	this.total_other=0;
	
	// Hide loading image
	this.show_loading_image=false;
  
	this.authData = this.session.get('authData');
	this.petro_id = this.authData.petro_id;
	this.serachCustCSRData.from_date=new Date(Date.now());
	this.serachCustCSRData.to_date=new Date(Date.now());
	this.pump_name=this.authData.petro_name;	
	this.pump_address=this.authData.petro_address;
	//this.serachCustCSRData.to_date=new Date(Date.now());
	// Get customer list
	this.commonsServices.getLedgerListByGroup(constDefault.SUNDRY_DEBTOR_GROUP, this.petro_id)
	  .subscribe(customerList => {
	   //console.log(customerList);
	   //this.selectedPeople2=customerList.data;
	   //var data = { firstName: 'John', lastName: 'Doe', email: 'john.doe@gmail.com' }
	   // Convert  key value array to multi dimensial array Ex: {1:India,2:Nepal} to [{vlaue: 1,label:India} ,{vlaue: 2,label:Nepal}]
	   if(customerList.data)
	   {
	   this.selectedPeople2 = Object.entries(customerList.data).map(([value, label]) => ({value,label}));
	   }
	  });
	  
  }
  searchAllCSR(){
      
	this.show_loading_image=true;  
    let datePipe =  new DatePipe('en-US');
    this.setDob = datePipe.transform(this.serachCustCSRData.from_date, 'yyyy-MM-dd');

    this.setDob1 = datePipe.transform(this.serachCustCSRData.to_date, 'yyyy-MM-dd');

    this.serachCustCSRData.from_date=this.setDob;
    this.serachCustCSRData.to_date=this.setDob1;
	if(this.serachCustCSRData.tmp_customer_id)
	{
    this.serachCustCSRData.customer_id=this.serachCustCSRData.tmp_customer_id.value;
	}
    this.saleServiceService.getselectedCSRData(this.petro_id,this.serachCustCSRData)
   
  .subscribe(saleCustData => {
   //console.log(purchaseListData);
   if(saleCustData.status==200)
    {
		this.reportData=saleCustData.data;
		
  
       }else if(saleCustData.status==403)
       {
           this.session.remove('authData');
           this.router.navigate(['']);
       }else{
		   // Hide loading image
		   this.show_loading_image=false;  
	   } 
	      this.show_loading_image=false;  
  });
}
 
getTotalAmt(amount,qty_hsd,amt_hsd,qty_bhsd,amt_bhsd,qty_ms,amt_ms,qty_bms,amt_bms,other,i){

 
  this.total_amount=Number(this.total_amount)+Number(amount);
  this.total_qty_hsd=Number(this.total_qty_hsd)+Number(qty_hsd);
  this.total_amt_hsd=Number(this.total_amt_hsd)+Number(amt_hsd);
  this.total_qty_bhsd=Number(this.total_qty_bhsd)+Number(qty_bhsd);
  this.total_amt_bhsd=Number(this.total_amt_bhsd)+Number(amt_bhsd);
  this.total_qty_ms=Number(this.total_qty_ms)+Number(qty_ms);
  this.total_amt_ms=Number(this.total_amt_ms)+Number(amt_ms);
  this.total_qty_bms=Number(this.total_qty_bms)+Number(qty_bms);
  this.total_amt_bms=Number(this.total_amt_bms)+Number(amt_bms);
  this.total_other=Number(this.total_other)+Number(other);
  
   this.total_amount=this.total_amount.toFixed(2);
  this.total_qty_hsd=this.total_qty_hsd.toFixed(2);
  this.total_amt_hsd=this.total_amt_hsd.toFixed(2);
  this.total_qty_bhsd=this.total_qty_bhsd.toFixed(2);
  this.total_amt_bhsd=this.total_amt_bhsd.toFixed(2);
  this.total_qty_ms=this.total_qty_ms.toFixed(2);
  this.total_amt_ms=this.total_amt_ms.toFixed(2);
  this.total_qty_bms=this.total_qty_bms.toFixed(2);
  this.total_amt_bms=this.total_amt_bms.toFixed(2);
  this.total_other=this.total_other.toFixed(2);
  
  


}



resetAllCSRData(){
	  
  this.serachCustCSRData.from_date=new Date();
  this.serachCustCSRData.to_date=new Date();
  this.reportData=[];
  

  this.total_amount=0.00;
  this.total_qty_hsd=0.00;
  this.total_amt_hsd=0.00;
  this.total_qty_bhsd=0.00;
  this.total_amt_bhsd=0.00;
  this.total_qty_ms=0.00;
  this.total_amt_ms=0.00;
  this.total_qty_bms=0.00;
  this.total_amt_bms=0.00;
  this.total_other=0.00;
  

}
  exportTable(event) {
    if(event.target.value=='xlsx')
    {
    this.exportAsConfig = {
      type: event.target.value, // the type you want to download
      elementId: 'exportTable', // the id of html/table element
    }	
      //this.exportTableDirective.exportData();
      // download the file using old school javascript method
      this.exportAsService.save(this.exportAsConfig, 'Shift Report ');
      // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
      this.exportAsService.get(this.config).subscribe(content => {
      
      });
    }
  }
 // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
}
