import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { Router} from '@angular/router';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { $ } from 'protractor';
@Component({
  selector: 'app-user-master',
  templateUrl: './user-master.component.html',
  styleUrls: ['./user-master.component.css']
})
export class UserMasterComponent implements OnInit {

  constructor(private masterService: MasterServicesService, private router:Router,private session:SessionStorageService, private formBuilder: FormBuilder ) { }

  authData:any={};
  statusChangeData:any={};
  serachUserData:any={};	
  success_message:string;	
	error_message:string;	
	currentPage:number;	
	petro_id:number;	
	index:number;	
  totalData:number;
  userList:any[]=[];

  link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
  searchUserForm= new FormGroup({
		name : new FormControl(' ',Validators.required)
  });

  changeStatusForm= new FormGroup({
		status : new FormControl(' ',Validators.required)
  });
  
  
  ngOnInit() {
	  // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
    this.authData = this.session.get('authData');
		this.petro_id=this.authData.petro_id;
    this.pageWiseCustomerList(1);	
  }

    searchUser()
    {
       this.pageWiseCustomerList(1);
    }
    
    resetSearchUser(){
      this.serachUserData={};
      this.pageWiseCustomerList(1);
    }

    pageWiseCustomerList(pageno){
      this.currentPage=pageno;
      this.masterService.getUserListData(pageno,this.petro_id,this.serachUserData)
      .subscribe(alldata =>{
       this.userList=alldata.data;
       this.totalData=alldata.count 
      });
    }
    custData:any={};

    change_status(id,status,gstin,pan){
      //lert();
      if(status==1){
        status=0;
      }else{
        status=1;
      }
      //console.log(id);
      if(gstin==""){
        gstin=null;
      }else if(pan==""){
        pan=null;
      }

      this.custData['user_id']=id;
      this.custData['status']=status;
      this.custData['gstin']=gstin;
      this.custData['pan']=pan;
      this.custData['petro_id']=this.petro_id;

      console.log(this.custData);
      //return;
      this.masterService.customerStatusChange(this.custData).subscribe(data=>{
        if(data.status==200)
        {
          this.success_message='Status has been changed successfully';
          // setTimeout(this.success_time_out,3000);
          this.pageWiseCustomerList(1);	
          
        }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}
      })
     
    }

    success_time_out(){
      this.success_message='';
    }

}


     