import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../../admin-services/master-services.service';
import { FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { CommonsService } from '../../../admin-services/commons.service';
import { AccountService } from '../../../admin-services/account.service';
import { uniqueByModalOrNameValidation } from '../../../shared/unique-validation.directive';
import { Router} from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-group-master',
  templateUrl: './group-master.component.html',
  styleUrls: ['./group-master.component.css']
})
export class GroupMasterComponent implements OnInit {

  constructor(private accountService: AccountService,private masterService: MasterServicesService, private router:Router,private session:SessionStorageService, private commonsService:CommonsService, private formBuilder: FormBuilder) { }

  authData:any={};
  petro_id:number;
  addGroupData : any={};
	editGroupData : any={};
  data : any[] = [];
  success_message:string;
	error_message:string;
	serachGroupData : any={};
	groupList:any[] = [];
	index:number;
	model:string;
	currentPage:number;
	totalCat:number;
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
  successAddGroupData:{
		Location:{
			id:any,
      name:string,
      parent_group:string
		}
  }
  parentGroupList = [];
	addGroupForm:FormGroup;
  
  editGroupForm = new FormGroup({
		id : new FormControl('',Validators.required),
    name : new FormControl(' ',Validators.required),
    parent_id : new FormControl(' '),
	})
	
	serachGroupForm= new FormGroup({
    name : new FormControl(' '),
    search_parent_group : new FormControl(' ')
  });
  
  ngOnInit() {
    // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
    this.authData = this.session.get('authData');
    this.petro_id=this.authData.petro_id;
    
    this.addGroupForm =this.formBuilder.group({
      name: new FormControl(' ',Validators.required , uniqueByModalOrNameValidation(this.commonsService,'Group',this.petro_id)),  //
      parent_id: new FormControl(' ')
    });

    //for auto select 
    this.commonsService.getParentGroupList(this.petro_id)
		.subscribe(parentGroupData =>
		{
			this.parentGroupList= Object.entries(parentGroupData.data).map(([value, label]) => ({value,label}));			
    }); 
    
    this.pageWiseData(1);
  }


  addGroup(group){
    group.submitted=false;
    this.authData = this.session.get('authData');
      this.addGroupData.petro_id=this.authData.petro_id;
      this.addGroupData.parent_id=this.addGroupData.parent_id.value;
    	this.accountService.addNewGroup(this.addGroupData).subscribe(data=>{
    		if(data.status==201)
    		{
    			this.success_message='Group has been created';
    		$('#addGroupModal').modal('hide');
    		this.pageWiseData(1);
    		this.addGroupForm.reset(); 
        
    	}else{
    		this.error_message='Location could not be created';
    	}
    	});
  }


  editParentGroupList=[];
  // Get Group Data
  getGroupData(id,i){
  
    this.accountService.getEditGroupData(id).subscribe(data=>{
      this.editGroupData.id=data['data'].Group.id;
      this.editGroupData.name=data['data'].Group.name;
      this.editGroupData.parent_id=data['data'].ParentGroup.name;  
    });
    $('#editGroupModal').modal('show');
  }


  editGroup(editG){
    this.authData = this.session.get('authData');
    this.editGroupData.petro_id=this.authData.petro_id;
	if(this.editGroupData.parent_id)
	{
    this.editGroupData.parent_id=this.editGroupData.parent_id.value;
	}
		this.accountService.editGroup(this.editGroupData).subscribe(data=>{
      if(data.status==200)
      {
        this.success_message='Group has been Updated Successfully';
        $('#editGroupModal').modal('hide');
        this.pageWiseData(1);
        this.editGroupForm.reset();
		    editG.submitted=false;
		
      }else{
        this.error_message='Group could not be Update';
      }
		});
  }


  deleteGroupData(id,i){
    if(confirm("Are you sure to delete this Group")) { 
			this.index=i;
			this.model='Group'; 
			this.masterService.deleteDataByModel(id,this.model).subscribe(data=>{
				if(data.status==true)
				{
					this.groupList.splice(this.index,1);
					this.success_message="Group deleted sucessfully";	
				}else{
					this.error_message= "Group could not be deleted.";
				}
			});
		}
  }


  // search group data
  searchGroup(){
		this.pageWiseData(1);
	} 

	// Reset Search Data
	resetSearchGroup(){
		this.serachGroupData={};
		this.pageWiseData(1);
	} 

	// Pagination 
	pageWiseData(pageno){
		this.currentPage=pageno;
		this.accountService.getGroupList(pageno,this.petro_id,this.serachGroupData)
		.subscribe(alldata =>{
			this.groupList=alldata.data,
			this.totalCat=alldata.count
    });
	} 

}
