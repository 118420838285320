import {Component,OnInit,Input} from '@angular/core';
import {FormGroup,FormControl,Validators,FormBuilder,FormArray} from '@angular/forms';
import {LocalStorageService,SessionStorageService,LocalStorage,SessionStorage} from 'angular-web-storage';
import {AccountService} from '../../../admin-services/account.service';
import {MasterServicesService} from '../../../admin-services/master-services.service';
import {constDefault} from '../../../const_default';
import {Router} from '@angular/router';
// Date Picker
import {DatepickerOptions} from 'ng2-datepicker';
 import * as enLocale from 'date-fns/locale/en';
declare var $: any;
import { DatePipe } from '@angular/common';
@Component({
	selector: 'app-payment-voucher',
	templateUrl: './payment-voucher.component.html',
	styleUrls: ['./payment-voucher.component.css']
})
export class PaymentVoucherComponent implements OnInit {
	num1:number;
	num2:number;
	c:number;
	submit(){
		this.c=this.num1 + this.num2
		console.log(this.c);
	}

	constructor(private accountService: AccountService, private router: Router, private session: SessionStorageService, private masterService: MasterServicesService, private formBuilder: FormBuilder) {}
	// Options For From Date (Search)
// Options For To Date (Search)
	options_form: DatepickerOptions = {
	 minYear: 2015,
	 maxYear: 2030,
	 displayFormat: 'DD-MM-YYYY',
	 barTitleFormat: 'MMMM YYYY',
	 dayNamesFormat: 'dd',
	 firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
	 locale: enLocale,
 
	 //minDate: new Date(Date.now()), // Minimal selectable date
	 //maxDate: new Date(Date.now()),  // Maximal selectable date
 
	 barTitleIfEmpty: 'Click to select a date',
	 placeholder: 'From Date', // HTML input placeholder attribute (default: '')
	 addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
	 addStyle: {
		'width': '100%'
	 }, // Optional, value to pass to [ngStyle] on the input field
	 fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
	 useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};
 
	// Options For To Date (Search)
	options_to: DatepickerOptions = {
	 minYear: 2015,
	 maxYear: 2030,
	 displayFormat: 'DD-MM-YYYY',
	 barTitleFormat: 'MMMM YYYY',
	 dayNamesFormat: 'dd',
	 firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
	 locale: enLocale,
 
	 //minDate: new Date(Date.now()), // Minimal selectable date
	 //maxDate: new Date(Date.now()),  // Maximal selectable date
 
	 barTitleIfEmpty: 'Click to select a date',
	 placeholder: 'To Date', // HTML input placeholder attribute (default: '')
	 addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
	 addStyle: {
		'width': '100%'
	 }, // Optional, value to pass to [ngStyle] on the input field
	 fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
	 useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};
 
	// Options Date (Modal)
	options: DatepickerOptions = {
	 minYear: 2015,
	 maxYear: 2030,
	 displayFormat: 'DD-MM-YYYY',
	 barTitleFormat: 'MMMM YYYY',
	 dayNamesFormat: 'dd',
	 firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
	 locale: enLocale,
 
	 //minDate: new Date(Date.now()), // Minimal selectable date
	 //maxDate: new Date(Date.now()),  // Maximal selectable date
 
	 barTitleIfEmpty: 'Click to select a date',
	 placeholder: 'Select Date', // HTML input placeholder attribute (default: '')
	 addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
	 addStyle: {
		'width': '100%'
	 }, // Optional, value to pass to [ngStyle] on the input field
	 fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
	 useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};


	pipe: any;
	authData: any = {};
	addVoucher: any = {};
	editVoucher: any = {};
	cashBankLedgerList: any[] = [];
	ledgerListData: any[] = [];
	success_message: string;
	error_message: string;
	petro_id: number;
	addVoucherList: FormArray;
	addVoucherForm: FormGroup;
	credit_ledger: number;
	debit_ledger: number;
	voucher_ledger_type: number;
	serachVoucherData: any = {};
	currentPage: number;
	totalPayVoucher: number;
	voucherList: any[] = [];
	editVoucherList: FormArray;
	index: number;
	model: string;
	uploadFilesData: File = null;

		link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	searchPaymentVoucherForm = new FormGroup({
		from_date: new FormControl(''),
		to_date: new FormControl('')
	});

	viewVoucherForm = new FormGroup({
		id: new FormControl(''),
		name: new FormControl(' ', Validators.required),
	})

	editVoucherForm = this.formBuilder.group({
		vid: new FormControl('', Validators.required),
		date: new FormControl('', Validators.required),
		ledger_name: new FormControl('', Validators.required),
		cheque_no: new FormControl(''),
		subtype: new FormControl(''),
		cheque_date: new FormControl(''),
		amount: new FormControl('', Validators.required),
		narration: new FormControl(''),
		editVoucherList: this.formBuilder.array([])
	});


	ngOnInit() {
		// Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
			this.serachVoucherData.from_date=new Date(Date.now());
			this.serachVoucherData.to_date=new Date(Date.now());

		}
		// End Button Privilages
		
		$("#cheque_info").hide();
		// Session read for petro id
		this.authData = this.session.get('authData');
	
		this.petro_id = this.authData.petro_id;
		// Cash and Bank list
		this.accountService.getCashAndBankLedgerList(this.petro_id).subscribe(ledgerdata => {
			this.cashBankLedgerList = Object.entries(ledgerdata.data).map(([value, label]) => ({
				value,
				label
			}));
		})

		// Ledger List
		this.accountService.getLedgerListForVoucher(this.petro_id).subscribe(ledgerdata => {
			this.ledgerListData = Object.entries(ledgerdata.data).map(([value, label]) => ({
				value,
				label
			}));
		})
		this.pageWiseData(1);
		this.credit_ledger = constDefault.CREDIT_LEDGER;
		this.debit_ledger = constDefault.DEBIT_LEDGER;
		this.voucher_ledger_type = constDefault.CASH_LEDGER;
		//$('select option[value="this.credit_ledger"]').attr("selected",true);
		this.addVoucherForm = this.formBuilder.group({
			date: new FormControl('', Validators.required),
			ledger: new FormControl('', Validators.required),
			cheque_no: new FormControl(''),
			upload_file: new FormControl(''),
			subtype: new FormControl(''),
			cheque_date: new FormControl(''),
			amount: new FormControl('', Validators.required),
			narration: new FormControl(''),
			addVoucherList: this.formBuilder.array([this.addVoucherItem()])
		});

		this.addVoucher.date = new Date(Date.now());
		this.addVoucher.cheque_date = new Date(Date.now());

		this.editVoucher.cheque_date=new Date();

	}

	// Show check info options if selected ledget value is not cash for Add Payment Voucher
	show_check_options() {
		if (this.addVoucher.ledger.value != this.voucher_ledger_type) {
			$("#cheque_info").show();
		} else {
			$("#cheque_info").hide();
			$("#edit_cheque_info").hide();
		}
		
	}

	// Show check info options if selected ledget value is not cash for Edit Payment Voucher
	show_edit_check_option(value: any) {
		if (value.value != this.voucher_ledger_type) {
			$("#edit_cheque_info").show();
		} else {
			
			$("#edit_cheque_info").hide();
		}
		
	}

	// Get Voucher List Fields for (Add) Form Array
	get addVoucherFormData() {
		return this.addVoucherForm.get('addVoucherList')
	}

	// Get Voucher List Fields for (Edit) Form Array
	get editVoucherFormData() {
		return this.editVoucherForm.get('editVoucherList')
	}

	// Function to add One Row in Add Voucher Table (Add Modal)
	addItem() {
		this.addVoucherList = this.addVoucherForm.get('addVoucherList') as FormArray;
		this.addVoucherList.push(this.addVoucherItem());
	}

	// Function to add a row in Edit Voucher Table (Edit Modal)
	addEditItem() {
		this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
		this.editVoucherList.push(this.editVoucherItem());
	}

	// Function to Remove One Row in Add Voucher Table (Modal)
	removeItem(i) {
		if (confirm("Do you really want to Delete this Entry?")) {
			if (this.addVoucherList.length > 1) {
				this.addVoucherList.removeAt(i);
				this.getTotal(i)
			} else if (this.addVoucherList.length < 2) {
			}
		}
	}

	// Function to Remove One Row in Edit Voucher Table (Modal)
	removeEditItem(i) {
		if (confirm("Do you really want to Delete this Entry?")) {
			if (this.editVoucherList.length > 1) {
				this.editVoucherList.removeAt(i);
				this.getEditTotal(i)
			} else if (this.editVoucherList.length < 2) {
			}
		}
	}

	// function to add multiple Voucher Entries 
	addVoucherItem(): FormGroup {
		return this.formBuilder.group({
			dr_cr: [constDefault.DEBIT_LEDGER, Validators.required],
			ledger_id: ['', Validators.required],
			amount: ['', Validators.required]
		})
	}

	// Function to add Multiple voucher Entries
	editVoucherItem(): FormGroup {
		return this.formBuilder.group({
			dr_cr: [constDefault.DEBIT_LEDGER, Validators.required],
			ledger_id: ['', Validators.required],
			ledger_name: ['', Validators.required],
			amount: ['', Validators.required],
			id: ['', Validators.required],
		})
	}

	cr_amount: number;
	dr_amount: number;
	// Calculate Total Amount
	getTotal(index) {
		this.addVoucherList = this.addVoucherForm.get('addVoucherList') as FormArray;
		this.cr_amount = 0;
		this.dr_amount = 0;
		this.addVoucherList.value.forEach((data, index) => {

			if (this.addVoucherList.value[index]['dr_cr'] == constDefault.CREDIT_LEDGER) {
				this.cr_amount = this.cr_amount + Number(this.addVoucherList.value[index]['amount']);
			}

			if (this.addVoucherFormData.value[index]['dr_cr'] == constDefault.DEBIT_LEDGER) {
				this.dr_amount = this.dr_amount + Number(this.addVoucherList.value[index]['amount']);
			}
		})
	}

	cr_Edit_amount: number;
	dr_Edit_amount: number;

	getEditTotal(index) {
		this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
		this.cr_Edit_amount = 0;
		this.dr_Edit_amount = 0;
		this.editVoucherList.value.forEach((data, index) => {
			if (this.editVoucherList.value[index]['dr_cr'] == constDefault.CREDIT_LEDGER) {
				this.cr_Edit_amount = this.cr_Edit_amount + Number(this.editVoucherList.value[index]['amount']);
			}
			if (this.editVoucherList.value[index]['dr_cr'] == constDefault.DEBIT_LEDGER) {
				this.dr_Edit_amount = this.dr_Edit_amount + Number(this.editVoucherList.value[index]['amount']);
			}
		})
	}

	/*
	Upload Image
	Roshan Bagde
	21.11.18
	*/
	onFileChange(event) {
		this.uploadFilesData = < File > event.target.files[0];
	}

	total_credit: number;
	total_debit: number;
	voucherDetails: any[];

	// Submit Voucher Entry
	addVoucherData(add_voucher) {
		add_voucher.submitted = false;
		this.authData = this.session.get('authData');
		this.pipe = new DatePipe('en-US'); 
		this.addVoucher.date= this.pipe.transform(this.addVoucher.date, 'yyyy-MM-dd');
		this.addVoucher.cheque_date= this.pipe.transform(this.addVoucher.cheque_date, 'yyyy-MM-dd');
		
		this.addVoucher.petro_id = this.authData.petro_id;
		this.addVoucher.ledger_id = this.addVoucher.ledger.value;
		this.addVoucher.voucherDetails = this.addVoucherList.value;
		// If ledger type is cash then cheque info should be empty
		if (this.addVoucher.ledger_id == this.voucher_ledger_type) {
			this.addVoucher.cheque_no = "";
			this.addVoucher.cheque_date = "";
		}
		if (this.addVoucher.subtype == true)
			this.addVoucher.subtype = 1;
		else
			this.addVoucher.subtype = 0;
		//return;
		this.total_credit = Number(this.addVoucher.amount) + Number(this.cr_amount);
		const duploadData = new FormData();

	
		duploadData.append('image', this.uploadFilesData);
		duploadData.append('info', JSON.stringify(this.addVoucher));

		if (this.dr_amount == this.total_credit) {
			
			this.accountService.addPaymentVoucher(duploadData).subscribe(data => {
				if (data.status == 201) {
					this.success_message = 'Voucher has been created';
					this.cr_amount = 0;
		            this.dr_amount = 0;
				//	$('#addPaymentModal').modal('hide');
					this.pageWiseData(1);
					//this.addVoucherForm.reset();
						this.addVoucherForm.setControl('addVoucherList', this.formBuilder.array([this.addVoucherItem()]));
					   this.addVoucher.subtype='';
					   this.addVoucher.cheque_no='';
					   this.addVoucher.amount='';
					   this.addVoucher.upload_file='';
					   this.addVoucher.narration='';
					 this.addVoucher.cheque_date= new Date(Date.now()); 
				//	this.addVoucher.date = new Date(Date.now()); 
					this.addVoucherList = this.addVoucherForm.get('addVoucherList') as FormArray;
					this.addVoucherList.controls[0].get('dr_cr').setValue(this.debit_ledger);
				} else {
					//this.error_message = 'Item category could not be created';
					this.error_message = 'Voucher has been created';
				}
			});
		} else {
			this.error_message = 'Credit amount and Total Amount does not match!';
		}
	}

	public typed(value: any): void {
		//console.log('New search input: ', value);
	}

	// Function to Search Voucher Data
	searchPaymentVoucher() {
		//console.log(this.serachVoucherData)
		this.pageWiseData(1);
	}

	// Reset Search Data
	resetSearchPaymentVoucher() {
		this.serachVoucherData = {};
		this.pageWiseData(1);
	}

	// Pagination 
	pageWiseData(pageno) {
		this.currentPage = pageno;
		this.pipe = new DatePipe('en-US'); 
		this.serachVoucherData.from_date= this.pipe.transform(this.serachVoucherData.from_date, 'yyyy-MM-dd');
		this.serachVoucherData.to_date= this.pipe.transform(this.serachVoucherData.to_date, 'yyyy-MM-dd');
		console.log(this.serachVoucherData)
		this.accountService.getPaymentVouchers(pageno, this.petro_id, this.serachVoucherData)
			.subscribe(alldata => {
				this.voucherList = alldata.data,
					this.totalPayVoucher = alldata.count
			});
	}

	cr_total_view: number;
	dr_total_view: number;
	voucher_info: any = [];
	voucher_details: any = [];
	petro_name: string;
	v_date: string;
	v_no: number;
	test1: any[] = [];
	sum: number;
	viewStatus: boolean;
	// Get Voucher Data (Modal)
	getVoucherData(id, index) {
		this.test1 = [];
		this.sum = 0;
		this.viewStatus = true;
		$('#viewPaymentModal').modal('show');
		this.accountService.getReceiptVoucherDataById(id).subscribe(data => {
			this.voucher_info = data.data.Voucher;
			this.voucher_details = data.data.VoucherDetail;
			console.log(this.voucher_details);
			this.petro_name = this.authData.petro_name;
			this.v_date = data.data.Voucher.date;
			this.v_no = data.data.Voucher.no;
			this.cr_total_view = 0;
			this.dr_total_view = 0;
			this.voucher_details.forEach((v_details, index) => {
				if (v_details.dr_cr == constDefault.CREDIT_LEDGER) {
					this.cr_total_view = Number(v_details.amount) + Number(this.cr_total_view);
				} else if (v_details.dr_cr == constDefault.DEBIT_LEDGER) {
					this.dr_total_view = Number(v_details.amount) + Number(this.dr_total_view);
				}
			})
		});
	}

	// Get data to Edit Payment voucher data by id
	edit_index: number;
	getEditVoucher(id, index) {
		this.editVoucherForm.setControl('editVoucherList', this.formBuilder.array([]));
		$('#editPaymentModal').modal('show');

		this.accountService.getPaymentVoucherDataById(id).subscribe(data => {
			console.log(data);
			this.editVoucher.date = data.data.Voucher.date;
			this.editVoucher.vid = data.data.Voucher.id;
			if (data.data.Voucher.cheque_no == null) {
				$('#edit_cheque_info').hide();
			} else {
				this.editVoucher.cheque_no = data.data.Voucher.cheque_no;
				this.editVoucher.cheque_date = data.data.Voucher.cheque_date;
			}
			if (data.data.Voucher.subtype == 1) {
				this.editVoucher.subtype = true;
			} else {
				this.editVoucher.subtype = false;
			}
			this.editVoucher.amount = data.data.Voucher.amount;
			//this.editVoucher.ledger_id = data.data.Ledger.id;
			this.editVoucher.ledger_name = {'value':data.data.Ledger.id,'label':data.data.Ledger.name};
			this.editVoucher.narration = data.data.Voucher.narration;

			for (let voucher_entry of data.data.VoucherDetail) {
				this.edit_index = 1;
				if (data.data.Voucher.ledger_id == voucher_entry.ledger_id) {

				} else {
					this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
					this.editVoucherList.push(
						this.formBuilder.group({
							id: [voucher_entry.id, Validators.required],
							dr_cr: [voucher_entry.dr_cr, Validators.required],
							ledger_id: [voucher_entry.ledger_id, Validators.required],
							ledger_name: [voucher_entry.Ledger.name, ''],
							amount: [voucher_entry.amount, '']
						})
					);

				}
			}
			this.getEditTotal(this.edit_index)
		});
	}

	// Edit Voucher data and Save Data 
	editVoucherData(edit_voucher) {
		edit_voucher.submitted = false;
		this.editVoucher.ledger_id=this.editVoucher.ledger_name.value;
			
		// If ledger type is cash then cheque info should be empty
		if (this.editVoucher.ledger_id == this.voucher_ledger_type) {
			this.editVoucher.cheque_no = "";
			this.editVoucher.cheque_date = "";
		}
		this.pipe = new DatePipe('en-US'); 
		this.editVoucher.date= this.pipe.transform(this.editVoucher.date, 'yyyy-MM-dd');
		this.editVoucher.cheque_date= this.pipe.transform(this.editVoucher.cheque_date, 'yyyy-MM-dd');
		this.editVoucher.voucherDetails = this.editVoucherList.value;
		
		this.total_credit = Number(this.editVoucher.amount) + Number(this.cr_Edit_amount);
		
		if (this.dr_Edit_amount == this.total_credit) {
			
			this.accountService.editPaymentVoucher(this.editVoucher).subscribe(data => {
				if (data.status == 200) {
					this.success_message = 'Voucher has been Updated';
					$('#editPaymentModal').modal('hide');
					this.pageWiseData(1);
					this.editVoucherForm.reset();
				} else {
					this.error_message = 'Error';
				}
			});
		} else {
			this.error_message = 'Credit amount and Total Amount does not match!';
		}
	}

	// Get ledger value on change ledger list and set id to form array list option
	getLedgerId(value, i) {
		this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
		this.editVoucherList.controls[i].get('ledger_id').setValue(value.value);
	}

	// Delete Voucher by id
	deleteVoucher(id, index) {
		if (confirm("Are you sure to delete this Voucher Entry")) {
			this.index = index;
			this.model = 'Voucher';
			this.masterService.deleteDataByModel(id, this.model).subscribe(data => {
				if (data.status == true) {
					this.voucherList.splice(this.index, 1);
					this.success_message = "Voucher deleted sucessfully";
				} else {
					this.error_message = "Voucher could not be deleted.";
				}
			});
		}
	}

	printComponent() {
		var printContents = document.getElementById("voucher_print").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}

	// Voucher Amount Keyup set amount (Amit)
	onVoucherAmountKeyUp(event) {
		this.addVoucherList = this.addVoucherForm.get('addVoucherList') as FormArray;
		this.addVoucherList.controls[0].get('amount').setValue(event.target.value);
		this.getTotal(0);
	}

}