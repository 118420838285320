import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsService } from '../../../../admin-services/reports.service';
import { FormGroup,FormControl,Validators ,FormBuilder,FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../../const_default';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { DatePipe } from '@angular/common';
// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';

declare var $: any;
@Component({
  selector: 'app-stock-item-summary-item',
  templateUrl: './stock-item-summary-item.component.html',
  styleUrls: ['./stock-item-summary-item.component.css']
})
export class StockItemSummaryItemComponent implements OnInit {

  constructor(private reportsService: ReportsService,private session:SessionStorageService,private router:Router, private exportAsService:ExportAsService) { }
  exportAsConfig: ExportAsConfig;
	  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  
  authData:any={};
  serachReportData:any={};	
  reportData: any[] = [];
  petro_id:number;
  success_message:string;
  error_message:string;
  pump_name:number;
  pump_address:string;
  pump_mobile_no:number;
  pipe:any;
   config:any;
   show_loading_image:boolean;
  searchReportForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		//from_shift : new FormControl(' ',Validators.required),
		//to_shift : new FormControl(' ',Validators.required)
		
	});
	
  ngOnInit() {
	   this.authData = this.session.get('authData');
	 	this.petro_id = this.authData.petro_id;
	 	//console.log(this.authData);
	 	this.pump_name=this.authData.petro_name;
	 	this.pump_mobile_no=this.authData.petro_phone_no;
	 	this.pump_address=this.authData.petro_address;
		this.serachReportData.from_date=new Date(Date.now());
		this.serachReportData.to_date=new Date(Date.now());
		//this.serachReportData.from_shift=1;
		//this.serachReportData.to_shift=1;
  }
  
  getReportData() {
	  this.show_loading_image=true;
	  this.pipe = new DatePipe('en-US'); 
	  this.serachReportData.from_date= this.pipe.transform(this.serachReportData.from_date, 'yyyy-MM-dd');		
	  this.serachReportData.to_date= this.pipe.transform(this.serachReportData.to_date, 'yyyy-MM-dd');		
		
	 this.reportsService.stockSummaryItemWiseItem(this.petro_id,this.serachReportData)
	  .subscribe(reportAllData => {
	  // console.log(purchaseListData);
	   if(reportAllData.status==200)
		  {
			this.reportData=reportAllData.data;
			 this.opening_qty=0;
        this.opening_amt=0;
        this.in_qty=0;
        this.in_amt=0;
        this.out_qty=0;
        this.out_amt=0;
        this.close_qty=0;
        this.close_amt=0;
		  }
		  else if(reportAllData.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.reportData=[];
		 this.opening_qty=0;
        this.opening_amt=0;
        this.in_qty=0;
        this.in_amt=0;
        this.out_qty=0;
        this.out_amt=0;
        this.close_qty=0;
        this.close_amt=0;
		  }
	   this.show_loading_image=false;
	  });
	}
	 exportTable(event) {
	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'stock_summary_report');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }
  goMonthWiseSummaryPage(item_id,item_name){
		this.serachReportData.item_id=item_id;
		this.serachReportData.item_name=item_name;
		this.session.set('itemWiseStockSummaryMonthSession',this.serachReportData);
		this.router.navigate(['admin/stockItemSummaryMonthWise']);
	}
	
	opening_qty:number;
opening_amt:number;
in_qty:number;
in_amt:number;
out_qty:number;
out_amt:number;
close_qty:number;
close_amt:number;
 getTotal(opening_qty,opening_amt,in_qty,in_amt,out_qty,out_amt,close_qty,close_amt,i){
   
    if(i==0)   
    {   
        
        this.opening_qty=0;
        this.opening_amt=0;
        this.in_qty=0;
        this.in_amt=0;
        this.out_qty=0;
        this.out_amt=0;
        this.close_qty=0;
        this.close_amt=0;
    }

 
    this.opening_qty=Number(this.opening_qty)+Number(opening_qty);
    this.opening_amt=Number(this.opening_amt)+Number(opening_amt);
    this.in_qty=Number(this.in_qty)+Number(in_qty);
    this.in_amt=Number(this.in_amt)+Number(in_amt);
    this.out_qty=Number(this.out_qty)+Number(out_qty);
    this.out_amt=Number(this.out_amt)+Number(out_amt);
    this.close_qty=Number(close_qty);
    this.close_amt=Number(close_amt);
  }	
  // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}

}
