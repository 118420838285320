import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsService } from '../../../admin-services/reports.service';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { CommonsService } from '../../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder,FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { MasterServicesService } from '../../../admin-services/master-services.service';
import { constDefault } from '../../../const_default';
import { DatePipe } from '@angular/common';
// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
declare var $: any;
@Component({
  selector: 'app-sale-cash-credit-report',
  templateUrl: './sale-cash-credit-report.component.html',
  styleUrls: ['./sale-cash-credit-report.component.css']
})
export class SaleCashCreditReportComponent implements OnInit {

 constructor(private reportsService: ReportsService,private session:SessionStorageService,private router:Router,private commonsServices: CommonsService, private exportAsService:ExportAsService,private masterService: MasterServicesService) { }

 exportAsConfig: ExportAsConfig;
	  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  
  authData:any={};
  serachReportData:any={};	
  reportData: any[] = [];
  config:any;
  petro_id:number;
  success_message:string;
  error_message:string;
  pump_name:number;
  pump_address:string;
  pump_mobile_no:number;
  pipe:any;
  show_loading_image:boolean;
categoryDropdownList: any[] = [];
  //Total
  cash_qty:number;
cash_amt:number;
credit_qty:number;
credit_amt:number;
meeter_amt:number;
meeter_qty:number;
bozer_qty:number;
bozer_amt:number;
bozer_refill:number;
act_meeter_qty:number;
dif_qty:number;
show_bozer:number
genelarGeneralSetup:any[]=[];
show_detailscol:string="8";
    group_name:string;
  
  searchReportForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		tmp_group_id : new FormControl(' '),
	});
	
  ngOnInit() {
	   this.authData = this.session.get('authData');
	 	this.petro_id = this.authData.petro_id;
	 	//console.log(this.authData);
	 	this.pump_name=this.authData.petro_name;
	 	this.pump_mobile_no=this.authData.petro_phone_no;
	 	this.pump_address=this.authData.petro_address;
		this.serachReportData.from_date=new Date(Date.now());
		this.serachReportData.to_date=new Date(Date.now());
		
			this.masterService.getCategoryListOilForDropdown(this.petro_id)
			.subscribe(catlistdata => {
				//this.categoryDropdownList = catlistdata.data
				this.categoryDropdownList = Object.entries(catlistdata.data).map(([value, label]) => ({
				value,
				label
			}));
			});

			this.show_detailscol="8";
			this.show_bozer=0;
		  this.commonsServices.applicationGeneralData(this.petro_id)
			  .subscribe(listdata =>{ 	
			  this.genelarGeneralSetup=listdata.data
			  this.show_bozer=listdata.data.AppSetupGeneral.show_bozer;
			  if (this.show_bozer==1)
			  this.show_detailscol="12";
			  console.log(this.show_detailscol);
			  });
	  
	 

  }
  getReportData() {
	  this.show_loading_image=true;
	


	  this.pipe = new DatePipe('en-US'); 
	  this.serachReportData.from_date= this.pipe.transform(this.serachReportData.from_date, 'yyyy-MM-dd');		
	  this.serachReportData.to_date= this.pipe.transform(this.serachReportData.to_date, 'yyyy-MM-dd');		
	  if(this.serachReportData.tmp_group_id)
		{
			this.serachReportData.group_id=this.serachReportData.tmp_group_id.value;
			this.group_name=this.serachReportData.tmp_group_id.label;
		}
	 this.reportsService.getSaleCashCreditReportData(this.petro_id,this.serachReportData)
	  .subscribe(reportAllData => {
	  // console.log(purchaseListData);
	   if(reportAllData.status==200)
		  {
				this.reportData=reportAllData.data;		 
		  }
		  else if(reportAllData.status==403)
			{
				this.session.remove('authData');
				this.router.navigate(['']);
				
			}else{
				this.reportData=[];	
				this.cash_qty=0;
				this.cash_amt=0;
				this.credit_qty=0;
				this.credit_amt=0;
				this.meeter_amt=0;
				this.meeter_qty=0;
				this.bozer_amt=0;
				this.bozer_qty=0;
				this.bozer_refill=0;
				this.act_meeter_qty=0;
				this.dif_qty=0;	
			}	 
			this.show_loading_image=false;			
	  });
	}
	

getTotal(cash_qty,cash_amt,credit_qty,credit_amt,meeter_amt,meeter_qty,total_sale,bozer_qty,bozer_amt,bozer_refill,act_meeter_qty,i){   
    if(i==0)   
    {
        this.cash_qty=0;
        this.cash_amt=0;
        this.credit_qty=0;
        this.credit_amt=0;
        this.meeter_amt=0;
        this.meeter_qty=0;
		this.bozer_amt=0;
		this.bozer_qty=0;
		this.bozer_refill=0;
		this.act_meeter_qty=0;
        this.dif_qty=0;
    }
    this.cash_qty=Number(this.cash_qty)+Number(cash_qty);
    this.cash_amt=Number(this.cash_amt)+Number(cash_amt);
    this.credit_qty=Number(this.credit_qty)+Number(credit_qty);
	
    this.credit_amt=Number(this.credit_amt)+Number(credit_amt);
    this.meeter_amt=Number(this.meeter_amt)+Number(meeter_amt);
    this.meeter_qty=Number(this.meeter_qty)+Number(meeter_qty);

	this.bozer_qty=Number(this.bozer_qty)+Number(bozer_qty);
	this.bozer_amt=Number(this.bozer_amt)+Number(bozer_amt);
	this.bozer_refill=Number(this.bozer_refill)+Number(bozer_refill);
	this.act_meeter_qty=Number(this.act_meeter_qty)+Number(act_meeter_qty);

    this.dif_qty=Number(this.dif_qty)+(Number(act_meeter_qty)-Number(cash_qty)-Number(credit_qty));
  }	
	exportTable(event) {
	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'stock_transfer_report');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }
// Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
}
