
import { Component,	OnInit } from '@angular/core';
import { FormGroup, FormControl,	Validators,	FormBuilder, FormArray } from '@angular/forms';
import { LocalStorageService,SessionStorageService,LocalStorage,	SessionStorage } from 'angular-web-storage';
import { AccountService } from '../../admin-services/account.service';
import { CommonsService } from '../../admin-services/commons.service';
import { ShiftSaleService } from '../../admin-services/shift-sale.service';
import { ApplicationSetupService } from '../../admin-services/application-setup.service';
import { constDefault } from '../../const_default';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { DatePipe } from '@angular/common';
import { Router} from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
	
 constructor(private accountService: AccountService, private router:Router, private shiftSaleService: ShiftSaleService,private applicationService: ApplicationSetupService,  private session: SessionStorageService,private formBuilder: FormBuilder) {}
  changedPasswordForm:FormGroup;
user_id:number;
addChangedPassData:any={};
  authData:any = {};
  pipe: any;
  success_message: string;
  error_message: string;
  ngOnInit() {
	  
		   this.authData = this.session.get('authData');
		   this.user_id = this.authData.user_id;
		   this.changedPasswordForm=this.formBuilder.group({
					new_password: new FormControl('', Validators.required),
					reenter_pass: new FormControl('', Validators.required),
					old_password: new FormControl('', Validators.required)		
			});
  }
    changedPass(){
    this.authData = this.session.get('authData');
//	console.log(this.authData);
    this.addChangedPassData.user_id=this.authData.user_id;
 
    this.applicationService.changedPassword(this.addChangedPassData).subscribe(data=>{
			if(data.status==200)
			{
			     this.success_message='Password Changed Successfully ';
				 this.addChangedPassData.old_password="";
				 this.addChangedPassData.new_password="";
				 this.addChangedPassData.reenter_pass="";
				 
			  }else if(data.status==204){
				this.error_message=data.message;
			  }else if(data.status==400){
				this.error_message=data.message;
			  }else if(data.status==402){
				this.error_message=data.message;
			  }else{
				  this.error_message='Bad request';
			  }
		});
  
  }

}
