import { Component, OnInit } from '@angular/core';
import { PurchaseService } from '../../admin-services/purchase.service';
import { CommonsService } from '../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder,FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../const_default';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
import { DatePipe } from '@angular/common';
import { Router} from '@angular/router'
declare var $: any;

@Component({
  selector: 'app-purchase-list',
  templateUrl: './purchase-list.component.html',
  styleUrls: ['./purchase-list.component.css']
})
export class PurchaseListComponent implements OnInit {

    constructor(private purchaseService: PurchaseService, private commonsServices:CommonsService, private session:SessionStorageService, private formBuilder: FormBuilder ,private router:Router) { }
	
	 math = Math;
 options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
	//	minDate: new Date(Date.now()), // Minimal selectable date
	  maxDate: new Date(Date.now()),  // Maximal selectable date
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Expiry Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};
	
	authData:any={};
	addPurchaseData:any={};	
	addMsHsdPurchaseData:any={};	
	searchPurchaseData:any={};	
	searchPurchaseNewData:any={};	
	tanklistByCommodity:any={};	
	locationList:any={};	
	shiftList:any=[];	
	sundryCreditorListData=[];	
	commodityWiseTankList=[];	
	purchaseList:any[]=[];	
	purchaseItemList:any[]=[];	
	purchaseDataForView:any[]=[];	
	purchaseMsDataForView:any[]=[];	
	purchaseCurrentPage:number;	
	purchaseTotalCount:number;	
	viewPurchaseStatus:number;	
	viewMsPurchaseStatus:number;	
	error_message:string;	
	success_message:string;	
	qty_error:boolean;
	petro_id: number;
	comp_id: number;
	shift_count: number;
	pipe:any;
	addPurchaseForm:FormGroup;
	addPurchaseItemList:FormArray;
	
	addMsHsdPurchaseForm:FormGroup;
	addMsPurchaseItemList:FormArray;
	tankList:FormArray;
	
	searchPurchaseForm=new FormGroup({
		vendor_id_data : new FormControl(' '),
		date : new FormControl(' '),
	});	


	qty: number;
	sale_type: number;
	rate: number;
	net_amt: number;
	dis_per: number;
	dis_amt: number;
	total_amt: number;
	item_id: number;
	batch_no: string;
	gst_type: number;
   
   
	cgst_per: number;
	sgst_per: number;
	igst_per: number;
   
	cgst_amt: number;
	sgst_amt: number;
	igst_amt: number;
   
	total_net_amt: number;
	total_sgst_amt: number;
	total_cgst_amt: number;
	total_igst_amt: number;
	total_dis_amt: number;
	total_gt_amt: number;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	// Autocomplete
	item_name: string;
	key_code: number;
	focus_input_index: number;
	selectvalue: string;
	use_no: number;
	itemAutoSugestList: any[] = [];
	// End Autocomplete
	
  ngOnInit() {
	  
	  // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
		this.viewPurchaseStatus=0;
		this.viewMsPurchaseStatus=0;
		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		this.comp_id = this.authData.comp_id;
		this.shift_count = this.authData.daily_shift;
		this.qty_error=true;
		for(let a=1;a<=this.shift_count ;a++)
		{
			this.shiftList[a]=a;
		}
		this.addPurchaseData.shift=1;
		this.addMsHsdPurchaseData.shift=1;
	 
	  this.addPurchaseForm= this.formBuilder.group({
			vendor_id : new FormControl('',Validators.required),
			date : new FormControl('',Validators.required),
			shift : new FormControl(' ',Validators.required),
			invoice_no : new FormControl(' ',Validators.required),
			grn_date: new FormControl(''),
			gst_type: new FormControl('', Validators.required),
			state_id: new FormControl('', Validators.required),
			total_net_amt: new FormControl(' ', Validators.required),
			total_sgst_amt: new FormControl(' 0'),
			total_cgst_amt: new FormControl(' 0'),
			total_igst_amt: new FormControl(' '),
			total_dis_amt: new FormControl('0 '),
			vehicle_no: new FormControl(' 0'),
			round_up: new FormControl(''),
			tcs_gt_amt: new FormControl('0'),
			total_gt_amt: new FormControl('0 ', Validators.required),
			
			addPurchaseItemList: this.formBuilder.array([this.addPurchaseItem()])
		});
		
		this.addMsHsdPurchaseForm= this.formBuilder.group({
			vendor_id : new FormControl('',Validators.required),
			date : new FormControl('',Validators.required),
			invoice_no : new FormControl('',Validators.required),
			shift: new FormControl('',Validators.required),
			gst_type: new FormControl(''),
			state_id: new FormControl(''),
			total_net_amt: new FormControl('', Validators.required),
			total_vat_amt: new FormControl('0'),
			total_cess_amt: new FormControl(' 0'),
			total_dly_amt: new FormControl(''),
			total_lfr_svt_amt: new FormControl('0'),
			tanker_no: new FormControl(''),
			driver_name: new FormControl(''),
			inx_density: new FormControl(''),
			tt_density: new FormControl(''),
			grn_date: new FormControl(''),
			discount_amt: new FormControl(''),
			tcs_gt_amt: new FormControl(''),
			round_up: new FormControl(''),
			total_gt_amt: new FormControl('0', Validators.required),
			addMsPurchaseItemList: this.formBuilder.array([this.addPurchaseItemMs()])
		});
		
		
		
		//Default current date set
		this.addPurchaseData.date=new Date(Date.now());
		this.addMsHsdPurchaseData.date=new Date(Date.now());
		this.searchPurchaseData.date=new Date(Date.now());
		this.addMsHsdPurchaseData.grn_date=new Date(Date.now());
		this.addPurchaseData.grn_date=new Date(Date.now());
		// Vendor Or Sundry Creditors List
			this.commonsServices.getLedgerListByGroup(constDefault.SUNDRY_CREDITOR_GROUP,this.petro_id).subscribe(creditorList=>{
				if(creditorList.data)
				{
				this.sundryCreditorListData=Object.entries(creditorList.data).map(([value, label]) => ({value,label}));
				}
		});
		
		// Get Purchase List
		this.getPageWisePurchaseList(1);
		
		
		// Get Location List By Petro Id
		this.commonsServices.getLocationListByPetroId(this.petro_id).subscribe(data => {
			this.locationList =data.data;
		
		});
		
  }
  
  	get purchaseListArray() {return this.addPurchaseForm.get('addPurchaseItemList')}
  	get purchaseListMsArray() {return this.addMsHsdPurchaseForm.get('addMsPurchaseItemList')}
	getTank(fields) {
    return fields.controls.tankList.controls;
  }
  
  addPurchaseItem(): FormGroup {
	 return this.formBuilder.group({
	  item_id: ['', Validators.required],
	  //item_name: ['', Validators.required],
	  batch_no: [''],
	  qty: ['', Validators.required],
	  rate: ['', Validators.required],
	  net_amt: ['', Validators.required],
	  gst_rate: ['0'],
	  cgst_per: ['0'],
	  sgst_per: ['0'],
	  igst_per: ['0'],
	  cgst_amt: ['0'],
	  sgst_amt: ['0'],
	  igst_amt: ['0'],
	  dis: [''],
	  scheme: [''],
	  dis_amt: [''],
	  total_amt: ['', Validators.required],
	  location_id: [constDefault.LOCATION_GODOWN, Validators.required],
	 })
	}
	
	addPurchaseItemMs(): FormGroup {
	 return this.formBuilder.group({
	  item_id: ['', Validators.required],
	  item_name: [''],
	  //batch_no: [''],
	  qty: ['', Validators.required],
	  tank_total_qty: [''],
	  rate: ['', Validators.required],
	  net_amt: ['', Validators.required],
	  dly: ['0'],
	  vat: ['0'],
	  cess: ['0'],
	  lfr_svt: ['0'],
	   dly_per: ['0'],
	  vat_per: ['0'],
	  cess_per: ['0'],
	  lfr_svt_per: ['0'],
	  per_kl_rate: ['0'],
	  wb1:['0'],
	  ab1:['0'],
	  wb2:['0'],
	  ab2:['0'],
	  wb3:['0'],
	  ab3:['0'],
	  wb4:['0'],
	  ab4:['0'],
	  total_amt: ['', Validators.required],
	  tankList: this.formBuilder.array([])
	 })
	}
	
	
	
	// Function to add One More Field
	addPurchaseNewItem() {
	 this.addPurchaseItemList = this.addPurchaseForm.get('addPurchaseItemList') as FormArray;
	 this.addPurchaseItemList.push(this.addPurchaseItem());
	}
   
	// Function to Remove One Field
	removePurchaseItem(i) {
	 if (confirm("Do you really want to Delete this Item?")) {
	  if (this.addPurchaseItemList.length > 1) {
	   this.addPurchaseItemList.removeAt(i);
	   this.calculateTotal();
	  }
   
	 }
	}
	
	
	addMsPurchaseNewItem() {
	 this.addMsPurchaseItemList = this.addMsHsdPurchaseForm.get('addMsPurchaseItemList') as FormArray;
	 this.addMsPurchaseItemList.push(this.addPurchaseItemMs());
	}
   
	// Function to Remove One Field
	removeMsPurchaseItem(i) {
	 if (confirm("Do you really want to Delete this Item?")) {
	  if (this.addMsPurchaseItemList.length > 1) {
	   this.addMsPurchaseItemList.removeAt(i);
	  // this.calculateTotal();
	  }
   
	 }
	}
	
	/*
	 Amit Shau
	 Get Vendor Data by id;
	 20.11.18
	 */
   
	onChangeCustomer() {
	 //console.log(this.addSaleData);
	 // alert(this.addSaleData.customer.value);
	 this.commonsServices.getCustomerDataById(this.addPurchaseData.vendor_id.value).subscribe(data => {
	  this.addPurchaseData.state_id = data.data.PartyDetail.state_id;
	  this.gstTypeFind(data.data.PartyDetail.state_id);
	 });
	}
	
	/*onChangeMsCustomer() {
	 //console.log(this.addSaleData);
	 // alert(this.addSaleData.customer.value);
	 this.commonsServices.getCustomerDataById(this.addPurchaseData.vendor_id.value).subscribe(data => {
	  this.addMsHsdPurchaseData.state_id = data.data.PartyDetail.state_id;
	  this.gstTypeFindMs(data.data.PartyDetail.state_id);
	 });
	}*/
	
	// Find GST Type 
	gstTypeFind(state_id) {
	 if (this.authData.state_id == state_id) {
	  this.gst_type = constDefault.GST_TYPE_CGST;
	 } else {
	  this.gst_type = constDefault.GST_TYPE_IGST;
	 }

	 this.addPurchaseData.gst_type = this.gst_type;
	 this.calculateTotal();
	}
	
	
	/*gstTypeFindMs(state_id) {
	 if (this.authData.state_id == state_id) {
	  this.gst_type = constDefault.GST_TYPE_CGST;
	 } else {
	  this.gst_type = constDefault.GST_TYPE_IGST;
	 }
	 this.addMsHsdPurchaseData.gst_type = this.gst_type;
	 this.calculateTotal();
	}*/
	
	// Add urchase
	
	addPurchase() {
	this.pipe = new DatePipe('en-US'); 
	  this.addPurchaseForm.value.date = this.pipe.transform(this.addPurchaseForm.value.date, 'yyyy-M-d');
	  this.addPurchaseForm.value.grn_date = this.pipe.transform(this.addPurchaseForm.value.grn_date, 'yyyy-M-d');
	 this.addPurchaseForm.value.petro_id = this.petro_id;
	 this.addPurchaseForm.value.purchase_type = constDefault.PURCHASE_TYPE_OTHER;
	 //console.log(this.addSaleForm.value);
	 this.purchaseService.addPurchaseData(this.addPurchaseForm.value)
	  .subscribe(addData => {
	   if (addData.status == 200 || addData.status == 201) {
		$('#addPurchaseModal').modal('hide');
		this.getPageWisePurchaseList(1);
		this.addPurchaseForm.reset();
		this.addPurchaseData.date=new Date(Date.now());
		this.addPurchaseData.grn_date=new Date(Date.now());
		this.addPurchaseData.shift=1;
		
	   }
	  });   
	}
	
	
	//Get Purchase List
	getPageWisePurchaseList(page: number) {
		
	 this.purchaseCurrentPage = page;
	 this.purchaseService.getPurchaseList(page, this.petro_id,this.searchPurchaseNewData)
	  .subscribe(purchaseListData => {
	   this.purchaseList = purchaseListData.data,
	   this.purchaseTotalCount = purchaseListData.count
	  });
	}
	
	
	searchPurchase()
  {
	  if(this.searchPurchaseData.vendor_id_data)
		{
			this.searchPurchaseNewData.vendor_id=this.searchPurchaseData.vendor_id_data.value;
		}
		this.searchPurchaseNewData.date=this.searchPurchaseData.date;
	 	this.getPageWisePurchaseList(1);
  }
  
  resetSearchPurchase(){
	  this.searchPurchaseNewData={};
	  this.searchPurchaseData={};
		this.getPageWisePurchaseList(1);
  }
	
	viewPurchase(purchase_id: number) {
   
	 this.purchaseService.getPurchaseDataById(purchase_id)
	  .subscribe(purchaseData => {
	   this.purchaseDataForView = purchaseData.data;

		this.viewPurchaseStatus = 1;

	  });
	 $('#viewPurchaseModal').modal('show');
	}
	
	/*
  Delete Purchase 
  Amit Sahu
  28.11.18
*/
  deletePurchase(id,index)
  {
		if(confirm("Are you sure to delete this Purchase ")){ 

			this.purchaseService.deletePurchase(id).subscribe(data=>{
				if(data.status==200)
				{
				this.purchaseList.splice(index,1);
				this.success_message="Purchase deleted sucessfully";		
				
				}else{
					this.error_message="Purchase could not be deleted.";
				}
			});
		}
  }
	
	
	/*---------------------------------------------Auto Complete----------------------------------*/
	 public filterItemList(value:any):void {	
  
	   this.commonsServices.getItemByNameOrType(value, this.petro_id,constDefault.ITEM_TYPE_OTHER)
		.subscribe(alldata => {
			if(alldata.data)
			{
		     this.itemAutoSugestList=Object.entries(alldata.data).map(([value, label]) => ({value,label}));
			}
		});
  }
  
   public filterMsHsdItemList(value:any):void {	
  
	   this.commonsServices.getItemByNameOrType(value, this.petro_id,constDefault.ITEM_TYPE_MSHSD)
		.subscribe(alldata => {
			if(alldata.data)
			{
		     this.itemAutoSugestList=Object.entries(alldata.data).map(([value, label]) => ({value,label}));
			}
		});
  }
  

	
 onselectItemOnSale(event,un)
 {
	 if(event)	
	{
   this.purchaseService.getItemDetailsByItemIdForSale(event.value)
	   .subscribe(itemData => {
		//console.log(itemData);
		this.onselectItemOnSaleCalc(itemData.data, un);
		//this.getNozzleList(nozzlelistdata.data,nozzlelistdata.status);
	   });
	}else{
		this.addPurchaseItemList = this.addPurchaseForm.get('addPurchaseItemList') as FormArray;
	 //this.addPurchaseItemList.controls[un].get('item_id').setValue(itemData.Item.id);
	 this.addPurchaseItemList.controls[un].get('rate').setValue('');
	 this.addPurchaseItemList.controls[un].get('gst_rate').setValue('');
	 this.addPurchaseItemList.controls[un].get('cgst_per').setValue('');
	 this.addPurchaseItemList.controls[un].get('sgst_per').setValue('');
	 this.addPurchaseItemList.controls[un].get('igst_per').setValue('');
	 this.addPurchaseItemList.controls[un].get('net_amt').setValue('');
	 this.addPurchaseItemList.controls[un].get('total_amt').setValue('');
	 this.calculateTotal();
	}
 
 }
  onselectItemOnSaleCalc(itemData, un) {
		//console.log(itemData)
	/*this.commonsServices.getTankByCommodityWise(this.petro_id,itemData.Item.id).subscribe(tankList=>{
		this.tanklistByCommodity[un]=tankList.data;
	});*/
	
	
		
	 this.addPurchaseItemList = this.addPurchaseForm.get('addPurchaseItemList') as FormArray;
	 //this.addPurchaseItemList.controls[un].get('item_id').setValue(itemData.Item.id);
	 this.addPurchaseItemList.controls[un].get('rate').setValue(itemData.Item.mrp);
	 this.addPurchaseItemList.controls[un].get('gst_rate').setValue(itemData.GstMaster.gst_percentage);
	 this.addPurchaseItemList.controls[un].get('cgst_per').setValue(itemData.GstMaster.cgst);
	 this.addPurchaseItemList.controls[un].get('sgst_per').setValue(itemData.GstMaster.sgst);
	 this.addPurchaseItemList.controls[un].get('igst_per').setValue(itemData.GstMaster.igst);

   
	 if (itemData.Item.batch_wise == 1) {
	  $('#batch_no' + un).removeAttr('disabled');
	 } else {
	  $('#batch_no' + un).attr('disabled', 'disabled');
	  this.addPurchaseItemList.controls[un].get('batch_no').setValue('');
	 }
	 this.rowWiseTotalSaleItem(un)
	}

	// Sale Item Row on discount key up 
	onDiscountPerkeyup(row) {
	 this.rowWiseTotalSaleItem(row);
	}
	// Schme calculation
	onSchemekeyup(row) {
	 this.rowWiseTotalSaleItem(row);
	}
	// Sale Item Row on quantity key up 
	onQtykeyup(row) {
	 this.addPurchaseItemList = this.addPurchaseForm.get('addPurchaseItemList') as FormArray;
	 this.qty = this.addPurchaseItemList.value[row]['qty'];
	 this.item_id = this.addPurchaseItemList.value[row]['item_id'].value;

	 this.batch_no = this.addPurchaseItemList.value[row]['batch_no'];
	 if (!this.batch_no) {
	  this.batch_no = " ";
	 }
   
	 this.rowWiseTotalSaleItem(row);
	 //this. rowWiseTotalSaleItem(row);
	}
   
	cgst_amt_str:string;
	sgst_amt_str:string;
	igst_amt_str:string;
	dis_amt_str:string;
	net_amt_str:string;
	total_amt_str:string;
	scheme:number;
	rowWiseTotalSaleItem(row) {
	 this.addPurchaseItemList = this.addPurchaseForm.get('addPurchaseItemList') as FormArray;
	 //this.addPurchaseItemList.controls[un].get('item_id').setValue(itemData.Item.id);
	 this.item_id = this.addPurchaseItemList.value[row]['item_id'].value;
	 if (this.item_id) {
	  this.qty = this.addPurchaseItemList.value[row]['qty'];
	  this.rate = this.addPurchaseItemList.value[row]['rate'];
	  this.dis_per = this.addPurchaseItemList.value[row]['dis'];
	  this.scheme = this.addPurchaseItemList.value[row]['scheme'];
		if(!this.scheme){
			this.scheme=0;
		}
	  this.net_amt = this.qty * this.rate;
	  this.dis_amt = (this.net_amt / 100) * this.dis_per;
	  this.total_amt = this.net_amt - this.dis_amt;
	  
	    this.dis_amt_str=this.dis_amt.toFixed(2);
		this.net_amt_str=this.net_amt.toFixed(2);
		this.total_amt_str=this.total_amt.toFixed(2);
		this.dis_amt=Number(this.dis_amt_str);
		this.net_amt=Number(this.net_amt_str);
		this.total_amt=Number(this.total_amt_str)-Number(this.scheme);
	  
	  this.addPurchaseItemList.controls[row].get('dis_amt').setValue(this.dis_amt);
	  this.addPurchaseItemList.controls[row].get('net_amt').setValue(this.net_amt);
	  this.addPurchaseItemList.controls[row].get('total_amt').setValue(this.total_amt);
   
	  this.cgst_per = this.addPurchaseItemList.value[row]['cgst_per'];
	  this.sgst_per = this.addPurchaseItemList.value[row]['sgst_per'];
	  this.igst_per = this.addPurchaseItemList.value[row]['igst_per'];
   
	  this.cgst_amt = (this.total_amt / 100) * this.cgst_per;
	  this.sgst_amt = (this.total_amt / 100) * this.sgst_per;
	  this.igst_amt = (this.total_amt / 100) * this.igst_per;
   
		this.cgst_amt_str=this.cgst_amt.toFixed(2);
		this.sgst_amt_str=this.sgst_amt.toFixed(2);
		this.igst_amt_str=this.igst_amt.toFixed(2);
		this.sgst_amt=Number(this.cgst_amt_str);
		this.cgst_amt=Number(this.sgst_amt_str);
		this.igst_amt=Number(this.igst_amt);
	  this.addPurchaseItemList.controls[row].get('cgst_amt').setValue(this.cgst_amt);
	  this.addPurchaseItemList.controls[row].get('sgst_amt').setValue(this.sgst_amt);
	  this.addPurchaseItemList.controls[row].get('igst_amt').setValue(this.igst_amt);
	 }
	 this.calculateTotal();
	}
   
   total_igst_amt_str:string;
   total_sgst_amt_str:string;
   total_cgst_amt_str:string;
   total_net_amt_str:string;
   total_dis_amt_str:string;

	calculateTotal() {
	 this.addPurchaseItemList = this.addPurchaseForm.get('addPurchaseItemList') as FormArray;
	 /* this.saleItemListData = Object.keys(this.addPurchaseItemList).map(function(itemIndex){
		   let item = this.addPurchaseItemList[itemIndex];
			   return item;
		   });*/
	 this.total_net_amt = 0;
	 this.total_sgst_amt = 0;
	 this.total_cgst_amt = 0;
	 this.total_igst_amt = 0;
	 this.total_dis_amt = 0;
	 this.total_gt_amt = 0;
	 this.tcs_gt_amt = 0;
   
	 this.addPurchaseItemList.value.forEach((itemData, index) => {
	  //for (let a of this.commodityDropdownList ) {
	  this.total_net_amt = Number(this.total_net_amt) + Number(this.addPurchaseItemList.value[index]['net_amt']);
	  this.total_sgst_amt = Number(this.total_sgst_amt) + Number(this.addPurchaseItemList.value[index]['sgst_amt']);
	  this.total_cgst_amt = Number(this.total_cgst_amt) + Number(this.addPurchaseItemList.value[index]['cgst_amt']);
	  this.total_igst_amt = Number(this.total_igst_amt) + Number(this.addPurchaseItemList.value[index]['igst_amt']);
	  this.total_dis_amt = Number(this.total_dis_amt) + Number(this.addPurchaseItemList.value[index]['dis_amt']);
	  this.total_gt_amt = Number(this.total_gt_amt) + Number(this.addPurchaseItemList.value[index]['total_amt']);   
	  this.tcs_gt_amt =Number(this.total_gt_amt)*0.1/100;
	});
	 
	  this.total_net_amt_str=this.total_net_amt.toFixed(2);
	 this.total_dis_amt_str=this.total_dis_amt.toFixed(2);
	 
	 this.total_net_amt=Number(this.total_net_amt_str);
	 this.total_dis_amt=Number(this.total_dis_amt_str);
	 
	 this.addPurchaseData.total_net_amt = this.total_net_amt;
	 this.addPurchaseData.total_dis_amt = this.total_dis_amt;
	 if (this.gst_type == constDefault.GST_TYPE_IGST) {
	  this.total_sgst_amt = 0;
	  this.total_cgst_amt = 0;
	 } else {
	  this.total_igst_amt = 0;
	 }
	 
	 this.total_igst_amt_str=this.total_igst_amt.toFixed(2);
	 this.total_sgst_amt_str=this.total_sgst_amt.toFixed(2);
	 this.total_cgst_amt_str=this.total_cgst_amt.toFixed(2);
	 
	 this.total_igst_amt=Number(this.total_igst_amt_str);
	 this.total_sgst_amt=Number(this.total_sgst_amt_str);
	 this.total_cgst_amt=Number(this.total_cgst_amt_str);
	 
	 this.addPurchaseData.total_igst_amt = this.total_igst_amt;
	 this.addPurchaseData.total_sgst_amt = this.total_sgst_amt;
	 this.addPurchaseData.total_cgst_amt = this.total_cgst_amt;
	 this.addPurchaseData.tcs_gt_amt = this.tcs_gt_amt;

	 if(!this.addPurchaseData.round_up){
		 this.addPurchaseData.round_up=0;
	 }

	 if(!this.addPurchaseData.tcs_gt_amt){
		this.addPurchaseData.tcs_gt_amt=0;
	}
	 this.addPurchaseData.total_gt_amt = (this.total_gt_amt + this.total_igst_amt + this.total_sgst_amt + this.total_cgst_amt+Number(this.addPurchaseData.tcs_gt_amt)+Number(this.addPurchaseData.round_up)).toFixed(2);
	}
	
	roundUpkeyUpOtherProduct(){
		this.addPurchaseData.total_gt_amt =(this.total_gt_amt + this.total_igst_amt + this.total_sgst_amt + this.total_cgst_amt+Number(this.addPurchaseData.tcs_gt_amt)+Number(this.addPurchaseData.round_up)).toFixed(2);
	}
   //***************************************MS HSD Select**************************************/
    onselectItemMsHsd(event,un,controls)
 	{
		if(event)	
		{

		this.purchaseService.getItemTaxesDetailByItem(event.value,this.petro_id)
		.subscribe(itemData => {

			 if(itemData.data.ItemCategory.id==constDefault.ITEM_CATEGORY_BMS){
				$("#wbforms").show();
			 }else if(Number(itemData.data.ItemCategory.id)==constDefault.ITEM_CATEGORY_MS){
				$("#wbforms").show();
			 }else{
				$("#wbforms").css("display", "none");
			 }
			this.onselectItemOnMSCalc(itemData.data,un);
			this.getTankList(event.value,un,controls);			
		});
		}else{
			/*this.addPurchaseItemList = this.addPurchaseForm.get('addPurchaseItemList') as FormArray;
		//this.addPurchaseItemList.controls[un].get('item_id').setValue(itemData.Item.id);
		this.addPurchaseItemList.controls[un].get('rate').setValue('');
		this.addPurchaseItemList.controls[un].get('gst_rate').setValue('');
		this.addPurchaseItemList.controls[un].get('cgst_per').setValue('');
		this.addPurchaseItemList.controls[un].get('sgst_per').setValue('');
		this.addPurchaseItemList.controls[un].get('igst_per').setValue('');
		this.addPurchaseItemList.controls[un].get('net_amt').setValue('');
		this.addPurchaseItemList.controls[un].get('total_amt').setValue('');
		this.calculateTotal();*/
		}
	}
 getTankList(item_id,un,controls){
	controls.setControl('tankList',this.formBuilder.array([]));
	  this.purchaseService.getTankListByItem(item_id,this.petro_id)
	   .subscribe(tankData => {
		if(tankData.data){
		    this.commodityWiseTankList[un]=tankData.data;
			   this.addMsPurchaseItemList= this.addMsHsdPurchaseForm.get('addMsPurchaseItemList') as FormArray;
			  	
				tankData.data.forEach((tank, index) => { 
					this.tankList=this.addMsPurchaseItemList.controls[un].get('tankList') as FormArray;
				
					this.tankList.push(
						this.formBuilder.group({
							tank_id: [tank.Tank.id,''],
							qty: ['']									
						})
					);
				 });				
			}
	   });
	   
 }
	onselectItemOnMSCalc(taxData,un) {
	
	//console.log(taxData);
		
	this.addMsPurchaseItemList= this.addMsHsdPurchaseForm.get('addMsPurchaseItemList') as FormArray;
	 //this.addPurchaseItemList.controls[un].get('item_id').setValue(itemData.Item.id);
	
	 this.addMsPurchaseItemList.controls[un].get('dly_per').setValue(taxData.AppSetupPur.dly_taxabled);
	 this.addMsPurchaseItemList.controls[un].get('vat_per').setValue(taxData.AppSetupPur.vat);
	 this.addMsPurchaseItemList.controls[un].get('cess_per').setValue(taxData.AppSetupPur.cess_fixed);
	 this.addMsPurchaseItemList.controls[un].get('lfr_svt_per').setValue(taxData.AppSetupPur.lfr_svt);
   
	
	 this.rowWiseTotalMsItem(un)
	}
	
	dly_per:number;
	vat_per:number;
	sfr_svt_per:number;
	cess_per:number;
	
	dly_amt:number;
	vat_amt:number;
	sfr_svt_amt:number;
	cess_amt:number;
	
	ms_net:number;
	pr_kl_amt:number;
	all_sum_amt:number;
	
	ms_net_str:string;
	  vat_amt_str:string;
	  cess_amt_str:string;
	  sfr_svt_amt_str:string;
	  dly_amt_str:string;
	  pr_kl_amt_str:string;
	  all_sum_amt_str:string;
	  
	
	
	rowWiseTotalMsItem(row) {
	 this.addMsPurchaseItemList= this.addMsHsdPurchaseForm.get('addMsPurchaseItemList') as FormArray;
	 //this.addPurchaseItemList.controls[un].get('item_id').setValue(itemData.Item.id);
	 this.item_id = this.addMsPurchaseItemList.value[row]['item_id'].value;
	 if (this.item_id) {
	  this.qty = this.addMsPurchaseItemList.value[row]['qty'];
	  this.rate = this.addMsPurchaseItemList.value[row]['rate'];
	  
	  this.dly_per = this.addMsPurchaseItemList.value[row]['dly_per'];
	  this.vat_per = this.addMsPurchaseItemList.value[row]['vat_per'];
	  this.cess_per = this.addMsPurchaseItemList.value[row]['cess_per'];
	  this.sfr_svt_per = this.addMsPurchaseItemList.value[row]['lfr_svt_per'];
		
		// Per Kl Calculation
		this.ms_net=this.rate*1;
		this.dly_amt=1*Number(this.dly_per);
		this.vat_amt=((Number(this.ms_net)+Number(this.dly_amt))/100)*this.vat_per;
		this.cess_amt=1*this.cess_per;
		this.sfr_svt_amt=1*this.sfr_svt_per;
		
		this.pr_kl_amt=Number(this.ms_net)+Number(this.vat_amt)+Number(this.cess_amt)+Number(this.sfr_svt_amt)+Number(this.dly_amt);
		
		// Actual Calculation
		this.ms_net=this.rate*this.qty;
		this.vat_amt=((Number(this.ms_net)+Number(Number(this.dly_amt)*Number(this.qty)))/100)*this.vat_per;
		this.cess_amt=this.qty*this.cess_per;
		this.sfr_svt_amt=this.qty*this.sfr_svt_per;
		this.dly_amt=Number(this.dly_per) *Number(this.qty);
		this.all_sum_amt=Number(this.ms_net)+Number(this.vat_amt)+Number(this.cess_amt)+Number(this.sfr_svt_amt)+Number(this.dly_amt);
	  
	  
	  this.ms_net_str=this.ms_net.toFixed(2);
	  this.vat_amt_str=this.vat_amt.toFixed(2);
	  this.cess_amt_str=this.cess_amt.toFixed(2);
	  this.sfr_svt_amt_str=this.sfr_svt_amt.toFixed(3);
	  this.dly_amt_str=this.dly_amt.toFixed(2);
	  this.pr_kl_amt_str=this.pr_kl_amt.toFixed(2);
	  this.all_sum_amt_str=this.all_sum_amt.toFixed(2);
	  
	  this.ms_net=Number(this.ms_net_str);
	  this.vat_amt=Number(this.vat_amt_str);
	  this.cess_amt=Number(this.cess_amt_str);
	  this.sfr_svt_amt=Number(this.sfr_svt_amt_str);
	  this.dly_amt=Number(this.dly_amt_str);
	  this.pr_kl_amt=Number(this.pr_kl_amt_str);
	  this.all_sum_amt=Number(this.all_sum_amt_str);
	  
   
	  this.addMsPurchaseItemList.controls[row].get('net_amt').setValue(this.ms_net);
	  this.addMsPurchaseItemList.controls[row].get('vat').setValue(this.vat_amt);
	  this.addMsPurchaseItemList.controls[row].get('cess').setValue(this.cess_amt);
	  this.addMsPurchaseItemList.controls[row].get('lfr_svt').setValue(this.sfr_svt_amt);
	  this.addMsPurchaseItemList.controls[row].get('dly').setValue(this.dly_amt);
	  this.addMsPurchaseItemList.controls[row].get('per_kl_rate').setValue(this.pr_kl_amt);
	  this.addMsPurchaseItemList.controls[row].get('total_amt').setValue(this.all_sum_amt);
	 // this.addPurchaseItemList.controls[row].get('sgst_amt').setValue(this.sgst_amt);
	  //this.addPurchaseItemList.controls[row].get('igst_amt').setValue(this.igst_amt);
	 }
	 this.calculateMsBillTotal();
	}
	
	net_amt_total:number;
	vat_total:number;
	cess_total:number;
	sfr_svt_total:number;
	dly_total:number;
	total_amt_total:number;
	tcs_gt_amt:number;
	tcs_per:number;
	total_amt_total_round:number;
	round_up:number;
	
	calculateMsBillTotal() {
		
	 this.addMsPurchaseItemList = this.addMsHsdPurchaseForm.get('addMsPurchaseItemList') as FormArray;
	 /* this.saleItemListData = Object.keys(this.addPurchaseItemList).map(function(itemIndex){
		   let item = this.addPurchaseItemList[itemIndex];
			   return item;
		   });*/
		this.net_amt_total = 0;
		this.vat_total = 0;
		this.cess_total = 0;
		this.sfr_svt_total = 0;
		this.dly_total = 0;
		this.total_amt_total = 0;
		this.tcs_gt_amt=0;
		this.tcs_per=0.1;
		console.log("B"+this.tcs_gt_amt) ;
	 this.addMsPurchaseItemList.value.forEach((itemData, index) => { 
		 
		 
	  //for (let a of this.commodityDropdownList ) {
	  this.net_amt_total = this.net_amt_total + this.addMsPurchaseItemList.value[index]['total_amt'];
	  this.vat_total = this.vat_total + this.addMsPurchaseItemList.value[index]['vat'];
	  this.cess_total = this.cess_total + this.addMsPurchaseItemList.value[index]['cess'];
	  this.sfr_svt_total = this.sfr_svt_total + this.addMsPurchaseItemList.value[index]['lfr_svt'];
	  this.dly_total = this.dly_total + this.addMsPurchaseItemList.value[index]['dly'];
	  this.total_amt_total = this.total_amt_total + this.addMsPurchaseItemList.value[index]['total_amt'];  
	 if (this.comp_id==3){
		this.tcs_gt_amt = this.tcs_gt_amt + (this.addMsPurchaseItemList.value[index]['total_amt']- this.addMsPurchaseItemList.value[index]['lfr_svt'] )*0.1/100 
	 }
	 else
	 {
		this.tcs_gt_amt = this.tcs_gt_amt + this.addMsPurchaseItemList.value[index]['total_amt'] *0.1/100 
	 }
	 console.log(this.tcs_gt_amt) ;
	 });
	 
	 this.addMsHsdPurchaseData.tcs_gt_amt = this.tcs_gt_amt.toFixed(2);
	 this.addMsHsdPurchaseData.total_net_amt = this.net_amt_total;

	 if(!this.addMsHsdPurchaseData.discount_amt){
		 this.addMsHsdPurchaseData.discount_amt=0;
	 }

	 if (!this.addMsHsdPurchaseData.tcs_gt_amt) {
		this.addMsHsdPurchaseData.tcs_gt_amt = 0;
	}
	 this.addMsHsdPurchaseData.total_gt_amt = this.net_amt_total-Number(this.addMsHsdPurchaseData.discount_amt)+Number(this.addMsHsdPurchaseData.tcs_gt_amt);
	 // Comment auto round up
	 //this.total_amt_total_round=Math.round(this.addMsHsdPurchaseData.total_gt_amt );
	//this.round_up=Number(this.total_amt_total_round)-Number(this.addMsHsdPurchaseData.total_gt_amt);
	// this.addMsHsdPurchaseData.total_gt_amt=this.total_amt_total_round;
	// if(!this.round_up){
	//	 this.round_up=0;
	// }
	 //this.addMsHsdPurchaseData.round_up=this.round_up.toFixed(2);
	 // Manual round up
	 // if (this.addMsHsdPurchaseData.round_up) {
       //     this.addMsHsdPurchaseData.total_gt_amt = Number(this.addMsHsdPurchaseData.total_gt_amt) + Number(this.addMsHsdPurchaseData.round_up);
       // }
        //else {
          //  this.addMsHsdPurchaseData.total_gt_amt = this.addMsHsdPurchaseData.total_gt_amt;
       // }
	   
	   if (this.addMsHsdPurchaseData.round_up) {
		this.addMsHsdPurchaseData.total_gt_amt = Number(this.addMsHsdPurchaseData.total_gt_amt) + Number(this.addMsHsdPurchaseData.round_up);
	}
	else {
		this.addMsHsdPurchaseData.total_gt_amt = this.addMsHsdPurchaseData.total_gt_amt;
	}
	 // End Manual round up
	 this.addMsHsdPurchaseData.total_vat_amt=this.vat_total.toFixed(2);
	 this.addMsHsdPurchaseData.total_cess_amt=this.cess_total.toFixed(2);
	 this.addMsHsdPurchaseData.total_dly_amt=this.dly_total.toFixed(2);
	 this.addMsHsdPurchaseData.total_lfr_svt_amt=this.sfr_svt_total.toFixed(2);
	// this.addMsHsdPurchaseData.net_amt_total=this.net_amt_total.toFixed(2);
	
	// this.addMsHsdPurchaseData.total_gt_amt=this.total_gt_amt.toFixed(2);

	}
	
onDiscoundKeyUpTotal(){

	if(!this.addMsHsdPurchaseData.discount_amt){
		this.addMsHsdPurchaseData.discount_amt=0;
	}

	if (!this.addMsHsdPurchaseData.tcs_gt_amt) {
		this.addMsHsdPurchaseData.tcs_gt_amt = 0;
	}
	 this.addMsHsdPurchaseData.total_gt_amt = Number(this.addMsHsdPurchaseData.total_net_amt)-Number(this.addMsHsdPurchaseData.discount_amt)+Number(this.addMsHsdPurchaseData.tcs_gt_amt);
	 
	 /*this.total_amt_total_round=Math.round(this.addMsHsdPurchaseData.total_gt_amt );
	 this.round_up=Number(this.total_amt_total_round)-Number(this.addMsHsdPurchaseData.total_gt_amt);
	 this.addMsHsdPurchaseData.total_gt_amt=this.total_amt_total_round;
	 if(!this.round_up){
		 this.round_up=0;
	 }
	 this.addMsHsdPurchaseData.round_up=this.round_up.toFixed(2);*/
	  if(this.addMsHsdPurchaseData.round_up)
	 {
		this.addMsHsdPurchaseData.total_gt_amt=Number(this.addMsHsdPurchaseData.total_gt_amt)+Number(this.addMsHsdPurchaseData.round_up);
	 }else{
		 this.addMsHsdPurchaseData.total_gt_amt=this.addMsHsdPurchaseData.total_gt_amt;
	 }
}

onTCSKeyUpTotal(){

	if(!this.addMsHsdPurchaseData.discount_amt){
		this.addMsHsdPurchaseData.discount_amt=0;
	}

	if(!this.addMsHsdPurchaseData.tcs_gt_amt){
		this.addMsHsdPurchaseData.tcs_gt_amt=0;
	}
	this.addMsHsdPurchaseData.total_gt_amt = Number(this.addMsHsdPurchaseData.total_net_amt)-Number(this.addMsHsdPurchaseData.discount_amt)+Number(this.addMsHsdPurchaseData.tcs_gt_amt);
	
	/*this.total_amt_total_round=Math.round(this.addMsHsdPurchaseData.total_gt_amt );
	this.round_up=Number(this.total_amt_total_round)-Number(this.addMsHsdPurchaseData.total_gt_amt);
	this.addMsHsdPurchaseData.total_gt_amt=this.total_amt_total_round;
	if(!this.round_up){
		this.round_up=0;
	}
	this.addMsHsdPurchaseData.round_up=this.round_up.toFixed(2);*/
	 if(this.addMsHsdPurchaseData.round_up)
	{
	   this.addMsHsdPurchaseData.total_gt_amt=Number(this.addMsHsdPurchaseData.total_gt_amt)+Number(this.addMsHsdPurchaseData.round_up);
	}else{
		this.addMsHsdPurchaseData.total_gt_amt=this.addMsHsdPurchaseData.total_gt_amt;
	}
}

tank_total:number;
tank_qty:number;
tankQtyCalc(un,iun){
		this.addMsPurchaseItemList= this.addMsHsdPurchaseForm.get('addMsPurchaseItemList') as FormArray;
		this.tankList=this.addMsPurchaseItemList.controls[un].get('tankList') as FormArray;
		//this.tankList.value[index]['qty'];
			this.tank_total=0;
			this.tankList.value.forEach((tank, index) => { 
			//console.log(tank.qty);
						if(tank.qty)
						{
							this.tank_qty=tank.qty;
						}else{
							this.tank_qty=0;
						}
						this.tank_total=Number(this.tank_total)+Number(this.tank_qty);
				 });
				 
		  this.addMsPurchaseItemList.controls[un].get('tank_total_qty').setValue(this.tank_total);
}


	/*---------------------------------------------End Auto Complete----------------------------------*/

addMsPurchase() {

	this.addMsPurchaseItemList= this.addMsHsdPurchaseForm.get('addMsPurchaseItemList') as FormArray;
		//this.tankList=this.addMsPurchaseItemList.controls[un].get('tankList') as FormArray;
		this.qty_error=true;
			this.addMsPurchaseItemList.value.forEach((tank, index) => { 
						if(tank.qty==tank.tank_total_qty)
						{
							
						}else{
							this.qty_error=false;
						}
				 });
	
	
		if(this.qty_error)
		{
			this.pipe = new DatePipe('en-US'); 
			this.addMsHsdPurchaseForm.value.date = this.pipe.transform(this.addMsHsdPurchaseForm.value.date, 'yyyy-M-d');
			this.addMsHsdPurchaseForm.value.grn_date = this.pipe.transform(this.addMsHsdPurchaseForm.value.grn_date, 'yyyy-M-d');
			 this.addMsHsdPurchaseForm.value.petro_id = this.petro_id;
			 this.addMsHsdPurchaseForm.value.purchase_type = constDefault.PURCHASE_TYPE_MSHSD;
			 //console.log(this.addMsHsdPurchaseForm);
			 //return;
			 this.purchaseService.addMsHsdPurchaseData(this.addMsHsdPurchaseForm.value)
			  .subscribe(addData => {
			   if (addData.status == 200 || addData.status == 201) {
				$('#addMsHsdPurchaseModal').modal('hide');
				this.addMsHsdPurchaseForm.reset();
				this.getPageWisePurchaseList(1);

				this.addMsHsdPurchaseData.date=new Date(Date.now());
				this.addMsHsdPurchaseData.grn_date=new Date(Date.now());
						this.addMsHsdPurchaseData.shift=1;

			   }
			  });
		}
	}
 
 viewMsHsdPurchase(purchase_id: number) {
   
	 this.purchaseService.getPurchaseDataById(purchase_id)
	  .subscribe(purchaseData => {
		  console.log(purchaseData.data);
	   this.purchaseMsDataForView = purchaseData.data;

		this.viewMsPurchaseStatus = 1;
		

	  });
	 $('#viewMsHsdPurchaseModal').modal('show');
	}

}
