import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { CommonsService } from '../../admin-services/commons.service';
import { Router} from '@angular/router';
declare var $: any;

/*interface serviceData {
  data: any
}*/


@Component({
  selector: 'app-state-master',
  templateUrl: './state-master.component.html',
  styleUrls: ['./state-master.component.css']
})
export class StateMasterComponent implements OnInit {

  constructor(private masterService: MasterServicesService, private commonsServices: CommonsService, private router: Router, private session: SessionStorageService, private formBuilder: FormBuilder) {}

	authData: any = {};
	addStateData: any = {};
	editStateData: any = {};
	serachData: any = {};
	listState: any[] = [];
	countryDropdownList: any[] = [];
	totalItem:number;
	//petro_id:1;
	success_message: string;
	error_message: string;
	
	currentPage: 1;
	index: number;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	
	add_loading:boolean;
	addStateForm:FormGroup;
	editStateForm = new FormGroup({
		id: new FormControl(''),
		
		name: new FormControl('', Validators.required),
		country_id: new FormControl('', Validators.required),
		
		
	});
	serachStateForm = new FormGroup({
		name: new FormControl('')

	});


	ngOnInit() {
		// Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
	
	
		this.addStateData.country_id = "";
		this.authData = this.session.get('authData');
		

		this.addStateForm= this.formBuilder.group({
	
	
		country_id: new FormControl(' ', Validators.required),
		name: new FormControl(' ', Validators.required),
		
	   });
		
		this.stateListData(1);

		
		this.commonsServices.getcountryDropdownList()
			.subscribe(listdata => {
				this.countryDropdownList = listdata.data
					
			});

		
	}
	 get name(){
	  return this.addStateForm.get('name');
  }

	

	// Add state
	addState(add_item) {
		//console.log(add_item);
		this.add_loading=true;
		add_item.submitted = false;
		this.authData = this.session.get('authData');
		//this.addStateData.petro_id = this.authData.petro_id;
		//console.log(this.addStateData);
		this.masterService.addState(this.addStateData).subscribe(data => {
			if (data.status == 201) {

				this.success_message = 'State has been created';
				
				$('#addStateModal').modal('hide');
			//	$('#addStateForm').reset();
			     this.addStateForm.reset();
				this.stateListData(1);
			
				this.addStateData.country_id = "";
			}else if(data.status==403)
				{
					 this.session.remove('authData');
						this.router.navigate(['']);
				} else {
				this.error_message = 'State could not be created';
			}
			this.add_loading=false;
			
		});

	}

	stateListData(pageno) {
		this.currentPage = pageno;
		
		this.masterService.getStateListData(pageno,this.serachData)
			.subscribe(alldata => {
				if(alldata.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}
					this.listState = alldata.data,
					this.totalItem = alldata.count
			});

	}

	// Get Edit Data

	getStateDataForEdit(id, index) {


		this.index = index;

		this.masterService.getEditStateData(id).subscribe(data => {
			if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}
				//console.log(data.data);
			//this.editStateData = data.data.Country;
			this.editStateData = data.data.State;
			
			$('#editStateModal').modal('show');


		});
	}


	editState(edit_item) {
		edit_item.submitted = false;

		this.masterService.editStateData(this.editStateData).subscribe(data => {
			if (data.status == 200) {
				this.success_message = 'State has been updated';
				$('#editStateModal').modal('hide');
                  
				this.editStateForm.reset();
                this.stateListData(1);
			}else if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				} else {
				this.error_message = 'State could not be created';
			}
		});

	}
	/*
	Delete State 
	Mrunali D
	16.7.2019
	*/
	deleteState(id, index) {
		if (confirm("Are you sure to delete this State ")) {
			this.index = index;

			this.masterService.deleteDataByModel(id, 'State').subscribe(data => {
				if (data.status == true) {
					this.listState.splice(this.index, 1);
					this.success_message = "State deleted sucessfully";

				} else if(data.status==403)
				{
					  this.session.remove('authData');
						this.router.navigate(['']);
				}else {
					this.error_message = "State could not be deleted.";
				}
			});

			//this.editname=this.editCategoryData.name;	
		}
	}

	/*
	Mrunali D
	16.7.2019
	*/
	searchState() {

		this.stateListData(1);
	}

	resetSearchState() {
		this.serachData ={};
		this.stateListData(1);
	}
  
    }
  