import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { LoginService } from '../../admin-services/login.service';
import {CommonsService} from '../../admin-services/commons.service';
import {constDefault} from '../../const_default';
import { Router} from '@angular/router';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class AdminLoginComponent implements OnInit {
	model:any={};
	message:string;
	email:string;
	setItem:string;
	financial_years: any[]=[]
	year:string;
	clear_login:number;
	password:string;
	public logininfo:any=[];
	profileForm= new FormGroup({
		email : new FormControl(' ',[Validators.required,Validators.email]),
		password : new FormControl(' ',Validators.required),
		financial_year: new FormControl(' ',Validators.required),
		clear_login: new FormControl(' ')
		
	});
	

	
  constructor(private loginService: LoginService,private commonsService: CommonsService, private router:Router, private session: SessionStorageService, private local_storage: LocalStorageService ) { }
	 
  ngOnInit() {
		this.clear_login=0;
		//this.model.financial_year = constDefault.CURRENT_FINANCIAL_YEAR;

		this.commonsService.getFinancialYear()
		.subscribe(financial_year => {
			this.financial_years = financial_year.data;
			this.setCurrentFinacialyear(financial_year.data);
		});
		
  }
  
  setCurrentFinacialyear(yearList){
	  //console.log(yearList);
	  let i=0;
	  for (let id in yearList) {
		  i+=1;
		// if(i==1)
		// {
			this.model.financial_year=id;
		// }
		
	}
  }

  loginData()
  {
	 
	//alert(JSON.stringify(this.model));
	//this.email=this.model.email;
	//this.password=this.model.password;
	this.email=this.model.email;
	this.password=this.model.password;
	this.year=this.model.financial_year;
	
	
	this.loginService.checkloginData(this.email,this.password,this.year,this.clear_login).subscribe(data=>{
		if(data.status==200)
		{
			//alert(data.role);
			this.loginService.setLoggedIn(true); 
			//console.log(data);
			this.session.set('authData',data);
			this.local_storage.set('localAuthData',data);
			if(data.role==1)
			{
				//this.clear_login=1;
		//	this.router.navigate(['super-admin']);
			 this.router.navigateByUrl('/super-admin');
			
			}else(data.role==2)
			{
				// For page refresh
				window.location.href = "#/admin/dashboard/?refresh=1";
				//window.open('#/admin/dashboard', '_self')
				
			}
		
			//console.log(this.local_storage.get('localAuthData'));
			//this.session.store('userData','amit')	;
		}else if(data.status==401){
			this.message='User already loggedin on other pc ';
		}else{
			this.message='Invalid user id or password';
		}
	});
	
	
  }
  
   clearToken(event){
	  if(event.target.checked)
	  {
		this.clear_login=1;
		 
	  }else{
		  this.clear_login=0;
	  }

}
}
