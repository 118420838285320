import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { CommonsService } from '../../admin-services/commons.service';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
import { Router} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-bozer-master',
  templateUrl: './bozer-master.component.html',
  styleUrls: ['./bozer-master.component.css']
})
export class BozerMasterComponent implements OnInit {
  constructor(private masterService: MasterServicesService, private router:Router,private session:SessionStorageService, private commonsService:CommonsService, private formBuilder: FormBuilder) { }
  authData:any={};
  petro_id:number;
  addBozerData : any={};
	editPetrolPump : any={};
  data : any[] = [];
  success_message:string;
	error_message:string;
	serachPetrolPump : any={};
	serachBozerData : any={};
	BozerList:any[] = [];
	index:number;
	editBozerData:any={};
	model:string;
	serachBozer:any={};
	currentPage:number;
	totalCat:number;
	add_loading:boolean;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	selectedPeople2 = [];
	CommodityDropdownList:any[]=[];
  succeAddLocData:{
		Bozer:{
			id:any,
			name:string
		}
	}
	addBozerForm:FormGroup;
  /*addBozerForm = new FormGroup({
    name : new FormControl(' ',Validators.required)
  })*/

  //editBozerForm:FormGroup;
  editBozerForm = new FormGroup({
		id : new FormControl(''),
    name : new FormControl(' ',Validators.required),
	commodity : new FormControl(' ',Validators.required),
	})
	
	serachBozerForm= new FormGroup({
		name : new FormControl(' '),
	});

  ngOnInit() {
	  
	this.btnPrevSession = this.session.get('ButtonAuth');
	if(this.btnPrevSession)
	{
		let urlArr=this.router.url.split('/');
		this.link_path=urlArr[2];
		let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
		this.delete_btn=result.UserPrivilege.delete_auth;
		this.edit_btn=result.UserPrivilege.edit_auth;
		this.create_btn=result.UserPrivilege.create_auth;
	}
	  
    this.authData = this.session.get('authData');
		this.petro_id=this.authData.petro_id;
		
		this.addBozerForm = this.formBuilder.group({
    name : new FormControl(' ',Validators.required ,uniqueByModalOrNameValidation(this.commonsService,'Bozer',this.petro_id)),
	commodity : new FormControl(' ',Validators.required)
  })

  this.commonsService.getCommodityLsit(this.petro_id)
  .subscribe(commoditylistdata =>
  {			//console.log(commoditylistdata);
	  this.selectedPeople2= Object.entries(commoditylistdata.data).map(([value, label]) => ({value,label}));			
  });
		//alert();
    // this.masterService.getBozers(this.petro_id)
		// .subscribe(Bozerlistdata =>{this.masterService=Bozerlistdata.data});
		this.pageWiseData(1);
  }
  
  get name(){
	  return this.addBozerForm.get('name');
  }

  // Add Bozer
  addBozer(add_des)
	{
		this.add_loading=true;
		add_des.submitted = false;
		this.authData = this.session.get('authData');
	  this.addBozerData.petro_id=this.authData.petro_id;
	  this.addBozerData.commodity=this.addBozerData.commodity.value;
		this.masterService.addBozerData(this.addBozerData).subscribe(data=>{
			//alert(data);
			if(data.status==200)
			{
				//console.log("Bozer Data ///////////"+data);
				this.success_message='Bozer has been created';
			$('#addBozerModal').modal('hide');

			// this.succeAddLocData={
			// 	Bozer:{
			// 		id:data.id,
			// 		name:data.name
			// 	}
			// }
			// this.BozerList.unshift(this.succeAddLocData);
			this.pageWiseData(1);
			this.addBozerForm.reset(); 
			
		}else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.error_message='Bozer could not be created';
		}
		this.add_loading=false;
		});
	}

	// Get Bozer data by id
	getBozerData(id,index){
	
			this.index=index;
			this.masterService.getEditBozerData(id).subscribe(data=>{
     
				this.editBozerData.id=data.data.Bozer.id;	
				this.editBozerData.name=data.data.Bozer.name;	
				this.editBozerData.commodity=data.data.Bozer.item_id;	
				this.commonsService.getCommodityLsit(this.petro_id)
				.subscribe(commoditylistdata =>{
					if(commoditylistdata.status==403)
					{
						  this.session.remove('authData');
							this.router.navigate(['']);
					}else{
					this.CommodityDropdownList=commoditylistdata.data
					}
					});
					$('#editBozerModal').modal('show');
			});

			
		
	}

	// Delete Loaction by id
	deleteBozerData(id,index)
	{
		if(confirm("Are you sure to delete this Bozer")) { 
			this.index=index;
			this.model='Bozer'; 
				//alert(id);
			this.masterService.deleteDataByModel(id,this.model).subscribe(data=>{
				//console.log(data);
				if(data.status==true)
				{
					this.BozerList.splice(this.index,1);
					this.success_message="Bozer deleted sucessfully";	
				}else{
					this.error_message= "Bozer could not be deleted.";
				}
			});
		//this.editname=this.editCategoryData.name;	
		}
	}

	// Submit Edited Bozer
	editBozer(edit_form){
		edit_form.submitted=false;
		this.masterService.editBozerData(this.editBozerData).subscribe(data=>{
		//console.log(data);
			if(data.status=200)
			{
				//alert("success");
				this.success_message='Bozer Edited Successfully!';				
        this.editBozerForm.reset(); 	
        this.pageWiseData(1);
				$('#editBozerModal').modal('hide');
			}else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
				this.error_message='Bozer cannot be Register!';
			}
		});
	}

	// Function to Search Petrol Pump list
	searchBozer(){
		this.pageWiseData(1);
	} 

	// Reset Search Data
	resetSearchBozer(){
		this.serachBozerData={};
		this.pageWiseData(1);
	} 

	// Pagination 
	pageWiseData(pageno){
		//alert('getBozers');
		this.currentPage=pageno;
    this.masterService.getBozers(pageno,this.petro_id,this.serachBozerData)
    
		.subscribe(alldata =>{
			if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.BozerList=alldata.data,
			this.totalCat=alldata.count
			}
		});
		
	}

}
