import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { CommonsService } from '../../admin-services/commons.service';
import { FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
import { DatePipe } from '@angular/common';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';

import { Router} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-nozzle-master',
  templateUrl: './nozzle-master.component.html',
  styleUrls: ['./nozzle-master.component.css']
})
export class NozzleMasterComponent implements OnInit {

  constructor(private masterService: MasterServicesService, private commonsServices:CommonsService,private router:Router,private session:SessionStorageService, private formBuilder: FormBuilder) { }

  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Start Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  
  // Initialize Veriables
  addNozzleData:any={};
  editNozzleData:any={};
  searchNozzleData:any={};
  resetNozzleData:any={};
  changeTankData:any={};
  tankChangeData:any={};
  commodity:any[]=[];
  authData:any={};
  data : any[] = [];
 // name : string;
  success_message:string;
  error_message:string;
  index:number;
  currentPage:number;
  totalCat:number;
  petro_id:number;
	model:string;
	serachNozzleData:any={};
  nozzleDropdownList:any[] = [];
  nozzleList:any[] = [];
  tankDropdownList:any[] = [];
  dispenserDropdownList:any[] = [];
	pipe: any;
	add_loading: boolean;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
  succeAddNozzleData:{
    Nozzle:{
      id:any,
      name:string,
      tank_name:string,
      commodity:string,
      shift:string,
      start_reading:number,
      start_date:string,
      current_reading:number,
      max_reading:number
    },
    Tank:{
      tank_id:number,
      name:string
    }
		
  };
  succeResetNozzleData:{
    Nozzle:{
      id:any,
      name:string,
      commodity:number,
      shift:string,
      last_reading:number,
      start_date:string,
      current_reading:number,
    },
    Tank:{
      tank_id:number
    }
		
  }
  addNozzleForm:FormGroup;

  editNozzleForm = new FormGroup({
    id: new FormControl(' '),
    name: new FormControl(' ',Validators.required),
    shift: new FormControl(' ',Validators.required),
    tank_id: new FormControl(' ',Validators.required),
    start_reading: new FormControl(' ',Validators.required),
    start_date: new FormControl(' ',Validators.required),
    current_reading: new FormControl(' ',Validators.required),
    max_reading: new FormControl(' ',Validators.required),
    commodity: new FormControl(' ',Validators.required),
    dispenser_id: new FormControl(' ',Validators.required)

  });
  

  changeTankForm = new FormGroup({
    nozzle_id: new FormControl(' '),
    shift: new FormControl(' ',Validators.required),
    tank_id: new FormControl(' ',Validators.required),
    start_date: new FormControl(' ',Validators.required),
    current_reading: new FormControl(' ',Validators.required),
    changed_commodity_name: new FormControl(' '),
    changed_commodity: new FormControl(' ')
  });

  resetNozzleForm = new FormGroup({
    nozzle_id: new FormControl(' '),
    shift: new FormControl(' ',Validators.required),
    tank_id: new FormControl(' ',Validators.required),
    tank_name: new FormControl(' ',Validators.required),
    start_date: new FormControl(' ',Validators.required),
    last_reading: new FormControl(' ',Validators.required),
    start_reading: new FormControl(' ',Validators.required),
    max_reading: new FormControl(' ',Validators.required)
  });
	
	serachNozzleForm= new FormGroup({
		name : new FormControl(' ')
	});

  ngOnInit() {
	  
	  this.btnPrevSession = this.session.get('ButtonAuth');
	if(this.btnPrevSession)
	{
		let urlArr=this.router.url.split('/');
		this.link_path=urlArr[2];
		let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
		this.delete_btn=result.UserPrivilege.delete_auth;
		this.edit_btn=result.UserPrivilege.edit_auth;
		this.create_btn=result.UserPrivilege.create_auth;
	}
    this.addNozzleData.start_date =new Date();
    this.changeTankData.start_date =new Date();
	  this.addNozzleData.tank_id="";
    // Get auth id save into petro_id
    this.authData = this.session.get('authData');
    this.petro_id=this.authData.petro_id;
    

	
	this.addNozzleForm =this.formBuilder.group({
    name: new FormControl(' ',Validators.required, uniqueByModalOrNameValidation(this.commonsServices,'Nozzle',this.petro_id)),
    tank_id: new FormControl(' ',Validators.required),
    commodity: new FormControl(' ',Validators.required),
    shift: new FormControl(' ',Validators.required),
    start_reading: new FormControl(' ',Validators.required),
    start_date: new FormControl(' ',Validators.required),
    current_reading: new FormControl(' ',Validators.required),
    max_reading: new FormControl(' ',Validators.required),
    dispenser_id: new FormControl(' ',Validators.required)
  });
  
    // Get tank id and data
    this.masterService.getTankId(this.petro_id)
    .subscribe(tanklistdata =>{this.tankDropdownList=tanklistdata.data});
    
    this.commonsServices.getDispenserDropdown(this.petro_id)
      .subscribe(dispenserlistdata =>{this.dispenserDropdownList=dispenserlistdata.data});

    this.pageWiseData(1);
  }

  get name(){
	  return this.addNozzleForm.get('name');
  }

  // On Add Select Tank
  onAddSelectTank(){
    //alert();
    this.masterService.getEidtTankData(this.addNozzleData.tank_id).subscribe(data=>{	
      //console.log(data);				
      this.addNozzleData.commodity=data.data.Tank.commodity;						
      this.addNozzleData.commodity_name=data.data.Item.name;						
    });
  }

  // On Edit Select Tank
  onEditSelectTank(){
    this.masterService.getEidtTankData(this.editNozzleData.tank_id).subscribe(data=>{					
      this.editNozzleData.commodity=data.data.Tank.commodity;						
      this.editNozzleData.commodity_name=data.data.Item.name;						
    });
  }

  // On Edit Select Tank
  onChangeTank(){
    this.masterService.getEidtTankData(this.changeTankData.tank_id).subscribe(data=>{	
      //alert(data.data.Item.id);	
      this.changeTankData.commodity=data.data.Tank.commodity;						
      this.changeTankData.changed_commodity_name=data.data.Item.name;						
      this.changeTankData.changed_commodity=data.data.Item.id;						
    });
  }
  // Add Nozzle
  addNozzle(addNoz){
    //alert();
	this.add_loading=true;
    addNoz.submitted = false; 
    
    this.authData = this.session.get('authData');
	  this.addNozzleData.petro_id=this.authData.petro_id;
	  this.pipe = new DatePipe('en-US'); 
		this.addNozzleData.start_date= this.pipe.transform(this.addNozzleData.start_date, 'yyyy-MM-dd');
		this.masterService.addNozzleData(this.addNozzleData).subscribe(data=>{
      if(data.status==200)
      {
        //console.log("Location Data ///////////"+data);
        this.success_message='Nozzle has been Added Successfully';
        $('#addNozzleModal').modal('hide');
        this.pageWiseData(1);
        this.addNozzleForm.reset();   
        this.addNozzleForm.clearValidators(); 
		this.addNozzleData.start_date =new Date();		
      }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
        this.error_message='Nozzle could not be create';
      }
	  this.add_loading=false;
		});
  }

  // 
  getNozzleData(id,index){
    this.index=index;
    this.masterService.getEditNozzleDataById(id).subscribe(data=>{
      this.editNozzleData.id=data.data.Nozzle.id;	
      this.masterService.getTankId(this.petro_id)
      .subscribe(tanklistdata =>{this.tankDropdownList=tanklistdata.data});
      this.editNozzleData.name=data.data.Nozzle.name;	
      this.editNozzleData.tank_id=data.data.Tank.id;	
      this.editNozzleData.shift=data.data.Nozzle.shift;	
      this.editNozzleData.max_reading=data.data.Nozzle.max_reading;	
      this.editNozzleData.start_reading=data.data.Nozzle.start_reading;	
      this.editNozzleData.start_date=data.data.Nozzle.start_date;	
      this.editNozzleData.current_reading=data.data.Nozzle.current_reading;	  
      this.commonsServices.getDispenserDropdown(this.petro_id)
      .subscribe(dispenserlistdata =>{this.dispenserDropdownList=dispenserlistdata.data});
      this.editNozzleData.dispenser_id=data.data.Dispenser.id;
      this.onEditSelectTank()
    });
    $('#editNozzleModal').modal('show');
  }

  // Edit Nozzle Data 
  editNozzle(edit_nozzle){
    edit_nozzle.submit=false;
    this.authData = this.session.get('authData');
	  this.editNozzleData.petro_id=this.authData.petro_id;
	    this.pipe = new DatePipe('en-US'); 
		this.editNozzleData.start_date= this.pipe.transform(this.editNozzleData.start_date, 'yyyy-MM-dd');
		this.masterService.editNozzleData(this.editNozzleData).subscribe(data=>{
      if(data.status==200)
      {
        this.success_message='Nozzle has been Editted Successfully';
        $('#editNozzleModal').modal('hide');
        this.pageWiseData(1);
        this.editNozzleForm.reset();  
      }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
        this.error_message='Nozzle could not be create';
      }
		});
  }

  // Get reset Tank Data on resetTankModal Form 
  getTankChangedData(id, index){
    //alert(id);
    this.index=index;
    this.masterService.getResetNozzleList(id).subscribe(data=>{
      //console.log(data);
	  if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
      this.changeTankData.nozzle_id=data.data.Nozzle.id;	
      this.masterService.getTankId(this.petro_id)
      .subscribe(tanklistdata =>{this.tankDropdownList=tanklistdata.data});
      this.changeTankData.tank_id=data.data.Tank.id;		
      this.changeTankData.changed_commodity_name=data.data.Item.name;	
      this.changeTankData.shift=data.data.Nozzle.shift;	
      this.changeTankData.changed_commodity=data.data.Item.id;	
			}
    });
    $('#changeTankModal').modal('show');
  }

  // Change Tank Data And Submit Data
  tankChange(){
    this.authData = this.session.get('authData');
    this.changeTankData.petro_id=this.authData.petro_id;
    //console.log(this.changeTankData);
		this.masterService.tankChangedData(this.changeTankData).subscribe(data=>{
			//alert(data);
    			if(data.status==200)
    			{
    				this.success_message='Tank Changed Successfully';
            $('#changeTankModal').modal('hide');
            this.pageWiseData(1);
            this.changeTankForm.reset();
          }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
            this.error_message='Nozzle could not be Reset';
          }
		});
  }

  // Get Reset Nozzle Data
  getResetNozzleData(id,index){
    this.index=index;
    this.masterService.getResetNozzleList(id).subscribe(data=>{
      //console.log(data);
	  if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			  this.resetNozzleData.nozzle_id=data.data.Nozzle.id;	
			  this.masterService.getTankId(this.petro_id)
			  .subscribe(tanklistdata =>{this.tankDropdownList=tanklistdata.data});
			  this.resetNozzleData.tank_id=data.data.Tank.id;
			  this.resetNozzleData.tank_name=data.data.Tank.name;
			  this.resetNozzleData.shift=data.data.Nozzle.shift;	
			  this.resetNozzleData.start_date=data.data.Nozzle.start_date;	
			  this.resetNozzleData.max_reading=data.data.Nozzle.max_reading;	
			  this.resetNozzleData.start_reading=data.data.Nozzle.start_reading;	
			  this.resetNozzleData.last_reading=data.data.Nozzle.last_reading;	  
			$('#resetNozzleModal').modal('show');
			}
    });
  }

  // Submit Reset Nozzle Data
  resetNozzle(){
    this.authData = this.session.get('authData');
    this.resetNozzleData.petro_id=this.authData.petro_id;
    //console.log("Location Data ///////////"+this.resetNozzleData);
		this.masterService.resetNozzleData(this.resetNozzleData).subscribe(data=>{
      if(data.status==200)
      {
        this.success_message='Tank Changed Successfully';
        $('#resetNozzleModal').modal('hide');
        this.pageWiseData(1);
        this.resetNozzleForm.reset(); 
      }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
        this.error_message='Nozzle could not be Reset';
      }
		});
  }

  // Delete Nozzle
  deleteNozzleData(id,index)
	{
		if(confirm("Are you sure to delete this Nozzle Information")) { 
			this.index=index;
			this.model='Nozzle'; 
				//alert(id);
			this.masterService.deleteDataByModel(id,this.model).subscribe(data=>{
				//console.log(data);
				if(data.status==true)
				{
					this.nozzleList.splice(this.index,1);
					this.success_message="Nozzle deleted sucessfully";	
				}else{
					this.error_message= "Nozzle could not be deleted.";
				}
			});
		}
	}

  // Search Nozzle
  searchNozzle(){
		this.pageWiseData(1);
	} 

	// Reset Search Data
	resetSearchNozzle(){
		this.serachNozzleData={};
		this.pageWiseData(1);
	} 

	// Pagination 
	pageWiseData(pageno){
		this.currentPage=pageno;
		this.masterService.getNozzleListData(pageno,this.petro_id,this.serachNozzleData)
		.subscribe(alldata =>{
			if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
				this.nozzleList=alldata.data,
				this.totalCat=alldata.count
			}
     // console.log(this.nozzleList)
		});
  } 
  
  // Reset form on close button clicked
  reset_form(form_name){
    //alert(form_name);
    form_name.reset(); 
  }
  

  
}
