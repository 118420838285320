import { Component, OnInit } from '@angular/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router , ActivatedRoute } from '@angular/router';
import { ApplicationSetupService } from '../../admin-services/application-setup.service';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {


    value: any = null;
  constructor(private router:Router, private applicationService:ApplicationSetupService, private session: SessionStorageService, private route:ActivatedRoute) {
		// For page refresh
		this.route.queryParams.subscribe((data) => {
		  if (data && data['refresh']) {
			
			window.location.href = "#/admin/dashboard";

			location.reload();
		  }
		}); 
  }
  authData:any = {};
  petro_id:number;
  success_message: string;
	error_message: string;
	dragging:any={};
  ngOnInit() {
	  	
   // this.value =this.sessionStr.getItem('userData');
   this.authData = this.session.get('authData');
    this.petro_id = this.authData.petro_id;
   this.value = this.session.get('name');

   
  }
  


  // High Priority:- Delete All Data From Database by petro_id (By Petrolpump Id) 
  // clearData(){
  //   if(confirm("Do you really want to Delete all Data?")){ 
	// 		this.applicationService.deletedAllDataFormTable(this.petro_id).subscribe(data=>{
	// 			if(data.status==201)
	// 			{				
	// 			  this.success_message="Data Is Cleared From Database.";		
	// 			}else{
	// 				this.error_message="Data Could Not Be Deleted.";
	// 			}
	// 		});
	// 	}
  // }

}
