import { Component, OnInit } from '@angular/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';

import * as enLocale from 'date-fns/locale/en';
import{DatePipe}from '@angular/common';
import{ParseFloatPipe} from '../../../customePipe/parse-float.pipe'
import { ReportsService } from '../../../admin-services/reports.service';
// Custoem Function
import { OnCreatedDirective } from '../../../shared/on-created.directive';
import { Router} from '@angular/router';


@Component({
  selector: 'app-shift-report-cust1',
  templateUrl: './shift-report-cust1.component.html',
  styleUrls: ['./shift-report-cust1.component.css']
})
export class ShiftReportCust1Component implements OnInit {
  total_sale_other:number;
  total_sale_ms:number;
  total_sale_hsd:number;
  total_cash_bal:number=0;
 
  total_cash_other:number;
  total_cash_ms:number;
  total_cash_hsd:number;
 
  total_credit_other:number;
  total_credit_ms:number;
  total_credit_hsd:number;
  
  ms_hsd_total:number;
  credit_total:number;
  hsdltr_total:number=0;
  msltr_total:number=0;
  card_total:number;
  wallet_total:number;
   other_sale_total:number;
   bank_total:number;
   dc_total:number;
   sale_total:number;
   cash_sale_total:number;
   credit_sale_total:number;
   receipt_total:number;
   expenses_total:number;
   row1:number;
   mshsd_cash_total:number;
   mshsd_credit_total:number;
    ms_hsd_sale:number;
  ms_hsd_cash_sale:number;
  ms_hsd_credit_sale:number;
  ms_hsd_sale_total:number;
  
   setDob:string;
   totalamount:number;
   authData:any={};
   petro_id:number;
   pump_name:string;
   pump_address:string;
   shift_no:number;
    receiptData:any=[];
 expensesData:any=[];
   shift_detail:any[]=[];
   row_total_array:any={};
   config:any;
  amount_data:number;
   ledger_is_debit:number;
   credit:any[]=[];
   dc:any[]=[];
   bank:any[]=[];
   mshsddetails:any[]=[];

   receipt:any[]=[];
   othersale:any[]=[];
   DailyShift:number;
   shiftsummary:any[]=[];
   shiftsummarydtl:any[]=[];
   shiftsummarycard:any[]=[];
   reportData: any[] = [];
   dipReading: any[] = [];
   reportData1: any[] = [];
   totalCardSaleDtl: any[] = [];
   totalWalletSaleDtl: any[] = [];
   serachShiftReportData:any={};	
   merged:any[]=[];
   merged1:string;
   selectedPeople2 = [];

  constructor(private reportService: ReportsService, private session:SessionStorageService) { }
  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		//locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
   
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

	searchShiftReportForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		shift_no : new FormControl(' ',Validators.required),
    petro_id: new FormControl(' ',Validators.required),
		tmp_ledger_id : new FormControl(' ')
		
  });	


  ngOnInit() {
    this.authData = this.session.get('authData');
    this.petro_id = this.authData.petro_id;
    this.serachShiftReportData.from_date=new Date();
    this.pump_name=this.authData.petro_name;	
	 	this.pump_address=this.authData.petro_address;
     this.DailyShift=1;

    //  this.petro_id = this.DashData.petro_id;
    //  this.serachShiftReportData.from_date=new Date();
    //  this.pump_name=this.DashData.petro_name;	
	 	//  this.pump_address=this.DashData.petro_address;
   
  }

  resetSearchShiftReport(){
	  
    this.serachShiftReportData.from_date=new Date();
  this.serachShiftReportData.shift_no='1';
  this.reportData=[];
  this.credit=[];
  this.dc=[];
  this.bank=[];
  this.othersale=[]; 
  this.shiftsummary=[];
  this.shiftsummarydtl=[];
  this.shiftsummarycard=[];
  this.dipReading=[];
  this.shift_detail=[];
  this.mshsddetails=[];

  this.totalCardSaleDtl=[];
  this.totalWalletSaleDtl=[];



  this.receiptData=[];
  this.expensesData=[];
  this.hsdltr_total=0.00;
  this.msltr_total=0.00;
  this.ms_hsd_total=0.00;
  this.credit_total=0.00;
  this.card_total=0.00;
  this.wallet_total=0.00;
  this.other_sale_total=0.00;
  this.bank_total=0.00;
  this.dc_total=0.00;
  this.sale_total=0.00;
  this.cash_sale_total=0.00;
  this.credit_sale_total=0.00;
  this.receipt_total=0.00;
  this.expenses_total=0.00;
  this.mshsd_cash_total=0.00;
  this.mshsd_credit_total=0.00;
  this.total_cash_other=0.00;
  this.total_cash_bal=0.00;
  this.total_credit_other=0.00;
  this.ms_hsd_sale=0;
  this.ms_hsd_cash_sale=0;
  this.ms_hsd_credit_sale=0;
}

hsd_sale:number;
ms_sale:number;
  searchShiftReport(){

   // this.pump_name=this.serachShiftReportData.petro_id.label;
      
    var datePipe =  new DatePipe('en-US');
    this.setDob = datePipe.transform(this.serachShiftReportData.from_date, 'yyyy-MM-dd');
    this.serachShiftReportData.shift_no='1';
    //this.petro_id=this.serachShiftReportData.petro_id.value;
    //console.log(this.setDob);
    this.reportService.getShiftReportData(this.serachShiftReportData.shift_no,this.setDob,this.petro_id)
   
  .subscribe(purchaseListData => {
   
   if(purchaseListData.status==200)
    {
   this.reportData=purchaseListData.data;
    this.othersale=purchaseListData.otherSale;
    this.credit=purchaseListData.creditSale;
    this.dc=purchaseListData.markdc;
    this.receiptData=purchaseListData.receiptData;
    this.expensesData=purchaseListData.expenseData;
	this.bank=purchaseListData.bankDeposit;
	this.mshsddetails=purchaseListData.mshsdDetails;
	this.shiftsummary=purchaseListData.shiftSummary;
  
if (this.shiftsummary['card_wallet'])
{
  this.shiftsummarycard=this.shiftsummary['card_wallet'];
}
else{
  this.shiftsummarycard=[];
}


  if (this.shiftsummary[0]){
  this.shiftsummarydtl=this.shiftsummary[0];
  this.shiftsummarydtl=this.shiftsummarydtl[0];
  }
  else{
    this.shiftsummarydtl=[];
  }
	this.dipReading=purchaseListData.tank_wise_dip_reading;
  this.totalCardSaleDtl=purchaseListData.totalCardSaleDtl;
  this.totalWalletSaleDtl=purchaseListData.totalWalletSaleDtl;
  
	this.ms_hsd_sale_total=0;
	this.hsd_sale=0;
	this.ms_sale=0;
	if(purchaseListData.mshsdDetails[0])
	{
		if(purchaseListData.mshsdDetails[0].sale_amount){
			this.hsd_sale=purchaseListData.mshsdDetails[0].sale_amount;
		}
		if(purchaseListData.mshsdDetails[1].sale_amount){
			this.ms_sale=purchaseListData.mshsdDetails[1].sale_amount;
		}
		this.ms_hsd_sale_total=Number(this.ms_sale)+Number(this.hsd_sale);
    //this.ms_hsd_sale_total.toFixed(2);
	}
	
	
 
}else if(purchaseListData.status==403)
{
  // this.session.remove('DashData');
  //  this.router.navigate(['']);
} 
else{
      this.merged=[];
	    this.ms_hsd_total=0.00;
  this.credit_total=0.00;
  this.card_total=0.00;
  this.wallet_total=0.00;
  this.other_sale_total=0.00;
  this.bank_total=0.00;
  this.dc_total=0.00;
  this.sale_total=0.00;
  this.cash_sale_total=0.00;
  this.credit_sale_total=0.00;
  this.receipt_total=0.00;
  this.expenses_total=0.00;
  this.mshsd_cash_total=0.00;
  this.mshsd_credit_total=0.00;
  this.total_cash_other=0.00;
  this.total_cash_bal=0.00;
  this.total_credit_other=0.00;
  this.ms_hsd_sale=0;
  this.ms_hsd_cash_sale=0;
  this.ms_hsd_credit_sale=0;
    }
  });
  
}

  getTotal(amount,i){
   
    if(i==0)   
    {
        this.ms_hsd_total=0;
        
       // console.log(this.row_total);
    }
    this.ms_hsd_total=Number(this.ms_hsd_total)+Number(amount);
    this.getBalCash();
  }

  getcredit(hsdltr,msltr,i){

    if(i==0)   
    {
        this.hsdltr_total=0;
        this.msltr_total=0;
        //console.log(this.row_total1);
        
    }
    
    this.hsdltr_total=Number(this.hsdltr_total)+Number(hsdltr);
 
    this.msltr_total=Number(this.msltr_total)+Number(msltr );
  }

  getcardSale(total_amount,i){

    if(i==0)   
    {
        this.card_total=0;
        //console.log(this.row_total1);
        
    }
    this.card_total=Number(this.card_total)+Number(total_amount);

    this.getBalCash();
  }

  getwalletSale(total_amount,i){

    if(i==0)   
    {
        this.wallet_total=0;
        //console.log(this.row_total1);
        
    }
    this.wallet_total=Number(this.wallet_total)+Number(total_amount);
    this.getBalCash();
  }
  getothersale(total_amount,i){
   
    if(i==0)   
    {
        this.other_sale_total=0;
        //console.log(this.row_total2);
    }
    this.other_sale_total=Number(this.other_sale_total)+Number(total_amount);
    this.getBalCash();
  }

  getbank(total_amount,i){
   
    if(i==0)   
    {
        this.bank_total=0;
        //console.log(this.row_total2);
    }
    this.bank_total=Number(this.bank_total)+Number(total_amount);
  }

  getdc(total_amount,i){
   
    if(i==0)   
    {
        this.dc_total=0;
        //console.log(this.row_total2);
    }
    this.dc_total=Number(this.dc_total)+Number(total_amount);
  }

  getreceipt(total_amount,i){
   
    if(i==0)   
    {
        this.receipt_total=0;
        //console.log(this.row_total2);
    }
    this.receipt_total=Number(this.receipt_total)+Number(total_amount);
  }

  getexpenses(total_amount,i){
   
    if(i==0)   
    {
        this.expenses_total=0;
        //console.log(this.row_total2);
    }
    this.expenses_total=Number(this.expenses_total)+Number(total_amount);
  }
 
  getMsHsdTotal(sale_amt,cash_sale_amt,credit_sale_amt,i){
   
    if(i==0)   
    {
        this.ms_hsd_sale=0;
        this.ms_hsd_cash_sale=0;
        this.ms_hsd_credit_sale=0;
      
    }
    this.ms_hsd_sale=Number(this.ms_hsd_sale)+Number(sale_amt);
    this.ms_hsd_cash_sale=Number(this.ms_hsd_cash_sale)+Number(cash_sale_amt);
    this.ms_hsd_credit_sale=Number(this.ms_hsd_credit_sale)+Number(credit_sale_amt);
  }

  getBalCash(){
    
    this.total_cash_bal=0.00;
    this.total_cash_bal= Number((Number(this.ms_hsd_total||0.00)+Number(this.other_sale_total||0.00)-Number(this.credit_total||0.00) -Number(this.card_total||0.00)-Number(this.wallet_total||0.00)).toFixed(2));
    
  }
  printReport() {
		var printContents = document.getElementById("exportTable").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}


}
