import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { constDefault } from '../const_default';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

interface serviceDataShiftReport{
  status:any,
  data:any,
  amount:number,
  creditSale:any,
  bankDeposit:any,
  bozerRefillData:any,
  mshsdDetails:any,
  otherSale:any,
  shiftSummary:any,
  receiptExpenseData:any,
  totalOtherSaleReport:any,
  receiptData:any,
  expenseData:any,
  tank_wise_dip_reading:any,
  totalCardSaleDtl:any;
  totalWalletSaleDtl:any;
  cardreceiptData:any;
 
}
interface serviceDataDSMReport{
	status:any,
    data:any,
    shiftSummary:any,
	 amount:number,
}
interface allcsr{
  status:any,
  data:any,
  ledger:any,
  salesdetails:any,
  
}
interface selectedcsr{
  status:any,
  data:any,
}
interface getname{
  status:any,
  data:any,
}
interface statement{
  status:any,
  data:any,
  opening:any,
}
@Injectable({
  providedIn: 'root'
})
export class SaleServiceService {
  headersdata:any={};
  authValue:any={};
  user_id:string;
  login_token:string;
  login_year:string;

  
  constructor(private http: HttpClient, private session:SessionStorageService, private local_storage:LocalStorageService) { 

  this.authValue = this.local_storage.get('localAuthData');
	if(this.authValue)
	{
	//console.log(this.authValue);
	this.user_id=this.authValue.user_id;
	this.login_token=this.authValue.token;
	this.login_year=this.authValue.financial_year_id;
	 //console.log(this.login_year);
	let headers: HttpHeaders = new HttpHeaders();
	headers = headers.append('Accept', 'application/json');
	headers = headers.append('user', this.user_id);
	headers = headers.append('year', this.login_year);
	this.headersdata = headers.append('token', this.login_token);
	}
}
  getShiftReportData(shift_no:number,from_date:string,petro_id:number){
    //console.log("dfdf");
    //console.log(shift_no+'/'+from_date+'/'+petro_id);
    return this.http.get<serviceDataShiftReport>(constDefault.API_URL+'/reports/shiftWiseReport/'+shift_no+'/'+from_date+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata});
    
  }

  getallCSRData(petro_id:number,serachAllCSRData:any){
    //console.log("dfdf");
    //console.log(from_date+'/'+petro_id);
    return this.http.post<allcsr>(constDefault.API_URL+'/reports/allCustomerCsr/'+petro_id+'/'+this.login_year,serachAllCSRData,{headers:this.headersdata});
    
  }

  getDailySaleData(petro_id:number,serachAllCSRData:any){
    //console.log("dfdf");
    //console.log(from_date+'/'+petro_id);
    return this.http.post<allcsr>(constDefault.API_URL+'/reports/dailycreditsale/'+petro_id+'/'+this.login_year,serachAllCSRData,{headers:this.headersdata});
    
  }
  
  getselectedCSRData(petro_id:number,serachSeletcedCSRData:any){
    //console.log("dfdf");
    //console.log(shift_no+'/'+from_date+'/'+petro_id);
    return this.http.post<selectedcsr>(constDefault.API_URL+'/reports/dateWiseCrSaleReportByCustomer/'+petro_id+'/'+this.login_year,serachSeletcedCSRData,{headers:this.headersdata});
    
  }

  /*
		Amit Shau 
		Get item by name for auto sugest
		12/04/2019  
	*/
	getcustomerByName(name:string,petro_id:number){
		return this.http.get<getname>(constDefault.API_URL+"/commons/getLedgerByName/"+name+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}
	 /*
		Amit Shau 
		Customer Statement Report
		26/04/2019  
	*/
	getCustomerStatementData(petro_id:number,filterData:any){
		return this.http.post<statement>(constDefault.API_URL+"/reports/customerStatement/"+petro_id+'/'+this.login_year,filterData, {headers:this.headersdata})
	}
	 /*
		Amit Shau 
		Sale sgt report
		10/05/2019  
	*/
	getSaleGstReportData(petro_id:number,filterData:any){
		return this.http.post<statement>(constDefault.API_URL+"/reports/gstOtherSaleReport/"+petro_id+'/'+this.login_year,filterData, {headers:this.headersdata})
	}

 /*
		Amit Shau 
		Miscellaneous Customer Statement Report
		05/06/2019  
	*/
	getMissCustomerStatementData(petro_id:number,filterData:any){
		return this.http.post<statement>(constDefault.API_URL+"/reports/misclCustomerStatement/"+petro_id+'/'+this.login_year,filterData, {headers:this.headersdata})
	}
	
	  getDSMReportData(shift_no:number,from_date:string,petro_id:number,ledger_id:number){
    //console.log("dfdf");
    //console.log(shift_no+'/'+from_date+'/'+petro_id);
    return this.http.get<serviceDataDSMReport>(constDefault.API_URL+'/reports/dsmReportShiftWise/'+shift_no+'/'+from_date+'/'+petro_id+'/'+this.login_year+'/'+ledger_id,{headers:this.headersdata});
    
  }
  
}
