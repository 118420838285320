import { Component, OnInit  ,Input,ViewChild, ChangeDetectorRef} from '@angular/core';
import { AccountReportService } from '../../../admin-services/account-report.service';
import {AccountService} from '../../../admin-services/account.service';
import { CommonsService } from '../../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder,FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
import { DatePipe } from '@angular/common';
// Child Component
import { EditReceiptVoucherLedgerComponent } from './edit-receipt-voucher-ledger/edit-receipt-voucher-ledger.component';
import { EditContraVoucherLedgerComponent } from './edit-contra-voucher-ledger/edit-contra-voucher-ledger.component';
import { EditJournalVoucherLedgerComponent } from './edit-journal-voucher-ledger/edit-journal-voucher-ledger.component';

// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';

// Custoem Function
import { OnCreatedDirective } from '../../../shared/on-created.directive';

// Export
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

declare var $: any;

@Component({
  selector: 'app-ledger-report',
  templateUrl: './ledger-report.component.html',
  styleUrls: ['./ledger-report.component.css']
})
export class LedgerReportComponent implements OnInit {
	
	/*exportAsConfig: ExportAsConfig = {
    type: 'xlsx', // the type you want to download
    elementId: 'exportTable', // the id of html/table element
  }*/
  
  @ViewChild(EditReceiptVoucherLedgerComponent) edit_receipt_voucher_child;
  @ViewChild(EditContraVoucherLedgerComponent) edit_contra_voucher_child;
  @ViewChild(EditJournalVoucherLedgerComponent) edit_journal_voucher_child;
  constructor(private accountReportService: AccountReportService, private session:SessionStorageService,private commonsServices:CommonsService,private exportAsService:ExportAsService, private accountService:AccountService , private formBuilder: FormBuilder) { }
	exportAsConfig: ExportAsConfig;
	  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
	
	authData:any={};
	serachLedgerReportData:any={};	
	ledgerAutoSugestList: any[] = [];
	reportData: any[] = [];
	petro_id:number;
	success_message:string;
	error_message:string;
	ledger_is_debit:number;
	ledger_is_credit:number;
	ledger_opening:number;
	ledger_name:string;
	pump_name:number;
	pump_address:string;
	pump_mobile_no:number;
	row_total:number;
	gt_dr:number;
	gt_cr:number;
	closing_balance:number;
	load_more_status:boolean;
	show_loading_image:boolean;
	credit_ledger:number;
	debit_ledger:number;
	cr_Edit_amount:number;
	dr_Edit_amount:number;
	row_total_array:any={};
	trailSessionData:any={};
	config:any;
	voucher_pyment_ledger_type:number;
	cashBankLedgerList: any[] = [];
	ledgerListData: any[] = [];
	myArr: any[] = [];
	pipe:any;
	voucher_ledger_type:any;
   status_check:number;
   
	searchLedgerReportForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		tmp_ledger_id : new FormControl(' ',Validators.required)
		
	});		
	
  ngOnInit() {
	  this.status_check=1;
	  this.gt_dr=0;
	  this.gt_cr=0;
	  this.closing_balance=0;
	  this.load_more_status=true;
	  this.show_loading_image=false;
	  this.ledger_is_debit=constDefault.DEBIT_LEDGER;
	  this.ledger_is_credit=constDefault.CREDIT_LEDGER;
	  this.voucher_pyment_ledger_type = constDefault.CASH_LEDGER;
	  this.authData = this.session.get('authData');
	 	this.petro_id = this.authData.petro_id;
	 	//console.log(this.authData);
	 	this.pump_name=this.authData.petro_name;
	 	this.pump_mobile_no=this.authData.petro_phone_no;
	 	this.pump_address=this.authData.petro_address;
	  this.serachLedgerReportData.from_date=new Date(Date.now());
	  this.serachLedgerReportData.to_date=new Date(Date.now());
	  
	  // Edit Voucher
	  this.credit_ledger = constDefault.CREDIT_LEDGER;
		this.debit_ledger = constDefault.DEBIT_LEDGER;
	  
	  this.trailSessionData = this.session.get('trialVoucherSession');
	  console.log(this.trailSessionData);
	  if(this.trailSessionData)
	  {
	  //this.serachLedgerReportData.tmp_ledger_id=[this.trailSessionData.ledger_id,this.trailSessionData.ledger_name];
	  this.serachLedgerReportData.ledger_id=this.trailSessionData.ledger_id;
	  this.ledger_name=this.trailSessionData.ledger_name;
	  this.serachLedgerReportData.from_date=this.trailSessionData.from_date;
	  this.serachLedgerReportData.to_date=this.trailSessionData.to_date;
	  this.serachLedgerReportData.tmp_ledger_id = {'value':this.serachLedgerReportData.ledger_id,'label': this.ledger_name};
	  this.getLedgerReportData();
	  }
	  
	  // Get Ledgr list foredit vouchers
	  this.accountService.getCashAndBankLedgerList(this.petro_id).subscribe(ledgerdata => {
			this.cashBankLedgerList = Object.entries(ledgerdata.data).map(([value, label]) => ({
				value,
				label
			}));
    	})
    
		// Ledger List
		this.accountService.getLedgerListForVoucher(this.petro_id).subscribe(ledgerdata => {
			this.ledgerListData = Object.entries(ledgerdata.data).map(([value, label]) => ({
				value,
				label
			}));
		});
		
		
	
		
		
		
  }
  //after edit submit
  public onButtonClick(event:any):void{
	  this.getLedgerReportData();
  }
  
  
  
  public filterItemList(value:any):void {	  
	   this.commonsServices.getLedgerByName(value, this.petro_id)
		.subscribe(alldata => {
		this.ledgerAutoSugestList=Object.entries(alldata.data).map(([value, label]) => ({value,label}));
		});
  }
	
  //Get Report Data
	getLedgerReportData() {
			this.show_loading_image=true;
			this.load_more_status=true;
			this.gt_dr=0;
			this.gt_cr=0;
			if(this.serachLedgerReportData.tmp_ledger_id)
			{
			this.serachLedgerReportData.ledger_id=this.serachLedgerReportData.tmp_ledger_id.value;
			}
	//console.log('Data : '+this.serachLedgerReportData);
	this.pipe = new DatePipe('en-US'); 
	  this.serachLedgerReportData.from_date= this.pipe.transform(this.serachLedgerReportData.from_date, 'yyyy-MM-dd');		
	  this.serachLedgerReportData.to_date= this.pipe.transform(this.serachLedgerReportData.to_date, 'yyyy-MM-dd');
	 this.accountReportService.getLedgerReportData(this.petro_id,this.serachLedgerReportData)
	  .subscribe(purchaseListData => {
	  // console.log(purchaseListData);
	   if(purchaseListData.status==200)
		  {
			this.reportData=purchaseListData.data;
		
			this.ledger_opening=purchaseListData.actual_opening;
		  }else{
			this.reportData=[];
			this.show_loading_image=false;
			this.ledger_opening=purchaseListData.actual_opening;
			this.closing_balance=purchaseListData.actual_opening;
		  }
		   this.load_more_status=false;
			    this.show_loading_image=false;
	   
	  });
	}
	// Get LoadMore Ledger Data
	getLedgerReportLoadMoreData(last_id:number) {
		
		if(this.serachLedgerReportData.tmp_ledger_id)
			{
			this.serachLedgerReportData.ledger_id=this.serachLedgerReportData.tmp_ledger_id.value;
			}
	
	 this.accountReportService.getLedgerReportLoadMoreData(this.petro_id,last_id,this.serachLedgerReportData)
	  .subscribe(loadmoreData => {
		  
		  if(loadmoreData.status==200)
		  {
			 // console.log(this.reportDataDummy);
			 // console.log(loadmoreData.data);
			 this.reportData=this.reportData.concat(loadmoreData.data);
			  //sconsole.log(this.reportData);
			  this.load_more_status=true;
			 
		  }else{
				// Order Desc
				
				//console.log(this.reportData);
			  
			    this.load_more_status=false;
			    this.show_loading_image=false;
				
		  }
	   //console.log(purchaseListData);
	   //this.reportData=purchaseListData.data;
	   //this.ledger_opening=purchaseListData.opening;
	  });
	}
	
  searchLedgerReport(){

	  this.ledger_name=this.serachLedgerReportData.tmp_ledger_id.label;
	  this.getLedgerReportData();
  }
  resetSearchLedgerReport(){
	  this.serachLedgerReportData.tmp_ledger_id='';
	    this.serachLedgerReportData.from_date=new Date(Date.now());
	  this.serachLedgerReportData.to_date=new Date(Date.now());
	  this.reportData=[];
	  this.closing_balance=0;
	  this.ledger_opening=0;
	  this.gt_cr=0;
	  this.gt_dr=0;
	
  }
  
  /*
  
  */
  getTotal(amount,type,opeing,i,last,last_id,status_check){
//console.log("tot",status_check);

			if(status_check==0)
			{
				if(i==0)
					{
						  this.row_total=0;
					}else{
						 this.row_total=this.row_total_array[i-1];
					}
					  if(type==this.ledger_is_debit)	 
					  {
					
						this.gt_dr=Number(this.gt_dr)+Number(amount);
					
						 this.row_total_array[i]= Number(this.row_total)+Number(amount);  
					  }else{
								this.gt_cr=Number(this.gt_cr)+Number(amount);
								 //this.row_total= Number(this.row_total)-Number(amount);  
								this.row_total_array[i]= Number(this.row_total)-Number(amount);  
					  }
					
					   if(last==true )
					 {
						this.closing_balance=this.row_total_array[i];
						
						
						//this.getLedgerReportLoadMoreData(last_id);
						
						
					 } 
					
					
			}else{
						 if(i==0)
							{
								  this.row_total=opeing;
							}else{
								 this.row_total=this.row_total_array[i-1];
							}
							
							  if(type==this.ledger_is_debit)	 
							  {
							
								this.gt_dr=Number(this.gt_dr)+Number(amount);
								 //this.row_total= Number(this.row_total)+Number(amount);  
								 this.row_total_array[i]= Number(this.row_total)+Number(amount);  
							  }else{
										this.gt_cr=Number(this.gt_cr)+Number(amount);
										 //this.row_total= Number(this.row_total)-Number(amount);  
										this.row_total_array[i]= Number(this.row_total)-Number(amount);  
							  }
							   if(last==true )
							 {
								this.closing_balance=this.row_total_array[i];
								//this.getLedgerReportLoadMoreData(last_id);
								if(this.ledger_opening >0 )
								{
									this.gt_dr=Math.abs(this.gt_dr)+Math.abs(opeing);
								}else if(this.ledger_opening <0){
									this.gt_cr=Math.abs(this.gt_cr)+Math.abs(opeing);
								}
							 } 
											
									
					
			}
				
	
  }

  addedOpening(event,ledger_opening)
{
	if(event.target.checked== true)
   { 
      this.status_check=1;
	   this.getLedgerReportData();
   }else{
	      this.status_check=0;
	    this.row_total=0;
		   this.getLedgerReportData();
		    	
   }
  
}
 
  
  // Short by date
  /*test(){
	  this.reportData.sort((val1, val2)=> {return Number(new Date(val1.Voucher.date)) - Number(new Date(val2.Voucher.date))});
	 this.reportData.forEach((row, i) => {
	   if(i==0)
		{
			  this.row_total=this.ledger_opening;
		}else{
			 this.row_total=this.row_total_array[i-1];
		}
	
	  if(row.VoucherDetail.dr_cr==this.ledger_is_debit)	 
	  {
	
		this.gt_dr=Number(this.gt_dr)+Number(row.VoucherDetail.amount);
		 //this.row_total= Number(this.row_total)+Number(amount);  
		 this.row_total_array[i]= Number(this.row_total)+Number(row.VoucherDetail.amount);  
	  }else{
				this.gt_cr=Number(this.gt_cr)+Number(row.VoucherDetail.amount);
		  		 //this.row_total= Number(this.row_total)-Number(amount);  
		  		this.row_total_array[i]= Number(this.row_total)-Number(row.VoucherDetail.amount);  
	  }
	 });
  }*/
  
  exportTable(event) {
	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'Ledger Report ');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }
	/* Edit Voucher 
	Amit Sahu
	11.02.2019	
	*/
	editVoucher(voucher_id:number,voucher_type:number,shift:number){
		if(!shift)
		{
			if(voucher_type==constDefault.VOCHER_TYPE_PAYMENT){
				this.setPayentVoucherData(voucher_id);
			}
			else if(voucher_type==constDefault.VOCHER_TYPE_CONTRA){
				this.edit_contra_voucher_child.getEditVoucher(voucher_id);
			}
			else if(voucher_type==constDefault.VOCHER_TYPE_RECEIPT){
				this.edit_receipt_voucher_child.getEditVoucher(voucher_id);
			}
			else if(voucher_type==constDefault.VOCHER_TYPE_JOURNAL){
				this.edit_journal_voucher_child.getEditVoucher(voucher_id);
			}else{
				alert("You can't edit this voucher.");
			}
		}else{
				alert("You can't edit this voucher.");
			}
		
	}
	/*========================Edit Payment Voucher Modal===========================*/
	/*
	Amit Sahu
	1102.2019
	Get Paymnt Voucher Data
	*/
	
	editPayentVoucher: any = {};
	

	editPaymentVoucherForm = this.formBuilder.group({
		vid: new FormControl('', Validators.required),
		date: new FormControl('', Validators.required),
		ledger_id: new FormControl('', Validators.required),
		ledger_name: new FormControl('', Validators.required),
		cheque_no: new FormControl(''),
		subtype: new FormControl(''),
		cheque_date: new FormControl(''),
		amount: new FormControl('', Validators.required),
		narration: new FormControl('', Validators.required),
		editPymentVoucherList: this.formBuilder.array([])
	});
	editPymentVoucherList:FormArray;
	
	
	// Show check info options if selected ledget value is not cash for Edit Payment Voucher
	show_edit_check_pyment_option(value: any) {
		this.editPayentVoucher.ledger_id = value.value;
		console.log(this.editPayentVoucher.ledger_id);
		if (this.editPayentVoucher.ledger_id.value != this.voucher_pyment_ledger_type) {
			$("#edit_cheque_info").show();
		}
	}
	
	// Get Voucher List Fields for (Edit) Form Array
	get editPaymentVoucherFormData() {
		return this.editPaymentVoucherForm.get('editPymentVoucherList')
	}
	// Function to add a row in Edit Voucher Table (Edit Modal)
	addPaymntEditItem() {
		this.editPymentVoucherList = this.editPaymentVoucherForm.get('editPymentVoucherList') as FormArray;
		this.editPymentVoucherList.push(this.editPaymenVoucherItem());
	}
	
		// Function to Remove One Row in Edit Voucher Table (Modal)
	removePaymentEditItem(i) {
		if (confirm("Do you really want to Delete this Entry?")) {
			if (this.editPymentVoucherList.length > 1) {
				this.editPymentVoucherList.removeAt(i);
				this.getEditPaymetTotal(i)
			} else if (this.editPymentVoucherList.length < 2) {
				//alert();
			}
		}
	}
	
	// Function to add Multiple voucher Entries
	editPaymenVoucherItem(): FormGroup {
		return this.formBuilder.group({
			dr_cr: [constDefault.DEBIT_LEDGER, Validators.required],
			ledger_id: ['', Validators.required],
			ledger_name: ['', Validators.required],
			amount: ['', Validators.required]
		})
	}
	
	cr_payment_edit_amount: number;
	dr_payment_edit_amount: number;

	getEditPaymetTotal(index) {
		this.editPymentVoucherList = this.editPaymentVoucherForm.get('editPymentVoucherList') as FormArray;
		//console.log(index);
		this.cr_payment_edit_amount = 0;
		this.dr_payment_edit_amount = 0;
		this.editPymentVoucherList.value.forEach((data, index) => {

			if (this.editPymentVoucherList.value[index]['dr_cr'] == constDefault.CREDIT_LEDGER) {
				this.cr_payment_edit_amount = this.cr_payment_edit_amount + Number(this.editPymentVoucherList.value[index]['amount']);
			}

			if (this.editPymentVoucherList.value[index]['dr_cr'] == constDefault.DEBIT_LEDGER) {
				this.dr_payment_edit_amount = this.dr_payment_edit_amount + Number(this.editPymentVoucherList.value[index]['amount']);
			}
		})
		//console.log(this.dr_Edit_amount);
	}
	
	// Get data to Edit Payment voucher data by id
	edit_index: number;
	setPayentVoucherData(id) {
		this.editPaymentVoucherForm.setControl('editPymentVoucherList', this.formBuilder.array([]));
		$('#editPaymentModal').modal('show');

		this.accountService.getPaymentVoucherDataById(id).subscribe(data => {
			//console.log(data);
			this.editPayentVoucher.date = data.data.Voucher.date;
			this.editPayentVoucher.vid = data.data.Voucher.id;
			if (data.data.Voucher.cheque_no == null) {
				//alert(data.data.Voucher.cheque_no);
				$('#edit_cheque_info').hide();
			} else {
				this.editPayentVoucher.cheque_no = data.data.Voucher.cheque_no;
				this.editPayentVoucher.cheque_date = data.data.Voucher.cheque_date;
			}
			if (data.data.Voucher.subtype == 1) {
				this.editPayentVoucher.subtype=true;
			} else {
				this.editPayentVoucher.subtype=false;
			}
			this.editPayentVoucher.amount = data.data.Voucher.amount;
			this.editPayentVoucher.ledger_id = data.data.Ledger.id;
			this.editPayentVoucher.ledger_name = data.data.Ledger.name;
			this.editPayentVoucher.narration = data.data.Voucher.narration;

			for (let voucher_entry of data.data.VoucherDetail) {
				this.edit_index = 1;
				if (data.data.Voucher.ledger_id == voucher_entry.ledger_id) {

				} else {
					this.editPymentVoucherList = this.editPaymentVoucherForm.get('editPymentVoucherList') as FormArray;
					this.editPymentVoucherList.push(
						this.formBuilder.group({
							dr_cr: [voucher_entry.dr_cr, Validators.required],
							ledger_id: [voucher_entry.ledger_id, Validators.required],
							ledger_name: [voucher_entry.Ledger.name, ''],
							amount: [voucher_entry.amount, '']
						})
					);

				}
			}
			this.getEditPaymetTotal(this.edit_index)
		});
	}
	
		// Edit Voucher data and Save Data 
		total_payment_credit:number;
		
	editPaymentVoucherData(edit_voucher) {
		edit_voucher.submitted=false;
		// If ledger type is cash then cheque info should be empty
		if(this.editPayentVoucher.ledger_id==this.voucher_pyment_ledger_type){
			this.editPayentVoucher.cheque_no = "";
			this.editPayentVoucher.cheque_date = "";
		}
		this.editPayentVoucher.voucherDetails = this.editPymentVoucherList.value;
		this.total_payment_credit = Number(this.editPayentVoucher.amount) + Number(this.cr_payment_edit_amount);
		if (this.dr_payment_edit_amount == this.total_payment_credit) {

			this.accountService.editPaymentVoucher(this.editPayentVoucher).subscribe(data => {
				if (data.status == 200) {
					this.success_message = 'Voucher has been Updated';
					$('#editPaymentModal').modal('hide');

					this.editPaymentVoucherForm.reset();
				} else {
					this.error_message = 'Error';
				}
			});
		} else {
			this.error_message = 'Credit amount and Total Amount does not match!';
		}
	}
	// Show check info options if selected ledget value is not cash for Edit Payment Voucher
	show_edit_check_option(value: any) {
		if (value.value != this.voucher_ledger_type) {
			$("#edit_cheque_info").show();
		} else {
			
			$("#edit_cheque_info").hide();
		}
		
	}
	  // Print ledger search data
	printComponent() {
	
		var printContents = document.getElementById("ledgerPrint").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
}
