import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-web-storage';
@Component({
  selector: 'app-deliver-status-note',
  templateUrl: './deliver-status-note.component.html',
  styleUrls: ['./deliver-status-note.component.css']
})
export class DeliverStatusNoteComponent implements OnInit {

  constructor(private local_storage: LocalStorageService) { }
  emaildata:any={};
  email : string;
  ngOnInit() {
    this.emaildata = this.local_storage.get('emaildata');
    this.email=this.emaildata.email;
  }

 
}
