import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsService } from '../../../admin-services/reports.service';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { CommonsService } from '../../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder,FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
import { DatePipe } from '@angular/common';
// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';

declare var $: any;
@Component({
  selector: 'app-item-wise-purchase-sale',
  templateUrl: './item-wise-purchase-sale.component.html',
  styleUrls: ['./item-wise-purchase-sale.component.css']
})
export class ItemWisePurchaseSaleComponent implements OnInit {

 constructor(private reportsService: ReportsService,private session:SessionStorageService,private router:Router,private commonsServices: CommonsService, private exportAsService:ExportAsService) { }
 exportAsConfig: ExportAsConfig;
	  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  
  authData:any={};
  serachReportData:any={};	
  reportData: any[] = [];
  config:any;
  itemAutoSugestList: any[] = [];
  petro_id:number;
  success_message:string;
  error_message:string;
  pump_name:number;
  pump_address:string;
  pump_mobile_no:number;
  pipe:any;
  search_type:number;
  sale_purchase_type:number;
  item_name:string;
  credit_sale:number;
  cash_sale:number;
  show_loading_image:boolean;
  
  searchReportForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		tmp_item_id : new FormControl(' ',Validators.required),
		type : new FormControl(' ',Validators.required),
		sale_type : new FormControl(' ')
	});
	
  ngOnInit() {
	   this.authData = this.session.get('authData');
	 	this.petro_id = this.authData.petro_id;
	 	this.credit_sale = constDefault.CREDIT_SALE;
	 	this.cash_sale = constDefault.CASH_SALE;
	 	//console.log(this.authData);
	 	this.pump_name=this.authData.petro_name;
	 	this.pump_mobile_no=this.authData.petro_phone_no;
	 	this.pump_address=this.authData.petro_address;
		this.serachReportData.from_date=new Date(Date.now());
		this.serachReportData.to_date=new Date(Date.now());
		this.total_qty=0;
		this.total_amount=0;
  }
  getReportData() {
	  this.pipe = new DatePipe('en-US'); 
	 this.sale_purchase_type=this.serachReportData.type;
	
	  this.serachReportData.from_date= this.pipe.transform(this.serachReportData.from_date, 'yyyy-MM-dd');		
	  this.serachReportData.to_date= this.pipe.transform(this.serachReportData.to_date, 'yyyy-MM-dd');		
		if(this.serachReportData.tmp_item_id)
		{
			this.serachReportData.item_id=this.serachReportData.tmp_item_id.value;
			 this.item_name=this.serachReportData.tmp_item_id.label;
		}
	 this.reportsService.getItemWiseSalePurchaseData(this.petro_id,this.serachReportData)
	  .subscribe(reportAllData => {
	  // console.log(purchaseListData);
	   if(reportAllData.status==200)
		  {
			 this.search_type=reportAllData.data.type;
			  if(this.search_type==1)
			  {
				this.reportData=reportAllData.data.SaleData;
			  }else if(this.search_type==2){
				  this.reportData=reportAllData.data.PurchaseData;
			  }else{
				  this.reportData=reportAllData.data.NozzleData;
			  }
			
		  }
		  else if(reportAllData.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.reportData=[];
			 this.total_qty=0;
          this.total_amount=0;
		  }
	   
	  });
	}
	
	filterItemList(value: any): void {
		this.commonsServices.getItemByNameOrType(value, this.petro_id,0)
		.subscribe(alldata => {
			this.itemAutoSugestList = Object.entries(alldata.data).map(([value, label]) => ({
				value,
				label
			}));
		});
	}
	total_qty:number;
	total_amount:number;
	 getTotal(amount,qty,i){
   
    if(i==0)   
    {
        this.total_qty=0;
        this.total_amount=0;
        
       // console.log(this.row_total);
    }
    this.total_qty=Number(this.total_qty)+Number(qty);
    this.total_amount=Number(this.total_amount)+Number(amount);
  }
  
  exportTable(event) {
	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'item_purchase_sale_report');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }
// Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
}
