import { Directive,Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[OnCreatedDirective]'
})
export class OnCreatedDirective {
  @Output() OnCreatedDirective: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}
  ngOnInit() {      
     this.OnCreatedDirective.emit('dummy'); 
  } 


}
