import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { CommonsService } from '../../admin-services/commons.service';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-vehicle-master',
  templateUrl: './vehicle-master.component.html',
  styleUrls: ['./vehicle-master.component.css']
})
export class VehicleMasterComponent implements OnInit {

  constructor(private masterService: MasterServicesService, private commonService: CommonsService, private router:Router,private session:SessionStorageService) { }  

  addVehicleData:any={};
  editVehicleData:any={};
  searchVehicleData:any={};
  authData:any={};
  data : any[] = [];
  name : string;
  success_message:string;
  error_message:string;
  index:number;
  currentPage:number;
  totalCat:number;
  petro_id:number;
	model:string;
	serachVehicleData:any={};
	CommodityDropdownList:any[]=[];
  ledgerDropdownList:any={};
  vehicleList:any[] = [];
  
  link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	
  succeAddVehicleData:{
    Vehicle:{
      id:any,
			veh_no:string,
			commodity:string,
		},
		Ledger:{
			name:string
		}
  }
  
  addVehicleForm = new FormGroup({
    ledger_id : new FormControl(' ',Validators.required),
    veh_no : new FormControl(' ',Validators.required),
    commodity: new FormControl(' ',Validators.required)
  })

  editVehicleForm = new FormGroup({
    ledger_id : new FormControl(' ',Validators.required),
    veh_no : new FormControl(' ',Validators.required),
    commodity : new FormControl(' ',Validators.required)
	})
	
	serachVehicleForm= new FormGroup({
		veh_no : new FormControl(' ')
    
	});

  ngOnInit() {
	  
	 // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
	
    this.authData = this.session.get('authData');
    this.petro_id=this.authData.petro_id;
    
    this.masterService.getledgerId(this.petro_id)
    .subscribe(ledgerlistdata =>{this.ledgerDropdownList=ledgerlistdata.data});
    //console.log(this.ledgerDropdownList);

		// Get commodity Dropdown list
		this.commonService.getCommodityLsit(this.petro_id)
    .subscribe(commoditylistdata =>{this.CommodityDropdownList=commoditylistdata.data});
    // Function to get Petrol Pump List
		this.pageWiseData(1);
  }

  // Add Vehicle 
  addVehicle(){
    // this.authData = this.session.get('authData');
		// this.addVehicleData.petro_id=this.authData.petro_id;
		
		this.masterService.addVehicleData(this.addVehicleData).subscribe(data=>{
			if(data.status==200)
			{
				this.success_message='Vehicle has been Added Successfully';
			$('#addVehicleModal').modal('hide');
			this.pageWiseData(1);
			this.addVehicleForm.reset(); 
			
		}else{
			this.error_message='Location could not be created';
		}
		});
  }

  // Get Edit Vehicle Data
  getVehicleData(id,index){
    this.index=index;
    this.masterService.getEditVehicleDataById(id).subscribe(data=>{

      this.editVehicleData.ledger_id=data.data.Ledger.id;	
      this.masterService.getledgerId(this.petro_id)
      .subscribe(ledgerlistdata =>{this.ledgerDropdownList=ledgerlistdata.data});

			this.editVehicleData.veh_no=data.data.Vehicle.veh_no;	
			this.editVehicleData.commodity=data.data.Vehicle.commodity;	
			this.commonService.getCommodityLsit(this.petro_id)
    	.subscribe(commoditylistdata =>{this.CommodityDropdownList=commoditylistdata.data});
  
    });
    $('#editVehicleModal').modal('show');
}

  // Edit Vehicle and submit data
  editVehicle(edit_form){
		edit_form.submitted=false;
    this.authData = this.session.get('authData');
		this.editVehicleData.petro_id=this.authData.petro_id;
		
		this.masterService.editVehicleData(this.editVehicleData).subscribe(data=>{
			if(data.status==200)
			{
				this.success_message='Vehicle has been Added Successfully';
			$('#editVehicleModal').modal('hide');	

			this.succeAddVehicleData={
				Vehicle:{
					id:data.data.id,
					veh_no:data.data.veh_no,
					commodity:data.data.commodity
				},
				Ledger:{
					name:data.data.cust_name
				}
			}
			this.vehicleList.unshift(this.succeAddVehicleData);
			this.addVehicleForm.reset(); 
			
		}else{
			this.error_message='Location could not be created';
		}
		});
  }

  deleteVehicleData(id,index)
	{
		if(confirm("Are you sure to delete this Location")) { 
			this.index=index;
			this.model='Vehicle'; 
			this.masterService.deleteDataByModel(id,this.model).subscribe(data=>{
				if(data.status==true)
				{
					this.vehicleList.splice(this.index,1);
					this.success_message="Location deleted sucessfully";	
				}else{
					this.error_message= "Location could not be deleted.";
				}
			});
		}
	}

  // Function to Search Petrol Pump list
	searchVehicle(){
		this.pageWiseData(1);
	} 
	// Reset Search Data
	resetSearchVehicle(){
		this.serachVehicleData={};
		this.pageWiseData(1);
	} 
	// Pagination 
	pageWiseData(pageno){
		this.currentPage=pageno;
		this.masterService.getVehicleListData(pageno,this.petro_id,this.serachVehicleData)
		.subscribe(alldata =>{
			this.vehicleList=alldata.data,
			this.totalCat=alldata.count
		});
	} 

}
