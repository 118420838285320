import { Component, OnInit } from '@angular/core';
import { PetrolPumpServicesService } from '../../super-admin-service/petrol-pump-services.service';
import { CommonsService } from '../../admin-services/commons.service';
import { uniqueEmailValidation } from '../../shared/unique-email.directive';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { LocalStorageService } from 'angular-web-storage';
import { Router} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-pump-registration',
  templateUrl: './pump-registration.component.html',
  styleUrls: ['./pump-registration.component.css']
})
export class PumpRegistrationComponent implements OnInit {

 constructor(private petrolPumpService: PetrolPumpServicesService, private router:Router,private commonsServices: CommonsService,private localService: LocalStorageService) { }
// variables for petrol pump lists
	addPetrolPump : any={};
	editPetrolPump : any={};
	data : any[] = [];
	countryDropdownList : any[] = [];
	ownerListData:any[]=[];
	dealerDropdownList:any[]=[];
	stateDropdownList : any[] = [];
	cityDropdownList : any[] = [];
	packageDropdownList : any[] = [];
	serachPetrolPump : any={};
	name : string;
	totalCat: number;
	petrolPumpList: any[] = [];	
	success_message : string;
	error_message : string;
add_loading:boolean;

	
	addPetrolPumpForm:FormGroup;
  ngOnInit() {
	 
	  this.addPetrolPumpForm = new FormGroup({
				// Compulsary Elements
				reg_no : new FormControl(' ',Validators.required),
				name : new FormControl(' ',Validators.required),
				owner_name : new FormControl(' ',Validators.required),
				gstn_no : new FormControl(' ',[Validators.required,Validators.maxLength(15),Validators.minLength(15)]),
				pan_card : new FormControl(' ',[Validators.maxLength(10),Validators.minLength(10)]),
				state_id : new FormControl(' ',Validators.required),
				city_id : new FormControl('',Validators.required),
				address : new FormControl(' ',Validators.required),
				mobile_no : new FormControl(' ',[Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern('^[0-9]*$')]),
				email : new FormControl(' ',[Validators.email,Validators.required],uniqueEmailValidation(this.commonsServices)),
				dealer_id: new FormControl(' ',Validators.required),
				daily_shift: new FormControl('',Validators.required),
			});
			this.addPetrolPump.daily_shift=1;
			// Function to get dealer dropdown list
		this.petrolPumpService.getDealerList()
		.subscribe(dealerlistdata =>{this.dealerDropdownList=dealerlistdata.data});
		
		this.petrolPumpService.getStates(1)
	.subscribe(statelistdata =>{this.stateDropdownList=statelistdata.data});
  }
  
  addNewPetrolPump(f)
	{
		this.add_loading=true;
		f.submitted=false;
		this.petrolPumpService.addPetrolPumpForMswipeData(this.addPetrolPump).subscribe(data=>{
			if(data.status==200 || data.status==201)
			{
				this.localService.set('emaildata',data);
				this.success_message='Petrol Pump Registered Successfully!';

				this.router.navigate(['deliverStatusNote']);
				
			}else{
				this.error_message='Petrol Pump could not be Register!';
			}
			this.add_loading=false;
		});
	}
	
	// Function to get Cities
	onSelectState(){
		this.petrolPumpService.getCities(this.addPetrolPump.state_id)
	.subscribe(citylistdata =>{this.cityDropdownList=citylistdata.data});
	}

}
