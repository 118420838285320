import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { LoginService } from '../../admin-services/login.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

user_id:number;
value:any={};
  constructor(private router:Router, private session: SessionStorageService,private loginService: LoginService) { }

  ngOnInit() {
	  this.value = this.session.get('authData');
	  this.user_id=this.value.user_id;
	  if(this.user_id)
	  {
		this.loginService.logout(this.user_id).subscribe(data=>{
			if(data.status==true)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}
		});
	  }else{
		  this.router.navigate(['']);
	  }
	  
	
  }

}
