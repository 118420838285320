import { Directive } from '@angular/core';

@Directive({
  selector: '[appUniqueMobile]'
})
export class UniqueMobileDirective {

  constructor() { }

}
