import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonsService } from '../../../../admin-services/commons.service';
import { ReportsService } from '../../../../admin-services/reports.service';
import { FormGroup,FormControl,Validators ,FormBuilder,FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../../const_default';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { DatePipe } from '@angular/common';
// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
@Component({
  selector: 'app-stock-item-summary-date',
  templateUrl: './stock-item-summary-date.component.html',
  styleUrls: ['./stock-item-summary-date.component.css']
})
export class StockItemSummaryDateComponent implements OnInit {

 
  constructor(private reportsService: ReportsService,private session:SessionStorageService,private router:Router, private exportAsService:ExportAsService,private commonsServices:CommonsService) { }
  exportAsConfig: ExportAsConfig;
	  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  
  authData:any={};
  serachReportData:any={};	
  reportData: any[] = [];
  itemAutoSugestList: any[] = [];
  petro_id:number;
  success_message:string;
  error_message:string;
  pump_name:number;
  pump_address:string;
  pump_mobile_no:number;
  pipe:any;
   config:any;
   
  opening_qty:number;
opening_amt:number;
show_loading_image:boolean;
  searchReportForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		tmp_item_id : new FormControl(' ',Validators.required),
		//to_shift : new FormControl(' ',Validators.required)
		
	});
	
  ngOnInit() {
	   this.authData = this.session.get('authData');
	 	this.petro_id = this.authData.petro_id;
	 	
	 	this.pump_name=this.authData.petro_name;
	 	this.pump_mobile_no=this.authData.petro_phone_no;
	 	this.pump_address=this.authData.petro_address;
		this.serachReportData.from_date=new Date(Date.now());
		this.serachReportData.to_date=new Date(Date.now());
		//this.serachReportData.from_shift=1;
		//this.serachReportData.to_shift=1;
		this.serachReportData=this.session.get('itemWiseStockSummaryDateSession');
		this.getReportData();
  }
  
  getReportData() {
	  this.show_loading_image=true;
	  this.pipe = new DatePipe('en-US'); 
	  this.serachReportData.from_date= this.pipe.transform(this.serachReportData.from_date, 'yyyy-MM-dd');		
	  this.serachReportData.to_date= this.pipe.transform(this.serachReportData.to_date, 'yyyy-MM-dd');
	  
		
	 this.reportsService.stockItemSummaryDateReport(this.petro_id,this.serachReportData)
	  .subscribe(reportAllData => {
		this.serachReportData.from_date=reportAllData.data.from_date;
			this.serachReportData.to_date=reportAllData.data.to_date;
	   if(reportAllData.status==200)
		  {
			this.reportData=reportAllData.data.viewData;
			this.opening_qty=reportAllData.data.opening_qty;
			this.opening_amt=reportAllData.data.opening_amount;
			
		  }
		  else if(reportAllData.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.reportData=[];
			this.opening_qty=reportAllData.data.opening_qty;
			this.opening_amt=reportAllData.data.opening_amount;
			  this.opening_qty=0;
        this.opening_amt=0;
        this.gt_in_qty=0;
        this.gt_in_amt=0;
        this.gt_out_qty=0;
        this.gt_out_amt=0;
        this.gt_cl_qty=reportAllData.data.opening_qty;
        this.gt_cl_amt=reportAllData.data.opening_amount;
		
		  }
	   this.show_loading_image=false;
	  });
	}
	
total_purchase_qty:number;
total_purchase_amt:number;
closing_qty:number;
closingArr: any[] = [];
closing_price:number;
closing_total:number;
gt_in_qty:number;
gt_in_amt:number;
gt_out_qty:number;
gt_out_amt:number;
gt_cl_qty:number;
gt_cl_amt:number;
 getTotal(qty,amt,type,i,last){
   
    if(i==0)   
    {   
      
        this.gt_in_qty=0;
        this.gt_in_amt=0;
        this.gt_out_qty=0;
        this.gt_out_amt=0;
        this.gt_cl_qty=0;
        this.gt_cl_amt=0;
        
		//Closing
		this.total_purchase_qty=this.opening_qty;
		this.closing_qty=this.opening_qty;
		this.total_purchase_amt=this.opening_amt;
    }

    /*this.opening_qty=Number(this.opening_qty)+Number(opening_qty);
    this.opening_amt=Number(this.opening_amt)+Number(opening_amt);
    this.in_qty=Number(this.in_qty)+Number(in_qty);
    this.in_amt=Number(this.in_amt)+Number(in_amt);
    this.out_qty=Number(this.out_qty)+Number(out_qty);
    this.out_amt=Number(this.out_amt)+Number(out_amt);
    this.close_qty=Number(this.close_qty)+Number(close_qty);
    this.close_amt=Number(close_amt);*/
	// Closing
	if(type==1)
	{
		this.total_purchase_qty=Number(this.total_purchase_qty)+Number(qty);
		this.total_purchase_amt=Number(this.total_purchase_amt)+Number(amt);
		this.closing_qty=Number(this.closing_qty)+Number(qty);
	}else{
		this.closing_qty=Number(this.closing_qty)-Number(qty);
		
		this.gt_out_qty=Number(this.gt_out_qty)+Number(qty);
		this.gt_out_amt=Number(this.gt_out_amt)+Number(amt);
	}
	this.closing_price=Number(this.total_purchase_amt)/Number(this.total_purchase_qty);
	this.closing_total=Number(this.closing_qty)*this.closing_price;
	this.closingArr[i]={'rate':this.closing_price,'closing_qty':this.closing_qty,'closing_amt':this.closing_total};
	if(last==true )
	 {
		this.gt_in_qty=this.total_purchase_qty;
		this.gt_in_amt=this.total_purchase_amt;
		
		this.gt_cl_qty=this.closing_qty;
		this.gt_cl_amt=this.closing_total;

	 } 
  }	
	
	 exportTable(event) {
	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'stock_summary_report');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }
  
  filterItemList(value: any): void {
		this.commonsServices.getItemByNameOrType(value, this.petro_id,0)
		.subscribe(alldata => {
			this.itemAutoSugestList = Object.entries(alldata.data).map(([value, label]) => ({
				value,
				label
			}));
		});
	}
  // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}


}
