import { Component, OnInit } from '@angular/core';
import { ShiftSaleService } from '../../admin-services/shift-sale.service';
import { CommonsService } from '../../admin-services/commons.service';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { FormGroup,FormControl,Validators,FormBuilder ,FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router} from '@angular/router';
import { constDefault } from '../../const_default';
import { DatePipe } from '@angular/common';
declare var $: any;

import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';

@Component({
  selector: 'app-credit-invoice',
  templateUrl: './credit-invoice.component.html',
  styleUrls: ['./credit-invoice.component.css']
})
export class CreditInvoiceComponent implements OnInit {
	
	options: DatepickerOptions = {
	 minYear: 2015,
	 maxYear: 2030,
	 displayFormat: 'DD-MM-YYYY',
	 barTitleFormat: 'MMMM YYYY',
	 dayNamesFormat: 'dd',
	 firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
	 locale: enLocale,
	 //	minDate: new Date(Date.now()), // Minimal selectable date
	 //maxDate: new Date(Date.now()), // Maximal selectable date
	 barTitleIfEmpty: 'Click to select a date',
	 placeholder: 'Select Date', // HTML input placeholder attribute (default: '')
	 addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
	 addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
	 fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
	 useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
   
	};

	options1: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//	minDate: new Date(Date.now()), // Minimal selectable date
		//maxDate: new Date(Date.now()), // Maximal selectable date
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	  
	   };

	   options2: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//	minDate: new Date(Date.now()), // Minimal selectable date
		//maxDate: new Date(Date.now()), // Maximal selectable date
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	  
	   };
  constructor(private shiftsaleService: ShiftSaleService, private router:Router,private session:SessionStorageService, private formBuilder: FormBuilder,private commonsServices: CommonsService, private masterService:MasterServicesService ) { }
  
  
	
	authData:any={};
	petro_id:number;
	searchInvoiceData : any={};
	generateInvoiceData : any={};
	success_message:string;
	error_message:string;
	currentPage:number;
	totalCat:number;
	selectedPeople2 = [];
	allSaleItemList:any[]=[];
	salesItemListData:any[]=[];
	invoiceList:any[]=[];
	net_total:number;
	ao_amt:number;
	grand_total:number;
	rndup_grand_total:number;
	totalData:number;
	receiptDataForView:any[]=[];
	invoiceDataForView:any[]=[];
	invoiceDetailsDataForView:any[]=[];
	invoiceDetailsIntrestForView:any[]=[];
	viewInvoice:number;
	vehicleDropdownList=[];
	itemMSAutoSugestList=[];
	pipe:any;
	ms_sale_qty:number;
	hsd_sale_qty:number;
	ms_sale_amt:number;
	hsd_sale_amt:number;
	index:number;
	model:string;
	logo_path:string;
	web_url:string;
	delear_of:string;
	inv_exist_msg:string;
	term_conditions:string;
	bank_details:string;
    from_date_inv:string;
    to_date_inv:string;
    inv_prefix:string;
	add_driver_cash:boolean;
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	driver_cash_col:number;
	ao_per:number;
	driver_cash_view_col:number;
	btnPrevSession:any[]=[];
	
	//generateInvoiceForm:FormGroup;
	saleItemList:FormArray;
	intrestCalcInvoiceList:FormArray;
    generateInvoiceForm = new FormGroup({
    party_id : new FormControl(' ',Validators.required),
    from_date : new FormControl(' ',Validators.required),
    commodity : new FormControl(' '),
    to_date : new FormControl(' ',Validators.required),
    invoice_date : new FormControl(' ',Validators.required),
    net_total : new FormControl(' ',Validators.required),
    grand_total : new FormControl(' ',Validators.required),
    ao_charges_per : new FormControl(' '),
    vehicle_id : new FormControl(' '),
    ao_charges_amt : new FormControl(' '),
    less_dis : new FormControl(' '),
    round_up : new FormControl(' '),
    total_ms_qty : new FormControl(' '),
    total_ms_amt : new FormControl(' '),
    total_hsd_qty : new FormControl(' '),
    total_hsd_amt : new FormControl(' '),
	ms_discount_per:new FormControl(' '),
	ms_discount_amt:new FormControl(' '),
	hsd_discount_per:new FormControl(' '),
	hsd_discount_amt:new FormControl(' '),
    include_prev_balance : new FormControl(' '),
    prev_balance : new FormControl(' '),
    receipt : new FormControl(' '),
    total_balance : new FormControl(' '),
    invoice_no : new FormControl(' ',[Validators.required]),
	saleItemList: this.formBuilder.array([]),
	include_over_due_intrest:new FormControl(' '),
	include_driver_cash:new FormControl(' '),
	intrestCalcInvoiceList: this.formBuilder.array([]),
	total_intrest_amount: new FormControl(' ')
  })


	
	serachInvoiceForm= new FormGroup({
		customer_id : new FormControl(' '),
		invoice_no : new FormControl(' '),
	});
	
  ngOnInit() {
	  
	  this.driver_cash_col=0;
	  this.driver_cash_view_col=0;
	  // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
	  
	  this.web_url =constDefault.API_URL;
	  this.viewInvoice=0;
	  this.generateInvoiceData.from_date= new Date();
	  this.generateInvoiceData.to_date= new Date(Date.now());
	  this.generateInvoiceData.invoice_date= new Date(Date.now());
	  
	  
		this.authData = this.session.get('authData');
		this.petro_id=this.authData.petro_id;
		
		// get all Invoice List
		this.pageWiseInvoiceList(1);
		
		
		
	  
	  this.commonsServices.getLedgerListByGroup(constDefault.SUNDRY_DEBTOR_GROUP, this.petro_id)
	  .subscribe(customerList => {
	   //console.log(customerList);
	   //this.selectedPeople2=customerList.data;
	   //var data = { firstName: 'John', lastName: 'Doe', email: 'john.doe@gmail.com' }
	   // Convert  key value array to multi dimensial array Ex: {1:India,2:Nepal} to [{vlaue: 1,label:India} ,{vlaue: 2,label:Nepal}]
	   if(customerList.data)
	   {
	   this.selectedPeople2 = Object.entries(customerList.data).map(([value, label]) => ({value,label}));
	   }
	  });
	  
   
  }
  get saleItemListData() { return this.generateInvoiceForm.get('saleItemList')}
  get intrestListData() { return this.generateInvoiceForm.get('intrestCalcInvoiceList')}
  	saleItem(): FormGroup {
	 return this.formBuilder.group({
	  sale_id: ['', Validators.required],
	  total_amt: ['', Validators.required],
   
	 })
	}
  // Get Sales Data for Generate Invoice
  getSalesData(){
	 
	  this.generateInvoiceData.customer_id= this.generateInvoiceData.party_id.value;
	  this.generateInvoiceData.petro_id= this.petro_id;
	  if(this.generateInvoiceData.vehicle_id){
		this.generateInvoiceData.vehicle_no= this.generateInvoiceData.vehicle_id.value;
	  }
	  if(this.generateInvoiceData.commodity){
		this.generateInvoiceData.commodity_id= this.generateInvoiceData.commodity.value;
	  }
	  this.pipe = new DatePipe('en-US'); 
	  this.generateInvoiceData.from_date = this.pipe.transform(this.generateInvoiceData.from_date, 'yyyy-MM-dd');

	  this.generateInvoiceData.to_date = this.pipe.transform(this.generateInvoiceData.to_date, 'yyyy-MM-dd');
	  this.generateInvoiceData.invoice_date = this.pipe.transform(this.generateInvoiceData.invoice_date, 'yyyy-MM-dd');
	  	

	  //console.log(this.generateInvoiceData);
	  	  this.shiftsaleService.getSalesDataForCreateInvoice(this.generateInvoiceData)
	  .subscribe(itemList => {
		    if(itemList.status==200)
		  {
			  this.salesItemListData=itemList.data;
			  this.ao_per=itemList.ao_per;
			  this.generateInvoiceData.ao_charges_per=itemList.ao_charge;
			  this.generateInvoiceData.invoice_no=itemList.invoice_no;
			  this.inv_prefix=itemList.inv_prefix;
			this.setSale(itemList.data);
			this.calculationGrandtotal();
			this.generateInvoiceData.receipt=0;
			this.generateInvoiceData.prev_balance=0;
			$('#prevBalanceIncCheckbox').removeAttr('checked');
		  }else if(itemList.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
				this.salesItemListData=[];
				 this.generateInvoiceData.ao_charges_per=0;
				  this.generateInvoiceForm.setControl('saleItemList', this.formBuilder.array([]));
				 this.calculationGrandtotal();
				
		  }
		  
			this.generateInvoiceData.receipt=0;
			this.generateInvoiceData.prev_balance=0;
			this.add_driver_cash=false;
			$('#prevBalanceIncCheckbox').prop('checked',false);
			$('#incDriverCashCheckbox').prop('checked',false);
	});
  }
  
  setSale(itemList:any){
		if(itemList)
		{
		this.allSaleItemList = Object.keys(itemList).map(function(personNamedIndex){
		let item = itemList[personNamedIndex];
    // do something with person
			return item;
		});
		}
		//console.log(this.commodityDropdownList);
		//this.i=0;
		
		this.generateInvoiceForm.setControl('saleItemList', this.formBuilder.array([]));
		this.net_total=0;
		this.ms_sale_qty=0;
		this.hsd_sale_qty=0;
		this.ms_sale_amt=0;
		this.hsd_sale_amt=0;
		this.allSaleItemList.forEach((a, index) => {
			
				if (a.Item.item_category_id == constDefault.ITEM_CATEGORY_MS || a.Item.item_category_id== constDefault.ITEM_CATEGORY_BMS )
				{
					
					this.ms_sale_qty=Number(this.ms_sale_qty)+Number(a.SalesDetail.quantity);
					this.ms_sale_amt=Number(this.ms_sale_amt)+Number(a.SalesDetail.total_amount);
				}
				if( a.Item.item_category_id == constDefault.ITEM_CATEGORY_HSD || a.Item.item_category_id == constDefault.ITEM_CATEGORY_BHSD)
				{
					this.hsd_sale_qty=Number(this.hsd_sale_qty)+Number(a.SalesDetail.quantity);
					this.hsd_sale_amt=Number(this.hsd_sale_amt)+Number(a.SalesDetail.total_amount);
				}
				this.generateInvoiceData.total_ms_qty=this.ms_sale_qty;
				this.generateInvoiceData.total_ms_amt=this.ms_sale_amt;
				
				this.generateInvoiceData.total_hsd_qty=this.hsd_sale_qty;
				this.generateInvoiceData.total_hsd_amt=this.hsd_sale_amt;
				
		//for (let a of this.commodityDropdownList ) {
			this.net_total=Number(this.net_total)+Number(a.SalesDetail.total_amount);
			this.saleItemList=this.generateInvoiceForm.get('saleItemList') as FormArray;
			this.saleItemList.push(
			this.formBuilder.group({
				sale_id: [a.Sale.id, Validators.required],
				total_amt: [a.SalesDetail.total_amount, Validators.required],
			})
			);
			
			
		 });
		 this.generateInvoiceData.net_total=this.net_total;
		 this.generateInvoiceData.total_balance=this.net_total;
		
	}
	generateInvoice(gen_inv){
		 this.inv_exist_msg="";
		this.pipe = new DatePipe('en-US'); 
		this.generateInvoiceForm.value.invoice_date = this.pipe.transform(this.generateInvoiceForm.value.invoice_date, 'yyyy-MM-dd');
		gen_inv.submitted=false;
		this.generateInvoiceForm.value.customer_id= this.generateInvoiceForm.value.party_id.value;
	  this.generateInvoiceForm.value.petro_id= this.petro_id;
	  	  this.shiftsaleService.generateCreditInvoice(this.generateInvoiceForm.value)
	  .subscribe(itemList => {
		  //this.salesItemListData=itemList.data;
		 // this.generateInvoiceData.ao_charges_per=itemList.ao_charge;
		//this.setSale(itemList.data);
	//	this.calculationGrandtotal();
	if(itemList.status==205)
			{
				 this.inv_exist_msg='Invoice No. already exist';
			}
	else if(itemList.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
		this.success_message='Invoice Generateed successfully';
		//$('#addGenerateInvoiceModal').modal('hide');
		this.pageWiseInvoiceList(1);
		this.generateInvoiceForm.setControl('saleItemList', this.formBuilder.array([]));
		//this.generateInvoiceForm.reset();
		
		this.from_date_inv=this.generateInvoiceData.from_date;
		this.to_date_inv=this.generateInvoiceData.to_date;
		this.ao_per=0;
		this.generateInvoiceData={};
		this.vehicleDropdownList=[];
		this.generateInvoiceData.from_date=this.from_date_inv;
		this.generateInvoiceData.to_date=this.to_date_inv;
		this.generateInvoiceData.invoice_date=this.to_date_inv;
		
		
			}
	});
	}
	
	calculationGrandtotal(){
		 
		//this.generateInvoiceData.ao_charges_per

		if (Number(this.generateInvoiceData.ao_charges_per)>0)
		{
			this.ao_amt=Number(this.generateInvoiceData.ao_charges_per);
		}
		else{
			this.ao_amt=(Number(this.generateInvoiceData.net_total)/100)*Number(this.ao_per);
		}


		
		this.generateInvoiceData.ao_charges_amt=this.ao_amt;
		this.grand_total=Number(this.generateInvoiceData.net_total)+Number(this.ao_amt);
		if(!this.generateInvoiceData.hsd_discount_amt){
			this.generateInvoiceData.hsd_discount_amt=0;
		}
		if(!this.generateInvoiceData.ms_discount_amt){
			this.generateInvoiceData.ms_discount_amt=0;
		}
		this.generateInvoiceData.less_dis=Number(this.generateInvoiceData.hsd_discount_amt)+Number(this.generateInvoiceData.ms_discount_amt);
		if(this.generateInvoiceData.less_dis)
		{
		this.grand_total=Number(this.grand_total)-Number(this.generateInvoiceData.less_dis);
		}

		this.rndup_grand_total=Math.round(this.grand_total);
		
        this.generateInvoiceData.round_up=Number(this.rndup_grand_total)-Number(this.grand_total);
        this.generateInvoiceData.round_up=Number(this.generateInvoiceData.round_up).toFixed(2);
        this.generateInvoiceData.grand_total=Number(this.rndup_grand_total).toFixed(2);
		
		// Total balance
		
		if(!this.generateInvoiceData.prev_balance)
		{
			this.generateInvoiceData.prev_balance=0;
		}
			if(!this.generateInvoiceData.grand_total)
		{
			this.generateInvoiceData.grand_total=0;
		}
			if(!this.generateInvoiceData.receipt)
		{
			this.generateInvoiceData.receipt=0;
		}
		if(!this.generateInvoiceData.total_intrest_amount)
		{
			this.generateInvoiceData.total_intrest_amount=0;
		}
		
		this.generateInvoiceData.total_balance=(Number(this.generateInvoiceData.prev_balance)+Number(this.generateInvoiceData.grand_total)+Number(this.generateInvoiceData.total_intrest_amount))-Number(this.generateInvoiceData.receipt);
		
		
	}
	
	/*
	Amit Shau
	Get page wise invoice list
	25.01.2019
	*/
	  pageWiseInvoiceList(pageno){
	   this.currentPage=pageno;
	   this.shiftsaleService.getInvoiceListData(pageno,this.petro_id,this.searchInvoiceData)
		.subscribe(alldata =>{
			 if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.invoiceList=alldata.data,
			this.totalData=alldata.count
			}
	 	});
   	}
	
	/*
	Amit Sahu
	25.01.19	
	Get Invoice data for view
	*/
	getInvoiceData(invoice_id){
		$('#viewInvoiceModal').modal('show');
		  this.shiftsaleService.getInvoiceDataById(invoice_id)
		.subscribe(alldata =>{
			 if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.invoiceDataForView=alldata.data.invoice_data,
			this.invoiceDetailsDataForView=alldata.data.sale_data,
			this.logo_path=alldata.data.logo,
			this.delear_of=alldata.data.description,
			this.term_conditions=alldata.data.term,
			this.bank_details=alldata.data.bank_detail,
			this.receiptDataForView=alldata.data.receipt,
			this.viewInvoice=1;
			if(alldata.data.invoice_data.CreditInvoice.cash_to_driver==1){
				this.driver_cash_view_col=1;
			}else{
				this.driver_cash_view_col=0;
			}
			}
	 	});
	}
	sendInvoiceDataMail(invoice_id){
		if(confirm("Are you sure to send email")) { 
		 this.shiftsaleService.sendInvoiceDataMail(invoice_id)
		.subscribe(alldata =>{
			 if(alldata.status==403)
			{
				  this.session.remove('authData');
				this.router.navigate(['']);
			}
			else if(alldata.status==200){
			this.success_message= "Email send";
			}else if(alldata.status==406){
			this.error_message= "Please insert email address in party account";
			}else if(alldata.status==405){
			this.error_message= "Email not send";
			}else{
				this.error_message= "Something went wrong";
			}
	 	});
		}
	}
	
	
	searchInvoice(){
		if(this.searchInvoiceData.customer_id)
		{
		this.searchInvoiceData.party_id=this.searchInvoiceData.customer_id.value;
		}
		 this.pageWiseInvoiceList(1);
	}
	
	deleteInvoice(id,index){
		if(confirm("Are you sure to delete this Location")) { 
			this.index=index;
			this.model='CreditInvoice'; 
				//alert(id);
			this.shiftsaleService.deleteCreditInvoice(id).subscribe(data=>{
				//console.log(data);
				if(data.status==true)
				{
					this.invoiceList.splice(this.index,1);
					this.success_message="Invoice deleted sucessfully";	
				}else{
					this.error_message= "Location could not be deleted.";
				}
			});
		//this.editname=this.editCategoryData.name;	
		}
	}

	resetSearch(){
		
		this.searchInvoiceData={};
		 this.pageWiseInvoiceList(1);
	}

	getVehicleInvoiceList(value){
	
		this.getVehicleList(value.value);
		this.generateInvoiceForm.setControl('intrestCalcInvoiceList', this.formBuilder.array([]));
		this.generateInvoiceForm.setControl('saleItemList', this.formBuilder.array([]));
		
	}

	getVehicleList(customer_id) {
		$('#show_vehicle_info').show();
		this.shiftsaleService.getVehicleListByCustId(customer_id, this.petro_id)
			.subscribe(vehicleData => {
				if(vehicleData.data)
				{
				this.vehicleDropdownList = Object.entries(vehicleData.data).map(([value, label]) => ({value,label}));
				}
			})
	}
	
	// Get Commodity for filter
	   public filterMsHsdItemList(value:any):void {	
  
	   this.commonsServices.getItemByNameOrType(value, this.petro_id,constDefault.ITEM_TYPE_MSHSD)
		.subscribe(alldata => {
			if(alldata.data)
			{
		     this.itemMSAutoSugestList=Object.entries(alldata.data).map(([value, label]) => ({value,label}));
			}else if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}
		});
  }
	// Include previous balance
	includePreviusBalance(event){
	  if(event.target.checked)
	  {
		  if(this.generateInvoiceData.party_id)
		  {
			  this.pipe = new DatePipe('en-US'); 
		   this.generateInvoiceData.customer_id= this.generateInvoiceData.party_id.value;
		    this.generateInvoiceData.from_date = this.pipe.transform(this.generateInvoiceData.from_date, 'yyyy-MM-dd');
			this.generateInvoiceData.to_date = this.pipe.transform(this.generateInvoiceData.to_date, 'yyyy-MM-dd');
			this.shiftsaleService.getPreviosBalanceForInvoices(this.petro_id,this.generateInvoiceData)
			.subscribe(prevData => {
				this.generateInvoiceData.receipt=prevData.data.receipt;
				this.generateInvoiceData.prev_balance=prevData.data.prev_balance;
				 this.calculationGrandtotal(); 
			})
		  }else{
			  this.generateInvoiceData.receipt=0;
			  this.generateInvoiceData.prev_balance=0;
			  this.generateInvoiceData.total_balance=this.generateInvoiceData.grand_total;
			  
		  }
		 
	  }else{
		      this.generateInvoiceData.receipt=0;
			  this.generateInvoiceData.prev_balance=0;
			  this.generateInvoiceData.total_balance=this.generateInvoiceData.grand_total;
			  this.generateInvoiceData.total_intrest_amount=0;
			  this.generateInvoiceForm.setControl('intrestCalcInvoiceList', this.formBuilder.array([]));
			  this.calculationGrandtotal();
	  }
	 
	}
	
	// Set invoice date after change to date
	onChangeToDate(){
		 this.pipe = new DatePipe('en-US'); 
		this.generateInvoiceData.to_date = this.pipe.transform(this.generateInvoiceData.to_date, 'yyyy-MM-dd');
		this.generateInvoiceData.invoice_date =this.generateInvoiceData.to_date ;
	}
  // Print Journal voucher veiw details
	printComponent() {
		var printContents = document.getElementById("credit_invoice").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
	
	// Include over due intrest
	includeOverDueIntrest(event){
		this.generateInvoiceForm.setControl('intrestCalcInvoiceList', this.formBuilder.array([]));
		this.generateInvoiceData.total_intrest_amount=0;
	  if(event.target.checked)
	  {
		 this.getoverdueIntrestDetails();
	 
	}else{
			this.calculationGrandtotal();
	}
	}
	// Get over due intresh details
	getoverdueIntrestDetails(){
		 if(this.generateInvoiceData.party_id)
		  {
			  
			  this.pipe = new DatePipe('en-US'); 
			  this.generateInvoiceData.invoice_date = this.pipe.transform(this.generateInvoiceForm.value.invoice_date, 'yyyy-MM-dd');
		   this.generateInvoiceData.customer_id= this.generateInvoiceData.party_id.value;
		  
			this.shiftsaleService.getPreviousInvoiceBalanceIntrest(this.petro_id,this.generateInvoiceData)
			.subscribe(prevData => {
			if(prevData.status==200)
				{
			    this.generateInvoiceData.total_intrest_amount=prevData.data.final_int_amt.toFixed(2);
				prevData.data.data.forEach((a, index) => {
				this.intrestCalcInvoiceList=this.generateInvoiceForm.get('intrestCalcInvoiceList') as FormArray;
					this.intrestCalcInvoiceList.push(
						this.formBuilder.group({
							invoice_id: [a.invoice_id, Validators.required],
							invoice_no: [a.invoice_no, Validators.required],
							invoice_date: [a.invoice_date, Validators.required],
							due_date: [a.due_date],
							invoice_amt: [a.invoice_amount, Validators.required],
							balance_amt: [a.balance],
							intrest_rate: [a.int_rate],
							intrest_amt: [a.intrest_amount],
							intrest_apply_days: [a.intrest_apply_days],
							paid_amount: [a.paid_amount],
							paid_date: [a.paid_date],
							
						})
					);
				});
					this.calculationGrandtotal();
			}else{
				   this.generateInvoiceData.total_intrest_amount=0;
				this.generateInvoiceForm.setControl('intrestCalcInvoiceList', this.formBuilder.array([]));
		     	this.calculationGrandtotal();
			}
		 
			})
		
	  }
	}
	
	// On invoice date change remove intrest data
	onInvoiceDateChange(){
		this.generateInvoiceForm.setControl('intrestCalcInvoiceList', this.formBuilder.array([]));
		this.generateInvoiceData.total_intrest_amount=0;
		this.generateInvoiceData.include_over_due_intrest=false;

		this.calculationGrandtotal();
	}
	
	getInvoiceIntrestData(invoice_id){
		$('#viewInvoiceIntrestModal').modal('show');
		  this.shiftsaleService.getInvoiceDataById(invoice_id)
		.subscribe(alldata =>{
			 if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.invoiceDataForView=alldata.data.invoice_data,
			this.invoiceDetailsIntrestForView=alldata.data.intrest_data,
			this.logo_path=alldata.data.logo,
			this.delear_of=alldata.data.description,
			this.term_conditions=alldata.data.term,
			this.bank_details=alldata.data.bank_detail,
			this.viewInvoice=1;
			this.driver_cash_col=0;
			}
	 	});
	}
	printIntrestDataComponent(){
		var printContents = document.getElementById("credit_invoice_intrest").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
	
	// Add Driver Cash on Invoice
	includeDriverCash(event){
		  if(event.target.checked)
		  {
			  this.driver_cash_col=1;
			  this.add_driver_cash=true;
			  // Calculate row total
			  	this.saleItemList=this.generateInvoiceForm.get('saleItemList') as FormArray;
				if(this.allSaleItemList)
				{
					this.net_total=0;
					this.allSaleItemList.forEach((a, index) => {
						if(!a.SalesDetail.total_amount){
							a.SalesDetail.total_amount=0;
						}
						this.saleItemList.controls[index].get('total_amt').setValue(Number(a.Sale.cash_to_driver)+Number(a.SalesDetail.total_amount));
						this.net_total=Number(this.net_total)+Number(a.Sale.cash_to_driver)+Number(a.SalesDetail.total_amount);
					});
				}
				 this.generateInvoiceData.net_total=this.net_total;
				this.generateInvoiceData.total_balance=this.net_total;
				this.calculationGrandtotal(); // recalculate 
		
		 
		}else{
			this.driver_cash_col=0;
			 this.add_driver_cash=false;
			 	this.saleItemList=this.generateInvoiceForm.get('saleItemList') as FormArray;
				if(this.allSaleItemList)
				{
					this.net_total=0;
					this.allSaleItemList.forEach((a, index) => {
						if(!a.SalesDetail.total_amount){
							a.SalesDetail.total_amount=0;
						}
						this.saleItemList.controls[index].get('total_amt').setValue(a.SalesDetail.total_amount);
						this.net_total=Number(this.net_total)+Number(a.SalesDetail.total_amount);
					});
				}
				 this.generateInvoiceData.net_total=this.net_total;
				this.generateInvoiceData.total_balance=this.net_total;
			this.calculationGrandtotal(); // recalculate 
		}
	}
	
	// Calculate MS Discount per litter
	ms_dis_per:number;
	ms_dis_amt:any;
	calCulateMsDiscount(event){
		this.ms_dis_per=event.target.value;
		this.ms_dis_amt=0;
		if(this.ms_dis_per>0 && this.generateInvoiceData.total_ms_qty>0){
			this.ms_dis_amt=Number(this.ms_dis_per)*Number(this.generateInvoiceData.total_ms_qty);
			this.ms_dis_amt=this.ms_dis_amt.toFixed(2);
			this.generateInvoiceData.ms_discount_amt=this.ms_dis_amt;
		}else{
			this.generateInvoiceData.ms_discount_amt=0;
		}
		
		this.calculationGrandtotal(); // recalculate 
	}
	// Calculate HSD Discount per litter
	hsd_dis_per:number;
	hsd_dis_amt:any;
	calCulateHsdDiscount(event){
		
		this.hsd_dis_per=event.target.value;
		this.hsd_dis_amt=0;
		if(this.hsd_dis_per>0 && this.generateInvoiceData.total_hsd_qty>0){
			this.hsd_dis_amt=Number(this.hsd_dis_per)*Number(this.generateInvoiceData.total_hsd_qty);
			this.hsd_dis_amt=this.hsd_dis_amt.toFixed(2);
			this.generateInvoiceData.hsd_discount_amt=this.hsd_dis_amt;
		}else{
			this.generateInvoiceData.hsd_discount_amt=0;
		}
		this.calculationGrandtotal(); // recalculate 
		
	}
}
