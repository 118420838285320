import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { constDefault } from '../const_default';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

// Interface to get all data as array
interface serviceData {
	data: any
	count:number;

}

interface successData{
	status:boolean,
	message:any,
	id:number,
	name:string,
	parent_id:number,
	parentName:string,
}

// interface to add pump record and get responce in form of data(array), status(200/401), message(success/Failed)  
interface addData{
	data:any,
	status:number,
	message:string
} 

// Interface to delete petrol pump record and get response as status(200/401), id(number)  
interface deleteData{
	status:boolean,
	id:number,
}


@Injectable({
  providedIn: 'root'
})

export class PetrolPumpServicesService {
	headersdata:any={};
  authValue:any={};
  user_id:string;
  login_token:string;
  login_year:string;
	constructor(private http: HttpClient, private local_storage:LocalStorageService) {
		this.authValue = this.local_storage.get('localAuthData');
		
		if(this.authValue){
			this.user_id=this.authValue.user_id;
			this.login_token=this.authValue.token;
			this.login_year=this.authValue.financial_year_id;
			
			let headers: HttpHeaders = new HttpHeaders();
			headers = headers.append('Accept', 'application/json');
			headers = headers.append('user', this.user_id);
			headers = headers.append('year', this.login_year);
			this.headersdata = headers.append('token', this.login_token);
		}
	}

	
  // Add new Petrol pump here
  	addPetrolPumpData(addPump){
	  return this.http.post<addData>(constDefault.API_URL+"/managements/petroRegistration/"+this.login_year,addPump,{headers:this.headersdata})
	}

	// Get Petrol Pump List Data
	getPetrolPumpListData(pageno:number,searchValue){
		//console.log(searchValue);
		return this.http.post<serviceData>(constDefault.API_URL+"/managements/petrolPumpList/"+this.login_year+'/page:'+pageno,JSON.stringify(searchValue),{headers:this.headersdata})
	}

	// Get petrol pump data for a single Entry
	getEditPetrolPumpData(id:number){
		//console.log(searchValue);
		return this.http.get<serviceData>(constDefault.API_URL+"/managements/getPetrolPumpDataById/"+id+'/'+this.login_year,{headers:this.headersdata})
	}

	// Submit Edited data 
	editPetrolPumpData(editData){
		return this.http.post<addData>(constDefault.API_URL+"/managements/editPetrolPump/"+this.login_year,JSON.stringify(editData),{headers:this.headersdata})
	}
	// Delete Pump List Record
	getDeletePumpData(id){
	  return this.http.get<deleteData>(constDefault.API_URL+"/managements/deletePetrolPump/"+id+'/'+this.login_year,{headers:this.headersdata})
	} 
	
	// Get Countries List
	getCountries(){
		return this.http.get<serviceData>(constDefault.API_URL+"/commons/countryList/"+this.login_year,{headers:this.headersdata})
	}

	// Get States List
	getStates(country_id:number){
		return this.http.get<serviceData>(constDefault.API_URL+"/commons/stateList/"+country_id+'/'+this.login_year,{headers:this.headersdata})
	}

	// Get Cities List
	getCities(state_id:number){
		return this.http.get<serviceData>(constDefault.API_URL+"/commons/cityList/"+state_id+'/'+this.login_year,{headers:this.headersdata})
	}

	// Get Package List
	getPackages(){
		return this.http.get<serviceData>(constDefault.API_URL+"/commons/packagingList/"+this.login_year,{headers:this.headersdata})
	}

	// Get Package List
	getOwner(){
		return this.http.get<serviceData>(constDefault.API_URL+"/commons/petroOwnerDropdownList/"+this.login_year,{headers:this.headersdata})
	}




	// Add new owner here
	addOwnerData(addowner){
		return this.http.post<addData>(constDefault.API_URL+"/managements/ownerRegistration/"+this.login_year,addowner,{headers:this.headersdata})
	  }

	  // Submit Edited data 
	editOwnerData(editData){
		return this.http.post<addData>(constDefault.API_URL+"/managements/editOwner/"+this.login_year,editData,{headers:this.headersdata})
	}

	// Get owner List Data
	getOwnerListData(pageno:number,searchValue){
		//console.log(searchValue);
		return this.http.post<serviceData>(constDefault.API_URL+"/managements/petroOwnerList/"+this.login_year+'/'+pageno,searchValue)
	}

	// Get owner data for a single Entry
	getEditOwnerData(id:number){
		//console.log(searchValue);
		return this.http.get<serviceData>(constDefault.API_URL+"/managements/getOwnerDataById/"+id+'/'+this.login_year)
	}

	// Get owner List Data
	getDealerList(){
		//console.log(searchValue);
		return this.http.get<serviceData>(constDefault.API_URL+"/commons/companyDropList/"+this.login_year,{headers:this.headersdata})
	}
	  // Add new Petrol pump here
  	addPetrolPumpForMswipeData(addPump){
	  return this.http.post<addData>(constDefault.API_URL+"/commons/petroRegistration/"+this.login_year,addPump,{headers:this.headersdata})
	}
}
