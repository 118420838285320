import { Directive } from '@angular/core';
import { AsyncValidator,AsyncValidatorFn,ValidationErrors, AbstractControl ,NG_ASYNC_VALIDATORS } from '@angular/forms';
import { CommonsService } from '../admin-services/commons.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//import {InjectorInstance} from '../app.module';



export function uniqueEmailValidation(commonService):AsyncValidatorFn{

 	return (c:AbstractControl):Promise<ValidationErrors | null > | Observable<ValidationErrors | null > =>{
		
 			return commonService.uniqueEmailid(c.value).pipe(
		map(data=>{
			
				return data==true ? {'uniqueValidation':true}:null
			})
 			)
	}
 }
/*
export function uniqueByModalOrNameValidation(): AsyncValidatorFn {

	const commonsService = InjectorInstance.get<CommonsService>(CommonsService);
  
	return (c: AbstractControl): Promise < ValidationErrors | null > | Observable < ValidationErrors | null > => {
	  return commonsService.uniqueByModalOrName(c.value).pipe(
		map(data => {
		  return data && data.status ? {
			'uniqueValidation': true
		  } : null
	   })
	  )
	}
  }

*/

@Directive({
  selector: '[uniqueValidation]',
  providers : [{provide : NG_ASYNC_VALIDATORS, useExisting:UniqueEmailDirective, multi: true }]
})
//implements AsyncValidator   && data.status
export class UniqueEmailDirective  {
/*
  constructor( private commonService:CommonsService){ }
	
	validate(c:AbstractControl):Promise<ValidationErrors | null > | Observable<ValidationErrors | null>{
		//return uniqueByModalOrNameValidation(this.commonService)(c);
			return this.commonService.uniqueByModalOrName(modal,c.value,petro_id)
			.pipe(
			map(data=>{
				return data ? {'uniqueValidation':true}:null
			})
			)
		}*/
}
