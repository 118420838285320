import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators ,FormBuilder, FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { AccountService } from '../../../admin-services/account.service';
import { ShiftSaleService } from '../../../admin-services/shift-sale.service';
import { CommonsService } from '../../../admin-services/commons.service';
import { MasterServicesService } from '../../../admin-services/master-services.service';
import { DatePipe } from '@angular/common';
import { constDefault } from '../../../const_default';
declare var $: any;

@Component({
	selector: 'app-sl-nozzle-reading',
	templateUrl: './sl-nozzle-reading.component.html',
	styleUrls: ['./sl-nozzle-reading.component.css']
})
export class SlNozzleReadingComponent implements OnInit {

	constructor(private commonsServices: CommonsService, private accountService: AccountService, private shiftSaleService: ShiftSaleService, private session: SessionStorageService, private masterService: MasterServicesService, private formBuilder: FormBuilder) {}
	// Nozzle Reading

	pipe: any;
	authData: any = {};
	nozzleListData: any = {};
	addShiftData: any = {};
	sendDataForTotalSale: any = {};
	nozzleListAllData: any[] = [];
	petro_id: number;
	item_price: number;
	total_nozzle_sale: any;
	total_nozzle_sale_amt: any;
	total_nozzle_testing: number;
	error_messages: string;
	invalid_shift: string;
	entry_date: string;
	nozzleList: any[] = [];
	dsmList: any[] = [];
	assignNozzleForm: FormGroup;
	dsmListEntry: FormArray;
	shift_date: any;
	shift_no: number;
	day_shift_no: number;
	nozzle_list_loading:boolean;
	nozzle_sale_calculated:boolean;
	addShiftWiseReadingForm: FormGroup;
  	nozzleListEntry: FormArray;
  
	success_message: string;
	error_message: string;
	start_reading: number;
	close_reading: number;
	dsm_wise_track: number;
	show_bozer: number;
    block_reading:number;
	bozer_list = [];
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	
	lock_shift:number;
	role_id:number;

  nozzleDropdownListData: any[] = [];
  
	ngOnInit() {

		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;

		this.assignNozzleForm = this.formBuilder.group({
			dsmListEntry: this.formBuilder.array([])
		});

		this.addShiftWiseReadingForm = this.formBuilder.group({
			nozzleListEntry: this.formBuilder.array([])
		});
		// Nozzle Drop Down
		this.commonsServices.getNozzleDropdownList(this.petro_id)
			.subscribe(nozzleList => {
				this.nozzleDropdownListData = nozzleList.data
			});

		
	}

	//get data from Shift Sale Transaction
	getTransactionShiftData(shiftData: any,genelarGeneralSetup:any,dsmList:any) {
		if(genelarGeneralSetup)
		{
			if(genelarGeneralSetup.AppSetupGeneral.dsm_wise_track)
			{
				this.dsm_wise_track=genelarGeneralSetup.AppSetupGeneral.dsm_wise_track;
			}

			if(genelarGeneralSetup.AppSetupGeneral.show_bozer)
			{
				this.show_bozer=genelarGeneralSetup.AppSetupGeneral.show_bozer;
			}

			if(genelarGeneralSetup.AppSetupGeneral.reading_lock)
			{
				this.block_reading=genelarGeneralSetup.AppSetupGeneral.reading_lock;
			}
		}

		this.addShiftData=shiftData;
		this.shift_no = shiftData.shift;
		this.shift_date = shiftData.date;
		this.day_shift_no = shiftData.day_shift;
		this.role_id= shiftData.role_id;
		this.lock_shift= shiftData.lock_shift;
		//  console.log (this.role_id);
		//  console.log (this.lock_shift);

		this.getNozzleListOrdataByShift();
		this.pageWiseData(1);
	}

	/*===========================Nozzle Reading Amit================================*/

	
	get addShiftWiseReading() {
		return this.addShiftWiseReadingForm.get('nozzleListEntry')
	}


	getNozzleListOrdataByShift() {
		this.pipe = new DatePipe('en-US');
		this.entry_date = this.shift_date;
		this.entry_date = this.pipe.transform(this.entry_date, 'd-M-yy');
		this.shiftSaleService.getShifyWiseZozzleData(this.day_shift_no, this.shift_no, this.entry_date, this.petro_id)
			.subscribe(nozzlelistdata => {
				this.getNozzleList(nozzlelistdata.data, nozzlelistdata.status);
			});
	}

	getNozzleList(nozzleList: any, status: number) {
		this.nozzle_list_loading=true;
		this.addShiftWiseReadingForm = this.formBuilder.group({
			nozzleListEntry: this.formBuilder.array([])
		});
		this.nozzleListAllData = nozzleList;
		if(nozzleList)
		{
			this.nozzleListData = Object.keys(nozzleList).map(function (nozzleNamedIndex) {
				let nozzle = nozzleList[nozzleNamedIndex];
				return nozzle;
			});
		}
		if (status == 200) {
			this.invalid_shift = ""
			this.nozzleListData.forEach((nozzleData, index) => {
				//for (let a of this.commodityDropdownList ) {
					
				this.total_nozzle_sale = Number(nozzleData.ShiftWiseNozzle.testing) + Number(nozzleData.ShiftWiseNozzle.total_sale);

				this.nozzleListEntry = this.addShiftWiseReadingForm.get('nozzleListEntry') as FormArray;
				this.nozzleListEntry.push(
					this.formBuilder.group({
						id: [nozzleData.ShiftWiseNozzle.id],
						nozzle_id: [nozzleData.Nozzle.id, Validators.required],
						commodity_id: [nozzleData.Item.id, Validators.required],
						start_reading: [nozzleData.ShiftWiseNozzle.start_reading, Validators.required],
						close_reading: [nozzleData.ShiftWiseNozzle.closing_reading, Validators.required],
						testing: [nozzleData.ShiftWiseNozzle.testing],
						total_sale_tmp: [this.total_nozzle_sale, Validators.required],
						total_sale: [nozzleData.ShiftWiseNozzle.total_sale, Validators.required],
						rate: [nozzleData.Price.price, [Validators.required,Validators.min(1)]],
						amount: [nozzleData.ShiftWiseNozzle.amount, Validators.required],
					})
				);
				if(nozzleData.Price.price==nozzleData.ShiftWiseNozzle.price){
					
				}else{
					if(this.total_nozzle_sale)
					{
						
					this.calculateRow(this.total_nozzle_sale, index) ;
					}
				}
				
				

			});

		} else if (status == 201) {
			this.invalid_shift = ""
			this.nozzleListData.forEach((nozzleData, index) => {
				//for (let a of this.commodityDropdownList ) {

				this.nozzleListEntry = this.addShiftWiseReadingForm.get('nozzleListEntry') as FormArray;
				this.nozzleListEntry.push(
					this.formBuilder.group({
						nozzle_id: [nozzleData.Nozzle.id, Validators.required],
						commodity_id: [nozzleData.Item.id, Validators.required],
						start_reading: [nozzleData.Nozzle.start_reading, Validators.required],
						close_reading: [nozzleData.Nozzle.start_reading, Validators.required],
						testing: [''],
						total_sale_tmp: ['0', Validators.required],
						total_sale: ['0', Validators.required],
						rate: [nozzleData.Price.price, [Validators.required,Validators.min(1)]],
						amount: ['0', Validators.required],

					})
				);

			});
		} else if (status == 401) {
			this.invalid_shift = 'Please enter a valid shift id';
		}
		this.calculateTotalMsHsd();
		this.nozzle_list_loading=false;
		

	}

	
	setBozerSale() {
		this.getBozerList();
		$('#bozer_reading').modal('show');
	}

	bozerRefillForm = new FormGroup({
		    bozer_no: new FormControl('', Validators.required),
			quantity: new FormControl('', Validators.required),
			
	});

	editbozerRefillForm = new FormGroup({
		bozer_no: new FormControl('', Validators.required),
		quantity: new FormControl('', Validators.required),
		
});


viewBozerSale(id,index){

	this.index=index;
	this.shiftSaleService.getBozerTransferById(id).subscribe(data=>{
		//this.editBozerData.bozer_id=data.data.BozerTransfer.bozer_id;	
		this.editBozerData.bozer_no = {'value':data.data.BozerTransfer.bozer_id,'label': data.data.Bozer.name};
	//	this.editBozerData.bozer_no=data.data.BozerTransfer.bozer_id;	
		this.editBozerData.id=data.data.BozerTransfer.id;	
		this.editBozerData.quantity=data.data.BozerTransfer.quantity;	
		this.getBozerList();
			$('#edit_bozer_reading').modal('show');
	});

	

}


	addBozerTransfer() { 
		this.pipe = new DatePipe('en-US'); 
		this.addShiftData.date= this.pipe.transform(this.addShiftData.date, 'yyyy-MM-dd');
		this.bozerRefillForm.value['date'] = this.addShiftData.date;
		this.bozerRefillForm.value['shift'] = this.addShiftData.shift;
		this.bozerRefillForm.value['petro_id'] = this.petro_id;
		this.bozerRefillForm.value['day_shift'] = this.addShiftData.day_shift;
		this.bozerRefillForm.value['bozer_id'] = this.setBozerData.bozer_no.value;
// console.log(this.bozerRefillForm.value);

		 this.shiftSaleService.addBozerTransfer(this.bozerRefillForm.value)
		 .subscribe(editData => {
		 	if (editData.status == 201) {
			this.bozerRefillForm.reset();
				this.pageWiseData(1);
				$('#bozer_reading').modal('hide');
		 	}
		 });
	}

	editBozerTransfer() { 
		this.pipe = new DatePipe('en-US'); 
		this.addShiftData.date= this.pipe.transform(this.addShiftData.date, 'yyyy-MM-dd');
		this.editbozerRefillForm.value['date'] = this.addShiftData.date;
		this.editbozerRefillForm.value['shift'] = this.addShiftData.shift;
		this.editbozerRefillForm.value['petro_id'] = this.petro_id;
		this.editbozerRefillForm.value['day_shift'] = this.addShiftData.day_shift;
		this.editbozerRefillForm.value['bozer_id'] = this.editBozerData.bozer_no.value;
		this.editbozerRefillForm.value['id'] = this.editBozerData.id;
      // console.log(this.editbozerRefillForm.value);

		 this.shiftSaleService.editBozerTransfer(this.editbozerRefillForm.value)
		 .subscribe(editData => {
		 	if (editData.status == 201) {
			this.editbozerRefillForm.reset();
				this.pageWiseData(1);
				$('#edit_bozer_reading').modal('hide');
		 	}
		 });
	}

	setBozerData:any = {};
	editBozerData:any = {};

	getTotalSale(event, start_reading, nozzle_id, row) {
		this.nozzle_sale_calculated=false;
		this.sendDataForTotalSale['closing_reading'] = event.target.value;
		this.sendDataForTotalSale['start_reading'] = start_reading;
		this.sendDataForTotalSale['nozzle_id'] = nozzle_id;
		this.sendDataForTotalSale['petro_id'] = this.petro_id;
		this.sendDataForTotalSale['shift'] = this.addShiftData.shift;

		this.shiftSaleService.getCalculateSale(this.sendDataForTotalSale)
			.subscribe(saleData => {
				this.calculateRow(saleData.data, row);
			});
	}
	total_nozzle_sale_amt_str:string;
	calculateRow(sale, row) {
		this.nozzleListEntry = this.addShiftWiseReadingForm.get('nozzleListEntry') as FormArray;
		this.item_price = this.nozzleListEntry.value[row]['rate'];
		this.total_nozzle_testing = this.nozzleListEntry.value[row]['testing'];
		if(!this.total_nozzle_testing)
		{
			this.total_nozzle_testing=0;
		}
		this.total_nozzle_sale = sale - this.total_nozzle_testing;
		//console.log(sale);
		//console.log(this.total_nozzle_testing);
		this.total_nozzle_sale = this.total_nozzle_sale.toFixed(2);
		//this.total_nozzle_sale=sale-this.total_nozzle_testing;
		this.total_nozzle_sale_amt = this.item_price * this.total_nozzle_sale;
		this.total_nozzle_sale_amt=this.total_nozzle_sale_amt.toFixed(2);
		//this.total_nozzle_sale_amt=Number(this.total_nozzle_sale_amt_str);
	
		this.nozzleListEntry.controls[row].get('total_sale').setValue(this.total_nozzle_sale);
		this.nozzleListEntry.controls[row].get('total_sale_tmp').setValue(sale);
		this.nozzleListEntry.controls[row].get('amount').setValue(this.total_nozzle_sale_amt);
		this.nozzle_sale_calculated=true;	
		
		this.calculateTotalMsHsd();
		

	}
	
	onTestingRow(row){
		this.nozzle_sale_calculated=false;
		this.nozzleListEntry = this.addShiftWiseReadingForm.get('nozzleListEntry') as FormArray;
		this.item_price = this.nozzleListEntry.value[row]['rate'];
		this.total_nozzle_testing = this.nozzleListEntry.value[row]['testing'];

		this.total_nozzle_sale = this.nozzleListEntry.value[row]['total_sale_tmp'] - this.total_nozzle_testing;
		this.total_nozzle_sale = this.total_nozzle_sale.toFixed(2);
		//this.total_nozzle_sale=this.total_nozzle_sale-this.total_nozzle_testing;
		//this.total_nozzle_sale=sale-this.total_nozzle_testing;
		this.total_nozzle_sale_amt = this.item_price * this.total_nozzle_sale;
		this.total_nozzle_sale_amt=this.total_nozzle_sale_amt.toFixed(2);
		this.nozzleListEntry.controls[row].get('total_sale').setValue(this.total_nozzle_sale);
		this.nozzleListEntry.controls[row].get('amount').setValue(this.total_nozzle_sale_amt);
		this.nozzle_sale_calculated=true;
		this.calculateTotalMsHsd();
	}
	
	// Total MS Hsd Total for View
	total_ms_qty:any;
	total_hsd_qty:any;
	total_ms_amt:any;
	total_hsd_amt:any;
	total_bms_qty:any;
	total_bms_amt:any;
	calculateTotalMsHsd(){
		this.total_ms_qty=0;
		this.total_bms_qty=0;
		this.total_hsd_qty=0;
		this.total_ms_amt=0;
		this.total_bms_amt=0;
		this.total_hsd_amt=0;
		this.nozzleListEntry = this.addShiftWiseReadingForm.get('nozzleListEntry') as FormArray;
		if(this.nozzleListAllData)
		{
		
			this.nozzleListAllData.forEach((nozzleData, index) => {
				if(this.nozzleListEntry.value[index])
				{
					if(nozzleData.Item.item_category_id== constDefault.ITEM_CATEGORY_MS){
						if(this.nozzleListEntry.value[index]['total_sale']){
							this.total_ms_qty=Number(this.total_ms_qty)+Number(this.nozzleListEntry.value[index]['total_sale']);
							this.total_ms_amt=Number(this.total_ms_amt)+Number(this.nozzleListEntry.value[index]['amount']);
						}
						
					}
					if(nozzleData.Item.item_category_id==constDefault.ITEM_CATEGORY_BMS){
						if(this.nozzleListEntry.value[index]['total_sale']){
							this.total_bms_qty=Number(this.total_bms_qty)+Number(this.nozzleListEntry.value[index]['total_sale']);
							this.total_bms_amt=Number(this.total_bms_amt)+Number(this.nozzleListEntry.value[index]['amount']);
						}
						
					}
					if(nozzleData.Item.item_category_id== constDefault.ITEM_CATEGORY_HSD || nozzleData.Item.item_category_id==constDefault.ITEM_CATEGORY_BHSD){
						if(this.nozzleListEntry.value[index]['total_sale']){
							this.total_hsd_qty=Number(this.total_hsd_qty)+Number(this.nozzleListEntry.value[index]['total_sale']);
							this.total_hsd_amt=Number(this.total_hsd_amt)+Number(this.nozzleListEntry.value[index]['amount']);
						}
						
					}
				}
			});
			this.total_ms_qty=Number(this.total_ms_qty).toFixed(2);
			this.total_hsd_qty=Number(this.total_hsd_qty).toFixed(2);
			this.total_ms_amt=Number(this.total_ms_amt).toFixed(2);
			this.total_hsd_amt=Number(this.total_hsd_amt).toFixed(2);
			
	
		}
	}

	getBozerList()
	{
		this.bozer_list =[];
	   // alert(this.addSaleData.customer_id.value);
	   // alert( $('#venicle_no_id').text());
				this.commonsServices.getBozerList(this.petro_id)
			   .subscribe(vehicleList => {
				   
				   if (vehicleList.status == 200) {
					   
				   this.bozer_list = Object.entries(vehicleList.data).map(([value, label]) => ({value,label}));
					   
				   }
			   });

		
	}

	addNozzleReadingStock() {
		this.nozzle_list_loading=true;
		 this.pipe = new DatePipe('en-US'); 
		this.addShiftData.date= this.pipe.transform(this.addShiftData.date, 'yyyy-MM-dd');
		this.addShiftWiseReadingForm.value['date'] = this.addShiftData.date;
		this.addShiftWiseReadingForm.value['shift'] = this.addShiftData.shift;
		this.addShiftWiseReadingForm.value['petro_id'] = this.petro_id;
		this.addShiftWiseReadingForm.value['day_shift'] = this.addShiftData.day_shift;

		// Nozzle Reading Entry
		if(this.nozzle_sale_calculated)
		{
		this.shiftSaleService.shiftWiseNozzleDateentry(this.addShiftWiseReadingForm.value)
			.subscribe(nozzlelistdata => {
				if (nozzlelistdata.status == 200) {
					this.error_message="";
					this.success_message = "Nozzle Reading added sucessfully";
					this.getNozzleListOrdataByShift();
				} else {
					this.success_message="";
					this.error_message = "Nozzle Reading could not be added ";
					
				}
				this.nozzle_list_loading=false;
			});
		}else{
			this.nozzle_list_loading=false;
			this.success_message="";
			this.error_message = "Nozzle Reading could not be added ";
			
		}
			

	}

	// Get Nozzle List Fields for (Edit) Form Array
	get dsmEmployeeListForm() {
		return this.assignNozzleForm.get('dsmListEntry')
	}

	// Assign DSM
	nozzle_assign:boolean;
	assign_dsm_to_employee() {
		this.assignNozzleForm.setControl('dsmListEntry', this.formBuilder.array([]));
		this.pipe = new DatePipe('en-US');
		this.entry_date = this.addShiftData.date;
		this.entry_date = this.pipe.transform(this.entry_date, 'd-M-yy');

		this.shiftSaleService.getDsmData(this.petro_id)
			.subscribe(dsmListInfo => {
				this.dsmList = dsmListInfo.data;
			});
			
			this.shiftSaleService.getShiftNozzleAssignedList(this.petro_id, this.addShiftData.shift)
			.subscribe(nozzlelistInfo => {
				if(nozzlelistInfo.status==200)
					{
						this.nozzle_assign=true;
					this.nozzleList = nozzlelistInfo.data;
				
					for (let dsm_entry of this.nozzleList) {
						this.dsmListEntry = this.assignNozzleForm.get('dsmListEntry') as FormArray;
						
						this.dsmListEntry.push(
							this.formBuilder.group({
								nozzle_id: [dsm_entry.NozzlesAssign.id, Validators.required],
								nozzle_name: [dsm_entry.Nozzle.name, Validators.required],
								dsm_id: [dsm_entry.NozzlesAssign.dsm_id, Validators.required]
							})
						);
					}
				}else{
					this.nozzle_assign=false;
				}
			});
			// Get Nozzle list
			if(this.nozzle_assign==false)
			{
				console.log('set');
		this.shiftSaleService.getShifyWiseZozzleData(this.addShiftData.day_shift, this.addShiftData.shift, this.entry_date, this.petro_id)
			.subscribe(nozzlelistInfo => {
				this.nozzleList = nozzlelistInfo.data;
			
				for (let dsm_entry of this.nozzleList) {
					this.dsmListEntry = this.assignNozzleForm.get('dsmListEntry') as FormArray;
					
					this.dsmListEntry.push(
						this.formBuilder.group({
							nozzle_id: [dsm_entry.Nozzle.id, Validators.required],
							nozzle_name: [dsm_entry.Nozzle.name, Validators.required],
							dsm_id: ['', Validators.required]
						})
					);
				}
			});
			}
		
		$('#assignDsmToNozzle').modal('show');
	}

	assignDsm() {
		this.assignNozzleForm.value['date'] = this.addShiftData.date;
		this.assignNozzleForm.value['shift'] = this.addShiftData.shift;
		this.assignNozzleForm.value['petro_id'] = this.petro_id;
		this.assignNozzleForm.value['day_shift'] = this.addShiftData.day_shift;
		//console.log(this.assignNozzleForm.value);

		//Nozzle Reading Entry
		this.shiftSaleService.assignDsmData(this.assignNozzleForm.value)
			.subscribe(dsmlistdata => {
				if (dsmlistdata.status == 200) {
					this.success_message = "DSM added sucessfully";
				} else {
					this.error_message = "DSM could not be added ";
				}

			});
		$('#assignDsmToNozzle').modal('hide');
	}
	currentPage: number;
	list_loading:boolean;
	voucherList: any[] = [];
	totalPayVoucher: number;

	pageWiseData(pageno) {
		this.list_loading=true;
		this.currentPage = pageno;
		this.voucherList =[];
		this.shiftSaleService.bozerTransferList(this.currentPage, this.shift_no, this.day_shift_no, this.petro_id)
			.subscribe(alldata => {
				//if(alldata){
				this.voucherList = alldata.data,
				this.totalPayVoucher = alldata.count,
				this.list_loading=false
			});
	}
	index: number;
	model: string;
	deleteVoucher(id, index) {
		if (confirm("Are you sure to delete this Transfer Entry")) {
			this.index = index;
			this.model = 'BozerTransfer';
			this.masterService.deleteDataByModel(id,this.model).subscribe(data=>{
				//console.log(data);
				if(data.status==true)
				{
					this.voucherList.splice(this.index,1);
					this.success_message="Transfer Entry deleted sucessfully";	
				}else{
					this.error_message= "Transfer Entry could not be deleted.";
				}
			});
		}
	}
}
