import { Component,	OnInit } from '@angular/core';
import { FormGroup, FormControl,	Validators,	FormBuilder, FormArray } from '@angular/forms';
import { LocalStorageService,SessionStorageService,LocalStorage,	SessionStorage } from 'angular-web-storage';
import { AccountService } from '../../admin-services/account.service';
import { CommonsService } from '../../admin-services/commons.service';
import { ShiftSaleService } from '../../admin-services/shift-sale.service';
import { ApplicationSetupService } from '../../admin-services/application-setup.service';
import { constDefault } from '../../const_default';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { DatePipe } from '@angular/common';
import { Router} from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DatepickerOptions } from 'ng2-datepicker';
 //import * as enLocale from 'date-fns/locale/en';
 import * as frLocale from 'date-fns/locale/en';
declare var $: any;
@Component({
  selector: 'app-application-setup',
  templateUrl: './application-setup.component.html',
  styleUrls: ['./application-setup.component.css']
})
export class ApplicationSetupComponent implements OnInit {

	// Options For To Date (Search)
	options_form: DatepickerOptions = {
	 minYear: 2015,
	 maxYear: 2030,
	 displayFormat:'MMM D[,] YYYY',
	 barTitleFormat: 'MMMM YYYY',
	 dayNamesFormat: 'dd',
	 firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
	locale: frLocale,
 
	 //minDate: new Date(Date.now()), // Minimal selectable date
	 //maxDate: new Date(Date.now()),  // Maximal selectable date
 
	 barTitleIfEmpty: 'Click to select a date',
	 placeholder: 'From Date', // HTML input placeholder attribute (default: '')
	 addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
	 addStyle: {
		'width': '100%'
	 }, // Optional, value to pass to [ngStyle] on the input field
	 fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
	 useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};
 
	// Options For To Date (Search)
	options_to: DatepickerOptions = {
	 minYear: 2015,
	 maxYear: 2030,
	 displayFormat: 'DD-MM-YYYY',
	 barTitleFormat: 'MMMM YYYY',
	 dayNamesFormat: 'dd',
	 firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
     locale: frLocale,
 
	 //minDate: new Date(Date.now()), // Minimal selectable date
	 //maxDate: new Date(Date.now()),  // Maximal selectable date
 
	 barTitleIfEmpty: 'Click to select a date',
	 placeholder: 'To Date', // HTML input placeholder attribute (default: '')
	 addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
	 addStyle: {
		'width': '100%'
	 }, // Optional, value to pass to [ngStyle] on the input field
	 fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
	 useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};
 
	// Options Date (Modal)
	options: DatepickerOptions = {
	 minYear: 2015,
	 maxYear: 2030,
	 displayFormat: 'DD-MM-YYYY',
	 barTitleFormat: 'MMMM YYYY',
	 dayNamesFormat: 'dd',
	 firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
	locale: frLocale,
 
	 //minDate: new Date(Date.now()), // Minimal selectable date
	 //maxDate: new Date(Date.now()),  // Maximal selectable date
 
	 barTitleIfEmpty: 'Click to select a date',
	 placeholder: 'Select Date', // HTML input placeholder attribute (default: '')
	 addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
	 addStyle: {
		'width': '100%'
	 }, // Optional, value to pass to [ngStyle] on the input field
	 fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
	 useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};

  constructor(private accountService: AccountService, private router:Router, private shiftSaleService: ShiftSaleService,private applicationService: ApplicationSetupService,  private session: SessionStorageService,private formBuilder: FormBuilder) {}
	public Editor=ClassicEditor;
  addPurchaseDetailsForm:FormGroup;
  generalSetupForm:FormGroup;
  saleSetupForm:FormGroup;
  tcsSetupForm:FormGroup;
  invoiceSetupForm:FormGroup;
  tempShiftCloseForm:FormGroup;
  authData:any = {};
  
  pipe: any;
  petro_id:number;
  addPurchaseData:any={};
  generalSetupData:any={};
  saleSetupData:any={};
  tcsSetupData:any = {};
  invoiceSetupData:any={};
  success_message: string;
	error_message: string;
	addShiftData:any={};

  ngOnInit() {
	 
    this.authData = this.session.get('authData');
    this.petro_id = this.authData.petro_id;
    this.addPurchaseDetailsForm=this.formBuilder.group({
			ms_vat_percent: new FormControl(' '),
			ms_dly_taxable: new FormControl(' '),
			ms_cess_fixed: new FormControl(' '),
			ms_lfr_svt: new FormControl(' '),
			hsd_vat_percent: new FormControl(' '),
			hsd_dly_taxable: new FormControl(' '),
			hsd_cess_fixed: new FormControl(' '),
			hsd_lfr_svt: new FormControl(' '),
			bms_vat_percent: new FormControl(' '),
			bms_dly_taxable: new FormControl(' '),
            bms_cess_fixed: new FormControl(' '),
			bms_lfr_svt: new FormControl(' '),
			bhsd_vat_percent: new FormControl(' '),
			bhsd_dly_taxable: new FormControl(' '),
			bhsd_cess_fixed: new FormControl(' '),
            bhsd_lfr_svt: new FormControl(' '),
            post_sslf: new FormControl(' ')   
    });
	
	this.generalSetupForm=this.formBuilder.group({
			dsm_wise_track: new FormControl(' '),
			intrest_cal_from: new FormControl(' '),
			reading_lock: new FormControl(' '),
			show_bozer: new FormControl(' '),
			
    });
	this.saleSetupForm=this.formBuilder.group({
			reset_cust_name: new FormControl(' '),
			reset_item_name: new FormControl(' '),
			show_receipts_in_invoice: new FormControl(' '),
			
    });

	this.tcsSetupForm=this.formBuilder.group({
		tcs_per: new FormControl(' '),
		tcs_slab: new FormControl(' '),
		calculate_tcs_purchase: new FormControl(' '),
		calculate_tcs_sale: new FormControl(' '),
    });
	this.invoiceSetupForm=this.formBuilder.group({
			bank_details: new FormControl(' '),			
			term: new FormControl(' '),			
			slip_no_manual: new FormControl(' '),			
			credit_inv_prefix: new FormControl(' '),			
			gst_credit_inv_prefix: new FormControl(' '),			
			credit_inv_start_from: new FormControl(' ',Validators.pattern('^[0-9]*$')),			
			gst_credit_inv_start_from: new FormControl(' ',Validators.pattern('^[0-9]*$')),			
			other_inv_prefix: new FormControl(' '),			
			other_inv_start_from	: new FormControl(' ',Validators.pattern('^[0-9]*$')),			
			cash_inv_prefix: new FormControl(' '),			
			cash_inv_start_from: new FormControl(' ',Validators.pattern('^[0-9]*$')),			
			indent_no_head: new FormControl(' '),			
    });
	
	//shift changed
		this.tempShiftCloseForm=this.formBuilder.group({
				id: new FormControl('', Validators.required),	
				shift: new FormControl('', Validators.required),	
		    	day_shift:new FormControl('', Validators.required),
				date: new FormControl('', Validators.required),
			//	new_shift: new FormControl('', Validators.required),	
				new_day_shift:new FormControl('', Validators.required),
				new_date: new FormControl('', Validators.required),
        });
		
		
    this.addShiftData.date = new Date(Date.now());
	 this.addShiftData.new_date = new Date(Date.now());
		
    this.addPurchaseData.post_sslf=0;
    this.getPurchaseDetails();
    this.getInvoiceSetupDetails();
    this.getGeneralSetupDetails();
    this.geSaleSetupDetails();
	this.getTCSSetupDetails();
	
	
	
	 // Get Current Shift Data
		this.shiftSaleService.getCurrentShiftData(this.petro_id)
			.subscribe(currentShiftData => {
		
				this.addShiftData = currentShiftData.data.DailyShift;
				
			});
  
		this.generalSetupData.dsm_wise_track=0;
		this.invoiceSetupData.slip_no_manual=0;
		this.saleSetupData.reset_cust_name=0;
		this.saleSetupData.reset_item_name=0;
		this.saleSetupData.show_receipts_in_invoice=0;

  }

  // get purchase data 
  getPurchaseDetails(){
    
      this.applicationService.getPurchaseData(this.petro_id)
		.subscribe(data =>{
      if(data.status==200){
      data.data.forEach((data, index) => {
        if(data.AppSetupPur.category_id==1){
          this.addPurchaseData.ms_vat_percent = data.AppSetupPur.vat;
          this.addPurchaseData.ms_dly_taxable = data.AppSetupPur.dly_taxabled;
          this.addPurchaseData.ms_cess_fixed = data.AppSetupPur.cess_fixed;
          this.addPurchaseData.ms_lfr_svt = data.AppSetupPur.lfr_svt;
        }
        if(data.AppSetupPur.category_id==2){
          this.addPurchaseData.hsd_vat_percent = data.AppSetupPur.vat;
          this.addPurchaseData.hsd_dly_taxable = data.AppSetupPur.dly_taxabled;
          this.addPurchaseData.hsd_cess_fixed = data.AppSetupPur.cess_fixed;
          this.addPurchaseData.hsd_lfr_svt = data.AppSetupPur.lfr_svt;
        }
        if(data.AppSetupPur.category_id==3){
          this.addPurchaseData.bms_vat_percent = data.AppSetupPur.vat;
          this.addPurchaseData.bms_dly_taxable = data.AppSetupPur.dly_taxabled;
          this.addPurchaseData.bms_cess_fixed = data.AppSetupPur.cess_fixed;
          this.addPurchaseData.bms_lfr_svt = data.AppSetupPur.lfr_svt;
        }
        if(data.AppSetupPur.category_id==4){
          this.addPurchaseData.bhsd_vat_percent = data.AppSetupPur.vat;
          this.addPurchaseData.bhsd_dly_taxable = data.AppSetupPur.dly_taxabled;
          this.addPurchaseData.bhsd_cess_fixed = data.AppSetupPur.cess_fixed;
          this.addPurchaseData.bhsd_lfr_svt = data.AppSetupPur.lfr_svt;
        }
        this.addPurchaseData.post_sslf=data.AppSetupPur.post_sslf;
      });
      }else{
        this.error_message='Server Error Occurred.';
      }
	 	});
  }

  // Add purchase setup data
  addPurchaseDetails(){
    this.authData = this.session.get('authData');
    this.addPurchaseData.petro_id=this.authData.petro_id;
    this.addPurchaseData.ms=1;
    this.addPurchaseData.bms=2;
    this.addPurchaseData.hsd=3;
    this.addPurchaseData.bhsd=4;
    this.applicationService.addPurchaseSetup(this.addPurchaseData).subscribe(data=>{
			if(data.status==201)
			{
				this.getPurchaseDetails();
						this.success_message='Purchase Details has been Added Successfully';
			  }else{
				this.error_message='Purchase Details could not be Added';
			  }
		});
  
  }
  /*
  Amit Sahu
  add application setup invoice data
  */
  
  addInvoiceSetup(){
	  this.invoiceSetupData.petro_id=this.authData.petro_id;
	  this.applicationService.addInvoiceSetup(this.invoiceSetupData).subscribe(data=>{
			if(data.status==201)
			{
				
						this.success_message='Invoice Setup has been Added Successfully';
			  }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
				this.error_message='Invoice Setup could not be Added';
			  }
		});
  }
  
  
  // Get invoice setp data
  getInvoiceSetupDetails(){
	   this.applicationService.getInvoiceSetupData(this.petro_id)
		.subscribe(data =>{
		  if(data.status==200){
			
			this.invoiceSetupData=data.data.AppSetupInvoice;
			this.invoiceSetupData.bank_details=data.data.AppSetupInvoice.bank_detail;
		  }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.error_message='Server Error Occurred.';
		  }
	 	});
  }
  
  updateGeneralSetup(){
	  
	   this.generalSetupData.petro_id=this.authData.petro_id;
	  this.applicationService.addGeneralSetup(this.generalSetupData).subscribe(data=>{
			if(data.status==201)
			{
				
						this.success_message='General Setup has been Added Successfully';
			  }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
				this.error_message='General Setup could not be Added';
			  }
		});
  }
   // Get general setup data
  getGeneralSetupDetails(){
	   this.applicationService.getGeneralSetupData(this.petro_id)
		.subscribe(data =>{
		  if(data.status==200){
			
			this.generalSetupData=data.data.AppSetupGeneral;

		  }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			//this.error_message='Server Error Occurred.';
		  }
	 	});
  }
  
changedShift(){
	
	this. petro_id=this.authData.petro_id;
    	this.pipe = new DatePipe('en-US'); 
		this.addShiftData.date= this.pipe.transform(this.addShiftData.date, 'yyyy-MM-dd');
		this.addShiftData.new_date= this.pipe.transform(this.addShiftData.new_date, 'yyyy-MM-dd');
		//console.log( this.addShiftData);
	  this.applicationService.changedDsr(this.petro_id,this.addShiftData).subscribe(data=>{
			if(data.status==200)
			{
				
						this.success_message='Updated Successfully';
			  }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
				this.error_message='Could not be Updated';
			  }
		});
	
}
//setting for sale 
   updateSaleSetup(){
	  
	   this.saleSetupData.petro_id=this.authData.petro_id;
	  this.applicationService.addSaleSetup(this.saleSetupData).subscribe(data=>{
			if(data.status==201)
			{
				
						this.success_message='Sale Setup has been Added Successfully';
			  }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
				this.error_message='Sale Setup could not be Added';
			  }
		});
  }
  // Get sale setup data
  geSaleSetupDetails(){
	   this.applicationService.getSaleSetupData(this.petro_id)
		.subscribe(data =>{
		  if(data.status==200){
			
			this.saleSetupData=data.data.AppSetupSale;

		  }else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.error_message='Server Error Occurred.';
		  }
	 	});
  }

  updateTCSSetup(){
	this.tcsSetupData.petro_id = this.authData.petro_id;
	this.applicationService.addTCSSetup(this.tcsSetupData).subscribe(data=>{
		if (data.status == 200) {
			this.success_message = 'TCS Setup has been Added Successfully';
		}
		else if (data.status == 403) {
			this.session.remove('authData');
			this.router.navigate(['']);
		}
		else {
			this.error_message = 'TCS Setup could not be Added';
		}
	});
  }

  getTCSSetupDetails(){
	this.applicationService.getTCSSetupData(this.petro_id)
		.subscribe(data =>{
		if (data.status == 200) {

			this.tcsSetupData = data.data.AppSetupTcs;
			
		}
		else if (data.status == 403) {
			this.session.remove('authData');
			this.router.navigate(['']);
		}
		else {
			this.error_message = 'Server Error Occurred.';
		}
	});
  }

  
}
