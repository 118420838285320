import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { constDefault } from '../const_default';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

interface getData{
  data:any,
  status:number
}

interface serviceDataSale {
  data: any;
  count: number;
}

interface successData {
  data: any;
  status: number;
}

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
	headersdata:any={};
		authValue:any={};
		user_id:string;
		login_token:string;

    login_year:string;
    
 constructor(private http: HttpClient, private session:SessionStorageService, private local_storage:LocalStorageService) {


		this.authValue = this.local_storage.get('localAuthData');
  //console.log(this.authValue);
  if(this.authValue){
    this.user_id=this.authValue.user_id;
    this.login_token=this.authValue.token;
    this.login_year=this.authValue.financial_year_id;
    // console.log(this.login_year);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('user', this.user_id);
    headers = headers.append('year', this.login_year);
    this.headersdata = headers.append('token', this.login_token);
  }
 }
 
  
   /*
  Get Item Data By Id For Sale 
  15.12.18
  Amit Sahu  
  */
  getItemDetailsByItemIdForSale(item_id:any){
	return this.http.get<getData>(constDefault.API_URL+"/petros/getItemDetailsByForSale/"+item_id+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
   /*
  Add Purchase
  27.12.18
  Amit Sahu  
  */
  addPurchaseData(purchaseData:any){
	return this.http.post<getData>(constDefault.API_URL+"/petros/addPurchase/"+this.login_year,purchaseData,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
     /*
  Add MS HSD Purchase
  27.12.18
  Amit Sahu  
  */
  addMsHsdPurchaseData(purchaseData:any){
	return this.http.post<getData>(constDefault.API_URL+"/petros/addMsHsdPurchase/"+this.login_year,purchaseData,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
  /*
  Get Purchase List
  22.12.18
  Amit Sahu  
  */
  getPurchaseList(pages:number,petro_id:number,purchaseSearch:any){
	return this.http.post<serviceDataSale>(constDefault.API_URL+'/petros/purchaseList/page:'+pages+'/'+petro_id+'/'+this.login_year,purchaseSearch,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
  /*
  Get Purchase Details By Id
  22.12.18
  Amit Sahu  
  */
  getPurchaseDataById(purchase_id:number){
	return this.http.get<serviceDataSale>(constDefault.API_URL+'/petros/getPurchaseDetailById/'+purchase_id+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
  /*
  Delete Purchase
  28.12.18
  Amit Sahu  
  */
  deletePurchase(purchase_id:number){
	return this.http.get<successData>(constDefault.API_URL+'/petros/deletePurchase/'+purchase_id+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
  /*
  Get Taxes Details By Item and Petro_id
  28.12.18
  Amit Sahu  
  */
  getItemTaxesDetailByItem(item_id:number,petro_id:number){
	return this.http.get<successData>(constDefault.API_URL+'/petros/getAppSetupPurchaseData/'+item_id+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
   /*
  Get Tank List By Item 
  28.12.18
  Amit Sahu  
  */
  getTankListByItem(item_id:number,petro_id:number){
	return this.http.get<successData>(constDefault.API_URL+'/petros/getTankByItemId/'+item_id+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
   /*
  Get Invoice List by Party or item 
  02.02.2019
  Amit Sahu  
  */
  getInvoiceListByCustomerAndItem(item_id:number,petro_id:number,party_id:number){
	return this.http.get<successData>(constDefault.API_URL+'/petros/getInvoiceNoByCustId/'+party_id+'/'+item_id+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
    /*
  Get Purchase details by purchase details id
  02.02.2019
  Amit Sahu  
  */
  getPurchaseHistoryByPurchaseDetailId(tetail_id:number){
	return this.http.get<successData>(constDefault.API_URL+'/petros/getPurchaseDetailDataById/'+tetail_id+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
  /*
  Add Credit note
  04.02.19
  Amit Sahu  
  */
  addCreditNote(addCreditNoteData:any){
	return this.http.post<getData>(constDefault.API_URL+"/petros/addCreditNote/"+this.login_year,addCreditNoteData,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
   /*
  Get Credit Note List
  04.02.19
  Amit Sahu  
  */
  getCreditNoteList(pages:number,petro_id:number,crnSearch:any){
	return this.http.post<serviceDataSale>(constDefault.API_URL+'/petros/creditNoteList/page:'+pages+'/'+petro_id+'/'+this.login_year ,crnSearch,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
    /*
  Delete Purchase
  28.12.18
  Amit Sahu  
  */
  deleteCreditNote(crnid:number){
	return this.http.get<successData>(constDefault.API_URL+'/petros/deleteCreditNote/'+crnid+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
   /*
  Get Credit Note data By Id
  04.02.19
  Amit Sahu  
  */
  getCreditNoteDataById(crnid:number){
	return this.http.get<serviceDataSale>(constDefault.API_URL+'/petros/getCreditNoteDataById/'+crnid+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
}
