import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { ShiftSaleService } from '../../admin-services/shift-sale.service';
import { CommonsService } from '../../admin-services/commons.service';
import { FormGroup,FormControl,Validators,FormArray,FormBuilder } from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router} from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { DatePipe } from '@angular/common';
import * as enLocale from 'date-fns/locale/en';
declare var $: any;

@Component({
  selector: 'app-paricelist-master',
  templateUrl: './paricelist-master.component.html',
  styleUrls: ['./paricelist-master.component.css']
})
export class ParicelistMasterComponent implements OnInit {
	
	options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		//locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
	  	//maxDate: new Date(Date.now()),  // Maximal selectable date
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Expiry Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};
 

  constructor(private shiftSaleService:ShiftSaleService,  private masterService: MasterServicesService, private commonsServices:CommonsService,private router:Router,private session:SessionStorageService, private formBuilder:FormBuilder) {  }

  addPriceData:any={};
  addPriceFormData:any={};
  serachPriceListData:any={};
  authData:any={};
  itemError:any={};
  commodityDropdownList:any[]=[];
  editCommodityDropdownList:any[]=[];
  priceList:any[]=[];
  editPriceDate:any;
  editPriceShift:any;
  add_loading:boolean;
  //commodityDropdownList:any[]=[];
  petro_id:number;
  editPriceList:any[]=[];
  	success_message:string;	
	error_message:string;	
	currentPage:number;	
	totalData:number;	
	priceValerror:boolean;	
	i:number;	
	pipe:any;	
	editPriceFormData:any={};
	Item: FormArray;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	editPriceForm= new FormGroup({
		date : new FormControl(' ',Validators.required),
		shift_no : new FormControl(' ',Validators.required),
		Item: this.formBuilder.array([])
	});	
   // addPriceForm:FormGroup;
	
	addPriceForm:FormGroup;
	
  
  ngOnInit() {
	  
	  // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
		
	  	this.authData = this.session.get('authData');
		this.petro_id=this.authData.petro_id;
		// Get MS and HSD Item list
		this.masterService.getItemListForPriceUpdate(this.petro_id)
		.subscribe(listdata =>{ this.commodityDropdownList=listdata.data
		});
		
		//console.log(this.categories);
		this.pageWisePriceList(1);
		this.shiftSaleService.getCurrentShiftData(this.petro_id)
		.subscribe(currentShiftData => {
		//	console.log(currentShiftData);
			this.addPriceFormData.shift_no = currentShiftData.data.DailyShift.shift;
			this.addPriceFormData.date = currentShiftData.data.DailyShift.date;
		}); 
		this.addPriceFormData.date= new Date(Date.now())
		this.priceValerror=true;
		this.addPriceForm=new FormGroup({
            date: new FormControl('', Validators.required),
            shift_no: new FormControl('', Validators.required)
        });
	 
  }
  
  pushItemPrice(event:any,index:number){
	  //alert(data.value);
	  //console.log(event.target.value);
	  //console.log(index);
	  this.addPriceData=this.commodityDropdownList;	 
	  this.addPriceData[index].Item.price=event.target.value;
	  if(event.target.value)
	  {
		this.itemError[index]=false;	
	  }else{
		  this.itemError[index]=true;	
	  }
  }
  
  addPrice(add_price){
	  //console.log(this.addPriceFormData.date);
	  this.add_loading=true;
	  add_price.submitted=false;
	 this.priceValerror=true;
	 this.i=0;
	 for(let item of this.commodityDropdownList)
	 {		 
		 //alert(item.Item.name);
		// console.log(item.Item);
		if(!item.Item.price)
		{
			this.itemError[this.i]=true;	
			this.priceValerror=false;
		}else{
			this.itemError[this.i]=false;	
		}
		this.i=this.i+1;
	 }	 
	  if(this.priceValerror==true)
	  {
		  if(this.addPriceFormData.date==null){
			this.addPriceFormData.date = new Date(Date.now());
		  }
	  this.addPriceFormData.Item=this.addPriceData;
	  this.addPriceFormData.petro_id=this.petro_id;
	  //this.addPriceFormData.date= new Date(Date.now())
	  //console.log(this.addPriceData); 
	    this.pipe = new DatePipe('en-US'); 
		this.addPriceFormData.date= this.pipe.transform(this.addPriceFormData.date, 'yyyy-MM-dd');
	  this.masterService.addPriceData(this.addPriceFormData).subscribe(data=>{
		  
			if(data.status==200 || data.status==201)
			{
				this.success_message='Price has been added';
				this.addPriceForm.reset(); 
				this.addPriceFormData.date= new Date(Date.now());
				$('#addItemCategoryModal').modal('hide');
				this.pageWisePriceList(1);				
			}
			if(data.status==204)
			{
				this.error_message='Invalid Date or Shift';
								
			}
			else{
				this.error_message='Price could not be added';
			}
		});
	  }else{
		  this.error_message='Please enter price for all item';
	  }
	  this.add_loading=false;
  }
   
  /*
  Get Price list show
  Amit Shau
  29.11.18 
  */
    pageWisePriceList(pageno){
	   this.currentPage=pageno;
	   this.masterService.getPriceListData(pageno,this.petro_id,this.serachPriceListData)
		.subscribe(alldata =>{
			this.priceList=alldata.data,
			this.totalData=alldata.count	
			//console.log(this.priceList);
	 	});	
    }
	
	
	// // Get Alredy Set price for this shift
	getOldPrice(){
		if(this.addPriceFormData.shift_no)
		{ 
		this.addPriceFormData.Item=this.commodityDropdownList;
	  this.addPriceFormData.petro_id=this.petro_id;
	  //this.addPriceFormData.date= new Date(Date.now())
	 // console.log(this.addPriceData); 
	  this.masterService.getExistPriceData(this.addPriceFormData).subscribe(data=>{		
			this.commodityDropdownList=data.data;
		});
		} 
	}

	// getEditItemPrice(shift,date){ 
	// 	//this.editPriceDate = date;
	// 	//this.editPriceShift = shift;
	// 	this.editPriceFormData.date = date;
	// 	this.editPriceFormData.shift_no = shift; 
	// 	$('#editItemCategoryModal').modal('show');	
	// 	this.masterService.getEditPriceData(shift,date,this.petro_id)
	// 	.subscribe(data=>{		
	// 		this.editCommodityDropdownList=data.data;
	// 		console.log(data);
	// 	});
	// 	// if(id)
	// 	// {
	// 	// this.editPriceFormData.Item=this.commodityDropdownList;
	//   // this.editPriceFormData.petro_id=this.petro_id;
	//   // //this.addPriceFormData.date= new Date(Date.now())
	//   // //console.log(this.editPriceFormData); 
	//   // this.masterService.getExistPriceData(this.editPriceFormData).subscribe(data=>{		
	// 	// 	this.commodityDropdownList=data.data;
	// 	// });
	// 	// } 
	// }

	pushEditItemPrice(event:any,index:number){
		//alert(data.value);
		//console.log(event.target.value);
		//console.log(index);
		this.editPriceFormData=this.editCommodityDropdownList;
		this.editPriceFormData[index].Price.price=event.target.value;
	//	console.log(this.editPriceFormData); 
		if(event.target.value)
		{
		  this.itemError[index]=false;	
		}else{
			this.itemError[index]=true;	
		} 
	}

	

	// Get Nozzle List Fields for (Edit) Form Array
	get priceListForm() {
		return this.editPriceForm.get('Item')
	} 
	
	getEditItemPrice(shift,date) {
		this.editPriceForm.setControl('Item', this.formBuilder.array([]));
		this.editPriceFormData.date = date;
		this.editPriceFormData.shift_no = shift; 
		
		// Get Price list
		this.masterService.getEditPriceData(shift,date,this.petro_id)
			.subscribe(priceListInfo => {
				this.editPriceList = priceListInfo.data;

				//console.log(this.editPriceList);
				for (let price_entry of this.editPriceList) {
					this.Item = this.editPriceForm.get('Item') as FormArray;
					
					this.Item.push(
						this.formBuilder.group({
							item_id: [price_entry.Item.id, Validators.required],
							item_name: [price_entry.Item.name, Validators.required],
							price_id: [price_entry.Price.id, Validators.required],
							item_price: [price_entry.Price.price, Validators.required]
						})
					);
				}
		});
		$('#editItemCategoryModal').modal('show');
	}

	editPrice(editPricesub){
		editPricesub.submitted=false;
		//this.editPriceFormData=this.editCommodityDropdownList;
		//console.log(this.editPriceForm.value['Item']);
		//return;
		if(!this.editPriceFormData.date || !this.editPriceFormData.shift_no){
			return this.error_message='Invalid Date or Shift';			
		}
		this.masterService.editPriceData(this.editPriceForm.value).subscribe(data=>{		  
			if(data.status==200)
			{
				this.success_message='Price has been Updated';
				this.addPriceForm.reset(); 
				$('#editItemCategoryModal').modal('hide');
				//this.addPriceFormData.date= new Date(Date.now())
				this.pageWisePriceList(1);				
			}
			else if(data.status==204)
			{
				this.error_message='Invalid Date or Shift';
								
			}
			else{
				this.error_message='Price could not be added';
			}
		});
	}
  
}
