import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators ,FormBuilder, FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
import { CommonsService } from '../../../admin-services/commons.service';
import { AccountService } from '../../../admin-services/account.service';
import { DatePipe } from '@angular/common';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
import { Router} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-stock-variation',
  templateUrl: './stock-variation.component.html',
  styleUrls: ['./stock-variation.component.css']
})
export class StockVariationComponent implements OnInit {

  constructor(private router:Router, private commonsServices: CommonsService, private session: SessionStorageService, private formBuilder: FormBuilder,private accountService:AccountService) {}
options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		// locale: enLocale,
		//	minDate: new Date(Date.now()), // Minimal selectable date
		maxDate: new Date(Date.now()), // Maximal selectable date
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {
			'width': '100%'
		}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 

	};
	error_message:string;
	success_message:string;
	stock_transfer_error:string;
	pipe:any;
	stockTransferData: any = {};
	authData: any = {};
	petro_id: number;
	totalData: number;
	currentPage: number;
	variationList:any[]=[];
	itemAutoSugestList:any[]=[];
	stock_transfer_loading:boolean;
	stockTransferForm: FormGroup;
	addVaritionItemList: FormArray;	
	sale_success_error:any;
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
  ngOnInit() {
	  // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		
	  this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		
		this.stockTransferForm = this.formBuilder.group({
			date: new FormControl('', Validators.required),
			addVaritionItemList: this.formBuilder.array([this.addTransferItem()])
		});
		this.stockTransferData.date = new Date(Date.now());
		this.pageWiseVariationList(1);
  }
   addTransferItem(): FormGroup {
		return this.formBuilder.group({
			item_id: ['', Validators.required],
			batch_no: [''],
			qty: ['', Validators.required],
			
		})
	}
	// Get from controll date
	get addTransferFormData() {
		return this.stockTransferForm.get('addVaritionItemList')
	}
	
	// Function to add One More Field
	addTransferNewItem() {
		this.addVaritionItemList = this.stockTransferForm.get('addVaritionItemList') as FormArray;
		this.addVaritionItemList.push(this.addTransferItem());
	}

	// Function to Remove One Field
	removeTransferItem(i) {
		if (confirm("Do you really want to Delete this Item?")) {
			if (this.addVaritionItemList.length > 1) {
				this.addVaritionItemList.removeAt(i);
				
			}

		}
	}
		// Add Stock Transfer
	addStockVariation(stock_transfer){
		stock_transfer.submitted=false;
		this.stock_transfer_error="";
		this.stock_transfer_loading=true;
		this.stockTransferForm.value.petro_id=this.petro_id;
		 this.pipe = new DatePipe('en-US'); 
		
		this.stockTransferForm.value.date= this.pipe.transform(this.stockTransferData.date, 'yyyy-M-d');
		
			this.accountService.addStockVariation(this.stockTransferForm.value)
			.subscribe(alldata => {
				if (alldata.status == 200 || alldata.status == 201) {
				this.stockTransferForm.setControl('addVaritionItemList', this.formBuilder.array([this.addTransferItem()]));
				this.stock_transfer_loading=false;
				//this.getPageWiseTransferList(1);
				$('#stockTransferModal').modal('hide');
				this.pageWiseVariationList(1);
				}else{
					this.stock_transfer_error='Stock variation could not be added';
					this.stock_transfer_loading=false;
				}
			});
		
	}
	
	pageWiseVariationList(pageno){
	   this.currentPage=pageno;
	   this.accountService.getStockVariationListData(pageno,this.petro_id)
		.subscribe(alldata =>{
			if(alldata.status==403)
			{
				 this.session.remove('authData');
					this.router.navigate(['']);
			}
			this.variationList=alldata.data,
			this.totalData=alldata.count
			 
	 	});
   	}
	// Item list Auto Filter
	/*---------------------------------------------Auto Complete----------------------------------*/
	public filterItemList(value: any): void {
		this.commonsServices.getItemByNameOrType(value, this.petro_id,0)
		.subscribe(alldata => {
			this.itemAutoSugestList = Object.entries(alldata.data).map(([value, label]) => ({
				value,
				label
			}));
		});
	}
}
