import { Component, OnInit } from '@angular/core';
import { PurchaseService } from '../../admin-services/purchase.service';
import { CommonsService } from '../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder,FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../const_default';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
declare var $: any;

@Component({
  selector: 'app-credit-note-list',
  templateUrl: './credit-note-list.component.html',
  styleUrls: ['./credit-note-list.component.css']
})
export class CreditNoteListComponent implements OnInit {

  constructor(private purchaseService: PurchaseService, private commonsServices:CommonsService, private session:SessionStorageService, private formBuilder: FormBuilder ) { }
	
	
 options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
	//	minDate: new Date(Date.now()), // Minimal selectable date
	  maxDate: new Date(Date.now()),  // Maximal selectable date
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select Expiry Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};
	
	authData:any={};
	addCreditNoteData:any={};	
	searchCreditNoteData:any={};	
	sundryCreditorListData=[];	
	creditNoteDataForView=[];	
	creditNoteList=[];	
	invoiceListByRow=[];	

	error_message:string;	
	success_message:string;	
	qty_error:boolean;
	petro_id: number;
	crnCurrentPage: number;
	crnTotalCount: number;
	viewStatus: number;
	
	addCreditNoteForm:FormGroup;
	addItemList:FormArray;
	
	
	searchForm=new FormGroup({
		vendor_id_data : new FormControl(' '),
		date : new FormControl(' '),
	});	


	qty: number;
	sale_type: number;
	rate: number;
	net_amt: number;
	dis_per: number;
	dis_amt: number;
	total_amt: number;
	item_id: number;
	batch_no: string;
	gst_type: number;
   
   
	cgst_per: number;
	sgst_per: number;
	igst_per: number;
   
	cgst_amt: number;
	sgst_amt: number;
	igst_amt: number;
   
	total_net_amt: number;
	total_sgst_amt: number;
	total_cgst_amt: number;
	total_igst_amt: number;
	total_dis_amt: number;
	total_gt_amt: number;
	
	
	// Autocomplete

	itemAutoSugestList: any[] = [];
	// End Autocomplete

  ngOnInit() {
	  
	  this.searchCreditNoteData.date=new Date(Date.now());
	  this.addCreditNoteData.date=new Date(Date.now());
	  this.authData = this.session.get('authData');
	  this.petro_id = this.authData.petro_id;
	  
	  this.commonsServices.getLedgerListByGroup(constDefault.SUNDRY_CREDITOR_GROUP,this.petro_id).subscribe(creditorList=>{
				if(creditorList.data)
				{
				this.sundryCreditorListData=Object.entries(creditorList.data).map(([value, label]) => ({value,label}));
				}
		});
		
		this.addCreditNoteForm= this.formBuilder.group({
			vendor_id : new FormControl('',Validators.required),
			date : new FormControl('',Validators.required),
			shift : new FormControl('',Validators.required),
			gst_type: new FormControl('', Validators.required),
			state_id: new FormControl('', Validators.required),
			total_net_amt: new FormControl(' ', Validators.required),
			total_sgst_amt: new FormControl(' 0'),
			total_cgst_amt: new FormControl(' 0'),
			total_igst_amt: new FormControl(' '),
			total_dis_amt: new FormControl('0 '),
			vehicle_no: new FormControl(' 0'),
			total_gt_amt: new FormControl('0 ', Validators.required),
			addItemList: this.formBuilder.array([this.addCreditNoteItem()])
		});
		
		this.getPageWiseCreditNoteList(1);
		
		
  }
  
  get itemListArray() {return this.addCreditNoteForm.get('addItemList')}
	
	
  addCreditNoteItem(): FormGroup {
	 return this.formBuilder.group({
	  item_id: ['', Validators.required],
	  //item_name: ['', Validators.required],
	  invoice_no: [''],
	  qty: ['', Validators.required],
	  rate: ['', Validators.required],
	  net_amt: ['', Validators.required],
	  gst_rate: ['0'],
	  cgst_per: ['0'],
	  sgst_per: ['0'],
	  igst_per: ['0'],
	  cgst_amt: ['0'],
	  sgst_amt: ['0'],
	  igst_amt: ['0'],
	  dis: [''],
	  dis_amt: [''],
	  total_amt: ['', Validators.required],
	  bill_qty: [''],

	 })
	}
	// Function to add One More Field
	addPurchaseNewItem() {
	 this.addItemList = this.addCreditNoteForm.get('addItemList') as FormArray;
	 this.addItemList.push(this.addCreditNoteItem());
	}
   
	// Function to Remove One Field
	removePurchaseItem(i) {
	 if (confirm("Do you really want to Delete this Item?")) {
	  if (this.addItemList.length > 1) {
	   this.addItemList.removeAt(i);
	   this.calculateTotal();
	  }
   
	 }
	}
	
	// Add Credit Note
	addCreditNote() {
	
	 this.addCreditNoteForm.value.petro_id = this.petro_id;
	 //console.log(this.addSaleForm.value);
	 this.purchaseService.addCreditNote(this.addCreditNoteForm.value)
	  .subscribe(addData => {
	   if (addData.status == 200 || addData.status == 201) {
		$('#addCreditNoteModal').modal('hide');
		//this.getPageWisePurchaseList(1);
	   }
	  });   
	}
	
	//Get Purchase List
	getPageWiseCreditNoteList(page: number) {
		
	 this.crnCurrentPage = page;
	 this.purchaseService.getCreditNoteList(page, this.petro_id,this.searchCreditNoteData)
	  .subscribe(crnListData => {
	   this.creditNoteList = crnListData.data,
	   this.crnTotalCount = crnListData.count
	  });
	}
	
	searchCreditNote()
  {
	  if(this.searchCreditNoteData.vendor_id_data)
		{
			this.searchCreditNoteData.vendor_id=this.searchCreditNoteData.vendor_id_data.value;
		}
		this.searchCreditNoteData.date=this.searchCreditNoteData.date;
	 	this.getPageWiseCreditNoteList(1);
  }
  
  resetSearchCn(){
	  this.searchCreditNoteData={};
		this.getPageWiseCreditNoteList(1);
  }
  /*
  Credit Note View
  04.02.2019
  Amit Sahu
  */
  viewCreditNote(crn_id: number) {
   
	 this.purchaseService.getCreditNoteDataById(crn_id)
	  .subscribe(purchaseData => {
	   this.creditNoteDataForView = purchaseData.data;

		this.viewStatus = 1;

	  });
	 $('#viewCreditNoteModal').modal('show');
	}
  	/*
  Delete Credit Note 
  Amit Sahu
  04.02.18
*/
  deleteCreditNote(id,index)
  {
		if(confirm("Are you sure to delete this Purchase ")){ 

			this.purchaseService.deleteCreditNote(id).subscribe(data=>{
				if(data.status==200)
				{
				this.creditNoteList.splice(index,1);
				this.success_message="Credit Note deleted sucessfully";		
				
				}else{
					this.error_message="Credit Note could not be deleted.";
				}
			});
		}
  }
	onChangeCustomer() {
	 //console.log(this.addSaleData);
	 // alert(this.addSaleData.customer.value);
	 if(this.addCreditNoteData.vendor_id)
	 {
	 this.commonsServices.getCustomerDataById(this.addCreditNoteData.vendor_id.value).subscribe(data => {
	  this.addCreditNoteData.state_id = data.data.PartyDetail.state_id;
	  this.gstTypeFind(data.data.PartyDetail.state_id);
	 });
	 }
	}
	// Find GST Type 
	gstTypeFind(state_id) {
	 if (this.authData.state_id == state_id) {
	  this.gst_type = constDefault.GST_TYPE_CGST;
	 } else {
	  this.gst_type = constDefault.GST_TYPE_IGST;
	 }

	 this.addCreditNoteData.gst_type = this.gst_type;
	 //this.calculateTotal();
	}
	/* -------------------------------------------------Auto Completed Item List------------------------------*/
	public filterItemList(value: any): void {
		this.commonsServices.getItemByNameOrType(value, this.petro_id, constDefault.ITEM_TYPE_OTHER)
		.subscribe(alldata => {
			this.itemAutoSugestList = Object.entries(alldata.data).map(([value, label]) => ({
				value,
				label
			}));
		});
	}
	
	 onselectItemOnSale(event,un)
	 {								
		 if(event)	
		{
			if(this.addCreditNoteData.vendor_id)
			{
	   this.purchaseService.getInvoiceListByCustomerAndItem(event.value,this.petro_id,this.addCreditNoteData.vendor_id.value)
		   .subscribe(invoiceData => {
			   if(invoiceData.status==200)
			   {
				   this.invoiceListByRow[un]=invoiceData.data;
				    this.addItemList = this.addCreditNoteForm.get('addItemList') as FormArray;
	
			   }else{
				   this.clearerow(un);
				   this.invoiceListByRow[un]=[];
				    this.addItemList.controls[un].get('cgst_per').setValue('');
			   }
			//console.log(itemData);
				//		this.onselectItemOnSaleCalc(itemData.data, un);
			//this.getNozzleList(nozzlelistdata.data,nozzlelistdata.status);
		   });
			}else{
				alert('Please select a vendor.')
			}
		}else{
			this.clearerow(un);
			 this.invoiceListByRow[un]=[];
		// this.calculateTotal();
		}
		
	 
	 }
	 
	 onselectInvoiceOnSale(event,un)
	 {		
        //console.log(event.target.value);	 																																			
		 if(event)	
		{
			
	   this.purchaseService.getPurchaseHistoryByPurchaseDetailId(event.target.value)
		   .subscribe(itemData => {
			   if(itemData.status==200)
			   {
				   console.log(itemData);
				   //alert(itemData.data.PurchasesDetail.sp);
				this.addItemList = this.addCreditNoteForm.get('addItemList') as FormArray;

				this.addItemList.controls[un].get('rate').setValue(itemData.data.PurchasesDetail.sp);
				this.addItemList.controls[un].get('cgst_per').setValue(itemData.data.PurchasesDetail.cgst_per);
				this.addItemList.controls[un].get('sgst_per').setValue(itemData.data.PurchasesDetail.sgst_per);
				this.addItemList.controls[un].get('igst_per').setValue(itemData.data.PurchasesDetail.igst_per);
				this.addItemList.controls[un].get('cgst_amt').setValue(itemData.data.PurchasesDetail.cgst_amt);
				this.addItemList.controls[un].get('sgst_amt').setValue(itemData.data.PurchasesDetail.sgst_amt);
				this.addItemList.controls[un].get('igst_amt').setValue(itemData.data.PurchasesDetail.igst_amt);
				this.addItemList.controls[un].get('gst_rate').setValue(itemData.data.PurchasesDetail.gst_slab);
				this.addItemList.controls[un].get('dis').setValue(itemData.data.PurchasesDetail.discount_per);
				this.addItemList.controls[un].get('bill_qty').setValue(itemData.data.PurchasesDetail.quantity);

				//this.addItemList.controls[un].get('gst_rate').setValue(itemData.GstMaster.gst_percentage);
			//	this.addItemList.controls[un].get('cgst_per').setValue(itemData.GstMaster.cgst);
				//this.addItemList.controls[un].get('sgst_per').setValue(itemData.GstMaster.sgst);
			//	this.addItemList.controls[un].get('igst_per').setValue(itemData.GstMaster.igst);
			

	
			   }else{
				   this.clearerow(un);
				   this.invoiceListByRow[un]=[];
			   }
			//console.log(itemData);
				//		this.onselectItemOnSaleCalc(itemData.data, un);
			//this.getNozzleList(nozzlelistdata.data,nozzlelistdata.status);
		   });
		
		}else{
			this.clearerow(un);
			  this.invoiceListByRow[un]=[];
		 
		}
		this.calculateTotal();
		
	 
	 }
	 
	 clearerow(un){
		 this.addItemList = this.addCreditNoteForm.get('addItemList') as FormArray;
		 //this.addPurchaseItemList.controls[un].get('item_id').setValue(itemData.Item.id);
		 this.addItemList.controls[un].get('rate').setValue('');
		 this.addItemList.controls[un].get('gst_rate').setValue('');
		 this.addItemList.controls[un].get('cgst_per').setValue('');
		 this.addItemList.controls[un].get('sgst_per').setValue('');
		 this.addItemList.controls[un].get('igst_per').setValue('');
		 this.addItemList.controls[un].get('net_amt').setValue('');
		 this.addItemList.controls[un].get('total_amt').setValue('');
		 this.addItemList.controls[un].get('invoice_no').setValue('');
	 }
	

	rowWiseTotalSaleItem(row) {
	 this.addItemList = this.addCreditNoteForm.get('addItemList') as FormArray;
	 //this.addItemList.controls[un].get('item_id').setValue(itemData.Item.id);
	 this.item_id = this.addItemList.value[row]['item_id'].value;
	 if (this.item_id) {
	  this.qty = this.addItemList.value[row]['qty'];
	  this.rate = this.addItemList.value[row]['rate'];
	  this.dis_per = this.addItemList.value[row]['dis'];
   
	  this.net_amt = this.qty * this.rate;
	  this.dis_amt = (this.net_amt / 100) * this.dis_per;
	  this.total_amt = this.net_amt - this.dis_amt;
	  this.addItemList.controls[row].get('dis_amt').setValue(this.dis_amt);
	  this.addItemList.controls[row].get('net_amt').setValue(this.net_amt);
	  this.addItemList.controls[row].get('total_amt').setValue(this.total_amt);
   
	  this.cgst_per = this.addItemList.value[row]['cgst_per'];
	  this.sgst_per = this.addItemList.value[row]['sgst_per'];
	  this.igst_per = this.addItemList.value[row]['igst_per'];
   
	  this.cgst_amt = (this.total_amt / 100) * this.cgst_per;
	  this.sgst_amt = (this.total_amt / 100) * this.sgst_per;
	  this.igst_amt = (this.total_amt / 100) * this.igst_per;
   
	  this.addItemList.controls[row].get('cgst_amt').setValue(this.cgst_amt);
	  this.addItemList.controls[row].get('sgst_amt').setValue(this.sgst_amt);
	  this.addItemList.controls[row].get('igst_amt').setValue(this.igst_amt);
	 }
	 this.calculateTotal();
	}
	
	onQtyKeyup(event,row){
		this.addItemList = this.addCreditNoteForm.get('addItemList') as FormArray;
	 if(event.target.value>this.addItemList.value[row]['bill_qty'])
	 {
		 this.addItemList.controls[row].get('qty').setValue(this.addItemList.value[row]['bill_qty']);
	 }
		this.rowWiseTotalSaleItem(row) ;
	}
	
	
	calculateTotal() {
	this.addItemList = this.addCreditNoteForm.get('addItemList') as FormArray;
	 /* this.saleItemListData = Object.keys(this.addPurchaseItemList).map(function(itemIndex){
		   let item = this.addPurchaseItemList[itemIndex];
			   return item;
		   });*/
	 this.total_net_amt = 0;
	 this.total_sgst_amt = 0;
	 this.total_cgst_amt = 0;
	 this.total_igst_amt = 0;
	 this.total_dis_amt = 0;
	 this.total_gt_amt = 0;
   
	 this.addItemList.value.forEach((itemData, index) => {
	  //for (let a of this.commodityDropdownList ) {
	  this.total_net_amt = this.total_net_amt + this.addItemList.value[index]['net_amt'];
	  this.total_sgst_amt = this.total_sgst_amt + this.addItemList.value[index]['sgst_amt'];
	  this.total_cgst_amt = this.total_cgst_amt + this.addItemList.value[index]['cgst_amt'];
	  this.total_igst_amt = this.total_igst_amt + this.addItemList.value[index]['igst_amt'];
	  this.total_dis_amt = this.total_dis_amt + this.addItemList.value[index]['dis_amt'];
	  this.total_gt_amt = this.total_gt_amt + this.addItemList.value[index]['total_amt'];   
	 });
	 this.addCreditNoteData.total_net_amt = this.total_net_amt;
	 this.addCreditNoteData.total_dis_amt = this.total_dis_amt;
	 if (this.gst_type == constDefault.GST_TYPE_IGST) {
	  this.total_sgst_amt = 0;
	  this.total_cgst_amt = 0;
	 } else {
	  this.total_igst_amt = 0;
	 }
	 this.addCreditNoteData.total_igst_amt = this.total_igst_amt;
	 this.addCreditNoteData.total_sgst_amt = this.total_sgst_amt;
	 this.addCreditNoteData.total_cgst_amt = this.total_cgst_amt;
	 this.addCreditNoteData.total_gt_amt = Number(this.total_gt_amt) + Number(this.total_igst_amt) + Number(this.total_sgst_amt) + Number(this.total_cgst_amt);
	}
	
}
