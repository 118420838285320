import { Component, OnInit } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { CommonsService } from '../../admin-services/commons.service';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
import { Router} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-location-master',
  templateUrl: './location-master.component.html',
  styleUrls: ['./location-master.component.css']
})
export class LocationMasterComponent implements OnInit {

  constructor(private masterService: MasterServicesService, private router:Router,private session:SessionStorageService, private commonsService:CommonsService, private formBuilder: FormBuilder) { }
  authData:any={};
  petro_id:number;
  addLocationData : any={};
	editPetrolPump : any={};
  data : any[] = [];
  success_message:string;
	error_message:string;
	serachPetrolPump : any={};
	serachLocationData : any={};
	locationList:any[] = [];
	index:number;
	editLocationData:any={};
	model:string;
	serachLocation:any={};
	currentPage:number;
	totalCat:number;
	add_loading:boolean;
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
  succeAddLocData:{
		Location:{
			id:any,
			name:string
		}
	}
	addLocationForm:FormGroup;
  /*addLocationForm = new FormGroup({
    name : new FormControl(' ',Validators.required)
  })*/

  //editLocationForm:FormGroup;
  editLocationForm = new FormGroup({
		id : new FormControl(''),
    name : new FormControl(' ',Validators.required)
	})
	
	serachLocationForm= new FormGroup({
		name : new FormControl(' '),
	});

  ngOnInit() {
	  
	this.btnPrevSession = this.session.get('ButtonAuth');
	if(this.btnPrevSession)
	{
		let urlArr=this.router.url.split('/');
		this.link_path=urlArr[2];
		let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
		this.delete_btn=result.UserPrivilege.delete_auth;
		this.edit_btn=result.UserPrivilege.edit_auth;
		this.create_btn=result.UserPrivilege.create_auth;
	}
	  
    this.authData = this.session.get('authData');
		this.petro_id=this.authData.petro_id;
		
		this.addLocationForm = this.formBuilder.group({
    name : new FormControl(' ',Validators.required ,uniqueByModalOrNameValidation(this.commonsService,'Location',this.petro_id))
  })
		//alert();
    // this.masterService.getLocations(this.petro_id)
		// .subscribe(locationlistdata =>{this.masterService=locationlistdata.data});
		this.pageWiseData(1);
  }
  
  get name(){
	  return this.addLocationForm.get('name');
  }

  // Add Location
  addLocation(addLocation)
	{
		this.add_loading=true;
		addLocation.submitted = false;
		this.authData = this.session.get('authData');
	  this.addLocationData.petro_id=this.authData.petro_id;
		this.masterService.addLocationData(this.addLocationData).subscribe(data=>{
			//alert(data);
			if(data.status==200)
			{
				//console.log("Location Data ///////////"+data);
				this.success_message='Location has been created';
			$('#addLocationModal').modal('hide');

			// this.succeAddLocData={
			// 	Location:{
			// 		id:data.id,
			// 		name:data.name
			// 	}
			// }
			// this.locationList.unshift(this.succeAddLocData);
			this.pageWiseData(1);
			this.addLocationForm.reset(); 
			
		}else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.error_message='Location could not be created';
		}
		this.add_loading=false;
		});
	}

	// Get location data by id
	getLocationData(id,index){
		
			// this.editLocationForm=this.formBuilder.group({
			//  id : new FormControl(''),
			// name : new FormControl(' ',Validators.required)
			// });
	
			this.index=index;
			this.masterService.getEditLocationData(id).subscribe(data=>{

				this.editLocationData.id=data.id;	
				this.editLocationData.name=data.name;	
		
			});
			$('#editLocationModal').modal('show');
	}

	// Delete Loaction by id
	deleteLocationData(id,index)
	{
		if(confirm("Are you sure to delete this Location")) { 
			this.index=index;
			this.model='Location'; 
				//alert(id);
			this.masterService.deleteDataByModel(id,this.model).subscribe(data=>{
				//console.log(data);
				if(data.status==true)
				{
					this.locationList.splice(this.index,1);
					this.success_message="Location deleted sucessfully";	
				}else{
					this.error_message= "Location could not be deleted.";
				}
			});
		//this.editname=this.editCategoryData.name;	
		}
	}

	// Submit Edited Location
	editLocation(edit_form){
		//console.log(edit_form.submitted);
		edit_form.submitted=false;
		this.masterService.editLocationData(this.editLocationData).subscribe(data=>{
		//console.log(data.status);
			if(data.status==true)
			{
				//alert("success");
				this.success_message='Pump Data Edited Successfully!';
				$('#editLocationModal').modal('hide');
				$('#PP'+data.id).html(data.id);
				//console.log(data.data[0].id);
				this.succeAddLocData={
					Location:{
						id:data.id,
						name:data.name,
					},
				}
				this.locationList[this.index]=this.succeAddLocData;
				this.editLocationForm.reset(); 	
			}else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
				this.error_message='Petrol Pump cannot be Register!';
			}
		});
	}

	// Function to Search Petrol Pump list
	searchLocation(){
		this.pageWiseData(1);
	} 

	// Reset Search Data
	resetSearchLocation(){
		this.serachLocationData={};
		this.pageWiseData(1);
	} 

	// Pagination 
	pageWiseData(pageno){
		//alert('getLocations');
		this.currentPage=pageno;
		this.masterService.getLocations(pageno,this.petro_id,this.serachLocationData)
		.subscribe(alldata =>{
			if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.locationList=alldata.data,
			this.totalCat=alldata.count
			}
		});
		//console.log(this.locationList);
	} 
	selectedFile: File = null;
	getSelectedFile(event){
		this.selectedFile = <File>event.target.files[0];
	}

	upload_file(){
		//console.log(this.selectedFile);
		const fd = new FormData();
		fd.append('image',this.selectedFile,this.selectedFile.name);
		let shape = "Square";
		this.masterService.upload_file_data(shape)
		.subscribe(response=>
			console.log(response)
			)
	}

	
}
