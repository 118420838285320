import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

@Component({
  selector: 'app-super-admin-top-header',
  templateUrl: './super-admin-top-header.component.html',
  styleUrls: ['./super-admin-top-header.component.css']
})
export class SuperAdminTopHeaderComponent implements OnInit {
  authData:any={};
  constructor(private session:SessionStorageService) { }

  ngOnInit(): void {
    this.authData = this.session.get('authData');
  }

}
